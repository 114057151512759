/*============
  RELATED NEWS
  ============*/

  .related-news {

    .news__feed {
      max-width: $maxContentWidth;
      padding: 0 20px;
      margin: 0 auto;

      @media (min-width: $breakpoint--small) {
        padding: 0 55px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }

      li {
        margin-bottom: 30px;
      }

      a {
        @include font-size(20);
        display: block;
        font-family: $aleckBlack;
        color: inherit;
        margin-bottom: 10px;
        line-height: 1.25;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }

        .region--white &, .region--gray & {
          color: $color--link;
        }

        .region[class*="black"] & {
          color: $color--cta;
        }
      }

      p {
        font-family: $aleckMedium;
        margin-bottom: 0;
        line-height: 1.35;
      }
    }

  }
