/*==============
  STORY TEMPLATE
  ==============*/

  /* Story Header */

  .story__header {
    position: relative;

    .featured-image,
    .featured__image {
      background-color: $black;
      max-height: 483px;
      overflow: hidden;

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
    }

    &.story__header--light .featured-image,
    &.story__header--light .featured__image {
      background-color: $white;
    }
  }

  /* Story Preface */

  .story__preface > *:last-child {
    margin-bottom: 30px;
  }

  .story__preface:first-child + .story__wrap {
    padding-top: 40px;

    @media (min-width: $breakpoint--medium) {
      padding-top: 0;
    }
  }

  /* Search Widget */

  .widget--search {
    .component:first-child {
      margin-bottom: 0;
    }
  }

  /* Top Videos Widget */

  .widget--videos {

    .widget__items {
      @include clearfix;

      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        margin: 0 -10px;
      }

      .component {
        @media (min-width: $breakpoint--xSmall) and (max-width: $breakpoint--small - 1) {
          @include column(6);
          float: left;
          padding-right: 10px;
          padding-left: 10px;
        }
        @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
          @include column(4);
          float: left;
          padding-right: 10px;
          padding-left: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  /* Component Style Overrides */

  .story {

    .story__wrap .row__wrap {
      .col-12{
        margin-left:auto;
        margin-right:auto;
      }
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        display: block;
      }
    }

    /* Article */
    .article.col-12 {
      @media (min-width: $breakpoint--large) {
        padding-left: 0px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding-left: 0px;
      }
    }
    .article {
      padding-bottom: 20px;

      @media (min-width: $breakpoint--large) {
        padding-left: $gutter--small;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding-left: $gutter--large;
      }

      > .content__wrap {
        padding-bottom: 40px;
      }
      /* Article Banner Components */
      .banner .banner__content {
        padding-bottom: 0;
      }
      .banner .banner__cta {
        margin-top: 20px;
      }

      .text.story--pub-date p {
        margin-bottom: 0px;
      }
      .text.story-info {
        margin-top:-23px;
        @include font-size(16);
      }
    }
    .article.col-12{
      .story-title{
        .component{
          margin-bottom:0px;
        }
      }
    }
    /* Sidebar */

    .sidebar {
      padding-top: 20px;

      /* Sidebar Components */

      .sidebar__content {
        .component {
          margin-bottom: 40px;

          &.component--no-margin-bottom {
            margin-bottom: 20px;
          }

          &.component--border-bottom > div {
            @media (min-width: $breakpoint--large) {
              padding-bottom: 40px;
              margin-bottom: 50px;
            }
          }

          &.component--border-bottom > div:after {
            width: 60%;
            right: auto;
          }
        }
      }

      /* Sidebar Featured Item Components */

      .featured-item {
        overflow: inherit;
      }

      .item__content {
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 20px;
      }

      .component:last-child .item__content {
        margin-bottom: 0;
      }

      .item__media + .item__content {
        padding-top: 20px;
      }

      .item__media {
        max-height: none;
      }

      .item__preview {
        @media (min-width: $breakpoint--small) {
          display: block;
          max-height: 160px;
          overflow: hidden;
        }

        @media (min-width: $breakpoint--medium) {
          max-height: none;
        }
      }

      .widget--videos {
        .item__media {
          background-color: transparent;
        }

        [class^="item__video"] {
          @media (min-width: $breakpoint--medium) {
            min-height: 150px;
          }

          @media (min-width: $breakpoint--xLarge) {
            min-height: 165px;
          }
        }

        .item__content {
          padding-right: 0;

          @media (min-width: $breakpoint--large) {
            padding-top: 15px;
          }
        }
      }

      /* Sidebar Media Components */

      .media__caption {
        @include font-size(16);
      }

      /* Sidebar Text Components */

      .text--blockquote .text__wrap,
      .text blockquote {
        @include font-size(18);
        margin-bottom: 0;
      }
    }

    .article,
    .sidebar {

      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        max-width: none;
      }

      @media (min-width: $breakpoint--medium) {
        padding-top: 65px;
        padding-bottom: 65px;
      }

      .component > .text {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .story__preface .alert {
      @media (min-width: $breakpoint--small) {
        padding-left: $gutter--small;
        padding-right: $gutter--small;
      }

      @media (min-width: $breakpoint--large) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .story__preface .alert__message {
      @media (min-width: $breakpoint--large) {
        padding-left: calc(#{$gutter--large} - 10px);
        padding-right: calc(#{$gutter--large} - 10px);
      }
    }
  }
