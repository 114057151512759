/*=========
  VARIABLES
  =========*/

/*===========
  Breakpoints
  ===========*/

  $breakpoint--xxSmall: 480px;
  $breakpoint--xSmall: 640px; /* we switch from mobile to tablet here */
  $breakpoint--small: 768px; /* we switch from tablet to desktop here */
  $breakpoint--medium: 1024px;
  $breakpoint--large: 1220px;
  $breakpoint--xLarge: 1440px; /* max width of standard 15" laptop viewport */
  $breakpoint--xxLarge: 1600px;

/*============
  Global Sizes
  ============*/

  $maxPageWidth: 1600px;
  $maxContentWidth: 1220px;
  $maxRowWidth: $maxContentWidth;

  $mobileNavHgt: 72px;
  $desktopNavHgt: 105px;
  $micrositeDesktopNavHgt: 72px;
  $micrositeCollapsedGlobalNav: 50px;
  $micrositeMobileNavHgt: 60px;

  $navWidthMobile: 320px;
  $navWidthTablet: 400px;
  $breakpoint--desktopNav: $breakpoint--small;

  /* Component Margin/Paddings */

  $air--xSmall: 20px;
  $air--small: 40px;
  $air--large: 60px;
  $air--xLarge: 80px;
  $air--xxLarge: 100px;

  /* Section Margin/Paddings */
  $sectionSpacing: (
      xl: (40px, 100px, 150px),
      lg: (35px, 80px, 120px),
      sm: (25px, 30px, 50px)
  );

  $gutter--small: 55px;
  $gutter--large: 105px;

  /* Line Height */

  $lineHeight--xSmall: 1.35;
  $lineHeight--small: 1.45;
  $lineHeight--large: 1.6;

  $banner-lineHeight--small: 1.15;
  $banner-lineHeight--large: 1.5;

  /* Misc */

  $lightboxOpacity: .85;
  $highlightBar: 5px;

/*==================
  Responsive Columns
  ==================*/

  /*
   * The following column sizing map is used to determine how different sized columns will adjust their widths at different breakpoints.
   * For each column size (12, 9, 8, 6, 4, 3, 2), the subsequent responsive widths are listed in parentheses, going in order from mobile, to tablet, to small desktop, to full desktop.
   * ex: colSize:(mobileWidth, tabletWidth, smDesktopWidth, lgDesktopWidth), where the width values refer to the number of parts-per-12 that the column should occupy at each screen size.
   * Note that all column widths are 12-based, so a 12 represents a full-width appearance.
   * Since all column sizes should appear full-width at mobile viewport size, the first number in each sequence is 12.
   * The settings from this map get applied by the columnSizer loop statement found in the "Columns" section of the _layout partial (see the styles under ".col").
   */

  $columnSizer: (
    12:(12, 12, 12, 12), // full-width column; 12/12 = 1 (100%)
    9: (12, 6, 9, 9), // three-fourths width column; 9/12 = 0.75 (75%)
    8: (12, 8, 8, 8), // two-thirds width column; 8/12 = 0.66 (66%)
    6: (12, 6, 6, 6), // half-width column (6/12 = 0.5 or 50%)
    4: (12, 4, 4, 4), // one-third width column (4/12 = 0.33 or 33%)
    3: (12, 6, 3, 3), // one-fourth width column (3/12 = 0.25 or 25%)
    2: (12, 4, 2.4, 2) // one-fifth width column (2.4/12 = 0.2 or 20%)
  );

/*=============
  Color Palette
  =============*/

  /* Global Colors */

  $attYellow: #FFB81C;
  $attOrange: #EA7400;
  $attLgtBlue: #71C5E8;
  $attBlue: #009FDB;
  $attDrkBlue: #0568AE;
  $attCobalt: #0057b8;
  $white: #FFF;
  $lgtGray: #F2F2F2;
  $regGray: #C9C9C9;
  $medGray: #959595;
  $drkGray: #5A5A5A;
  $functionalBlack: #191919;
  $black: #000;

  /* 2019 New Brand Secondary Colors */
  
  $cobalt: #0057B8;
  $purple: #AF29BB;
  $berry: #FF585D;
  $tangerine: #FFB000;
  $lime: #91DC00;
  $mint: #49EEDC;

  /* Specific Use Colors */

  $color--cta: $attBlue; /* call-to-action elements (links, buttons) */
  $color--cta-hover: darken($attBlue, 4%); /* call-to-action hover state */
  $color--cta-contrast: lighten($attBlue, 7%); /* transparent header link highlight */
  $color--link: $attDrkBlue; /* standard links inside of text blocks */
  $color--copy: $functionalBlack; /* general page body copy */
  $color--search-border: darken($lgtGray, 3%);
  $color--logo-default: "white";

  /* Color Maps */

  $backgroundColors: (
    //Variable 1 is background, Variable 2 is text color
    //Legacy
    blue: ($attBlue, $functionalBlack),
    dark-blue: ($attDrkBlue, $white),
    near-black: ($functionalBlack, $white),
    
    //2019
    white: ($white, $functionalBlack),
    black: ($black, $white),
    gray: ($lgtGray, $functionalBlack),
    blue--wh-text: ($attBlue, $white),
    blue--bl-text: ($attBlue, $functionalBlack),
    //Communications Secondary Colors
    comms-cobalt: ($cobalt, $white),
    comms-purple: ($purple, $white),
    comms-berry: ($berry, $functionalBlack),
    comms-tangerine: ($tangerine, $functionalBlack),
    comms-lime: ($lime, $functionalBlack),
    comms-mint: ($mint, $functionalBlack), 
    //Communications Secondary Colors - Gradients 
    //Var 1 is left background, Var 2 is text color Var 3 is right background
    blue-gradient--wh-text: ($attCobalt, $white, $attBlue),
    blue-gradient--bl-text: ($attCobalt, $functionalBlack, $attBlue),
    comms-cobalt-purple:($cobalt, $white, $purple),
    comms-berry-tangerine:($berry, $functionalBlack, $tangerine),
    comms-lime-mint:($lime, $functionalBlack, $mint)
  );

  /* Category Themes - theme names below are based on theme-- category classes that get applied to the body element by AEM */

  $themeColors: (
    // Communications: ("white", $white),
    // company: ("white", $white)
  );

  /*

  Communications: ("blue", $attBlue),
  company: ("silver", "silver")

  */

/*=============
  Font Families
  =============*/

  /* Aleck Sans - Base Font Variants */

  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans */
  	src: url('_fonts/Aleck/ATTAleckSans_W_Rg.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_Rg.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Rg.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Rg.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Rg.svg#webfont') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans Italic */
  	src: url('_fonts/Aleck/ATTAleckSans_W_It.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_It.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_It.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_It.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_It.svg#webfont') format('svg');
    font-weight: 400;
    font-style: italic;
  }
  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans Light */
  	src: url('_fonts/Aleck/ATTAleckSans_W_Lt.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_Lt.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Lt.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Lt.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Lt.svg#webfont') format('svg');
    font-weight: 300;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans Light Italic */
  	src: url('_fonts/Aleck/ATTAleckSans_W_LtIt.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_LtIt.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_LtIt.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_LtIt.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_LtIt.svg#webfont') format('svg');
    font-weight: 300;
    font-style: italic;
  }
  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans Bold */
  	src: url('_fonts/Aleck/ATTAleckSans_W_Bd.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_Bd.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Bd.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Bd.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Bd.svg#webfont') format('svg');
    font-weight: 700;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Sans'; /* Aleck Sans Bold Italic */
  	src: url('_fonts/Aleck/ATTAleckSans_W_BdIt.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_BdIt.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BdIt.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BdIt.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BdIt.svg#webfont') format('svg');
    font-weight: 700;
    font-style: italic;
  }

  /* Aleck Sans - Additional Font Variants */

  @font-face{
  	font-family: 'Aleck Sans Medium'; /* Aleck Sans Medium */
  	src: url('_fonts/Aleck/ATTAleckSans_W_Md.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_Md.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Md.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Md.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Md.svg#webfont') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Sans Medium'; /* Aleck Sans Medium Italic */
  	src: url('_fonts/Aleck/ATTAleckSans_W_MdIt.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_MdIt.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_MdIt.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_MdIt.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_MdIt.svg#webfont') format('svg');
    font-weight: 400;
    font-style: italic;
  }
  @font-face{
  	font-family: 'Aleck Sans Black'; /* Aleck Sans Black */
  	src: url('_fonts/Aleck/ATTAleckSans_W_Blk.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_Blk.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Blk.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Blk.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_Blk.svg#webfont') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Sans Black'; /* Aleck Sans Black Italic */
  	src: url('_fonts/Aleck/ATTAleckSans_W_BlkIt.eot');
  	src: url('_fonts/Aleck/ATTAleckSans_W_BlkIt.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BlkIt.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BlkIt.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSans_W_BlkIt.svg#webfont') format('svg');
    font-weight: 400;
    font-style: italic;
  }

  /* Aleck Slab */

  @font-face{
  	font-family: 'Aleck Slab'; /* Aleck Slab */
  	src: url('_fonts/Aleck/ATTAleckSlab_W_Rg.eot');
  	src: url('_fonts/Aleck/ATTAleckSlab_W_Rg.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_Rg.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_Rg.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_Rg.svg#webfont') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  @font-face{
  	font-family: 'Aleck Slab'; /* Aleck Slab Italic */
  	src: url('_fonts/Aleck/ATTAleckSlab_W_It.eot');
  	src: url('_fonts/Aleck/ATTAleckSlab_W_It.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_It.woff') format('woff'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_It.ttf') format('truetype'),
  	     url('_fonts/Aleck/ATTAleckSlab_W_It.svg#webfont') format('svg');
    font-weight: 400;
    font-style: italic;
  }


  /* AT&T Icon Font */

  @font-face{
  	font-family: 'ATT_Icons'; /* AT&T Icon Font */
  	src: url('_fonts/ATT_Icons/att-icons.eot');
  	src: url('_fonts/ATT_Icons/att-icons.eot?#iefix') format('embedded-opentype'),
  	     url('_fonts/ATT_Icons/att-icons.woff') format('woff'),
  	     url('_fonts/ATT_Icons/att-icons.ttf') format('truetype'),
  	     url('_fonts/ATT_Icons/att-icons.svg#webfont') format('svg');
    font-weight: 400;
    font-style: normal;
  }

/*==============
  Font Variables
  ==============*/

  $aleck: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  $aleckMedium: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  $aleckBlack: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  $aleckSlab: "Aleck Slab", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  $arial: "Arial", "Helvetica", sans-serif;
  $att-icons: "ATT_Icons","Arial", "Helvetica", sans-serif;

  /* AT&T Icon Font Maps */

  $attIcons: (
    alert: "\e900",
    att-logo: "\e901",
    att-logo-text: "\e90e",
    comment: "\e902",
    download: "\e903",
    email: "\e904",
    facebook: "\e905",
    faq: "\e906",
    file: "\e907",
    info: "\e908",
    info2: "\e909",
    instagram: "\e90a",
    link: "\e90b",
    linkedin: "\e90c",
    notification: "\e90f",
    minus: "\e90d",
    play: "\e910",
    plus: "\e911",
    quote: "\e912",
    search: "\e913",
    twitter: "\e914",
    youtube: "\e915",
    twitter-x: "\e916",
    arrow-down: "\e917",
    arrow-left: "\e918",
    arrow-right: "\e919",
    pause: "\e920",
    new-play: "\e921"
  );

/*================
  Nav Highlighting
  ================*/

  /*
   * The following map sets the nav highlighting default for different body classes
   * ex: bodyClassName: "nav-item-class-name"
   * Note that if a nav item with class of "nav-company" exists and you're on a page with a body class of "nav-company", that will highlight as well
   */

  $navHighlights: (
    story: "nav-news",
    blog: "nav-news",
    all-posts: "nav-news",
    media-gallery: "nav-news",
    media-kit: "nav-news",
    media-kit-landing: "nav-news"
  );
