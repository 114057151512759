/*=====
  EXPAND-COLLAPSE
  =====*/

.expand-collapse { 
  width:100%;
  margin:0 auto 20px;
  @media (min-width: $breakpoint--small) {
    margin-bottom:40px;
  }
  .item {
    &:last-of-type .item__label a {
      border-bottom: 1px solid $regGray;

      .region--blue-gradient--bl-text &,
      .region--blue--bl-text & {
        border-color: $black;
      }

      .region[class*="cobalt"] &,
      .region[class*="purple"] &,
      .region[class*="wh-text"] &,
      .region--dark-blue &,
      .region[class*="black"] & {
        border-color: $lgtGray;
      }
    }
    &__label {
      @include font-size(18);
      max-width: $maxContentWidth;
      width:100%;
      margin: 0 auto;
      padding:0 20px;
      @media (min-width: $breakpoint--small) {
        @include font-size(21);
        padding: 0 $gutter--small;
      }
      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
      .row & {
        padding:0;
      }
      a {
        position:relative;
        display:block;
        width:100%;
        border-top: 1px solid $regGray;
        padding: 10px 25px 10px 0;
        line-height:1.5;
        text-decoration: none;
        color: $black;

        .region--blue-gradient--bl-text &,
        .region--blue--bl-text & {
          border-color: $black;
        }

        .region--white &, .region--gray & {
          color: $color--copy;
        }
        .region[class*="cobalt"] &,
      .region[class*="purple"] &,
      .region[class*="wh-text"] &,
      .region--dark-blue &,
      .region[class*="black"] & {
          color: $lgtGray;
          border-color: $lgtGray;
        }
      }
      a:after {
        @include font-size(26);
        @include transitionAll(300ms);
        content:"\002B";
        position:absolute;
        top:50%;
        right:5px;
        transform: translateY(-50%);
        .expand-collapse--blue & {
          .region--white &, .region--gray & {
            border-color: $color--link;
          }
          .region[class*="black"] & {
            border-color: $attBlue;
          }
        }
      } 
    }
    &__wrap {
      visibility: hidden;
      opacity:0;
      height:0;
      overflow:hidden;
      transform-origin: top center;
      transition: opacity 300ms ease;
    }
    &__content {
      background-color: transparent;
      padding-left: 0px;

      .region--white .row--no-gutters &,
      .region--white .row--flush-horizontal &,
      .region--black &,
      .region--gray &,
      .region--blue--bl-text &,
      .region--blue--wh-text &,
      .region--comms-cobalt &,
      .region--blue-gradient--wh-text &,
      .region--blue-gradient--bl-text &
       {
        padding-left: 20px;
      }
    }
    &--active {
      .item__label a {
        border-bottom: 0 !important;
        &:after {
          @include font-size(28);
          @include transitionAll(300ms);
          transform: translateY(-50%) rotate(45deg);
        }
      }
      .item__wrap {
        visibility: visible;
        height:100%;
        opacity:1;
        transform-origin: top center;
        transition: opacity 300ms ease; 
      }
    }
    &__close {
      margin: 20px 20px 40px;
      .cta--link:after {
        content:'X';
        font-weight:bold;
        margin-left:4px;
      }
      @media (min-width: $breakpoint--small) {
        display:none;
      }
    } 
  } 
  &.expand-collapse--up-down, &.expand-collapse--view-hide {
    .item__label a:after {
      content:"";
      width:10px;
      height:10px;
      border-top:3px solid $color--copy;
      border-left:3px solid $color--copy;
      border-radius:3px 3px 3px 0;
      transform:translateY(-50%) rotate(225deg);
      @each $name, $color in $backgroundColors {
        .region--#{$name} & {
          @if (nth($color, 2) == $white) {
            border-color: $white;
          }
        }
      }
    }
  }
  &.expand-collapse--up-down {
    .item--active {
      .item__label a {
        border-bottom: 1px solid $regGray !important;
        &:after {
          transform: translateY(-50%) rotate(45deg);
        }
      }
      .item__wrap {
        margin-top:10px;
      }
    }
  }
  &.expand-collapse--view-hide {
    .item {
      display:flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 60px;
      &:last-of-type .item__label a {
        border-bottom: 0;
      }
      &__label {
        order:2;
        text-align:center;
        font-family: "Aleck Sans Medium", "Aleck Sans", Helvetica, Arial, sans-serif;
        a {
          display:block;
          border:none;
          padding:0;
        }
        a:after {
          position:relative;
          display: block;
          margin: 8px auto;
          transform: rotate(225deg);
          @media (min-width: $breakpoint--small) {
            display:inline-block;
            margin: 0 0 4px 20px;
          }
        }
      }
      &--active {
        margin-bottom: 60px;
        .item__label a:after {
          transform: rotate(45deg);
          @media (min-width:$breakpoint--small) {
            margin: 0 0 0 20px;
          }
        }
        .item__wrap {
          border: none;
        }
      }
    }
    &.expand-collapse--multi {
      .item--active {
        .item__label a {
          padding-bottom: 30px;
          border-bottom: 1px solid $regGray !important;
        }
       .item__wrap:before {
          content:"";
          display:block;
          width:auto;
          margin: 0 20px 30px;
          border-top: 1px solid $regGray;
          @media(min-width: $breakpoint--small) {
            margin: 0 55px 30px;
          }
          @media (min-width: $breakpoint--large) {
            max-width: 1110px;
            margin-left: auto;
            margin-right: auto;
          }
          @media (min-width: $breakpoint--xLarge) {
            max-width: 1220px;
          }
        }
      }
    }
  }
  &.expand-collapse--blue {
    .item__label a {
      color: $attDrkBlue;
      .region--white &, .region--gray & {
        color: $color--link;
      }
      .region[class*="black"] & {
        color: $attBlue;
      }
    }
    &.expand-collapse--up-down, &.expand-collapse--view-hide {
      .item__label a:after {
        .region--white &, .region--gray & {
          border-color: $color--link;
        }
        .region[class*="black"] & {
          border-color: $attBlue;
        }
      }
    }
  }
}