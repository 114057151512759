@charset "UTF-8";
/**
 * AT&T About Us for AEM
 * by FleishmanHillard, c. 2018
 *
 * NOTE: ATTN DEVS!
 * Please use this stylesheet for importing SASS partials only. Do not author any SASS or CSS directly in this file.
 * Also, please first refer to the _abstracts partials to review the existing global style settings and utilities before creating new variables, mixins, etc.
 *
 * For style guidelines and coding conventions for this project,
 * please consult the README.md in the root of the project.
 */
/*====
  MAIN
  ====*/
/* Import core component styles statically */
@import url("core_components.css");
/*=========
  Abstracts
  =========*/
/*=========
  VARIABLES
  =========*/
/*===========
  Breakpoints
  ===========*/
/* we switch from mobile to tablet here */
/* we switch from tablet to desktop here */
/* max width of standard 15" laptop viewport */
/*============
  Global Sizes
  ============*/
/* Component Margin/Paddings */
/* Section Margin/Paddings */
/* Line Height */
/* Misc */
/*==================
  Responsive Columns
  ==================*/
/*
   * The following column sizing map is used to determine how different sized columns will adjust their widths at different breakpoints.
   * For each column size (12, 9, 8, 6, 4, 3, 2), the subsequent responsive widths are listed in parentheses, going in order from mobile, to tablet, to small desktop, to full desktop.
   * ex: colSize:(mobileWidth, tabletWidth, smDesktopWidth, lgDesktopWidth), where the width values refer to the number of parts-per-12 that the column should occupy at each screen size.
   * Note that all column widths are 12-based, so a 12 represents a full-width appearance.
   * Since all column sizes should appear full-width at mobile viewport size, the first number in each sequence is 12.
   * The settings from this map get applied by the columnSizer loop statement found in the "Columns" section of the _layout partial (see the styles under ".col").
   */
/*=============
  Color Palette
  =============*/
/* Global Colors */
/* 2019 New Brand Secondary Colors */
/* Specific Use Colors */
/* call-to-action elements (links, buttons) */
/* call-to-action hover state */
/* transparent header link highlight */
/* standard links inside of text blocks */
/* general page body copy */
/* Color Maps */
/* Category Themes - theme names below are based on theme-- category classes that get applied to the body element by AEM */
/*

  Communications: ("blue", $attBlue),
  company: ("silver", "silver")

  */
/*=============
  Font Families
  =============*/
/* Aleck Sans - Base Font Variants */
@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans */
  src: url("_fonts/Aleck/ATTAleckSans_W_Rg.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_Rg.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_Rg.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_Rg.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_Rg.svg#webfont") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans Italic */
  src: url("_fonts/Aleck/ATTAleckSans_W_It.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_It.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_It.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_It.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_It.svg#webfont") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans Light */
  src: url("_fonts/Aleck/ATTAleckSans_W_Lt.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_Lt.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_Lt.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_Lt.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_Lt.svg#webfont") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans Light Italic */
  src: url("_fonts/Aleck/ATTAleckSans_W_LtIt.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_LtIt.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_LtIt.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_LtIt.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_LtIt.svg#webfont") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans Bold */
  src: url("_fonts/Aleck/ATTAleckSans_W_Bd.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_Bd.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_Bd.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_Bd.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_Bd.svg#webfont") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Sans';
  /* Aleck Sans Bold Italic */
  src: url("_fonts/Aleck/ATTAleckSans_W_BdIt.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_BdIt.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_BdIt.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_BdIt.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_BdIt.svg#webfont") format("svg");
  font-weight: 700;
  font-style: italic; }

/* Aleck Sans - Additional Font Variants */
@font-face {
  font-family: 'Aleck Sans Medium';
  /* Aleck Sans Medium */
  src: url("_fonts/Aleck/ATTAleckSans_W_Md.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_Md.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_Md.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_Md.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_Md.svg#webfont") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Sans Medium';
  /* Aleck Sans Medium Italic */
  src: url("_fonts/Aleck/ATTAleckSans_W_MdIt.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_MdIt.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_MdIt.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_MdIt.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_MdIt.svg#webfont") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Aleck Sans Black';
  /* Aleck Sans Black */
  src: url("_fonts/Aleck/ATTAleckSans_W_Blk.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_Blk.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_Blk.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_Blk.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_Blk.svg#webfont") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Sans Black';
  /* Aleck Sans Black Italic */
  src: url("_fonts/Aleck/ATTAleckSans_W_BlkIt.eot");
  src: url("_fonts/Aleck/ATTAleckSans_W_BlkIt.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSans_W_BlkIt.woff") format("woff"), url("_fonts/Aleck/ATTAleckSans_W_BlkIt.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSans_W_BlkIt.svg#webfont") format("svg");
  font-weight: 400;
  font-style: italic; }

/* Aleck Slab */
@font-face {
  font-family: 'Aleck Slab';
  /* Aleck Slab */
  src: url("_fonts/Aleck/ATTAleckSlab_W_Rg.eot");
  src: url("_fonts/Aleck/ATTAleckSlab_W_Rg.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSlab_W_Rg.woff") format("woff"), url("_fonts/Aleck/ATTAleckSlab_W_Rg.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSlab_W_Rg.svg#webfont") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Aleck Slab';
  /* Aleck Slab Italic */
  src: url("_fonts/Aleck/ATTAleckSlab_W_It.eot");
  src: url("_fonts/Aleck/ATTAleckSlab_W_It.eot?#iefix") format("embedded-opentype"), url("_fonts/Aleck/ATTAleckSlab_W_It.woff") format("woff"), url("_fonts/Aleck/ATTAleckSlab_W_It.ttf") format("truetype"), url("_fonts/Aleck/ATTAleckSlab_W_It.svg#webfont") format("svg");
  font-weight: 400;
  font-style: italic; }

/* AT&T Icon Font */
@font-face {
  font-family: 'ATT_Icons';
  /* AT&T Icon Font */
  src: url("_fonts/ATT_Icons/att-icons.eot");
  src: url("_fonts/ATT_Icons/att-icons.eot?#iefix") format("embedded-opentype"), url("_fonts/ATT_Icons/att-icons.woff") format("woff"), url("_fonts/ATT_Icons/att-icons.ttf") format("truetype"), url("_fonts/ATT_Icons/att-icons.svg#webfont") format("svg");
  font-weight: 400;
  font-style: normal; }

/*==============
  Font Variables
  ==============*/
/* AT&T Icon Font Maps */
/*================
  Nav Highlighting
  ================*/
/*
   * The following map sets the nav highlighting default for different body classes
   * ex: bodyClassName: "nav-item-class-name"
   * Note that if a nav item with class of "nav-company" exists and you're on a page with a body class of "nav-company", that will highlight as well
   */
/*======
  MIXINS
  ======*/
/*=======
  Accents
  =======*/
/*==========
  Animations
  ==========*/
/*=================
  Container Queries
  =================*/
/*===========
  Positioning
  ===========*/
/*====
  Misc
  ====*/
/* Silver style */
/* Default button style for white, gray or black backgrounds */
/* Horizontal/Vertical Rules */
/*==========
  Typography
  ==========*/
/*====
  Base
  ====*/
/*=====
  RESET
  =====*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  cursor: pointer;
  border: 0;
  padding: 0; }
  button:focus {
    outline: 0; }

/*====
  BASE
  ====*/
html {
  box-sizing: border-box; }
  html *,
  html *:before,
  html *:after {
    box-sizing: inherit; }

body.compensate-for-scrollbar {
  margin-right: 0;
  overflow: auto !important; }

/* Helper Classes */
.hidden {
  display: none; }

.hidden-spoken {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  position: absolute !important;
  word-wrap: normal !important; }
  .hidden-spoken:focus {
    clip: auto !important;
    clip-path: none;
    z-index: 100000;
    outline: 0; }

@media (min-width: 1024px) {
  .hidden-desktop {
    display: none; } }

@media (max-width: 1023px) {
  .hidden-mobile {
    display: none; } }

.editor,
.editor-title {
  display: none; }
  .cq-Editable-dom .editor, .cq-Editable-dom
  .editor-title {
    display: block; }
  .editor sup,
  .editor-title sup {
    font-style: italic; }

.no-results {
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center; }

/* Icons */
[class^="icon--"] {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif; }
  [class^="icon--"]:after {
    font-family: inherit;
    color: inherit;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

[class="icon--alert"]:after {
  content: ""; }

[class="icon--att-logo"]:after {
  content: ""; }

[class="icon--att-logo-text"]:after {
  content: ""; }

[class="icon--comment"]:after {
  content: ""; }

[class="icon--download"]:after {
  content: ""; }

[class="icon--email"]:after {
  content: ""; }

[class="icon--facebook"]:after {
  content: ""; }

[class="icon--faq"]:after {
  content: ""; }

[class="icon--file"]:after {
  content: ""; }

[class="icon--info"]:after {
  content: ""; }

[class="icon--info2"]:after {
  content: ""; }

[class="icon--instagram"]:after {
  content: ""; }

[class="icon--link"]:after {
  content: ""; }

[class="icon--linkedin"]:after {
  content: ""; }

[class="icon--notification"]:after {
  content: ""; }

[class="icon--minus"]:after {
  content: ""; }

[class="icon--play"]:after {
  content: ""; }

[class="icon--plus"]:after {
  content: ""; }

[class="icon--quote"]:after {
  content: ""; }

[class="icon--search"]:after {
  content: ""; }

[class="icon--twitter"]:after {
  content: ""; }

[class="icon--youtube"]:after {
  content: ""; }

[class="icon--twitter-x"]:after {
  content: ""; }

[class="icon--arrow-down"]:after {
  content: ""; }

[class="icon--arrow-left"]:after {
  content: ""; }

[class="icon--arrow-right"]:after {
  content: ""; }

[class="icon--pause"]:after {
  content: ""; }

[class="icon--new-play"]:after {
  content: ""; }

/* ============== */
/* YouTube Embeds */
/* ============== */
.embed--youtube,
.asset--youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  clear: both; }
  .embed--youtube:after,
  .asset--youtube:after {
    content: "";
    display: table;
    clear: both; }
  .embed--youtube iframe,
  .asset--youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important; }

/* ========== */
/* GVP Embeds */
/* ========== */
/* preview/player wrappers */
body > div[role="dialog"] {
  z-index: 999999999 !important; }

att-gvp > div {
  border-radius: 0 !important; }

[class^="gvp_player__view_"],
[class^="gvp_cta__ctaContainer"] {
  border-radius: 0 !important; }

.embed--gvp [class^="gvp_player__view_"], .embed--gvp
[class^="gvp_player__viewport"] {
  background: transparent !important;
  padding: 0 !important; }

[class^="gvp_player__player"] {
  justify-content: flex-start !important;
  width: 100%; }
  [class^="gvp_player__player"][class*="gvp_player__modal"] {
    background-color: transparent !important;
    max-width: 946px !important;
    width: 100% !important;
    padding: 0 !important; }
    @media (max-width: 479px) {
      [class^="gvp_player__player"][class*="gvp_player__modal"] {
        max-height: 300px !important; } }

[class^="gvp_player__viewport"] {
  position: relative;
  max-width: 100% !important;
  width: 100% !important; }

[class^="gvp_player__video"] {
  max-width: 100% !important; }

/* playback controls */
[class^="gvp_player__controller"] {
  background: #000;
  padding: 0 10px !important; }

/* play button */
[class^="gvp_cta__ctaPlayButton"] {
  position: relative;
  max-width: none !important;
  max-height: none !important;
  fill: none !important; }
  [class^="gvp_cta__ctaPlayButton"]:after {
    content: "";
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif;
    font-size: 10rem;
    text-align: center;
    color: #FFF;
    opacity: 0.85; }
    @media (max-width: 480px) {
      [class^="gvp_cta__ctaPlayButton"]:after {
        font-size: 8rem; } }
    @media (min-width: 768px) {
      .col-2 [class^="gvp_cta__ctaPlayButton"]:after {
        font-size: 8rem; } }
    @media (min-width: 1024px) {
      .col-3 [class^="gvp_cta__ctaPlayButton"]:after {
        font-size: 8rem; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .col-4 [class^="gvp_cta__ctaPlayButton"]:after {
        font-size: 8rem; } }
  [class^="gvp_cta__ctaPlayButton"] circle {
    display: none !important; }

[class^="gvp_player__title"],
[class^="gvp_player__closeButton"] {
  transition: opacity 300ms ease !important; }
  @media (min-width: 1024px) {
    [class^="gvp_player__title"],
    [class^="gvp_player__closeButton"] {
      opacity: 0 !important; }
      .embed--gvp:hover [class^="gvp_player__title"],
      [class^="gvp_player__view_"]:hover [class^="gvp_player__title"], .embed--gvp:hover
      [class^="gvp_player__closeButton"],
      [class^="gvp_player__view_"]:hover
      [class^="gvp_player__closeButton"] {
        opacity: 1 !important; } }

/* close button */
[class^="gvp_player__closeButton"] {
  margin: 0 !important;
  fill: #000 !important;
  z-index: 99 !important; }
  .component [class^="gvp_player__closeButton"] {
    width: 35px !important;
    height: 35px !important; }
  [class^="gvp_player__closeButton"]:hover path {
    fill: #000 !important; }
  [class^="gvp_player__closeButton"]:focus {
    box-shadow: 0 !important; }
  [class^="gvp_player__closeButton"] circle {
    fill: #FFF; }

/* video title */
[class^="gvp_player__title"] {
  position: absolute !important;
  top: 0;
  font-size: 16px !important;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif !important;
  font-weight: 300 !important;
  text-shadow: 1px 1px 5px #333 !important;
  color: #FFF !important;
  padding: 10px !important;
  min-height: 20% !important;
  width: 100% !important;
  z-index: 9;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(31, 104, 164, 0) 76%, rgba(41, 137, 216, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.55) 0%, rgba(31, 104, 164, 0) 76%, rgba(41, 137, 216, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(31, 104, 164, 0) 76%, rgba(41, 137, 216, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c000000', endColorstr='#00000000',GradientType=0 ); }
  @media (min-width: 1024px) {
    [class^="gvp_player__title"] {
      font-size: 18px !important;
      padding: 15px 20px !important; } }

[class^="gvp_cta__ctaTitle"] {
  display: none; }

/* video duration */
[class^="gvp_cta__ctaDuration"] {
  display: none; }

/*==========
  TYPOGRAPHY
  ==========*/
html {
  font-family: "Arial", "Helvetica", sans-serif;
  font-size: 62.5%; }

body {
  font-size: 16px;
  font-size: 1.6rem;
  color: #191919; }

strong, b {
  font-weight: 700; }

em, i:not([class*="icon--"]) {
  font-style: italic; }

sup {
  position: relative;
  vertical-align: top;
  font-size: .7em;
  top: 2px; }

sub {
  vertical-align: sub;
  font-size: .7em; }

sup sub, sub sup {
  font-size: inherit;
  vertical-align: baseline; }

a {
  color: #0568AE;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }
  .region--blue a {
    color: #191919;
    text-decoration: underline; }
  .region--dark-blue a {
    color: #FFF;
    text-decoration: underline; }
  .region--near-black a {
    color: #FFF;
    text-decoration: underline; }
  .region--white a {
    color: #191919;
    text-decoration: underline; }
  .region--black a {
    color: #FFF;
    text-decoration: underline; }
  .region--gray a {
    color: #191919;
    text-decoration: underline; }
  .region--blue--wh-text a {
    color: #FFF;
    text-decoration: underline; }
  .region--blue--bl-text a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-cobalt a {
    color: #FFF;
    text-decoration: underline; }
  .region--comms-purple a {
    color: #FFF;
    text-decoration: underline; }
  .region--comms-berry a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-tangerine a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-lime a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-mint a {
    color: #191919;
    text-decoration: underline; }
  .region--blue-gradient--wh-text a {
    color: #FFF;
    text-decoration: underline; }
  .region--blue-gradient--bl-text a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-cobalt-purple a {
    color: #FFF;
    text-decoration: underline; }
  .region--comms-berry-tangerine a {
    color: #191919;
    text-decoration: underline; }
  .region--comms-lime-mint a {
    color: #191919;
    text-decoration: underline; }
  .region--black a, .region--near-black a {
    color: #009FDB;
    text-decoration: none; }
  .region--white a, .region--gray a {
    color: #0568AE;
    text-decoration: none; }

.content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .content h1, .content h2 {
    font-weight: 300;
    line-height: 1.15; }
  .content h3, .content h4, .content h5, .content h6 {
    font-weight: normal;
    line-height: 1.25; }
  .content h1 {
    font-size: 35px;
    font-size: 3.5rem; }
    @media (min-width: 1220px) {
      .content h1 {
        font-size: 45px;
        font-size: 4.5rem; } }
    @media (min-width: 1440px) {
      .content h1 {
        font-size: 60px;
        font-size: 6rem; } }
  .content h2 {
    font-size: 25px;
    font-size: 2.5rem; }
    @media (min-width: 768px) {
      .content h2 {
        font-size: 30px;
        font-size: 3rem; } }
  .content h3 {
    font-size: 18px;
    font-size: 1.8rem;
    font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
    @media (min-width: 1220px) {
      .content h3 {
        font-size: 21px;
        font-size: 2.1rem; } }
  .content h4 {
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
    @media (min-width: 1220px) {
      .content h4 {
        font-size: 20px;
        font-size: 2rem; } }
  .content h5 {
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .content h6 {
    font-size: 15px;
    font-size: 1.5rem;
    font-family: "Aleck Sans Medium"; }

.featured-item,
.text {
  /* Lists */ }
  .featured-item ul,
  .featured-item ol,
  .text ul,
  .text ol {
    margin: 25px 0 25px 20px; }
    .featured-item ul ul,
    .featured-item ul ol,
    .featured-item ol ul,
    .featured-item ol ol,
    .text ul ul,
    .text ul ol,
    .text ol ul,
    .text ol ol {
      margin: 15px 0 15px 15px; }
      @media (min-width: 1220px) {
        .featured-item ul ul,
        .featured-item ul ol,
        .featured-item ol ul,
        .featured-item ol ol,
        .text ul ul,
        .text ul ol,
        .text ol ul,
        .text ol ol {
          margin: 25px 0 25px 20px; } }
      .featured-item ul ul > li,
      .featured-item ul ol > li,
      .featured-item ol ul > li,
      .featured-item ol ol > li,
      .text ul ul > li,
      .text ul ol > li,
      .text ol ul > li,
      .text ol ol > li {
        margin-left: 15px; }
        @media (min-width: 1220px) {
          .featured-item ul ul > li,
          .featured-item ul ol > li,
          .featured-item ol ul > li,
          .featured-item ol ol > li,
          .text ul ul > li,
          .text ul ol > li,
          .text ol ul > li,
          .text ol ol > li {
            margin-left: 25px; } }
  .featured-item ul > li:before,
  .text ul > li:before {
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    left: -15px;
    background-color: #000;
    height: 4px;
    width: 4px;
    border-radius: 50%; }
  .featured-item ul > li ul > li:before,
  .text ul > li ul > li:before {
    background-color: #000;
    height: 4px;
    width: 4px;
    border-radius: 50%; }
  .featured-item ol,
  .text ol {
    list-style-type: decimal; }
  .featured-item li,
  .text li {
    position: relative;
    margin-bottom: 10px; }

.region--dark-blue .text ul > li:before {
  background-color: #FFF; }

.region--near-black .text ul > li:before {
  background-color: #FFF; }

.region--black .text ul > li:before {
  background-color: #FFF; }

.region--blue--wh-text .text ul > li:before {
  background-color: #FFF; }

.region--comms-cobalt .text ul > li:before {
  background-color: #FFF; }

.region--comms-purple .text ul > li:before {
  background-color: #FFF; }

.region--blue-gradient--wh-text .text ul > li:before {
  background-color: #FFF; }

.region--comms-cobalt-purple .text ul > li:before {
  background-color: #FFF; }

/*=======================
  CALL-TO-ACTION ELEMENTS
  =======================*/
button {
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }

/* Base CTA Wrappers */
[class$="__cta"] {
  margin: 40px 0 20px; }

/* Base CTA */
.cta--button,
.cta--social {
  text-decoration: none; }

/* Button-style CTAs */
.cta--button,
.cta--button-new {
  transition: all 300ms ease;
  display: inline-block;
  padding: 15px 30px 17px;
  text-decoration: none;
  text-align: center;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  line-height: 1;
  cursor: pointer; }
  @media (min-width: 1024px) {
    .cta--button,
    .cta--button-new {
      font-size: 19px;
      font-size: 1.9rem;
      min-width: 170px; } }
  @media (min-width: 1024px) {
    .col-2 .cta--button, .col-2
    .cta--button-new {
      min-width: 100%; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .col-3 .cta--button, .col-3
    .cta--button-new {
      min-width: 100%; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .col-4 .cta--button, .col-4
    .cta--button-new {
      min-width: 100%; } }

.cta--button {
  font-size: 16px;
  font-size: 1.6rem;
  border: 0;
  background-color: #009FDB;
  color: #FFF;
  text-decoration: none;
  min-width: 150px;
  border-radius: 45px;
  font-weight: 700; }
  .cta--button:hover {
    background-color: #0090c7;
    color: #FFF; }
  .region--blue .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--blue .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--dark-blue .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--dark-blue .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--near-black .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--near-black .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--white .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--white .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--black .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--black .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--gray .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--gray .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--blue--wh-text .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--blue--wh-text .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--blue--bl-text .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--blue--bl-text .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-cobalt .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-cobalt .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--comms-purple .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-purple .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--comms-berry .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-berry .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-tangerine .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-tangerine .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-lime .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-lime .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-mint .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-mint .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--blue-gradient--wh-text .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--blue-gradient--wh-text .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--blue-gradient--bl-text .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--blue-gradient--bl-text .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-cobalt-purple .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-cobalt-purple .cta--button:hover {
      background-color: transparent;
      color: #FFF; }
  .region--comms-berry-tangerine .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-berry-tangerine .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--comms-lime-mint .cta--button {
    border: 0;
    background-color: #000;
    color: #FFF;
    text-decoration: none; }
    .region--comms-lime-mint .cta--button:hover {
      background-color: #FFF;
      color: #000; }
  .region--white .cta--button, .region--gray .cta--button, .region--black .cta--button, .region--near-black .cta--button {
    border: 0;
    background-color: #009FDB;
    color: #FFF;
    text-decoration: none; }
    .region--white .cta--button:hover, .region--gray .cta--button:hover, .region--black .cta--button:hover, .region--near-black .cta--button:hover {
      background-color: #0090c7;
      color: #FFF; }

.cta--button-new {
  font-size: 14px;
  font-size: 1.4rem;
  position: relative;
  min-width: 130px; }
  .cta--button-new:after {
    content: " \2192";
    color: inherit;
    font-weight: 400; }

/* Link-style CTAs */
.cta--link {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  position: relative;
  color: #0568AE;
  text-decoration: none;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  font-weight: 700;
  line-height: 2.3rem; }
  .cta--link:after {
    content: " \2192";
    color: inherit;
    font-weight: 400; }
  .cta--link:hover {
    text-decoration: underline; }
  .region--blue .cta--link {
    color: #191919; }
  .region--dark-blue .cta--link {
    color: #FFF; }

/* Social-style CTAs */
.cta--social, .region .cta--social {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none;
  font-size: 30px;
  font-size: 3rem;
  transition: all 300ms ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 100%; }
  .cta--social:hover, .region .cta--social:hover {
    background-color: #FFF;
    color: #000; }
  @media (min-width: 768px) {
    .cta--social, .region .cta--social {
      font-size: 45px;
      font-size: 4.5rem;
      height: 64px;
      width: 64px; } }
  .region--white .cta--social, .region--gray .cta--social, .region--black .cta--social, .region--near-black .cta--social, .region--white .region .cta--social, .region--gray .region .cta--social, .region--black .region .cta--social, .region--near-black .region .cta--social {
    border: 0;
    background-color: #009FDB;
    color: #FFF;
    text-decoration: none; }
    .region--white .cta--social:hover, .region--gray .cta--social:hover, .region--black .cta--social:hover, .region--near-black .cta--social:hover, .region--white .region .cta--social:hover, .region--gray .region .cta--social:hover, .region--black .region .cta--social:hover, .region--near-black .region .cta--social:hover {
      background-color: #0090c7;
      color: #FFF; }

/*======
  Bookmark
  ======*/
html {
  scroll-padding-top: 120px; }

@media (max-width: 768px) {
  html {
    scroll-padding-top: 0px; } }

/*======
  Layout
  ======*/
/*======
  LAYOUT
  ======*/
.content,
.region,
.region__wrap,
.row {
  width: 100%;
  margin: 0 auto; }

.region,
.region__wrap,
.row,
.col {
  box-sizing: border-box;
  position: relative; }

.region__wrap,
.row__wrap,
.col {
  min-height: 20px;
  /* for empty parsys containers that might otherwise collapse */ }

/* Page */
.content {
  position: relative; }

/* Sections (note that the class name had to be changed from "section" to "region") */
.region {
  overflow: hidden;
  padding: 30px 0;
  /* Make sure sections inside of the equal-height rows are full height sections */
  /* Remove padding from vertically flush sections */
  /* Apply any bottom borders to sections, as long as they aren't vertically flush sections */
  /* Loop through possible section background colors */ }
  @media (min-width: 768px) {
    .region {
      padding: 40px 0; } }
  @media (min-width: 1220px) {
    .region {
      padding: 80px 0; } }
  .xl-spacing .region {
    padding: 40px 0; }
    @media (min-width: 768px) {
      .xl-spacing .region {
        padding: 100px 0; } }
    @media (min-width: 1220px) {
      .xl-spacing .region {
        padding: 150px 0; } }
  .lg-spacing .region {
    padding: 35px 0; }
    @media (min-width: 768px) {
      .lg-spacing .region {
        padding: 80px 0; } }
    @media (min-width: 1220px) {
      .lg-spacing .region {
        padding: 120px 0; } }
  .sm-spacing .region {
    padding: 25px 0; }
    @media (min-width: 768px) {
      .sm-spacing .region {
        padding: 30px 0; } }
    @media (min-width: 1220px) {
      .sm-spacing .region {
        padding: 50px 0; } }
  @media (min-width: 768px) {
    .row--equal-heights .region {
      height: 100%; } }
  .region.region--flush-vertical {
    padding: 0; }
    .region.region--flush-vertical .section:last-of-type > .row:last-of-type > .col:last-of-type > .component:last-of-type,
    .region.region--flush-vertical .section:last-of-type > .component:last-of-type,
    .region.region--flush-vertical .region__wrap > .component:last-of-type {
      margin-bottom: 0; }
  .region.region--border-bottom:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #009FDB;
    border-radius: 30px;
    z-index: 2;
    max-width: 300px;
    width: 60%;
    height: 4px;
    margin: 0 auto;
    bottom: -2px;
    right: 0;
    left: 0;
    background-color: #C9C9C9;
    max-width: 1220px;
    bottom: 0;
    width: calc(100% - 40px);
    height: 1px; }
    .region--blue .region.region--border-bottom:after {
      background-color: #000; }
    .region--dark-blue .region.region--border-bottom:after {
      background-color: #FFF; }
    @media (min-width: 768px) {
      .region.region--border-bottom:after {
        width: calc(100% - 110px); } }
    @media (min-width: 1220px) {
      .region.region--border-bottom:after {
        width: auto; } }
  .region.region--border-bottom.region--flush-vertical:after {
    max-width: none; }
  .region.region--blue {
    background-color: #009FDB;
    color: #191919; }
  .region.region--dark-blue {
    background-color: #0568AE;
    color: #FFF; }
  .region.region--near-black {
    background-color: #191919;
    color: #FFF; }
  .region.region--white {
    background-color: #FFF;
    color: #191919; }
  .region.region--black {
    background-color: #000;
    color: #FFF; }
  .region.region--gray {
    background-color: #F2F2F2;
    color: #191919; }
  .region.region--blue--wh-text {
    background-color: #009FDB;
    color: #FFF; }
  .region.region--blue--bl-text {
    background-color: #009FDB;
    color: #191919; }
  .region.region--comms-cobalt {
    background-color: #0057B8;
    color: #FFF; }
  .region.region--comms-purple {
    background-color: #AF29BB;
    color: #FFF; }
  .region.region--comms-berry {
    background-color: #FF585D;
    color: #191919; }
  .region.region--comms-tangerine {
    background-color: #FFB000;
    color: #191919; }
  .region.region--comms-lime {
    background-color: #91DC00;
    color: #191919; }
  .region.region--comms-mint {
    background-color: #49EEDC;
    color: #191919; }
  .region.region--blue-gradient--wh-text {
    background: #0057b8;
    background: linear-gradient(to right, #0057b8 0%, #009FDB 100%);
    color: #FFF; }
  .region.region--blue-gradient--wh-text {
    background: linear-gradient(125deg, #0057b8 0%, #009FDB 100%); }
  .region.region--blue-gradient--bl-text {
    background: #0057b8;
    background: linear-gradient(to right, #0057b8 0%, #009FDB 100%);
    color: #191919; }
  .region.region--blue-gradient--bl-text {
    background: linear-gradient(125deg, #0057b8 0%, #009FDB 100%); }
  .region.region--comms-cobalt-purple {
    background: #0057B8;
    background: linear-gradient(to right, #0057B8 0%, #AF29BB 100%);
    color: #FFF; }
  .region.region--comms-cobalt-purple {
    background: linear-gradient(125deg, #0057B8 0%, #AF29BB 100%); }
  .region.region--comms-berry-tangerine {
    background: #FF585D;
    background: linear-gradient(to right, #FF585D 0%, #FFB000 100%);
    color: #191919; }
  .region.region--comms-berry-tangerine {
    background: linear-gradient(125deg, #FF585D 0%, #FFB000 100%); }
  .region.region--comms-lime-mint {
    background: #91DC00;
    background: linear-gradient(to right, #91DC00 0%, #49EEDC 100%);
    color: #191919; }
  .region.region--comms-lime-mint {
    background: linear-gradient(125deg, #91DC00 0%, #49EEDC 100%); }

/* Constrain the width of the section wrapper to the maximum page width */
.region__wrap {
  max-width: 1600px; }

/* Rows */
.row {
  max-width: 1220px;
  margin: 0 auto;
  /* Remove top margin from first row in section */
  /* Remove all side margins from horizontally flushed rows */
  /* Remove top margin from top-flushed rows */
  /* Remove gutters from columns inside no-gutter rows */
  /* Add gutter borders to columns inside gutter-border rows */
  /* Rows inside of other rows/columns */ }
  @media (min-width: 768px) {
    .row {
      margin-top: 20px;
      padding: 0 55px; } }
  @media (min-width: 1440px) {
    .row {
      padding: 0; } }
  .region .row:first-of-type {
    margin-top: 0; }
  .row.row--flush-horizontal {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden; }
  .row.row--flush-top {
    margin-top: 0; }
  .row.row--no-gutters .row__wrap {
    margin-left: 0;
    margin-right: 0; }
  .row.row--no-gutters .col {
    padding-left: 0;
    padding-right: 0; }
  .row.row--gutter-borders .col {
    position: relative;
    /* Start all gutter borders as horizontal borders */
    /* At larger viewport size, switch them to the vertical format */
    /* Prevent gutter borders from appearing next to left-most columns when the columns start to wrap responsively */ }
    @media (max-width: 767px) {
      .row.row--gutter-borders .col {
        margin-bottom: 60px;
        padding-bottom: 60px; }
        .row.row--gutter-borders .col:not(:last-child):before {
          content: "";
          display: block;
          position: absolute;
          background-color: #009FDB;
          border-radius: 30px;
          z-index: 2;
          max-width: 300px;
          width: 60%;
          height: 4px;
          margin: 0 auto;
          bottom: -2px;
          right: 0;
          left: 0; }
          .region--blue .row.row--gutter-borders .col:not(:last-child):before {
            background-color: #000; }
          .region--dark-blue .row.row--gutter-borders .col:not(:last-child):before {
            background-color: #FFF; } }
    @media (min-width: 768px) {
      .row.row--gutter-borders .col.col-6:not(:first-child):before {
        content: "";
        display: block;
        position: absolute;
        background-color: #009FDB;
        border-radius: 30px;
        z-index: 2;
        max-height: 300px;
        height: 60%;
        width: 4px;
        margin: auto 0;
        left: -2px;
        bottom: 0;
        top: 0; }
        .region--blue .row.row--gutter-borders .col.col-6:not(:first-child):before {
          background-color: #000; }
        .region--dark-blue .row.row--gutter-borders .col.col-6:not(:first-child):before {
          background-color: #FFF; } }
    @media (min-width: 1024px) {
      .row.row--gutter-borders .col {
        /* 1/3 width columns */
        /* 1/4 width columns */ }
        .row.row--gutter-borders .col.col-4:not(:first-child):before, .row.row--gutter-borders .col.col-3:not(:first-child):before {
          content: "";
          display: block;
          position: absolute;
          background-color: #009FDB;
          border-radius: 30px;
          z-index: 2;
          max-height: 300px;
          height: 60%;
          width: 4px;
          margin: auto 0;
          left: -2px;
          bottom: 0;
          top: 0;
          background-color: #C9C9C9;
          max-height: none;
          width: 1px;
          height: 100%; }
          .region--blue .row.row--gutter-borders .col.col-4:not(:first-child):before, .region--blue .row.row--gutter-borders .col.col-3:not(:first-child):before {
            background-color: #000; }
          .region--dark-blue .row.row--gutter-borders .col.col-4:not(:first-child):before, .region--dark-blue .row.row--gutter-borders .col.col-3:not(:first-child):before {
            background-color: #FFF; }
        .row.row--gutter-borders .col.col-4 {
          flex-basis: 31%;
          max-width: 31%; }
        .row.row--gutter-borders .col.col-4 + .col-4:before {
          left: -6%; }
        .row.row--gutter-borders .col.col-8 + .col-4:before {
          left: -8%; }
        .row.row--gutter-borders .col.col-3 {
          flex-basis: 23%;
          max-width: 23%; }
        .row.row--gutter-borders .col.col-3 + .col-3:before {
          left: -6%; }
        .row.row--gutter-borders .col.col-9 + .col-3:before {
          left: -12%; } }
    @media (min-width: 1220px) {
      .row.row--gutter-borders .col {
        /* 1/5 width columns */ }
        .row.row--gutter-borders .col.col-2:not(:first-child):before {
          content: "";
          display: block;
          position: absolute;
          background-color: #009FDB;
          border-radius: 30px;
          z-index: 2;
          max-height: 300px;
          height: 60%;
          width: 4px;
          margin: auto 0;
          left: -2px;
          bottom: 0;
          top: 0;
          background-color: #C9C9C9;
          max-height: none;
          width: 1px;
          height: 100%; }
          .region--blue .row.row--gutter-borders .col.col-2:not(:first-child):before {
            background-color: #000; }
          .region--dark-blue .row.row--gutter-borders .col.col-2:not(:first-child):before {
            background-color: #FFF; }
        .row.row--gutter-borders .col.col-2 + .col-2:before {
          left: -13%; } }
  .col .row {
    padding: 0; }

.row__wrap {
  margin: 0;
  /* If we have 5 columns in a row or gutter borders, justify the columns */
  /* If row is horizontally flush, apply additional negative margin to bring the row to full-width */
  /* Row wraps inside of other rows/columns (Featured/Latest Stories) */ }
  @media (min-width: 768px) {
    .row__wrap {
      /* Apply negative margin to negate inner padding of columns */
      margin: 0 -10px;
      /* Begin flexbox/flexbox fallback */
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      /* End flexbox stuff */ }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .row__wrap {
        display: block; }
        .no-flexbox.no-flexboxtweener.no-flexboxlegacy .row__wrap:after {
          content: "";
          display: table;
          clear: both; } }
  @media (min-width: 1024px) {
    .row--justified .row__wrap,
    .row--gutter-borders .row__wrap {
      justify-content: space-between; } }
  .row--flush-horizontal .row__wrap {
    margin-right: -20px;
    margin-left: -20px; }
    @media (min-width: 1024px) {
      .row--flush-horizontal .row__wrap {
        margin-right: -10px;
        margin-left: -10px; } }
  .col .row__wrap {
    margin: 0 -20px; }
    @media (min-width: 768px) {
      .col .row__wrap {
        margin: 0 -10px; } }

/* Columns */
.col {
  width: 100%;
  max-width: 100%;
  /* All components are full-width at mobile viewports */
  padding: 0 20px;
  /* Loop through the column sizer map to size the columns */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Tablet Widths */
  /* Small Desktop Widths */
  /* Large Desktop Widths */
  /* Make sure columns inside of the equal-height rows are full height components */ }
  @media (min-width: 768px) {
    .col {
      padding-right: 10px;
      padding-left: 10px;
      /* Apply flexbox fallback */ }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col {
        float: left; } }
  @media (min-width: 768px) {
    .col.col-12 {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 1024px) {
    .col.col-12 {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 1220px) {
    .col.col-12 {
      max-width: 100%;
      flex-basis: 100%; } }
  @media (min-width: 768px) {
    .col.col-9 {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1024px) {
    .col.col-9 {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 1220px) {
    .col.col-9 {
      max-width: 75%;
      flex-basis: 75%; } }
  @media (min-width: 768px) {
    .col.col-8 {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 1024px) {
    .col.col-8 {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 1220px) {
    .col.col-8 {
      max-width: 66.66667%;
      flex-basis: 66.66667%; } }
  @media (min-width: 768px) {
    .col.col-6 {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1024px) {
    .col.col-6 {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1220px) {
    .col.col-6 {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 768px) {
    .col.col-4 {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1024px) {
    .col.col-4 {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1220px) {
    .col.col-4 {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 768px) {
    .col.col-3 {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 1024px) {
    .col.col-3 {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 1220px) {
    .col.col-3 {
      max-width: 25%;
      flex-basis: 25%; } }
  @media (min-width: 768px) {
    .col.col-2 {
      max-width: 33.33333%;
      flex-basis: 33.33333%; } }
  @media (min-width: 1024px) {
    .col.col-2 {
      max-width: 20%;
      flex-basis: 20%; } }
  @media (min-width: 1220px) {
    .col.col-2 {
      max-width: 16.66667%;
      flex-basis: 16.66667%; } }
  @media (min-width: 768px) {
    .row--equal-heights .col > div {
      height: 100%; } }
  @media (min-width: 768px) {
    .aem-AuthorLayer-Edit .row--equal-heights .col > div {
      min-height: 45px;
      height: auto; } }

/* Components */
.component {
  width: 100%;
  margin-bottom: 20px;
  /* Make sure components inside of the equal-height rows are full height components */ }
  .component:after {
    content: "";
    display: table;
    clear: both; }
  .component.component--no-margin-bottom, .component.component--border-bottom,
  .row--gutter-borders .component,
  .row--no-gutters .component {
    margin-bottom: 0; }
  .component.component--border-bottom > div {
    position: relative;
    margin-bottom: 60px;
    padding-bottom: 60px; }
    @media (min-width: 768px) {
      .component.component--border-bottom > div {
        margin-bottom: 80px;
        padding-bottom: 80px; } }
    @media (min-width: 1220px) {
      .component.component--border-bottom > div {
        margin-bottom: 100px;
        padding-bottom: 100px; } }
    .marquee .component.component--border-bottom > div {
      margin-bottom: 0;
      padding-bottom: 0; }
    .component.component--border-bottom > div:after {
      content: "";
      display: block;
      position: absolute;
      background-color: #009FDB;
      border-radius: 30px;
      z-index: 2;
      max-width: 300px;
      width: 60%;
      height: 4px;
      margin: 0 auto;
      bottom: -2px;
      right: 0;
      left: 0; }
      .region--blue .component.component--border-bottom > div:after {
        background-color: #000; }
      .region--dark-blue .component.component--border-bottom > div:after {
        background-color: #FFF; }
    .marquee .component.component--border-bottom > div:after {
      content: none; }
  @media (min-width: 768px) {
    .row--equal-heights .component {
      height: 100%; }
      .row--equal-heights .component .component {
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .aem-AuthorLayer-Edit .row--equal-heights .component {
      height: auto; } }
  .component [class*="__heading"] {
    max-width: 1220px;
    margin: 0 auto 25px;
    padding: 0 20px; }
    @media (min-width: 768px) {
      .component [class*="__heading"] {
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .component [class*="__heading"] {
        padding: 0; } }
    .col .component [class*="__heading"] {
      padding: 0; }

/*======
  HEADER
  ======*/
body > .wrapper {
  width: 100%;
  overflow: hidden;
  position: relative; }

body > .wrapper .canvas {
  transition: all 300ms ease;
  position: relative;
  width: 100%;
  min-height: 100vh; }
  @media (max-width: 767px) {
    body > .wrapper .canvas {
      backface-visibility: hidden; }
      body > .wrapper .canvas.canvas--on {
        transform: translateX(-80%); } }
    @media (max-width: 767px) and (min-width: 360px) {
      body > .wrapper .canvas.canvas--on {
        transform: translateX(-320px); } }
    @media (max-width: 767px) and (min-width: 768px) {
      body > .wrapper .canvas.canvas--on {
        transform: translateX(-400px); } }
  @media (min-width: 768px) {
    body > .wrapper .canvas {
      overflow: auto; } }

/* Skip Navigation */
.skip-navigation {
  background-color: #414141; }
  @media (min-width: 768px) {
    .skip-navigation {
      background-color: #000; } }

.skip-navigation a {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  position: absolute !important;
  word-wrap: normal !important;
  color: transparent;
  width: calc(100vw - 10px);
  padding: 5px;
  top: 5px; }
  .skip-navigation a:focus {
    clip: auto !important;
    clip-path: none;
    z-index: 100000;
    outline: 0; }
  @media (min-width: 768px) {
    .skip-navigation a {
      top: -30px; } }
  .skip-navigation a:focus {
    transition: all 300ms ease;
    font-size: 12px;
    display: block;
    position: relative !important;
    text-decoration: underline;
    text-align: center;
    padding: 5px 5px 10px;
    height: auto;
    margin: 0 auto;
    top: 5px;
    clip-path: none;
    clip: initial;
    color: #C9C9C9;
    z-index: 9999999999; }
    @media (min-width: 768px) {
      .skip-navigation a:focus {
        position: absolute !important;
        padding-bottom: 5px;
        width: auto;
        text-align: left;
        left: 5px; } }

/* Standard and Microsite Headers */
#att-microsite-header.plusGlobal .siteNav {
  margin-top: 0px; }

#att-about-header > div,
#att-microsite-header .microsite-nav {
  background-color: #000;
  border-bottom: 1px solid #5A5A5A; }

@media (min-width: 768px) {
  #att-microsite-header.plusGlobal .siteNav {
    margin-top: 50px; } }

@media (min-width: 1220px) {
  #att-about-header.header--transparent > div,
  #att-microsite-header.header--transparent .microsite-nav {
    background-color: rgba(0, 0, 0, 0.65); } }

@media (min-width: 1220px) {
  #att-about-header.header--fixed > div,
  #att-microsite-header.header--fixed .microsite-nav {
    background-color: #000; } }

header {
  box-sizing: border-box; }
  header *,
  header *:before,
  header *:after {
    box-sizing: inherit; }
  .touch header,
  .touchevents header {
    cursor: pointer; }

@media (min-width: 768px) {
  .header--secondary + * {
    margin-top: 105px; } }

@media (min-width: 768px) {
  .header--secondary.active + * {
    margin-top: 72px; } }

@media (min-width: 1440px) {
  .header--secondary.active + * {
    margin-top: 105px; } }

@media (min-width: 768px) {
  .header--secondary.active--wGlobal + * {
    margin-top: 122px; } }

@media (min-width: 1440px) {
  .header--secondary.active--wGlobal + * {
    margin-top: 122px; } }

@media (min-width: 768px) {
  .header + *,
  .header + .header--secondary + * {
    margin-top: 105px;
    top: 0; } }

@media (min-width: 768px) {
  .header.header--transparent + * {
    margin-top: 105px; } }

@media (min-width: 1220px) {
  .header.header--transparent + * {
    margin-top: 0;
    min-height: auto; } }

@media (min-width: 1220px) {
  .header.header--transparent + .header--secondary.header--transparent + * {
    margin-top: 0; } }

@media (min-width: 1220px) {
  .header--secondary.header--transparent {
    position: fixed;
    width: 100%;
    top: 0; } }

@media (min-width: 1220px) {
  .header--secondary.header--transparent + * {
    margin-top: 0; } }

@media (min-width: 1220px) {
  .header--secondary.header--transparent.active.plusGlobal + *, .header--secondary.header--transparent.active--wGlobal + * {
    margin-top: 50px; } }

.att-about-header__wrap {
  z-index: 10; }
  @media (min-width: 768px) {
    .att-about-header__wrap {
      transition: all 450ms ease;
      position: fixed;
      height: 105px;
      width: 100%;
      margin-top: 0; }
      #att-about-header.header--fixed .att-about-header__wrap {
        height: 72px; } }

.plusMicrosite {
  height: 50px !important; }

.header--secondary > * {
  z-index: 10; }
  @media (min-width: 768px) {
    .header--secondary > * {
      transition: all 450ms ease;
      position: fixed;
      width: 100%;
      margin-top: 0;
      top: 0; } }

@media (min-width: 1220px) {
  #att-about-header {
    position: relative;
    z-index: 999999999; } }

#att-microsite-header {
  z-index: 5; }
  #att-microsite-header .component {
    margin-bottom: 0; }
  #att-microsite-header .microsite-nav {
    transition: all 450ms ease;
    margin: 0;
    padding: 0; }
    @media (min-width: 1024px) {
      #att-microsite-header .microsite-nav {
        height: 105px; } }
  @media (min-width: 1024px) {
    #att-microsite-header.header--fixed .microsite-nav {
      height: 72px; } }
  @media (min-width: 1024px) {
    #att-microsite-header .microsite-nav.microsite-nav--plusGlobal {
      height: 72px; } }
  @media (min-width: 1440px) {
    #att-microsite-header .microsite-nav.microsite-nav--plusGlobal {
      height: 72px; } }
  @media (min-width: 1024px) {
    #att-microsite-header.header--fixed .microsite-nav.microsite-nav--plusGlobal {
      height: 60px; } }

/* Author Instance Display Fixes */
.aem-AuthorLayer-Edit #att-about-header {
  min-height: 72px; }
  @media (min-width: 768px) {
    .aem-AuthorLayer-Edit #att-about-header {
      min-height: 105px; } }

.aem-AuthorLayer-Edit #att-about-header + *,
.aem-AuthorLayer-Edit #att-about-header + .header--secondary + *,
.aem-AuthorLayer-Edit #att-microsite-header + * {
  margin-top: 0; }

.aem-AuthorLayer-Edit #att-microsite-header.active + *,
.aem-AuthorLayer-Edit #att-microsite-header.active--wGlobal + * {
  min-height: 100px; }

.aem-AuthorLayer-Edit #att-microsite-header.active + * {
  margin-top: 130px; }

.aem-AuthorLayer-Edit #att-microsite-header.active--wGlobal + * {
  margin-top: 180px; }

/*===
  NAV
  ===*/
/* Nav-specific settings */
/* Helper mixins */
/*=====================*/
#att-about-header .nav {
  max-width: 1220px;
  margin: 0 auto;
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased; }
  #att-about-header .nav:after {
    content: "";
    display: table;
    clear: both; }

#att-about-header .plusMicrosite .nav__utils {
  height: 50px; }

#att-about-header .nav__utils {
  position: relative;
  box-sizing: border-box;
  padding: 0 20px;
  height: 72px; }
  #att-about-header .nav__utils:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    #att-about-header .nav__utils {
      padding: 0 55px;
      float: left;
      width: 15%; } }
  @media (min-width: 1440px) {
    #att-about-header .nav__utils {
      padding: 0; } }

/* Nav Logo */
.nav__logo {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  display: block;
  float: left; }
  .nav__logo .logo__icon {
    transition: all 300ms ease;
    height: 38px;
    min-width: 95px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    display: block;
    /* NOTE: Logo color can vary based on the theme. Default treatment is silver. */
    background-image: url("../img/logo_att-white-text.png"); }
    @media (min-width: 1220px) {
      .nav__logo .logo__icon {
        height: 38px;
        min-width: 95px; }
        .header--fixed .nav__logo .logo__icon {
          height: 38px;
          min-width: 95px; } }

/* Hamburger Toggle */
#att-about-header .nav__toggle,
.microsite-nav--plusGlobal .nav__toggle {
  height: 24px;
  width: 24px;
  background-color: transparent;
  padding: 0;
  border: 0; }
  #att-about-header .nav__toggle:focus,
  .microsite-nav--plusGlobal .nav__toggle:focus {
    outline: 0; }
  @media (min-width: 768px) {
    #att-about-header .nav__toggle,
    .microsite-nav--plusGlobal .nav__toggle {
      display: none; } }

#att-about-header .nav__toggle .bar,
.microsite-nav--plusGlobal .nav__toggle .bar {
  transition: all 300ms ease;
  display: block;
  background: #FFF;
  width: 100%;
  height: 3px;
  margin-bottom: 4px; }

.nav__toggle--open {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  float: right; }
  .nav--open .nav__toggle--open {
    display: none; }

.nav__toggle--close {
  display: none; }
  .nav--open .nav__toggle--close {
    display: block;
    position: absolute;
    top: 22px;
    right: 20px; }
    @media (min-width: 768px) {
      .nav--open .nav__toggle--close {
        display: none; } }
  .nav--open .nav__toggle--close .bar {
    position: absolute;
    background: none;
    top: 8px; }
    .nav--open .nav__toggle--close .bar:first-child, .nav--open .nav__toggle--close .bar:last-child {
      background: #FFF; }
    .nav--open .nav__toggle--close .bar:first-child {
      transform: rotate(-45deg); }
    .nav--open .nav__toggle--close .bar:last-child {
      transform: rotate(45deg); }

@media (min-width: 768px) {
  #att-about-header .nav__utils,
  #att-about-header .nav__menu--primary {
    height: 100%; } }

/* Nav Menus */
.nav__menu {
  position: absolute;
  box-sizing: border-box;
  background-color: #000; }
  @media (min-width: 768px) {
    .nav__menu {
      padding-right: 55px; } }
  @media (min-width: 1220px) {
    .nav__menu {
      padding-right: 55px; } }
  .nav__menu li {
    position: relative;
    list-style-type: none;
    margin: 0;
    z-index: 10;
    text-align: left; }
    .nav__menu li.expanded > .nav__menu-parent:after {
      content: '';
      background-color: #FFF;
      padding: 0;
      height: 2px;
      width: 12px;
      top: 19px;
      left: 14px; }
  .nav__menu .nav__menu-item {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.25;
    text-decoration: none;
    color: #FFF; }
    @media (min-width: 768px) {
      .nav__menu .nav__menu-item {
        font-size: 14px; } }
    .nav__menu .nav__menu-item span {
      display: inline-block;
      position: relative; }
      @media (max-width: 360px) {
        .nav__menu .nav__menu-item span {
          font-size: 14px; } }
  .nav__menu .nav__menu-parent {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    padding: 10px 0;
    right: 0;
    top: 0;
    line-height: 1;
    text-decoration: none; }
    .nav__menu .nav__menu-parent:after {
      content: '+';
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 10%;
      float: right;
      padding: 3px;
      color: #FFF;
      font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
      font-size: 25px;
      font-weight: 300;
      cursor: pointer; }
    @media (min-width: 768px) {
      .nav__menu .nav__menu-parent {
        height: 37px; } }
    .nav__menu .nav__menu-parent:hover {
      text-decoration: none; }

/* Primary Menus */
.nav__menu--primary {
  width: 320px;
  padding-left: 0;
  margin: 0; }
  @media (min-width: 768px) {
    .nav__menu--primary {
      width: 400px; }
      .nav__menu--primary:focus {
        outline: none; } }
  @media (max-width: 767px) {
    .nav__menu--primary {
      transition: width 300ms ease;
      max-width: 80%;
      left: 100%;
      top: 0;
      height: 100%;
      padding-top: 72px;
      border-left: 1px solid #5A5A5A; } }
  @media (max-width: 767px) and (min-width: 360px) {
    .nav__menu--primary {
      max-width: none; } }
  @media (min-width: 768px) {
    .nav__menu--primary {
      position: relative;
      float: right;
      width: 85%;
      background-color: transparent;
      text-align: right; } }
  @media (min-width: 1440px) {
    .nav__menu--primary {
      padding-right: 0; } }
  .nav__menu--primary > li {
    /* NOTE: Apply highlighting to nav items based on the body class name. */ }
    .story:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .story:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .story:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .blog:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .blog:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .blog:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .all-posts:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .all-posts:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .all-posts:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .media-gallery:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .media-gallery:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .media-gallery:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .media-kit:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .media-kit:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .media-kit:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .media-kit-landing:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .media-kit-landing:not([class*="nav-"]) .nav__menu--primary > li.nav-news > .nav__menu-item:before,
        .nav-news .nav__menu--primary > li.nav-news > .nav__menu-item:before {
          opacity: 1; } }
    .media-kit-landing:not([class*="nav-"]) .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item,
    .nav-news .header--transparent .nav__menu--primary > li.nav-news > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    .nav__menu--primary > li.active > .nav__menu-item {
      color: #009FDB; }
      @media (min-width: 768px) {
        .nav__menu--primary > li.active > .nav__menu-item:before {
          opacity: 1; } }
    .header--transparent .nav__menu--primary > li.active > .nav__menu-item {
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); }
    @media (max-width: 767px) {
      .nav__menu--primary > li {
        border-bottom: 1px solid #5A5A5A; }
        .nav__menu--primary > li.expanded > .nav__menu-item:after {
          transform: translate(1px, -2px) rotate(45deg); }
        .nav__menu--primary > li.expanded > .nav__menu-item span {
          border-bottom: 2px solid #009FDB;
          padding-bottom: 9px; } }
    @media (min-width: 768px) {
      .nav__menu--primary > li {
        display: inline-block;
        height: 100%;
        margin: 0 0 0 20px; }
        .nav__menu--primary > li:first-child {
          margin-left: 0; }
        .nav__menu--primary > li:nth-last-child(-n+2) > .nav__menu--nested {
          right: 0; }
        .nav__menu--primary > li:hover > .nav__menu-item:before,
        .nav__menu--primary > li.expanded > .nav__menu-item:before {
          opacity: 1; }
        .nav__menu--primary > li:hover > .nav__menu-item:after,
        .nav__menu--primary > li.expanded > .nav__menu-item:after {
          transform: translate(1px, -2px) rotate(45deg); } }
    @media (min-width: 850px) {
      .nav__menu--primary > li {
        margin-left: 35px; } }
    @media (min-width: 1024px) {
      .nav__menu--primary > li {
        margin-left: 65px; } }
    @media (min-width: 1220px) {
      .nav__menu--primary > li {
        margin-left: 80px; } }
    @media (min-width: 1440px) {
      .nav__menu--primary > li.last-item {
        padding-right: 0;
        margin-right: 0; } }
    @media (min-width: 1600px) {
      .nav__menu--primary > li:nth-last-child(-n+2) > .nav__menu--nested {
        right: auto; }
      .nav__menu--primary > li:last-child > .nav__menu--nested {
        right: 0; } }
    @media (max-width: 767px) {
      .nav__menu--primary > li > .nav__menu-item {
        width: calc(100% - 100px);
        padding: 10px 0 10px 20px; } }
    @media (min-width: 768px) {
      .nav__menu--primary > li > .nav__menu-item {
        text-transform: uppercase;
        font-weight: 400; }
        .nav__menu--primary > li > .nav__menu-item:before {
          transition: all 300ms ease;
          content: "";
          display: block;
          position: absolute;
          bottom: -1px;
          height: 5px;
          width: 100%;
          background-color: #009FDB;
          opacity: 0; }
        .nav__menu--primary > li > .nav__menu-item:focus:before {
          opacity: 1; } }
    @media (min-width: 768px) {
      .nav__menu--primary > li > .nav__menu-item span {
        display: block;
        top: 50%;
        transform: translate(0, -50%); } }
    @media (min-width: 768px) {
      .nav__menu--primary > li > .nav__menu-parent {
        height: 0;
        width: 0;
        padding: 0; }
        .nav__menu--primary > li > .nav__menu-parent:after {
          content: none; } }
    @media (min-width: 768px) {
      .nav__menu--primary > li.expanded > .nav__menu-parent:after {
        content: none; } }

@media (min-width: 768px) {
  .microsite-nav--plusGlobal .nav__menu--primary {
    position: relative;
    float: right;
    width: 85%;
    background-color: transparent;
    text-align: right;
    top: 45%; } }

.microsite-nav--plusGlobal .nav__logo {
  position: relative;
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
  top: 50%;
  display: block;
  float: left; }

/* Subnavs */
.nav__menu--secondary,
.nav__menu--tertiary {
  display: none;
  position: relative; }
  @media (min-width: 768px) {
    .nav__menu--secondary,
    .nav__menu--tertiary {
      position: absolute;
      width: 260px;
      margin-top: 1px;
      padding: 10px 0; }
      .nav__menu--secondary .nav__menu--nested,
      .nav__menu--tertiary .nav__menu--nested {
        position: relative;
        background-color: #000;
        margin-top: 0;
        padding: 5px 10px; } }
  @media (min-width: 1220px) {
    .nav__menu--secondary,
    .nav__menu--tertiary {
      width: 300px; } }
  @media (min-width: 1440px) {
    .nav__menu--secondary,
    .nav__menu--tertiary {
      width: 340px; } }
  @media (max-width: 767px) {
    .nav__menu--secondary li,
    .nav__menu--tertiary li {
      border-bottom: 1px solid #5A5A5A; } }
  @media (min-width: 768px) {
    .nav__menu--secondary li:hover,
    .nav__menu--tertiary li:hover {
      background-color: #5A5A5A; } }
  @media (min-width: 768px) {
    .nav__menu--secondary li:hover .nav__menu-parent,
    .nav__menu--tertiary li:hover .nav__menu-parent {
      background-color: #676767; } }
  .nav__menu--secondary li:last-child,
  .nav__menu--tertiary li:last-child {
    border-bottom: 0; }
  .nav__menu--secondary .nav__menu-item,
  .nav__menu--tertiary .nav__menu-item {
    width: calc(100% - 100px);
    padding: 10px 0 10px 0; }
    @media (min-width: 768px) {
      .nav__menu--secondary .nav__menu-item,
      .nav__menu--tertiary .nav__menu-item {
        padding-left: 20px;
        width: calc(100% - 40px); } }
    @media (min-width: 768px) {
      .nav__menu--secondary .nav__menu-item:focus,
      .nav__menu--tertiary .nav__menu-item:focus {
        background-color: #5A5A5A; } }
    .nav__menu--secondary .nav__menu-item:last-child,
    .nav__menu--tertiary .nav__menu-item:last-child {
      width: 100%;
      padding-right: 20px; }

/* Secondary Subnavs */
.nav__menu--secondary {
  /* When in desktop, rely on the hover or focused state of the parent element to show secondary navs */ }
  @media (max-width: 767px) {
    .nav__menu--secondary {
      padding-left: 40px;
      /* When in mobile, rely on the .expanded class of the parent element to show secondary navs */ }
      .expanded > .nav__menu-parent + .nav__menu--secondary {
        display: block; } }
  @media (min-width: 768px) {
    .nav__menu--primary > li:hover > .nav__menu-parent + .nav__menu--secondary,
    .nav__menu--primary > li.expanded > .nav__menu-parent + .nav__menu--secondary {
      display: block; } }

/* Tertiary Subnavs */
.nav__menu--tertiary {
  /* Always rely on the .expanded class of the parent element to show tertiary navs */ }
  @media (max-width: 767px) {
    .nav__menu--tertiary {
      padding-left: 25px; } }
  @media (min-width: 768px) {
    .nav__menu--tertiary {
      position: relative;
      background-color: #000;
      margin-top: 0;
      padding: 5px 10px; } }
  @media (min-width: 768px) {
    .nav__menu--tertiary li .nav__menu-item {
      padding-left: 30px; } }
  .expanded > .nav__menu-parent + .nav__menu--tertiary {
    display: block; }

/*======
  FOOTER
  ======*/
#att-about-footer {
  background-color: #191919; }

#att-about-footer .footer__wrap {
  font-family: "Arial", "Helvetica", sans-serif;
  font-size: 11px;
  line-height: 1;
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px;
  color: #959595; }
  @media (min-width: 768px) and (max-width: 1439px) {
    #att-about-footer .footer__wrap {
      padding-left: 55px;
      padding-right: 55px; } }
  @media (min-width: 1024px) {
    #att-about-footer .footer__wrap {
      padding-bottom: 50px; } }
  @media (min-width: 1440px) {
    #att-about-footer .footer__wrap {
      padding-right: 0;
      padding-left: 0; } }
  #att-about-footer .footer__wrap a {
    color: inherit; }

#att-about-footer .footer__menu {
  margin: 10px 0 20px;
  padding: 0; }
  #att-about-footer .footer__menu li {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: .5rem; }
  #att-about-footer .footer__menu a {
    font-weight: 300;
    text-decoration: none; }
    #att-about-footer .footer__menu a:hover {
      text-decoration: underline; }

#att-about-footer .footer__copyright {
  margin: 20px 0; }
  #att-about-footer .footer__copyright p {
    margin: 0; }
  #att-about-footer .footer__copyright a {
    text-decoration: underline; }

#att-about-footer .footer-icon-left {
  width: 32px;
  margin-left: 5px;
  vertical-align: middle; }

#att-about-footer .footer-icon-right {
  width: 32px;
  margin-right: 5px;
  vertical-align: middle; }

/*========
  ARTICLES
  ========*/
/* Featured Headline */
.featured-headline {
  padding: 0 0 30px; }
  .story__header .featured-headline {
    padding: 30px 20px; }
    @media (min-width: 768px) {
      .story__header .featured-headline {
        padding-left: 55px;
        padding-right: 55px; } }
    @media (min-width: 1024px) {
      .story__header .featured-headline {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin: 0 auto;
        max-width: 1220px;
        width: 100%;
        height: 100%; } }
    @media (min-width: 1220px) {
      .story__header .featured-headline {
        padding-left: calc(105px - 10px);
        padding-right: calc(105px - 10px); }
        .header--transparent + .content .story__header .featured-headline {
          height: calc(100% - 105px); } }
  .article__content[data-ftImg="true"] .featured-headline {
    display: none; }

.featured-headline h1,
.meta__info h1,
.meta__info h2 {
  font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  line-height: 1.25; }

@media (min-width: 1024px) {
  .story__header .featured-headline h1 {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    max-width: 50%;
    color: #FFF; }
  .story__header--light .featured-headline h1 {
    color: #000; } }

@media (min-width: 1220px) {
  .story .featured-headline h1,
  .blog-post .featured-headline h1, .story
  .meta__info h1,
  .blog-post
  .meta__info h1 {
    font-size: 40px;
    font-size: 4rem; } }

@media (min-width: 1220px) {
  .media-kit-post .meta__info h2 {
    font-size: 40px;
    font-size: 4rem;
    max-width: 95%; } }

.featured-asset [class*="asset"] {
  margin: 10px 0 40px; }

.featured-asset img {
  max-width: 100%; }

/* Article */
.article__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #C9C9C9; }
  .article__header:after {
    content: "";
    display: table;
    clear: both; }

.article.col-12 .article__header {
  padding-bottom: 0px; }

.author__meta .meta__img img {
  clip-path: circle(); }

.article__meta,
.author__meta {
  margin-bottom: 40px; }
  .article__meta:after,
  .author__meta:after {
    content: "";
    display: table;
    clear: both; }
  .article__meta .meta__img,
  .author__meta .meta__img {
    float: left;
    margin-right: 15px;
    min-height: 85px;
    width: 85px;
    overflow: hidden; }
    @media (min-width: 640px) {
      .article__meta .meta__img,
      .author__meta .meta__img {
        margin-right: 20px; } }
    .article__meta .meta__img img,
    .author__meta .meta__img img {
      max-width: 100%; }
  .article__meta .meta__info,
  .author__meta .meta__info {
    float: left;
    max-width: calc(100% - 108px); }
    .article__meta .meta__info span,
    .author__meta .meta__info span {
      display: block;
      margin-bottom: 8px; }
    .article__meta .meta__info .author,
    .author__meta .meta__info .author {
      font-weight: 700; }

@media (min-width: 480px) {
  .article__share {
    float: left; } }

.article__assets {
  margin-top: 10px; }
  @media (min-width: 480px) {
    .article__assets {
      margin-top: 0;
      margin-right: 15px;
      float: right; } }
  .article__assets .cta--link {
    position: relative; }
    .article__assets .cta--link:after {
      content: " \2193";
      color: inherit;
      font-weight: 400; }

/* Article Body Content */
.article__content {
  padding-top: 40px;
  /* Components */ }
  .article__content .section:first-child > .region:first-child {
    margin-top: 0;
    padding-top: 0; }
  .article__content h1,
  .article__content h2 {
    font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
    line-height: 1.25; }
  .article__content .region h1,
  .article__content .region h2 {
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .article__content .component.component--border-bottom > div {
    margin-bottom: calc(40px + 10px);
    padding-bottom: 40px; }
    @media (min-width: 1220px) {
      .article__content .component.component--border-bottom > div {
        margin-bottom: calc(60px + 10px);
        padding-bottom: 60px; } }

.blog-post .blog__wrap,
.media-kit-page .media-kit__wrap {
  /* Sections */
  /* Components */
  /* Text Components */
  /* Media Components */ }
  .blog-post .blog__wrap .row,
  .media-kit-page .media-kit__wrap .row {
    margin-top: 0; }
    @media (min-width: 768px) {
      .blog-post .blog__wrap .row,
      .media-kit-page .media-kit__wrap .row {
        padding: 0; } }
    @media (min-width: 1024px) {
      .blog-post .blog__wrap .row,
      .media-kit-page .media-kit__wrap .row {
        max-width: 1600px; } }
  @media (min-width: 768px) {
    .blog-post .blog__wrap .row__wrap,
    .media-kit-page .media-kit__wrap .row__wrap {
      margin-left: 0;
      margin-right: 0; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .article__content,
    .media-kit-page .media-kit__wrap .article__content {
      width: 100%; } }
  .blog-post .blog__wrap .region,
  .media-kit-page .media-kit__wrap .region {
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    margin: 0 0 20px; }
    .blog-post .blog__wrap .region[class*="region--"]:not(.region--white),
    .media-kit-page .media-kit__wrap .region[class*="region--"]:not(.region--white) {
      border-top: 0;
      border-bottom: 0; }
    .blog-post .blog__wrap .region.region--flush-vertical,
    .media-kit-page .media-kit__wrap .region.region--flush-vertical {
      border-top: 0;
      border-bottom: 0;
      padding: 0; }
    @media (min-width: 768px) {
      .blog-post .blog__wrap .region,
      .media-kit-page .media-kit__wrap .region {
        margin-bottom: 40px;
        padding: 80px 0; } }
    @media (min-width: 1024px) {
      .blog-post .blog__wrap .region,
      .media-kit-page .media-kit__wrap .region {
        margin-bottom: 50px; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .component,
    .blog-post .blog__wrap .region,
    .media-kit-page .media-kit__wrap .component,
    .media-kit-page .media-kit__wrap .region {
      max-width: 1220px;
      margin-left: auto;
      margin-right: auto; } }
  .blog-post .blog__wrap .component > div,
  .blog-post .blog__wrap .region > div,
  .media-kit-page .media-kit__wrap .component > div,
  .media-kit-page .media-kit__wrap .region > div {
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 768px) {
      .blog-post .blog__wrap .component > div,
      .blog-post .blog__wrap .region > div,
      .media-kit-page .media-kit__wrap .component > div,
      .media-kit-page .media-kit__wrap .region > div {
        padding: 0 55px 20px; } }
    @media (min-width: 1024px) {
      .blog-post .blog__wrap .component > div,
      .blog-post .blog__wrap .region > div,
      .media-kit-page .media-kit__wrap .component > div,
      .media-kit-page .media-kit__wrap .region > div {
        max-width: 66.66667%;
        flex-basis: 66.66667%;
        margin: 0 auto;
        padding: 0 0 20px; } }
    .blog-post .blog__wrap .component > div .component > div,
    .blog-post .blog__wrap .region > div .component > div,
    .media-kit-page .media-kit__wrap .component > div .component > div,
    .media-kit-page .media-kit__wrap .region > div .component > div {
      padding: 0px; }
      @media (min-width: 1024px) {
        .blog-post .blog__wrap .component > div .component > div,
        .blog-post .blog__wrap .region > div .component > div,
        .media-kit-page .media-kit__wrap .component > div .component > div,
        .media-kit-page .media-kit__wrap .region > div .component > div {
          max-width: 100%; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .component [class*="__heading"],
    .media-kit-page .media-kit__wrap .component [class*="__heading"] {
      padding: 0; } }
  .blog-post .blog__wrap .region .component,
  .media-kit-page .media-kit__wrap .region .component {
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .blog-post .blog__wrap .region .component,
      .media-kit-page .media-kit__wrap .region .component {
        max-width: none; } }
    .blog-post .blog__wrap .region .component > div,
    .media-kit-page .media-kit__wrap .region .component > div {
      padding: 0; }
      @media (min-width: 1024px) {
        .blog-post .blog__wrap .region .component > div,
        .media-kit-page .media-kit__wrap .region .component > div {
          max-width: none; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .region .component [class*="__heading"],
    .media-kit-page .media-kit__wrap .region .component [class*="__heading"] {
      padding: 0 55px; } }
  @media (min-width: 1440px) {
    .blog-post .blog__wrap .region .component [class*="__heading"],
    .media-kit-page .media-kit__wrap .region .component [class*="__heading"] {
      padding: 0; } }
  .blog-post .blog__wrap .region .component .text,
  .blog-post .blog__wrap .region .component .media__caption,
  .media-kit-page .media-kit__wrap .region .component .text,
  .media-kit-page .media-kit__wrap .region .component .media__caption {
    padding: 0 20px; }
    @media (min-width: 768px) {
      .blog-post .blog__wrap .region .component .text,
      .blog-post .blog__wrap .region .component .media__caption,
      .media-kit-page .media-kit__wrap .region .component .text,
      .media-kit-page .media-kit__wrap .region .component .media__caption {
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .blog-post .blog__wrap .region .component .text,
      .blog-post .blog__wrap .region .component .media__caption,
      .media-kit-page .media-kit__wrap .region .component .text,
      .media-kit-page .media-kit__wrap .region .component .media__caption {
        padding-left: 0;
        padding-right: 0; } }
  .blog-post .blog__wrap .region .component .text,
  .media-kit-page .media-kit__wrap .region .component .text {
    max-width: 1220px;
    padding: 0px; }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .region .component .text.text--featured,
    .media-kit-page .media-kit__wrap .region .component .text.text--featured {
      padding-top: 20px;
      padding-bottom: 20px; } }
  @media (min-width: 1440px) {
    .blog-post .blog__wrap .region .component .text.text--featured,
    .media-kit-page .media-kit__wrap .region .component .text.text--featured {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .region .component .media__caption,
    .media-kit-page .media-kit__wrap .region .component .media__caption {
      padding: 0; } }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .region .component .media__caption p,
    .media-kit-page .media-kit__wrap .region .component .media__caption p {
      max-width: 66.66667%;
      flex-basis: 66.66667%;
      margin-left: auto;
      margin-right: auto; } }
  .blog-post .blog__wrap .text.text--featured h2,
  .media-kit-page .media-kit__wrap .text.text--featured h2 {
    font-size: 30px;
    font-size: 3rem; }
    @media (min-width: 1440px) {
      .blog-post .blog__wrap .text.text--featured h2,
      .media-kit-page .media-kit__wrap .text.text--featured h2 {
        font-size: 38px;
        font-size: 3.8rem; } }
  .blog-post .blog__wrap .media,
  .media-kit-page .media-kit__wrap .media {
    float: none; }
  .blog-post .blog__wrap .region .component .media__img,
  .media-kit-page .media-kit__wrap .region .component .media__img {
    max-width: none; }
  .blog-post .blog__wrap .media__img img,
  .media-kit-page .media-kit__wrap .media__img img {
    margin: 0 auto; }
  .blog-post .blog__wrap .media__caption,
  .media-kit-page .media-kit__wrap .media__caption {
    padding: 0; }
  .blog-post .blog__wrap .component > .media-library,
  .blog-post .blog__wrap .component > .media-carousel,
  .blog-post .blog__wrap .component > .media-library .component > div,
  .blog-post .blog__wrap .component > .media-carousel .component > div,
  .media-kit-page .media-kit__wrap .component > .media-library,
  .media-kit-page .media-kit__wrap .component > .media-carousel,
  .media-kit-page .media-kit__wrap .component > .media-library .component > div,
  .media-kit-page .media-kit__wrap .component > .media-carousel .component > div {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }
  @media (min-width: 1024px) {
    .blog-post .blog__wrap .component > .media-library .component > div,
    .blog-post .blog__wrap .component > .media-carousel .component > div,
    .media-kit-page .media-kit__wrap .component > .media-library .component > div,
    .media-kit-page .media-kit__wrap .component > .media-carousel .component > div {
      max-width: none; } }
  .blog-post .blog__wrap .component > .media-carousel .component > div,
  .media-kit-page .media-kit__wrap .component > .media-carousel .component > div {
    padding: 0 20px; }
    @media (min-width: 768px) {
      .blog-post .blog__wrap .component > .media-carousel .component > div,
      .media-kit-page .media-kit__wrap .component > .media-carousel .component > div {
        padding: 0 10px; } }

@media (min-width: 1024px) {
  .blog__header .row .row__wrap .col-12,
  .blog__authors .row .row__wrap .col-12,
  .media-kit__header .row .row__wrap .col-12,
  .media-kit__authors .row .row__wrap .col-12,
  .story__wrap .row .row__wrap .col-12 {
    max-width: 75%;
    flex-basis: 75%; } }

@media (min-width: 1440px) {
  .blog__header .row .row__wrap .col-12,
  .blog__authors .row .row__wrap .col-12,
  .media-kit__header .row .row__wrap .col-12,
  .media-kit__authors .row .row__wrap .col-12,
  .story__wrap .row .row__wrap .col-12 {
    max-width: 66.66667%;
    flex-basis: 66.66667%; } }

/* Sidebar */
@media (min-width: 1024px) {
  .sidebar {
    padding-left: 40px; } }

@media (min-width: 1220px) {
  .sidebar {
    padding-left: 60px;
    padding-right: 60px; } }

.sidebar .sidebar__widget {
  margin-bottom: 40px; }

.sidebar .search-field fieldset {
  margin: 0; }
  @media (min-width: 1024px) {
    .sidebar .search-field fieldset {
      max-width: 245px; } }

/* Related Articles */
[class*="__related"] .featured-item .item__info {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  margin: 15px 0 30px; }

@media (max-width: 1023px) {
  [class*="__related"] .featured-item .item__description {
    display: none; } }

.blog-post .blog__wrap .region {
  border: none; }

/*=====
  SHARE
  =====*/
.share,
[class*="__share"] {
  position: relative;
  max-width: 1220px;
  overflow: hidden;
  margin: 0 20px; }
  .share:after,
  [class*="__share"]:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    .share,
    [class*="__share"] {
      margin-left: 55px;
      margin-right: 55px; } }
  @media (min-width: 1220px) {
    .share,
    [class*="__share"] {
      margin-left: auto;
      margin-right: auto;
      padding-left: 55px;
      padding-right: 55px; } }
  @media (min-width: 1440px) {
    .share,
    [class*="__share"] {
      padding-left: 0;
      padding-right: 0; } }
  .col .share, .col
  [class*="__share"] {
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 768px) {
      .col .share, .col
      [class*="__share"] {
        margin-left: 0;
        margin-right: 0; } }
    @media (min-width: 1220px) {
      .col .share, .col
      [class*="__share"] {
        padding-left: 0;
        padding-right: 0; } }
  .component > .share, .component >
  [class*="__share"] {
    padding-bottom: 10px;
    float: none; }
    .component > .share:before, .component >
    [class*="__share"]:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #C9C9C9;
      bottom: 0;
      left: 0; }
      @media (min-width: 1220px) and (max-width: 1439px) {
        .component > .share:before, .component >
        [class*="__share"]:before {
          width: calc(100% - (55px * 2));
          left: 55px; } }
      .region--blue .component > .share:before, .region--blue .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--dark-blue .component > .share:before, .region--dark-blue .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--near-black .component > .share:before, .region--near-black .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--white .component > .share:before, .region--white .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--black .component > .share:before, .region--black .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--gray .component > .share:before, .region--gray .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--blue--wh-text .component > .share:before, .region--blue--wh-text .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--blue--bl-text .component > .share:before, .region--blue--bl-text .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-cobalt .component > .share:before, .region--comms-cobalt .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--comms-purple .component > .share:before, .region--comms-purple .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--comms-berry .component > .share:before, .region--comms-berry .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-tangerine .component > .share:before, .region--comms-tangerine .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-lime .component > .share:before, .region--comms-lime .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-mint .component > .share:before, .region--comms-mint .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--blue-gradient--wh-text .component > .share:before, .region--blue-gradient--wh-text .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--blue-gradient--bl-text .component > .share:before, .region--blue-gradient--bl-text .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-cobalt-purple .component > .share:before, .region--comms-cobalt-purple .component >
      [class*="__share"]:before {
        background-color: #FFF; }
      .region--comms-berry-tangerine .component > .share:before, .region--comms-berry-tangerine .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--comms-lime-mint .component > .share:before, .region--comms-lime-mint .component >
      [class*="__share"]:before {
        background-color: #000; }
      .region--white .component > .share:before, .region--gray .component > .share:before, .region--white .component >
      [class*="__share"]:before, .region--gray .component >
      [class*="__share"]:before {
        background-color: #C9C9C9; }
    .component > .share.no-bottom-border:before, .component >
    [class*="__share"].no-bottom-border:before {
      content: none; }
  .component--border-bottom .share, .component--border-bottom
  [class*="__share"] {
    text-align: center; }
  .share h4,
  .share ul,
  [class*="__share"] h4,
  [class*="__share"] ul {
    float: left; }
  .share.share--right h4, .share.share--right ul,
  [class*="__share"].share--right h4,
  [class*="__share"].share--right ul {
    float: right; }
  .share h4,
  [class*="__share"] h4 {
    height: 20px;
    line-height: 20px;
    text-transform: lowercase;
    font-family: inherit;
    font-size: inherit; }
  .share ul,
  [class*="__share"] ul {
    margin: 0 10px; }
    .share ul li,
    [class*="__share"] ul li {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px; }
    .share ul a:hover,
    [class*="__share"] ul a:hover {
      text-decoration: none; }
    .share ul i,
    [class*="__share"] ul i {
      font-size: 25px;
      font-size: 2.5rem;
      color: #5A5A5A; }
      .region--blue .share ul i, .region--blue
      [class*="__share"] ul i {
        color: #000; }
      .region--dark-blue .share ul i, .region--dark-blue
      [class*="__share"] ul i {
        color: #FFF; }
      .region--near-black .share ul i, .region--near-black
      [class*="__share"] ul i {
        color: #FFF; }
      .region--white .share ul i, .region--white
      [class*="__share"] ul i {
        color: #000; }
      .region--black .share ul i, .region--black
      [class*="__share"] ul i {
        color: #FFF; }
      .region--gray .share ul i, .region--gray
      [class*="__share"] ul i {
        color: #000; }
      .region--blue--wh-text .share ul i, .region--blue--wh-text
      [class*="__share"] ul i {
        color: #FFF; }
      .region--blue--bl-text .share ul i, .region--blue--bl-text
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-cobalt .share ul i, .region--comms-cobalt
      [class*="__share"] ul i {
        color: #FFF; }
      .region--comms-purple .share ul i, .region--comms-purple
      [class*="__share"] ul i {
        color: #FFF; }
      .region--comms-berry .share ul i, .region--comms-berry
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-tangerine .share ul i, .region--comms-tangerine
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-lime .share ul i, .region--comms-lime
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-mint .share ul i, .region--comms-mint
      [class*="__share"] ul i {
        color: #000; }
      .region--blue-gradient--wh-text .share ul i, .region--blue-gradient--wh-text
      [class*="__share"] ul i {
        color: #FFF; }
      .region--blue-gradient--bl-text .share ul i, .region--blue-gradient--bl-text
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-cobalt-purple .share ul i, .region--comms-cobalt-purple
      [class*="__share"] ul i {
        color: #FFF; }
      .region--comms-berry-tangerine .share ul i, .region--comms-berry-tangerine
      [class*="__share"] ul i {
        color: #000; }
      .region--comms-lime-mint .share ul i, .region--comms-lime-mint
      [class*="__share"] ul i {
        color: #000; }
      .region--white .share ul i, .region--gray .share ul i, .region--white
      [class*="__share"] ul i, .region--gray
      [class*="__share"] ul i {
        color: #5A5A5A; }
      .share ul i .region[class*="black"],
      [class*="__share"] ul i .region[class*="black"] {
        color: #FFF; }
      .region--blue .share ul i:hover, .region--blue
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--dark-blue .share ul i:hover, .region--dark-blue
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--near-black .share ul i:hover, .region--near-black
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--white .share ul i:hover, .region--white
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--black .share ul i:hover, .region--black
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--gray .share ul i:hover, .region--gray
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--blue--wh-text .share ul i:hover, .region--blue--wh-text
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--blue--bl-text .share ul i:hover, .region--blue--bl-text
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-cobalt .share ul i:hover, .region--comms-cobalt
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--comms-purple .share ul i:hover, .region--comms-purple
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--comms-berry .share ul i:hover, .region--comms-berry
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-tangerine .share ul i:hover, .region--comms-tangerine
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-lime .share ul i:hover, .region--comms-lime
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-mint .share ul i:hover, .region--comms-mint
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--blue-gradient--wh-text .share ul i:hover, .region--blue-gradient--wh-text
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--blue-gradient--bl-text .share ul i:hover, .region--blue-gradient--bl-text
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-cobalt-purple .share ul i:hover, .region--comms-cobalt-purple
      [class*="__share"] ul i:hover {
        color: #000; }
      .region--comms-berry-tangerine .share ul i:hover, .region--comms-berry-tangerine
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--comms-lime-mint .share ul i:hover, .region--comms-lime-mint
      [class*="__share"] ul i:hover {
        color: #FFF; }
      .region--white .share ul i:hover, .region--gray .share ul i:hover, .region--black .share ul i:hover, .region--near-black .share ul i:hover, .region--white
      [class*="__share"] ul i:hover, .region--gray
      [class*="__share"] ul i:hover, .region--black
      [class*="__share"] ul i:hover, .region--near-black
      [class*="__share"] ul i:hover {
        color: #009FDB; }

/*=====
  FORMS
  =====*/
input {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  line-height: 1;
  color: inherit; }
  @media (min-width: 1024px) {
    input {
      font-size: 18px;
      font-size: 1.8rem; } }
  input:focus {
    outline: 0; }
  input::placeholder {
    color: #5A5A5A; }
    .region[class*="black"] input::placeholder,
    .region[class*="blue"] input::placeholder,
    .region[class*="cobalt"] input::placeholder,
    .region[class*="purple"] input::placeholder {
      color: #F2F2F2; }
  input::-moz-placeholder {
    color: #5A5A5A; }
    .region[class*="black"] input::-moz-placeholder,
    .region[class*="blue"] input::-moz-placeholder,
    .region[class*="cobalt"] input::-moz-placeholder,
    .region[class*="purple"] input::-moz-placeholder {
      color: #F2F2F2; }
  input::-ms-input-placeholder {
    color: #5A5A5A; }
    .region[class*="black"] input::-ms-input-placeholder,
    .region[class*="blue"] input::-ms-input-placeholder,
    .region[class*="cobalt"] input::-ms-input-placeholder,
    .region[class*="purple"] input::-ms-input-placeholder {
      color: #F2F2F2; }
  input::-ms-input-placeholder {
    color: #5A5A5A; }
    .region[class*="black"] input::-ms-input-placeholder,
    .region[class*="blue"] input::-ms-input-placeholder,
    .region[class*="cobalt"] input::-ms-input-placeholder,
    .region[class*="purple"] input::-ms-input-placeholder {
      color: #F2F2F2; }
  .region[class*="white"] input,
  .region[class*="gray"] input,
  .sidebar input {
    color: #5A5A5A; }

input:focus,
textarea:focus,
select:focus {
  outline-offset: 0; }

input:-internal-autofill-selected,
textarea:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: transparent !important; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset; }

input:-webkit-text-fill-color,
textarea:-webkit-text-fill-color,
select:-webkit-text-fill-color {
  color: #191919 !important; }

/*======================
    Submission Form Styles
    ======================*/
.section form[method=POST] {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px; }
  .col:not(.col-12) .section form[method=POST] {
    margin: 0; }
  @media (min-width: 640px) {
    .section form[method=POST] {
      padding: 0; } }
  .section form[method=POST] .form_leftcol {
    height: 0;
    line-height: inherit; }
  .section form[method=POST] .form_leftcolmark {
    color: #b30a3c;
    font-weight: 700;
    padding-left: 2px; }
  .section form[method=POST] .form_leftcollabel {
    float: left;
    margin-bottom: 10px; }
  .section form[method=POST] .form_leftcolnobr {
    float: left;
    margin-bottom: 15px; }
  .section form[method=POST] .form_rightcol {
    clear: both; }
  .section form[method=POST] .form_rightcolnobr {
    clear: none;
    float: right;
    margin-bottom: 15px; }
  .section form[method=POST] .form_rightcolnooverflow {
    overflow: hidden; }
  .section form[method=POST] .title {
    padding-top: 10px; }
  .section form[method=POST] .form_row {
    font-size: 16px;
    font-size: 1.6rem;
    clear: both;
    display: block;
    padding-bottom: 5px; }
  .section form[method=POST] label,
  .section form[method=POST] .form_row_description {
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .section form[method=POST] .form_row_description {
    font-size: 14px;
    font-size: 1.4rem;
    clear: both;
    color: #5A5A5A;
    line-height: 16px; }
  .section form[method=POST] .form_field_checkbox,
  .section form[method=POST] .form_field_radio {
    border: none;
    margin-left: 20px; }
  .section form[method=POST] .form_field_text,
  .section form[method=POST] .form_field_textarea {
    background: #f9f9f9;
    border-radius: 3px;
    border-color: #C9C9C9;
    border-style: solid;
    border-width: 1px;
    color: #5A5A5A;
    font-size: 100%;
    line-height: 34px;
    margin: 0;
    max-width: 500px;
    padding: 0 5px;
    width: 100%; }
  .section form[method=POST] .form_field_select {
    font-size: 16px;
    font-size: 1.6rem;
    max-width: 500px;
    width: 100%; }
  .section form[method=POST] .form_button_submit {
    font-size: 16px;
    font-size: 1.6rem;
    background-color: #009FDB;
    color: #FFF;
    transition: all 300ms ease;
    display: inline-block;
    min-width: 150px;
    padding: 15px 30px 17px;
    text-decoration: none;
    text-align: center;
    border: 0;
    border-radius: 45px;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
    line-height: 1;
    cursor: pointer; }
    @media (min-width: 1024px) {
      .section form[method=POST] .form_button_submit {
        font-size: 19px;
        font-size: 1.9rem;
        min-width: 170px; } }
  .section form[method=POST] .customer_survey_submit {
    float: right;
    margin-top: 28px; }
  .section form[method=POST] .form_field_text.form_field_multivalued {
    width: 314px; }
  .section form[method=POST] .form_error {
    font-size: 15px;
    font-size: 1.5rem;
    color: #f00;
    display: inline-block;
    padding: 5px 0; }
  .section form[method=POST] p.form_error {
    padding: 0 0 10px; }
  .section form[method=POST] div.section {
    margin-bottom: 30px; }
  .section form[method=POST] div.colctrl.section {
    padding-bottom: 0 !important; }
  .section form[method=POST] span.mr_write {
    display: inline-block;
    text-align: right;
    vertical-align: top;
    width: 16px; }

/* Submission Form Captcha */
.section form[method=POST] .form_captcha_input {
  float: left;
  width: 170px; }
  .section form[method=POST] .form_captcha_input input {
    width: 170px; }
  .section form[method=POST] .form_captcha_input .form_captcha_img {
    float: left;
    padding-left: 16px; }
  .section form[method=POST] .form_captcha_input .form_captchatimer {
    border: 1px solid #C9C9C9;
    float: left; }
  .section form[method=POST] .form_captcha_input .form_captchatimer_bar {
    background-color: #C9C9C9;
    float: left;
    height: 8px; }

/*=====
  MODAL
  =====*/
.privacy-modal.fancybox-content {
  position: relative;
  background-color: transparent; }
  @media (min-width: 559px) {
    .privacy-modal.fancybox-content {
      padding: 0 30px 0 24px; } }
  .privacy-modal.fancybox-content .modal__wrap {
    position: relative;
    background-color: #FFF;
    margin: 0 auto;
    padding: 30px;
    border-top: 15px solid #009FDB; }
    @media (min-width: 768px) {
      .privacy-modal.fancybox-content .modal__wrap {
        padding: 35px;
        max-width: 600px; } }
    @media (min-width: 1024px) {
      .privacy-modal.fancybox-content .modal__wrap {
        overflow-y: auto;
        overflow-x: hidden; } }
  .privacy-modal.fancybox-content .modal__close {
    position: absolute;
    top: 0px;
    right: 15px; }
    .privacy-modal.fancybox-content .modal__close .cta--close {
      font-size: 50px;
      font-weight: 300; }
      .privacy-modal.fancybox-content .modal__close .cta--close span {
        display: inline-block;
        transform: rotate(45deg); }
      .privacy-modal.fancybox-content .modal__close .cta--close:hover {
        text-decoration: none; }
      .privacy-modal.fancybox-content .modal__close .cta--close:focus, .privacy-modal.fancybox-content .modal__close .cta--close:active {
        outline: 0; }
  .privacy-modal.fancybox-content .modal__content {
    text-align: left;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
    @media (min-width: 1220px) {
      .privacy-modal.fancybox-content .modal__content {
        max-width: 95%; } }
    .privacy-modal.fancybox-content .modal__content h2 {
      font-size: 22px;
      font-size: 2.2rem;
      color: #009FDB;
      font-weight: 400;
      margin: 0 0 20px;
      line-height: 1.25; }
      @media (max-width: 1219px) {
        .privacy-modal.fancybox-content .modal__content h2 {
          max-width: 90%; } }
    .privacy-modal.fancybox-content .modal__content h3, .privacy-modal.fancybox-content .modal__content p {
      font-size: 14.5px;
      font-size: 1.45rem;
      line-height: 1.7; }
    .privacy-modal.fancybox-content .modal__content h3 {
      margin-bottom: 25px; }
  .privacy-modal.fancybox-content .cta--button {
    font-weight: 600; }
    .privacy-modal.fancybox-content .cta--button:hover {
      background-color: #000;
      text-decoration: none; }

/*======
  Vendor
  ======*/
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #eceef1; }

.flatpickr-calendar.hasTime .flatpickr-innerContainer {
  border-bottom: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid #eceef1; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #eceef1; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #eceef1; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eceef1; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #eceef1; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #eceef1;
  color: #5a6171;
  fill: #5a6171;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #5a6171;
  fill: #5a6171; }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #bbb; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(90, 97, 113, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #5a6171; }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #5a6171; }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(90, 97, 113, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #eceef1;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #eceef1;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: #eceef1;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #eceef1;
  color: #5a6171;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
  border-left: 1px solid #eceef1;
  border-right: 1px solid #eceef1; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #eceef1;
  box-shadow: -1px 0 0 #eceef1; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2; }

.flatpickr-day.today {
  border-color: #bbb; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #ff5a5f;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #ff5a5f; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #ff5a5f;
  box-shadow: -10px 0 0 #ff5a5f; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(72, 72, 72, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #ff5a5f, 5px 0 0 #ff5a5f;
  box-shadow: -5px 0 0 #ff5a5f, 5px 0 0 #ff5a5f; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid #eceef1; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #eceef1; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eaeaea; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

span.flatpickr-day.selected {
  font-weight: bold; }

/*! Flickity v1.2.1
http://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  filter: alpha(opacity=60);
  /* IE8 */
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  filter: alpha(opacity=30);
  /* IE8 */
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* color & size if no SVG - IE8 and Android 2.3 */
.flickity-prev-next-button.no-svg {
  color: #333;
  font-size: 26px; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  filter: alpha(opacity=25);
  /* IE8 */
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  filter: alpha(opacity=100);
  /* IE8 */
  opacity: 1; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  height: 100%;
  left: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s, visibility 0s linear .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s, visibility 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  text-align: center;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  height: 100%;
  margin-right: -.25em;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  overflow: visible;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0 6px; }

.fancybox-slide--iframe {
  padding: 44px 44px 0; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 6px 0;
  max-width: 100%;
  overflow: auto;
  padding: 0;
  padding: 24px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  margin: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-drag .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-dragging .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--html .fancybox-content {
  margin-bottom: 6px; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
  height: calc(100% - 44px);
  margin-bottom: 44px; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.fancybox-iframe {
  vertical-align: top; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  outline: none;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:focus,
.fancybox-button:hover {
  color: #fff; }

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default; }

.fancybox-button svg {
  display: block;
  overflow: visible;
  position: relative;
  shape-rendering: geometricPrecision; }

.fancybox-button svg path {
  fill: transparent;
  stroke: currentColor;
  stroke-linejoin: round;
  stroke-width: 3; }

.fancybox-button--play svg path:nth-child(2) {
  display: none; }

.fancybox-button--pause svg path:nth-child(1) {
  display: none; }

.fancybox-button--play svg path,
.fancybox-button--share svg path,
.fancybox-button--thumbs svg path {
  fill: currentColor; }

.fancybox-button--share svg path {
  stroke-width: 1; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  height: 38px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  top: 50%;
  width: 38px; }

.fancybox-show-nav .fancybox-navigation .fancybox-button {
  transition: opacity .25s, visibility 0s, color .25s; }

.fancybox-navigation .fancybox-button::after {
  content: '';
  left: -25px;
  padding: 50px;
  position: absolute;
  top: -25px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  right: 6px; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #555;
  cursor: pointer;
  height: 44px;
  margin: 0;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  z-index: 10; }

.fancybox-close-small svg {
  fill: transparent;
  opacity: .8;
  stroke: currentColor;
  stroke-width: 1.5;
  transition: stroke .1s; }

.fancybox-close-small:focus {
  outline: none; }

.fancybox-close-small:hover svg {
  opacity: 1; }

.fancybox-slide--image .fancybox-close-small,
.fancybox-slide--video .fancybox-close-small,
.fancybox-slide--iframe .fancybox-close-small {
  color: #ccc;
  padding: 5px;
  right: -12px;
  top: -44px; }

.fancybox-slide--image .fancybox-close-small:hover svg,
.fancybox-slide--video .fancybox-close-small:hover svg,
.fancybox-slide--iframe .fancybox-close-small:hover svg {
  background: transparent;
  color: #fff; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-drag .fancybox-close-small {
  display: none; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs > ul {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x > ul {
  overflow: hidden; }

.fancybox-thumbs-y > ul::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs > ul > li {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1); }

.fancybox-thumbs > ul > li {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.fancybox-thumbs > ul > li:before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs .fancybox-thumbs-active:before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs > ul > li {
    max-width: calc(100% - 10px); } }

/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */ }

table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold; }

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111; }

table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none; }

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111; }

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting {
  background-image: url("../images/sort_both.png"); }

table.dataTable thead .sorting_asc {
  background-image: url("../images/sort_asc.png"); }

table.dataTable thead .sorting_desc {
  background-image: url("../images/sort_desc.png"); }

table.dataTable thead .sorting_asc_disabled {
  background-image: url("../images/sort_asc_disabled.png"); }

table.dataTable thead .sorting_desc_disabled {
  background-image: url("../images/sort_desc_disabled.png"); }

table.dataTable tbody tr {
  background-color: #ffffff; }

table.dataTable tbody tr.selected {
  background-color: #B0BED9; }

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px; }

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid #ddd; }

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none; }

table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd; }

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd; }

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none; }

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9; }

table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4; }

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6; }

table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1; }

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  background-color: #fafafa; }

table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad5; }

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1; }

table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3; }

table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke; }

table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd; }

table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf; }

table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1; }

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fafafa; }

table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fcfcfc; }

table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fefefe; }

table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad5; }

table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #aebcd6; }

table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8; }

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea; }

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ececec; }

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #efefef; }

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a2aec7; }

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a3b0c9; }

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a5b2cb; }

table.dataTable.no-footer {
  border-bottom: 1px solid #111; }

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap; }

table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 4px 17px 4px 4px; }

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 4px; }

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px; }

table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left; }

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center; }

table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right; }

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify; }

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap; }

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left; }

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center; }

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right; }

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify; }

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap; }

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left; }

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center; }

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right; }

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify; }

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap; }

table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: content-box; }

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1; }

.dataTables_wrapper .dataTables_length {
  float: left; }

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right; }

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em; }

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em; }

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em; }

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px; }

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  /* W3C */ }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none; }

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */ }

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111; }

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em; }

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333; }

.dataTables_wrapper .dataTables_scroll {
  clear: both; }

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch; }

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle; }

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important; }

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111; }

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none; }

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0; }

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em; } }

@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center; }
  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em; } }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: 9px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '-';
  background-color: #d33333; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px; }

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px; }

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer; }

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: 'Courier New', Courier, monospace;
  line-height: 14px;
  content: '+';
  background-color: #31b131; }

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: '-';
  background-color: #d33333; }

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em; }

table.dataTable > tbody > tr.child:hover {
  background: transparent !important; }

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0; }

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none; }

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold; }

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em; }

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6); }

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em; }

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12; }

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea; }

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6); }

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%; } }

/*==========
  Components
  ==========*/
/*=====
  ALERT
  =====*/
.alert {
  padding: 20px;
  color: #191919;
  /* Loop through possible section background colors. */
  /* Special handling for bottom border */ }
  .alert.alert--centered {
    text-align: center; }
  .row--equal-heights .alert {
    height: 100%; }
  .alert.alert--black {
    background-color: #000; }
  .alert.alert--silver {
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .alert.alert--silver {
      filter: none; }
    .alert.alert--silver .cta--link {
      color: #0568AE; }
  .alert.alert--black, .alert.alert--tier-low {
    color: #FFF; }
  .alert.alert--blue {
    background-color: #009FDB; }
  .alert.alert--silver {
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .alert.alert--silver {
      filter: none; }
    .alert.alert--silver .cta--link {
      color: #0568AE; }
  .alert.alert--black, .alert.alert--tier-low {
    color: #FFF; }
  .alert.alert--tier-low {
    background-color: #0057B8; }
  .alert.alert--silver {
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .alert.alert--silver {
      filter: none; }
    .alert.alert--silver .cta--link {
      color: #0568AE; }
  .alert.alert--black, .alert.alert--tier-low {
    color: #FFF; }
  .alert.alert--tier-med {
    background-color: #FFB000; }
  .alert.alert--silver {
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .alert.alert--silver {
      filter: none; }
    .alert.alert--silver .cta--link {
      color: #0568AE; }
  .alert.alert--black, .alert.alert--tier-low {
    color: #FFF; }
  .alert.alert--tier-high {
    background-color: #FF585D; }
  .alert.alert--silver {
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .alert.alert--silver {
      filter: none; }
    .alert.alert--silver .cta--link {
      color: #0568AE; }
  .alert.alert--black, .alert.alert--tier-low {
    color: #FFF; }
  .component.component--border-bottom .alert {
    padding-bottom: 20px; }
    .component.component--border-bottom .alert:after {
      bottom: -60px; }
      @media (min-width: 768px) {
        .component.component--border-bottom .alert:after {
          bottom: -80px; } }
      @media (min-width: 1220px) {
        .component.component--border-bottom .alert:after {
          bottom: -100px; } }
  .alert--geo-targeted {
    display: none; }

.alert__message {
  transition: opacity 600ms ease;
  font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  max-width: 1220px;
  max-height: 52px;
  overflow: hidden;
  width: 100%;
  opacity: 0; }
  .col:not(.col-12) .alert__message {
    max-height: none; }
  .row--equal-heights .alert__message {
    position: relative;
    transform: translateY(-50%);
    top: 50%; }
  @media (min-width: 1220px) {
    .alert__message {
      margin: 0 auto; } }
  .alert__message.alert__message--revealed {
    opacity: 1; }
  .alert__message .message--mobile {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.35; }
    @media (min-width: 768px) {
      .alert__message .message--mobile {
        display: none; } }
  .alert__message .message--desktop {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.25;
    display: none; }
    @media (min-width: 768px) {
      .alert__message .message--desktop {
        display: block; } }
  .alert__message a {
    display: inline-block;
    color: #009FDB;
    text-decoration: underline;
    margin: 0;
    zoom: 1; }
    .alert--blue .alert__message a, .alert[class*="tier"] .alert__message a {
      color: inherit; }

/*======
  BANNER
  ======*/
.banner {
  position: relative;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .banner.banner--featured {
    overflow: hidden; }
    @media (max-width: 767px) {
      .col .banner.banner--featured {
        border-bottom: 1px solid #5A5A5A;
        max-height: 250px; }
      .col:last-child .banner.banner--featured {
        border-bottom: 0; } }
    @media (min-width: 768px) {
      .col .banner.banner--featured {
        border-left: 1px solid #5A5A5A;
        max-height: 450px;
        min-height: 325px; }
      .col:first-child .banner.banner--featured {
        border-left: 0; } }
    @media (min-width: 1024px) {
      .col .banner.banner--featured {
        max-height: 530px; } }
  .row--equal-heights .banner {
    height: 100%; }
  @media (min-width: 1220px) {
    .banner {
      overflow: hidden; } }
  .banner .banner__img,
  .banner .banner__video {
    display: none; }

/* Banner Image */
.banner__img {
  /* If we're loading an image banner, make sure the image container displays in the page */
  /* Homepage Featured Banners */
  /* Gradient overlay */ }
  .banner[data-type="image"] .banner__img {
    display: block; }
  @media (max-width: 767px) {
    .banner--featured .banner__img {
      position: relative; }
      .banner--featured .banner__img:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4); } }
  @media (min-width: 768px) and (max-width: 1219px) {
    .col-4 .banner--featured .banner__img img,
    .col-6 .banner--featured .banner__img img {
      min-height: 100%;
      max-width: none;
      width: auto; } }
  @media (min-width: 768px) {
    .banner--overlaid .banner__img {
      position: relative; }
      .banner--overlaid .banner__img:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, black 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, black 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, black 100%); }
        .no-cssgradients .banner--overlaid .banner__img:after {
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1);
          -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1); } }
  @media (min-width: 1220px) {
    .banner--overlaid .banner__img {
      position: relative; }
      .banner--overlaid .banner__img:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, black 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, black 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%, black 100%); }
        .no-cssgradients .banner--overlaid .banner__img:after {
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1);
          -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#330000', endColorstr='#000000', GradientType=1); } }
  .banner__img img {
    display: block;
    max-width: 100%;
    width: 100%; }
    @media (min-width: 768px) {
      .banner__img img {
        position: relative;
        transform: translateX(-50%);
        left: 50%; } }

/* Banner Video */
.banner__video {
  /* If we're loading a video banner, make sure the video container displays in the page */
  /* Gradient overlay */ }
  .banner[data-type="video"] .banner__video {
    display: block; }
  .no-touchevents.videoautoplay .banner--overlaid .banner__video {
    position: relative; }
    .no-touchevents.videoautoplay .banner--overlaid .banner__video:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6); }
  @media (min-width: 768px) {
    .touchevents.no-videoautoplay .banner--overlaid .banner__video {
      position: relative; }
      .touchevents.no-videoautoplay .banner--overlaid .banner__video:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6); } }
  .banner__video [class^="embed--"] {
    display: none; }
    .banner__video [class^="embed--"]:first-child {
      display: block; }
  .banner__video .embed--youtube {
    background-position: center;
    background-size: cover; }
  .banner__video .embed--gvp gvp-data img {
    display: none;
    width: 100%; }
    .touchevents.no-videoautoplay .banner__video .embed--gvp gvp-data img {
      display: block; }
  .touchevents.no-videoautoplay .banner__video iframe,
  .touchevents.no-videoautoplay .banner__video gvp-background {
    display: none !important; }

/* Banner Content */
.banner__content {
  max-width: 1220px;
  padding: 20px;
  margin: 0 auto;
  color: inherit;
  /* for Center or Default alignment */
  /* Left-aligned or Right-aligned */
  /* Left-aligned */
  /* Right-aligned */
  /*Mobile centered text */
  /* Homepage Featured Banners */ }
  @media (min-width: 768px) {
    .banner__content .reduced_width--10 {
      margin: auto 10%; } }
  @media (min-width: 768px) {
    .banner__content .reduced_width--20 {
      margin: auto 20%; } }
  @media (min-width: 768px) {
    .banner__content .reduced_width--30 {
      margin: auto 30%; } }
  @media (min-width: 768px) {
    .banner__content .reduced_width--40 {
      margin: auto 40%; } }
  @media (min-width: 768px) {
    .banner__content .reduced_width--50 {
      margin: auto 50%; } }
  @media (min-width: 768px) {
    .banner__content {
      position: absolute;
      padding: 0 55px;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%; } }
  @media (min-width: 1440px) {
    .banner__content {
      padding: 0; }
      .col .banner__content {
        padding: 0 55px; }
      .row--flush-horizontal .col .banner__content {
        padding: 0; } }
  .banner__content.banner__content--left .content__wrap, .banner__content.banner__content--right .content__wrap {
    align-items: flex-start;
    text-align: left !important; }
    @media (min-width: 768px) {
      .banner__content.banner__content--left .content__wrap .banner__text,
      .banner__content.banner__content--left .content__wrap .banner__cta, .banner__content.banner__content--right .content__wrap .banner__text,
      .banner__content.banner__content--right .content__wrap .banner__cta {
        position: relative; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .banner__content.banner__content--left .content__wrap .banner__text, .banner__content.banner__content--right .content__wrap .banner__text {
        max-width: 60%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--left .reduced_width--10 {
      margin: 0 10% 0 0; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--left .reduced_width--20 {
      margin: 0 20% 0 0; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--left .reduced_width--30 {
      margin: 0 30% 0 0; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--left .reduced_width--40 {
      margin: 0 40% 0 0; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--left .reduced_width--50 {
      margin: 0 50% 0 0; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .reduced_width--10 {
      margin: 0 0 0 10%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .reduced_width--20 {
      margin: 0 0 0 20%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .reduced_width--30 {
      margin: 0 0 0 30%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .reduced_width--40 {
      margin: 0 0 0 40%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .reduced_width--50 {
      margin: 0 0 0 50%; } }
  @media (min-width: 768px) {
    .banner__content.banner__content--right .content__wrap .banner__text,
    .banner__content.banner__content--right .content__wrap .banner__cta {
      left: 40%; } }
  @media (min-width: 1440px) {
    .banner__content.banner__content--right .content__wrap .banner__text,
    .banner__content.banner__content--right .content__wrap .banner__cta {
      left: 50%; } }
  @media (max-width: 767px) {
    .banner__content.banner__mobile--center .content__wrap {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center !important; } }
  @media (max-width: 767px) {
    .banner--featured .banner__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%; } }
  @media (min-width: 768px) {
    .banner--featured .banner__content {
      padding: 0; } }
  .banner__content .content__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    /* Flexbox fallback */
    /* Homepage Featured Banners */ }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .banner__content .content__wrap {
      position: relative;
      height: auto;
      transform: translateY(-50%);
      top: 50%; }
    @media (max-width: 767px) {
      .banner--featured .banner__content .content__wrap {
        flex-direction: row; } }
    @media (min-width: 768px) {
      .banner--featured .banner__content .content__wrap {
        justify-content: flex-end;
        padding-bottom: 6%; } }
    @media (min-width: 1220px) {
      .banner--featured .banner__content .content__wrap {
        padding-bottom: 5%; } }

/* Banner Text */
.banner__text {
  transition: all 300ms ease;
  max-width: 100%;
  /* Dark text */
  /* Homepage Featured Banners */
  /* Bump banner text down if the banner component is the first component in the page after a transparent header */
  /* */
  /* Banner Headline - Text Format */
  /* Banner Headline - Image Format */
  /* Banner Text - Level 2 */
  /* Banner Text - Level 3 */
  /* Banner Text - Level 4 */ }
  @media (min-width: 768px) {
    .banner__text {
      color: #FFF !important; } }
  @media (min-width: 1024px) {
    .banner__text {
      max-width: 50%; } }
  @media (min-width: 768px) {
    .banner__text.banner__text--dark {
      color: #000 !important; } }
  .banner--featured .banner__text {
    width: 100%;
    max-width: 65%;
    padding: 0 10px; }
    @media (min-width: 640px) {
      .banner--featured .banner__text {
        padding: 0 50px 0 0; } }
    @media (min-width: 768px) {
      .banner--featured .banner__text {
        padding: 0;
        max-width: 60%; } }
  @media (min-width: 768px) {
    .col-4 .banner--featured .banner__text,
    .col-6 .banner--featured .banner__text {
      max-width: 80%; } }
  @media (min-width: 1220px) {
    .header--transparent + .content .banner__text.banner__text--adjusted,
    .header--transparent + header + .content .banner__text.banner__text--adjusted {
      margin-top: 105px; } }
  @media (min-width: 1220px) {
    .header--transparent + .content .banner__text.banner__text--adjusted-lg,
    .header--transparent + header + .content .banner__text.banner__text--adjusted-lg {
      margin-top: calc(105px - 40px); } }
  .banner__text .banner__level2,
  .banner__text .banner__level3,
  .banner__text .banner__level4 {
    transition: all 300ms ease;
    margin-top: 15px;
    /* Homepage Featured Banners */ }
    .banner__text .banner__level2:first-child,
    .banner__text .banner__level3:first-child,
    .banner__text .banner__level4:first-child {
      margin-top: 0; }
    @media (min-width: 768px) and (max-width: 1219px) {
      .banner--featured .banner__text .banner__level2, .banner--featured
      .banner__text .banner__level3, .banner--featured
      .banner__text .banner__level4 {
        margin-top: 15px; } }
    .banner__text .banner__level2 p,
    .banner__text .banner__level3 p,
    .banner__text .banner__level4 p {
      margin: 0;
      padding: 0; }
  .banner__text .banner__headline--text {
    transition: all 300ms ease;
    font-weight: 300;
    /* Featured Banners (thicker text) */
    /* Homepage Featured Banners */ }
    @media (min-width: 1220px) {
      .banner__text .banner__headline--text {
        line-height: 1.15; } }
    @media (min-width: 768px) {
      .banner__text .banner__headline--text:not(:only-child), .banner__text .banner__headline--text:not(:last-child) {
        display: none; } }
    .banner__content--featured .banner__text .banner__headline--text {
      font-weight: 700; }
    .banner--featured .banner__text .banner__headline--text {
      font-size: 27px;
      font-size: 2.7rem;
      line-height: 1; }
      @media (max-width: 767px) {
        .banner--featured .banner__text .banner__headline--text {
          color: #FFF; } }
      @media (min-width: 768px) {
        .banner--featured .banner__text .banner__headline--text {
          font-size: 35px;
          font-size: 3.5rem;
          line-height: 1.15; } }
      @media (min-width: 1024px) {
        .banner--featured .banner__text .banner__headline--text {
          font-size: 38px;
          font-size: 3.8rem; } }
      @media (min-width: 1440px) {
        .banner--featured .banner__text .banner__headline--text {
          font-size: 45px;
          font-size: 4.5rem; } }
  .banner__text .banner__headline--img {
    transition: all 300ms ease;
    display: none;
    margin: 0 auto;
    /* Homepage Featured Banners */ }
    @media (min-width: 768px) {
      .banner__text .banner__headline--img {
        display: inline-block;
        max-width: 55%;
        margin-bottom: 5px; }
        .banner__text .banner__headline--img:not(:first-child), .banner__text .banner__headline--img:not(:only-child) {
          display: inline-block; } }
    @media (min-width: 1220px) {
      .banner__text .banner__headline--img {
        max-width: 65%; } }
    @media (min-width: 1440px) {
      .banner__text .banner__headline--img {
        max-width: 45%; } }
    @media (min-width: 1600px) {
      .banner__text .banner__headline--img {
        max-width: 75%; } }
    @media (min-width: 768px) {
      .banner--featured .banner__text .banner__headline--img {
        max-width: 60%; } }
    @media (min-width: 1440px) {
      .banner--featured .banner__text .banner__headline--img {
        max-width: 85%; } }
  .banner__text .banner__level2 {
    font-size: 30px;
    font-size: 3rem;
    line-height: 1.15;
    /* Featured Banners (thicker text) */
    /* Homepage Featured Banners */ }
    @media (min-width: 1024px) {
      .banner__text .banner__level2 {
        font-size: 34px;
        font-size: 3.4rem; } }
    @media (min-width: 1220px) {
      .banner__text .banner__level2 {
        font-size: 36px;
        font-size: 3.6rem; } }
    @media (min-width: 1440px) {
      .banner__text .banner__level2 {
        font-size: 40px;
        font-size: 4rem; } }
    .banner__content--featured .banner__text .banner__level2 {
      font-weight: 700; }
    .banner--featured .banner__text .banner__level2 {
      font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
      @media (max-width: 767px) {
        .banner--featured .banner__text .banner__level2 {
          margin-top: 5px;
          color: #FFF; }
          .banner--featured .banner__text .banner__level2:last-child {
            margin-bottom: 5px; } }
      @media (max-width: 1023px) {
        .banner--featured .banner__text .banner__level2 {
          font-size: 17px;
          font-size: 1.7rem; } }
      @media (min-width: 1024px) {
        .banner--featured .banner__text .banner__level2 {
          font-size: 20px;
          font-size: 2rem; } }
  .banner__text .banner__level3,
  .banner__text .banner__level4 {
    line-height: 1.5;
    /* Homepage Featured Banners */ }
    .banner__text .banner__level3 a,
    .banner__text .banner__level4 a {
      text-decoration: underline; }
    @media (max-width: 767px) {
      .banner--featured .banner__text .banner__level3, .banner--featured
      .banner__text .banner__level4 {
        display: none; } }
    @media (min-width: 1220px) {
      .banner--featured .banner__text .banner__level3, .banner--featured
      .banner__text .banner__level4 {
        line-height: 1.25; } }
    @media (min-width: 1600px) {
      .banner--featured .banner__text .banner__level3, .banner--featured
      .banner__text .banner__level4 {
        line-height: 1.35; } }
    @media (min-width: 768px) and (max-width: 1219px) {
      .col-4 .banner--featured .banner__text .banner__level3,
      .col-6 .banner--featured .banner__text .banner__level3, .col-4 .banner--featured
      .banner__text .banner__level4,
      .col-6 .banner--featured
      .banner__text .banner__level4 {
        line-height: 1.25; } }
  .banner__text .banner__level3 {
    font-size: 18px;
    font-size: 1.8rem; }
    @media (min-width: 1220px) {
      .banner__text .banner__level3 {
        font-size: 21px;
        font-size: 2.1rem; } }
  .banner__text .banner__level4 {
    font-size: 12px;
    font-size: 1.2rem;
    font-family: "Arial", "Helvetica", sans-serif; }
  .banner__text a {
    color: inherit; }

/* Banner Call-to-Action */
.banner__cta {
  /* Homepage Featured Banners */ }
  @media (min-width: 768px) {
    .banner__cta {
      margin-bottom: 0; }
      .banner__cta .cta--button {
        border: 0;
        background-color: #009FDB;
        color: #FFF;
        text-decoration: none; }
        .banner__cta .cta--button:hover {
          background-color: #0090c7;
          color: #FFF; } }
  .banner__cta .cta--link {
    font-size: 21px;
    font-size: 2.1rem;
    display: inline-block;
    margin-top: 10px;
    font-weight: 400; }
  .banner--featured .banner__cta {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .banner--featured .banner__cta {
        margin-bottom: 20px; } }
    @media (min-width: 768px) and (max-width: 1219px) {
      .banner--featured .banner__cta {
        margin: 25px 0; } }
    @media (min-width: 1220px) {
      .banner--featured .banner__cta {
        margin-top: 50px; } }
    @media (min-width: 1600px) {
      .banner--featured .banner__cta {
        margin-top: 70px; } }
    @media (max-width: 1599px) {
      .col .banner--featured .banner__cta .cta--button {
        font-size: 15px;
        font-size: 1.5rem;
        min-width: 135px;
        padding: 12px; } }

/* Marquee Banner Settings */
.marquee--banner,
.marquee {
  /* Make sure any video banners are tall enough */
  /* Tighten mobile spacing above cta for banners used in marquees */
  /* Make sure any video banner content overlays its video */ }
  .marquee--banner .banner__content,
  .marquee .banner__content {
    /* Anchor banner content to bottom */ }
    @media (max-width: 767px) {
      .marquee--banner .banner__content,
      .marquee .banner__content {
        padding-bottom: 0; } }
    @media (max-width: 1023px) {
      .marquee--banner .banner__content,
      .marquee .banner__content {
        max-width: 800px; } }
    @media (min-width: 768px) {
      .marquee--banner .banner__content .content__wrap,
      .marquee .banner__content .content__wrap {
        justify-content: flex-end;
        padding-bottom: 10%; } }
    @media (min-width: 1024px) {
      .marquee--banner .banner__content .content__wrap,
      .marquee .banner__content .content__wrap {
        padding-bottom: 8%; } }
  .marquee--banner .banner__text,
  .marquee .banner__text {
    /* Anchor banner content to bottom */
    /* Adjust banner headline size */ }
    @media (min-width: 1024px) and (max-width: 1439px) {
      .marquee--banner .banner__text,
      .marquee .banner__text {
        max-width: 65%; } }
    @media (min-width: 768px) {
      .marquee--banner .banner__text .banner__headline--text,
      .marquee .banner__text .banner__headline--text {
        font-size: 40px;
        font-size: 4rem; } }
    @media (min-width: 1024px) {
      .marquee--banner .banner__text .banner__headline--text,
      .marquee .banner__text .banner__headline--text {
        font-size: 50px;
        font-size: 5rem; } }
    @media (min-width: 1440px) {
      .marquee--banner .banner__text .banner__headline--text,
      .marquee .banner__text .banner__headline--text {
        font-size: 60px;
        font-size: 6rem; } }
    @media (min-width: 1600px) {
      .marquee--banner .banner__text .banner__headline--text,
      .marquee .banner__text .banner__headline--text {
        font-size: 65px;
        font-size: 6.5rem; } }
  .marquee--banner .banner__text .banner__level2,
  .marquee--banner .banner__text .banner__level3,
  .marquee--banner .banner__text .banner__level4,
  .marquee .banner__text .banner__level2,
  .marquee .banner__text .banner__level3,
  .marquee .banner__text .banner__level4 {
    /* Tighten up the mobile spacing between headlines for banners used in marquees */ }
    @media (max-width: 767px) {
      .marquee--banner .banner__text .banner__level2,
      .marquee--banner .banner__text .banner__level3,
      .marquee--banner .banner__text .banner__level4,
      .marquee .banner__text .banner__level2,
      .marquee .banner__text .banner__level3,
      .marquee .banner__text .banner__level4 {
        margin-top: 10px; } }
  .marquee--banner .banner__text .banner__level2,
  .marquee .banner__text .banner__level2 {
    font-size: 26px;
    font-size: 2.6rem;
    /* Reduce mobile subheadline size for banners used in marquees */ }
    @media (max-width: 767px) {
      .marquee--banner .banner__text .banner__level2,
      .marquee .banner__text .banner__level2 {
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: 400; } }
    @media (min-width: 1024px) {
      .marquee--banner .banner__text .banner__level2,
      .marquee .banner__text .banner__level2 {
        font-size: 30px;
        font-size: 3rem; } }
  .marquee--banner .banner__video,
  .marquee .banner__video {
    min-height: 215px; }
    @media (min-width: 768px) {
      .marquee--banner .banner__video,
      .marquee .banner__video {
        min-height: 425px; } }
    @media (min-width: 1024px) {
      .marquee--banner .banner__video,
      .marquee .banner__video {
        height: 565px; } }
    @media (min-width: 1601px) {
      .marquee--banner .banner__video,
      .marquee .banner__video {
        min-height: 632px; } }
  .marquee--banner .banner__cta,
  .marquee .banner__cta {
    margin: 20px 0 0; }
    @media (min-width: 1440px) {
      .marquee--banner .banner__cta,
      .marquee .banner__cta {
        margin-top: 25px; } }
  .marquee--banner .banner__cta .cta--button,
  .marquee .banner__cta .cta--button {
    margin-top: 5px; }
    .region--dark-blue .marquee--banner .banner__cta .cta--button, .region--dark-blue
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--near-black .marquee--banner .banner__cta .cta--button, .region--near-black
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--black .marquee--banner .banner__cta .cta--button, .region--black
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--blue--wh-text .marquee--banner .banner__cta .cta--button, .region--blue--wh-text
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--comms-cobalt .marquee--banner .banner__cta .cta--button, .region--comms-cobalt
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--comms-purple .marquee--banner .banner__cta .cta--button, .region--comms-purple
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--blue-gradient--wh-text .marquee--banner .banner__cta .cta--button, .region--blue-gradient--wh-text
    .marquee .banner__cta .cta--button {
      border: 0; }
    .region--comms-cobalt-purple .marquee--banner .banner__cta .cta--button, .region--comms-cobalt-purple
    .marquee .banner__cta .cta--button {
      border: 0; }
    @media (min-width: 768px) and (max-width: 1219px) {
      .marquee--banner .banner__cta .cta--button,
      .marquee .banner__cta .cta--button {
        margin-top: 0; } }
  @media (max-width: 767px) {
    .marquee--banner .banner--featured .banner__cta,
    .marquee .banner--featured .banner__cta {
      margin: 0; } }
  @media (min-width: 768px) {
    .marquee--banner .banner--featured .banner__cta,
    .marquee .banner--featured .banner__cta {
      margin: 20px 0 0; } }
  .marquee--banner [class*="gvp_gvp-background__"],
  .marquee [class*="gvp_gvp-background__"] {
    z-index: -1 !important; }

/*============
  BRAND ASSETS
  ============*/
.brand-assets {
  max-width: 1220px;
  margin: 0 auto;
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .brand-assets {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (min-width: 1440px) {
    .brand-assets {
      padding-left: 0;
      padding-right: 0; } }
  .brand-assets .assets__panels {
    margin-bottom: 70px; }
    @media (min-width: 1024px) {
      .brand-assets .assets__panels {
        display: flex;
        justify-content: center; } }
    @media (min-width: 1024px) {
      .brand-assets .assets__panels > div {
        flex: 1; } }
    .brand-assets .assets__panels .assets__panel-left {
      border-bottom: 1px solid #C9C9C9;
      margin-bottom: 30px; }
      @media (min-width: 1024px) {
        .brand-assets .assets__panels .assets__panel-left {
          max-width: 370px;
          border-bottom: 0;
          margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .brand-assets .assets__panels .assets__panel-right {
        max-width: 835px;
        border-left: 1px solid #C9C9C9; } }
  .brand-assets .assets__filter-nav {
    padding: 0 20px; }
    @media (min-width: 1440px) {
      .brand-assets .assets__filter-nav {
        padding-left: 0; } }
    .brand-assets .assets__filter-nav div[class^="asset-"] {
      margin-bottom: 30px; }
      @media (min-width: 1024px) {
        .brand-assets .assets__filter-nav div[class^="asset-"] {
          margin-bottom: 60px; } }
    .brand-assets .assets__filter-nav h3 {
      font-size: 18px;
      font-size: 1.8rem;
      margin-bottom: 15px; }
    @media (min-width: 1024px) {
      .brand-assets .assets__filter-nav .tag-toggle,
      .brand-assets .assets__filter-nav .type-toggle {
        display: none; } }
    .brand-assets .assets__filter-nav .asset-check-group-toggle {
      display: block;
      font-weight: 600;
      margin-bottom: 20px; }
      .brand-assets .assets__filter-nav .asset-check-group-toggle:hover {
        text-decoration: none; }
      .brand-assets .assets__filter-nav .asset-check-group-toggle:focus {
        outline: 1px dashed #959595; }
      @media (min-width: 1024px) {
        .brand-assets .assets__filter-nav .asset-check-group-toggle {
          display: none; } }
    .brand-assets .assets__filter-nav .asset-check-group {
      display: none; }
      .brand-assets .assets__filter-nav .asset-check-group.open {
        display: flex; }
      @media (min-width: 1024px) {
        .brand-assets .assets__filter-nav .asset-check-group {
          display: flex;
          flex-direction: column; } }
      .brand-assets .assets__filter-nav .asset-check-group > div {
        flex: 1; }
    .brand-assets .assets__filter-nav .checkbox {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .brand-assets .assets__filter-nav .checkbox:last-child {
        margin-bottom: 0; }
      .brand-assets .assets__filter-nav .checkbox input[type="checkbox"] {
        opacity: 0;
        height: 26px;
        width: 26px;
        margin: 0 8px 0 0; }
        .brand-assets .assets__filter-nav .checkbox input[type="checkbox"]:focus + label,
        .brand-assets .assets__filter-nav .checkbox input[type="checkbox"]:focus + label::before {
          outline: 1px dashed #959595; }
      .brand-assets .assets__filter-nav .checkbox input[type="checkbox"] + label {
        font-size: 14px;
        font-size: 1.4rem; }
        .brand-assets .assets__filter-nav .checkbox input[type="checkbox"] + label::before {
          content: "";
          position: absolute;
          display: block;
          border: 1px solid #5A5A5A;
          border-radius: 2px;
          height: 26px;
          width: 26px;
          left: 0;
          top: 0; }
        .brand-assets .assets__filter-nav .checkbox input[type="checkbox"] + label::after {
          content: none;
          position: absolute;
          display: inline-block;
          height: 9px;
          width: 14px;
          top: 6.5px;
          left: 6.5px;
          border-left: 3px solid;
          border-bottom: 3px solid;
          transform: rotate(-45deg);
          color: #FFF; }
      .brand-assets .assets__filter-nav .checkbox input[type="checkbox"]:checked + label::before {
        border-color: #0057B8;
        background-color: #0057B8; }
      .brand-assets .assets__filter-nav .checkbox input[type="checkbox"]:checked + label::after {
        content: ""; }
  .brand-assets .asset-search {
    flex-grow: 1;
    padding: 0; }
    .brand-assets .asset-search > form {
      padding: 0; }
    .brand-assets .asset-search fieldset {
      border-radius: 3px;
      border: 1px solid #5A5A5A !important;
      max-width: none;
      margin: 0; }
    .brand-assets .asset-search .search__input {
      font-size: 14px;
      font-size: 1.4rem;
      position: relative;
      background-color: #FFF;
      height: 45px;
      width: calc(100% - 50px);
      margin: 2px;
      padding: 5px 0 7px 14px; }
      .brand-assets .asset-search .search__input:focus {
        outline: 1px dashed #959595; }
    .brand-assets .asset-search .search__clear {
      position: relative;
      display: none;
      margin: 0 0 0 -28px;
      padding-right: 6px;
      height: 49px; }
      .brand-assets .asset-search .search__clear:after {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        content: "";
        width: 50px;
        height: calc(100% - 4px);
        right: 0;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
        /* IE6-9 */
        z-index: 1; }
      .brand-assets .asset-search .search__clear .icon--clear {
        display: block;
        position: relative;
        background-color: #d2d2d2;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        z-index: 2; }
      .brand-assets .asset-search .search__clear .icon--clear:after {
        font-size: 27px;
        font-size: 2.7rem;
        position: absolute;
        display: inline-block;
        content: "+";
        transform: translate(-50%, -50%) rotate(45deg);
        top: 48%;
        left: 55%;
        font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
        font-weight: 300;
        color: #FFF; }
    .brand-assets .asset-search .search__submit {
      height: calc(100% - 4px);
      width: 45px;
      right: 0;
      margin: 2px; }
      .brand-assets .asset-search .search__submit:focus {
        outline: 1px dashed #959595; }
    .brand-assets .asset-search .search__submit span:after {
      font-size: 35px;
      font-size: 3.5rem;
      color: #0057B8 !important; }
  .brand-assets .assets__feed .row + .row {
    margin-top: 0; }
  @media (min-width: 768px) {
    .brand-assets .assets__feed .row {
      padding: 0 20px; } }
  @media (min-width: 1440px) {
    .brand-assets .assets__feed .row {
      padding-right: 0; } }
  @media (min-width: 1440px) {
    .brand-assets .assets__feed .row__wrap {
      margin-right: -20px; } }
  @media (min-width: 500px) and (max-width: 767px) {
    .brand-assets .assets__feed .col {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 768px) {
    .brand-assets .assets__feed .col {
      max-width: 50%;
      flex-basis: 50%; } }
  @media (min-width: 768px) {
    .brand-assets .assets__feed .component {
      padding-bottom: 20px;
      margin-bottom: 10px; } }
  .brand-assets .assets__feed .item__media {
    position: relative;
    overflow: hidden; }
    @media (min-width: 1024px) {
      .brand-assets .assets__feed .item__media {
        height: 240px; } }
    @media (min-width: 1220px) {
      .brand-assets .assets__feed .item__media {
        height: 295px; } }
  @media (min-width: 1024px) {
    .brand-assets .assets__feed .item__media > div {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; } }
  .brand-assets .assets__feed .item__media .item__preview {
    display: block;
    height: 100%;
    width: 100%; }
    .brand-assets .assets__feed .item__media .item__preview:focus {
      outline: 1px dashed #959595;
      max-height: calc(100% - 4px);
      max-width: calc(100% - 4px);
      margin: 2px; }
  @media (min-width: 1024px) {
    .brand-assets .assets__feed .item__media .item__video > * {
      height: 100%; } }
  @media (min-width: 1024px) {
    .brand-assets .assets__feed .item__media .item__video .item__preview img {
      max-width: none; } }
  @media (min-width: 1220px) {
    .brand-assets .assets__feed .item__media .item__video .item__preview img {
      height: auto;
      width: 100%; } }
  .brand-assets .assets__feed .item__media .item__video .item__thumbnail:after,
  .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05); }
  .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail {
    position: relative; }
    .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
      content: "";
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif;
      font-size: 10rem;
      text-align: center;
      color: #FFF;
      opacity: 0.85; }
      @media (max-width: 480px) {
        .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 768px) {
        .col-2 .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 1024px) {
        .col-3 .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 768px) and (max-width: 1023px) {
        .col-4 .brand-assets .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
          font-size: 8rem; } }
  .brand-assets .assets__feed .item__media .item__img .item__thumbnail {
    height: 100%; }
  .brand-assets .assets__feed .item__media .item__img .item__thumbnail img {
    width: auto; }
    @media (max-width: 1023px) {
      .brand-assets .assets__feed .item__media .item__img .item__thumbnail img {
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1024px) {
      .brand-assets .assets__feed .item__media .item__img .item__thumbnail img {
        position: relative;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%; } }
  @media (min-width: 1024px) {
    .brand-assets .assets__feed .item__title {
      min-height: 45px; } }
  .brand-assets .assets__feed .item__title a {
    color: inherit; }
    .brand-assets .assets__feed .item__title a:hover {
      text-decoration: none; }
  .brand-assets .assets__feed .item__description {
    max-width: 500px;
    margin-top: 30px; }
  .brand-assets .assets__feed .item__cta {
    margin: 15px 0 0;
    /* Fixes for GVP watch links */ }
    .brand-assets .assets__feed .item__cta:first-child {
      margin-top: 0; }
    .brand-assets .assets__feed .item__cta a {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
      padding-bottom: 2px;
      color: #0568AE; }
    .brand-assets .assets__feed .item__cta .read-more {
      margin-right: 6px;
      padding-left: 10px;
      border-left: 1px solid #191919; }
      .brand-assets .assets__feed .item__cta .read-more:focus {
        outline: 1px dashed #959595; }
      .brand-assets .assets__feed .item__cta .read-more:first-child {
        padding-left: 0;
        border-left: 0; }
    .brand-assets .assets__feed .item__cta att-gvp {
      display: inline-block !important;
      width: auto !important; }
      .brand-assets .assets__feed .item__cta att-gvp > div {
        overflow: initial !important; }
  .brand-assets .assets__cta {
    display: none;
    text-align: center;
    margin: 20px 0 0; }
    @media (min-width: 1024px) {
      .brand-assets .assets__cta {
        margin: 40px auto 0; } }

/*=============
  FEATURED ITEM
  =============*/
.featured-item {
  padding: 0 20px; }
  .featured-item:after {
    content: "";
    display: table;
    clear: both; }
  .col .featured-item {
    padding: 0; }
  @media (min-width: 768px) {
    .row--equal-heights .featured-item {
      height: 100%;
      display: flex;
      flex-direction: column; } }
  @media (min-width: 1024px) {
    .featured-item {
      padding: 0; }
      .row--equal-heights .featured-item {
        display: flex;
        flex-direction: column; } }
  @media (min-width: 768px) {
    .col-12 .featured-item.featured-item--left,
    .col-12 .featured-item.featured-item--right {
      display: flex;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 768px) {
    .col-12 .featured-item.featured-item--right {
      flex-direction: row-reverse; } }
  @media (min-width: 640px) {
    .featured-item.featured-item--alternate {
      padding-left: 10%;
      padding-right: 10%; }
      .col-4 .featured-item.featured-item--alternate {
        padding-left: 0;
        padding-right: 0; } }
  @media (min-width: 1220px) {
    .featured-item.featured-item--alternate {
      padding-left: 105px;
      padding-right: 105px; }
      .col-2 .featured-item.featured-item--alternate,
      .col-3 .featured-item.featured-item--alternate {
        padding-left: 10%;
        padding-right: 10%; } }

/* Featured Item Media */
.item__media {
  overflow: hidden;
  /* General Preview Images */
  /* Image */
  /* Video */ }
  .featured-item--alternate .item__media {
    background-color: transparent; }
  .featured-item--border .item__media {
    border: 1px solid #5A5A5A; }
  @media (min-width: 768px) {
    .col-12 .featured-item--left .item__media,
    .col-12 .featured-item--right .item__media {
      width: 50%;
      max-width: 470px; } }
  @media (min-width: 768px) {
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col-12 .featured-item--left .item__media {
      float: left; } }
  @media (min-width: 768px) {
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col-12 .featured-item--right .item__media {
      float: right; } }
  .item__media .item__preview:hover {
    text-decoration: none; }
  .item__media .item__preview:focus {
    outline: 0; }
  .item__media .item__img {
    position: relative;
    background-color: #FFF; }
    .featured-item--alternate .item__media .item__img {
      background-color: transparent; }
    @media (min-width: 768px) {
      .col-2 .item__media .item__img {
        background-color: transparent; } }
    .item__media .item__img a {
      display: block;
      width: 100%;
      height: 100%; }
      .item__media .item__img a:not(.item__preview) {
        transition: opacity 200ms ease; }
        .featured-item .item__media .item__img a:not(.item__preview):hover,
        .featured-item .item__media .item__img a:not(.item__preview):focus {
          opacity: 0.9; }
    .item__media .item__img[data-placeholder="true"] {
      height: 35px;
      background-color: #000; }
      .region[class*="-black"] .item__media .item__img[data-placeholder="true"] {
        background-color: #191919; }
      .item__media .item__img[data-placeholder="true"] img {
        display: none; }
      .item__media .item__img[data-placeholder="true"] a:hover {
        text-decoration: none; }
    .item__media .item__img img {
      display: block;
      max-width: 100%; }
      @media (max-width: 767px) {
        .item__media .item__img img {
          width: 100%; } }
      .featured-item--alternate .item__media .item__img img {
        margin: 0 auto;
        width: auto; }
  .item__media .item__video[data-type="youtube"] .item__video--gvp {
    display: none; }
  .item__media .item__video[data-type="gvp"] .item__video--youtube {
    display: none; }
  .item__media .item__video .item__preview img {
    display: block;
    max-width: 100%;
    width: 100%; }
  .item__media .item__video .item__thumbnail {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .item__media .item__video .item__thumbnail:after {
      content: "";
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif;
      font-size: 10rem;
      text-align: center;
      color: #FFF;
      opacity: 0.85; }
      @media (max-width: 480px) {
        .item__media .item__video .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 768px) {
        .col-2 .item__media .item__video .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 1024px) {
        .col-3 .item__media .item__video .item__thumbnail:after {
          font-size: 8rem; } }
      @media (min-width: 768px) and (max-width: 1023px) {
        .col-4 .item__media .item__video .item__thumbnail:after {
          font-size: 8rem; } }
  .item__media [class*="embed--"] {
    display: none; }
    .item__media [class*="embed--"]:first-child {
      display: block; }

/* Item Text Content */
.region--dark-blue .item__content .item__title,
.region--dark-blue .item__content .item__info,
.region--dark-blue .item__content .item__description {
  color: #191919; }

.region--dark-blue .item__content .cta--link {
  color: #191919; }

.region--dark-blue .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--dark-blue .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--dark-blue .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--dark-blue .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--dark-blue .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--dark-blue .col-12 .featured-item--left .item__content .item__title,
  .region--dark-blue .col-12 .featured-item--left .item__content .item__info,
  .region--dark-blue .col-12 .featured-item--left .item__content .item__description,
  .region--dark-blue .col-12 .featured-item--right .item__content .item__title,
  .region--dark-blue .col-12 .featured-item--right .item__content .item__info,
  .region--dark-blue .col-12 .featured-item--right .item__content .item__description,
  .region--dark-blue .col-2 .item__content .item__title,
  .region--dark-blue .col-2 .item__content .item__info,
  .region--dark-blue .col-2 .item__content .item__description {
    color: #FFF; }
  .region--dark-blue .col-12 .featured-item--left .item__content .cta--link,
  .region--dark-blue .col-12 .featured-item--right .item__content .cta--link,
  .region--dark-blue .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--dark-blue .col-12 .featured-item--left .item__content .cta--button,
  .region--dark-blue .col-12 .featured-item--right .item__content .cta--button,
  .region--dark-blue .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--dark-blue .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--dark-blue .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--dark-blue .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--near-black .item__content .item__title,
.region--near-black .item__content .item__info,
.region--near-black .item__content .item__description {
  color: #191919; }

.region--near-black .item__content .cta--link {
  color: #191919; }

.region--near-black .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--near-black .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--near-black .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--near-black .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--near-black .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--near-black .col-12 .featured-item--left .item__content .item__title,
  .region--near-black .col-12 .featured-item--left .item__content .item__info,
  .region--near-black .col-12 .featured-item--left .item__content .item__description,
  .region--near-black .col-12 .featured-item--right .item__content .item__title,
  .region--near-black .col-12 .featured-item--right .item__content .item__info,
  .region--near-black .col-12 .featured-item--right .item__content .item__description,
  .region--near-black .col-2 .item__content .item__title,
  .region--near-black .col-2 .item__content .item__info,
  .region--near-black .col-2 .item__content .item__description {
    color: #FFF; }
  .region--near-black .col-12 .featured-item--left .item__content .cta--link,
  .region--near-black .col-12 .featured-item--right .item__content .cta--link,
  .region--near-black .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--near-black .col-12 .featured-item--left .item__content .cta--button,
  .region--near-black .col-12 .featured-item--right .item__content .cta--button,
  .region--near-black .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--near-black .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--near-black .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--near-black .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--black .item__content .item__title,
.region--black .item__content .item__info,
.region--black .item__content .item__description {
  color: #191919; }

.region--black .item__content .cta--link {
  color: #191919; }

.region--black .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--black .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--black .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--black .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--black .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--black .col-12 .featured-item--left .item__content .item__title,
  .region--black .col-12 .featured-item--left .item__content .item__info,
  .region--black .col-12 .featured-item--left .item__content .item__description,
  .region--black .col-12 .featured-item--right .item__content .item__title,
  .region--black .col-12 .featured-item--right .item__content .item__info,
  .region--black .col-12 .featured-item--right .item__content .item__description,
  .region--black .col-2 .item__content .item__title,
  .region--black .col-2 .item__content .item__info,
  .region--black .col-2 .item__content .item__description {
    color: #FFF; }
  .region--black .col-12 .featured-item--left .item__content .cta--link,
  .region--black .col-12 .featured-item--right .item__content .cta--link,
  .region--black .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--black .col-12 .featured-item--left .item__content .cta--button,
  .region--black .col-12 .featured-item--right .item__content .cta--button,
  .region--black .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--black .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--black .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--black .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--blue--wh-text .item__content .item__title,
.region--blue--wh-text .item__content .item__info,
.region--blue--wh-text .item__content .item__description {
  color: #191919; }

.region--blue--wh-text .item__content .cta--link {
  color: #191919; }

.region--blue--wh-text .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--blue--wh-text .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--blue--wh-text .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--blue--wh-text .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--blue--wh-text .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--blue--wh-text .col-12 .featured-item--left .item__content .item__title,
  .region--blue--wh-text .col-12 .featured-item--left .item__content .item__info,
  .region--blue--wh-text .col-12 .featured-item--left .item__content .item__description,
  .region--blue--wh-text .col-12 .featured-item--right .item__content .item__title,
  .region--blue--wh-text .col-12 .featured-item--right .item__content .item__info,
  .region--blue--wh-text .col-12 .featured-item--right .item__content .item__description,
  .region--blue--wh-text .col-2 .item__content .item__title,
  .region--blue--wh-text .col-2 .item__content .item__info,
  .region--blue--wh-text .col-2 .item__content .item__description {
    color: #FFF; }
  .region--blue--wh-text .col-12 .featured-item--left .item__content .cta--link,
  .region--blue--wh-text .col-12 .featured-item--right .item__content .cta--link,
  .region--blue--wh-text .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--blue--wh-text .col-12 .featured-item--left .item__content .cta--button,
  .region--blue--wh-text .col-12 .featured-item--right .item__content .cta--button,
  .region--blue--wh-text .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--blue--wh-text .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--blue--wh-text .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--blue--wh-text .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--comms-cobalt .item__content .item__title,
.region--comms-cobalt .item__content .item__info,
.region--comms-cobalt .item__content .item__description {
  color: #191919; }

.region--comms-cobalt .item__content .cta--link {
  color: #191919; }

.region--comms-cobalt .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--comms-cobalt .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--comms-cobalt .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--comms-cobalt .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--comms-cobalt .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--comms-cobalt .col-12 .featured-item--left .item__content .item__title,
  .region--comms-cobalt .col-12 .featured-item--left .item__content .item__info,
  .region--comms-cobalt .col-12 .featured-item--left .item__content .item__description,
  .region--comms-cobalt .col-12 .featured-item--right .item__content .item__title,
  .region--comms-cobalt .col-12 .featured-item--right .item__content .item__info,
  .region--comms-cobalt .col-12 .featured-item--right .item__content .item__description,
  .region--comms-cobalt .col-2 .item__content .item__title,
  .region--comms-cobalt .col-2 .item__content .item__info,
  .region--comms-cobalt .col-2 .item__content .item__description {
    color: #FFF; }
  .region--comms-cobalt .col-12 .featured-item--left .item__content .cta--link,
  .region--comms-cobalt .col-12 .featured-item--right .item__content .cta--link,
  .region--comms-cobalt .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--comms-cobalt .col-12 .featured-item--left .item__content .cta--button,
  .region--comms-cobalt .col-12 .featured-item--right .item__content .cta--button,
  .region--comms-cobalt .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-cobalt .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--comms-cobalt .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--comms-cobalt .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--comms-purple .item__content .item__title,
.region--comms-purple .item__content .item__info,
.region--comms-purple .item__content .item__description {
  color: #191919; }

.region--comms-purple .item__content .cta--link {
  color: #191919; }

.region--comms-purple .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--comms-purple .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--comms-purple .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--comms-purple .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--comms-purple .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--comms-purple .col-12 .featured-item--left .item__content .item__title,
  .region--comms-purple .col-12 .featured-item--left .item__content .item__info,
  .region--comms-purple .col-12 .featured-item--left .item__content .item__description,
  .region--comms-purple .col-12 .featured-item--right .item__content .item__title,
  .region--comms-purple .col-12 .featured-item--right .item__content .item__info,
  .region--comms-purple .col-12 .featured-item--right .item__content .item__description,
  .region--comms-purple .col-2 .item__content .item__title,
  .region--comms-purple .col-2 .item__content .item__info,
  .region--comms-purple .col-2 .item__content .item__description {
    color: #FFF; }
  .region--comms-purple .col-12 .featured-item--left .item__content .cta--link,
  .region--comms-purple .col-12 .featured-item--right .item__content .cta--link,
  .region--comms-purple .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--comms-purple .col-12 .featured-item--left .item__content .cta--button,
  .region--comms-purple .col-12 .featured-item--right .item__content .cta--button,
  .region--comms-purple .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-purple .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--comms-purple .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--comms-purple .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--blue-gradient--wh-text .item__content .item__title,
.region--blue-gradient--wh-text .item__content .item__info,
.region--blue-gradient--wh-text .item__content .item__description {
  color: #191919; }

.region--blue-gradient--wh-text .item__content .cta--link {
  color: #191919; }

.region--blue-gradient--wh-text .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--blue-gradient--wh-text .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--blue-gradient--wh-text .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--blue-gradient--wh-text .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--blue-gradient--wh-text .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .item__title,
  .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .item__info,
  .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .item__description,
  .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .item__title,
  .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .item__info,
  .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .item__description,
  .region--blue-gradient--wh-text .col-2 .item__content .item__title,
  .region--blue-gradient--wh-text .col-2 .item__content .item__info,
  .region--blue-gradient--wh-text .col-2 .item__content .item__description {
    color: #FFF; }
  .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .cta--link,
  .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .cta--link,
  .region--blue-gradient--wh-text .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .cta--button,
  .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .cta--button,
  .region--blue-gradient--wh-text .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--blue-gradient--wh-text .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--blue-gradient--wh-text .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--blue-gradient--wh-text .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.region--comms-cobalt-purple .item__content .item__title,
.region--comms-cobalt-purple .item__content .item__info,
.region--comms-cobalt-purple .item__content .item__description {
  color: #191919; }

.region--comms-cobalt-purple .item__content .cta--link {
  color: #191919; }

.region--comms-cobalt-purple .item__content .cta--button {
  border: 0;
  background-color: #000;
  color: #FFF;
  text-decoration: none; }
  .region--comms-cobalt-purple .item__content .cta--button:hover {
    background-color: #FFF;
    color: #000; }

.region--comms-cobalt-purple .featured-item--alternate .item__content .cta--link {
  color: #FFF !important;
  text-decoration: none !important; }

.region--comms-cobalt-purple .featured-item--alternate .item__content .cta--button {
  border: 2px solid #FFF;
  background-color: #FFF;
  color: #000;
  text-decoration: none; }
  .region--comms-cobalt-purple .featured-item--alternate .item__content .cta--button:hover {
    background-color: transparent;
    color: #FFF; }

@media (min-width: 768px) {
  .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .item__title,
  .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .item__info,
  .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .item__description,
  .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .item__title,
  .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .item__info,
  .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .item__description,
  .region--comms-cobalt-purple .col-2 .item__content .item__title,
  .region--comms-cobalt-purple .col-2 .item__content .item__info,
  .region--comms-cobalt-purple .col-2 .item__content .item__description {
    color: #FFF; }
  .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .cta--link,
  .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .cta--link,
  .region--comms-cobalt-purple .col-2 .item__content .cta--link {
    color: #FFF; }
  .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .cta--button,
  .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .cta--button,
  .region--comms-cobalt-purple .col-2 .item__content .cta--button {
    border: 2px solid #FFF;
    background-color: #FFF;
    color: #000;
    text-decoration: none; }
    .region--comms-cobalt-purple .col-12 .featured-item--left .item__content .cta--button:hover,
    .region--comms-cobalt-purple .col-12 .featured-item--right .item__content .cta--button:hover,
    .region--comms-cobalt-purple .col-2 .item__content .cta--button:hover {
      background-color: transparent;
      color: #FFF; } }

.item__content {
  flex: auto;
  padding: 20px 0px;
  background-color: #FFF; }
  .region--white .row--no-gutters .item__content,
  .region--white .row--flush-horizontal .item__content,
  .region--black .item__content,
  .region--gray .item__content,
  .region--blue--bl-text .item__content,
  .region--blue--wh-text .item__content,
  .region--comms-cobalt .item__content,
  .region--blue-gradient--wh-text .item__content,
  .region--blue-gradient--bl-text .item__content {
    padding-left: 20px; }
  .item__content .cta--link {
    text-decoration: none; }
    .region--white .item__content .cta--link, .region--gray .item__content .cta--link, .region--black .item__content .cta--link, .region--near-black .item__content .cta--link, .region[class*="blue"] .item__content .cta--link, .article .item__content .cta--link {
      color: #0568AE;
      text-decoration: none; }
    .region--white .featured-item--alternate .item__content .cta--link, .region--gray .featured-item--alternate .item__content .cta--link {
      color: #0568AE;
      text-decoration: none; }
    .region--black .featured-item--alternate .item__content .cta--link, .region--near-black .featured-item--alternate .item__content .cta--link {
      color: #009FDB;
      text-decoration: none; }
  .region--white .item__content .cta--button, .region--gray .item__content .cta--button, .region--black .item__content .cta--button, .region--near-black .item__content .cta--button {
    border: 0;
    background-color: #009FDB;
    color: #FFF;
    text-decoration: none; }
    .region--white .item__content .cta--button:hover, .region--gray .item__content .cta--button:hover, .region--black .item__content .cta--button:hover, .region--near-black .item__content .cta--button:hover {
      background-color: #0090c7;
      color: #FFF; }
  .region--white .featured-item--alternate .item__content .cta--button, .region--gray .featured-item--alternate .item__content .cta--button, .region--black .featured-item--alternate .item__content .cta--button, .region--near-black .featured-item--alternate .item__content .cta--button {
    border: 0;
    background-color: #009FDB;
    color: #FFF;
    text-decoration: none; }
    .region--white .featured-item--alternate .item__content .cta--button:hover, .region--gray .featured-item--alternate .item__content .cta--button:hover, .region--black .featured-item--alternate .item__content .cta--button:hover, .region--near-black .featured-item--alternate .item__content .cta--button:hover {
      background-color: #0090c7;
      color: #FFF; }
  @media (min-width: 768px) {
    .col-12 .featured-item--left .item__content,
    .col-12 .featured-item--right .item__content {
      padding: 0;
      width: 50%;
      background-color: transparent; }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col-12 .featured-item--left .item__content, .no-flexbox.no-flexboxtweener.no-flexboxlegacy
      .col-12 .featured-item--right .item__content {
        float: left; } }
  @media (min-width: 768px) {
    .col-12 .featured-item--left .item__content {
      padding-left: 45px; } }
  @media (min-width: 768px) {
    .col-12 .featured-item--right .item__content {
      padding-right: 45px; } }
  .featured-item--alternate .item__content {
    background-color: transparent;
    padding-left: 0; }
  @media (min-width: 768px) {
    .col-2 .item__content {
      /* Tiny orientation for smallest columns */
      background-color: transparent;
      padding: 10px 0 20px !important; } }
  @media (min-width: 1600px) {
    .item__content .content__wrap {
      max-width: 95%; } }
  .featured-item--alternate .item__content .content__wrap {
    max-width: none; }
  @media (min-width: 768px) {
    .col-12 .featured-item--left .item__content .content__wrap,
    .col-12 .featured-item--right .item__content .content__wrap {
      max-width: none; } }
  @media (min-width: 1024px) {
    .col-2 .item__content .content__wrap {
      max-width: none; } }
  .featured-item--alternate .item__content .item__title, .featured-item--alternate
  .item__content .item__info, .featured-item--alternate
  .item__content .item__description {
    color: inherit !important; }
  .item__content .item__title {
    font-size: 20px;
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    @media (min-width: 768px) {
      .col-12 .featured-item--left .item__content .item__title,
      .col-12 .featured-item--right .item__content .item__title {
        font-size: 30px;
        font-size: 3rem;
        line-height: 1.3; } }
    .featured-item--alternate .item__content .item__title {
      max-width: 90%; }
      @media (min-width: 768px) {
        .featured-item--alternate .item__content .item__title {
          font-size: 25px;
          font-size: 2.5rem; } }
      @media (min-width: 1024px) {
        .featured-item--alternate .item__content .item__title {
          font-size: 30px;
          font-size: 3rem; } }
      .col-4 .featured-item--alternate .item__content .item__title {
        font-size: 20px;
        font-size: 2rem;
        max-width: none; }
    .col-2 .featured-item:not(.featured-item--alternate) .item__content .item__title {
      /* Tiny orientation for smallest columns */
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.25; }
  .item__content .item__info {
    font-size: 13px;
    font-size: 1.3rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    margin: 10px 0 15px; }
    .item__content .item__info:first-child {
      margin-top: 0; }
    .featured-item--alternate .item__content .item__info {
      font-size: 16px;
      font-size: 1.6rem; }
    @media (min-width: 768px) {
      .col-12 .featured-item--left .item__content .item__info,
      .col-12 .featured-item--right .item__content .item__info,
      .featured-item--alternate .item__content .item__info {
        font-size: 18px;
        font-size: 1.8rem; } }
  .item__content .item__description {
    line-height: 1.45; }
    @media (min-width: 768px) {
      .col-12 .featured-item--left .item__content .item__description,
      .col-12 .featured-item--right .item__content .item__description {
        font-size: 21px;
        font-size: 2.1rem;
        line-height: 1.6; } }
    .featured-item--alternate .item__content .item__description {
      font-size: 18px;
      font-size: 1.8rem; }
      @media (min-width: 1024px) {
        .featured-item--alternate .item__content .item__description {
          font-size: 21px;
          font-size: 2.1rem;
          line-height: 1.6; } }
    .item__content .item__description p {
      margin-top: 10px; }
    .item__content .item__description ul,
    .item__content .item__description ol {
      margin: 15px 0 15px 20px; }
      .item__content .item__description ul ul,
      .item__content .item__description ul ol,
      .item__content .item__description ol ul,
      .item__content .item__description ol ol {
        margin: 15px 0 15px 15px; }
        .item__content .item__description ul ul > li,
        .item__content .item__description ul ol > li,
        .item__content .item__description ol ul > li,
        .item__content .item__description ol ol > li {
          margin-left: 15px; }
          @media (min-width: 1220px) {
            .item__content .item__description ul ul > li,
            .item__content .item__description ul ol > li,
            .item__content .item__description ol ul > li,
            .item__content .item__description ol ol > li {
              margin-left: 25px; } }
    .item__content .item__description ul > li:before {
      top: 12px; }
    .item__content .item__description li {
      margin-bottom: 5px; }
    .item__content .item__description:first-child p:first-child {
      margin-top: 0; }
    .item__content .item__description a {
      color: #0568AE; }
  .item__content .item__cta {
    margin: 20px 0 0;
    /* Links on black backgrounds */ }
    .featured-item--alternate .item__content .item__cta {
      margin-top: 50px;
      text-align: center; }
    @media (min-width: 768px) {
      .region[class*="-black"] .col-2 .item__content .item__cta .cta--link,
      .region[class*="-black"] .col-12 .featured-item--left .item__content .item__cta .cta--link,
      .region[class*="-black"] .col-12 .featured-item--right .item__content .item__cta .cta--link {
        color: #009FDB; } }
    @media (min-width: 768px) {
      .region--blue .col-2 .item__content .item__cta .cta--link,
      .region--blue .col-12 .featured-item--left .item__content .item__cta .cta--link,
      .region--blue .col-12 .featured-item--right .item__content .item__cta .cta--link {
        color: #191919;
        text-decoration: none; } }
    @media (min-width: 768px) {
      .region--dark-blue .col-2 .item__content .item__cta .cta--link,
      .region--dark-blue .col-12 .featured-item--left .item__content .item__cta .cta--link,
      .region--dark-blue .col-12 .featured-item--right .item__content .item__cta .cta--link {
        color: #FFF;
        text-decoration: none; } }
    @media (min-width: 1024px) {
      .col-12 .featured-item--left .item__content .item__cta .cta--link,
      .col-12 .featured-item--right .item__content .item__cta .cta--link {
        font-size: 21px;
        font-size: 2.1rem;
        font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
        font-weight: 400; } }

/*=====
    FACTS & STATS
    =====*/
.facts-stats .item__header {
  background-color: #F2F2F2; }

.facts-stats .item__icon, .facts-stats .item__text {
  width: 100%;
  height: 200px;
  padding: 40px;
  text-align: center;
  word-wrap: break-word;
  overflow: hidden; }
  .facts-stats .item__icon img, .facts-stats .item__text img {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    display: block;
    margin: 0 auto;
    max-width: 100%; }
  .facts-stats .item__icon h2, .facts-stats .item__text h2 {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    font-size: 40px;
    font-size: 4rem;
    display: block;
    color: #5A5A5A; }

.facts-stats .item__img {
  background-color: transparent; }
  .facts-stats .item__img img {
    margin: 0 auto; }

.facts-stats .item__content {
  padding: 40px; }
  .col-3 .facts-stats .item__content, .col-2 .facts-stats .item__content {
    padding: 20px; }
  .facts-stats .item__content h1, .facts-stats .item__content h2, .facts-stats .item__content h3, .facts-stats .item__content h4, .facts-stats .item__content h5, .facts-stats .item__content h6, .facts-stats .item__content p {
    margin-top: 0;
    margin-bottom: 10px; }
  @media (min-width: 1600px) {
    .facts-stats .item__content .content__wrap {
      max-width: 100%; } }

.facts-stats .item__header + .item__content {
  padding: 20px 40px; }

.facts-stats .item__cta {
  margin: 45px 0 0;
  text-align: center; }
  .facts-stats .item__cta .cta--button {
    position: relative;
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0 auto;
    border: 1px solid #191919;
    border-radius: 0;
    background-color: transparent;
    color: #191919;
    font-weight: normal; }
    .facts-stats .item__cta .cta--button:after {
      content: " \2192";
      color: inherit;
      font-weight: 400; }
    .facts-stats .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--blue .item__header {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--blue.facts-stats--full-height {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue.facts-stats--full-height .item__header, .facts-stats.facts-stats--blue.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--blue.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--blue.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--blue.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--blue.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--blue.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--blue.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--blue.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--blue.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--blue.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--dark-blue .item__header {
  background-color: #0568AE; }
  .facts-stats.facts-stats--dark-blue .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--dark-blue.facts-stats--full-height {
  background-color: #0568AE; }
  .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__header, .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--dark-blue.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--dark-blue.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--near-black .item__header {
  background-color: #191919; }
  .facts-stats.facts-stats--near-black .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--near-black.facts-stats--full-height {
  background-color: #191919; }
  .facts-stats.facts-stats--near-black.facts-stats--full-height .item__header, .facts-stats.facts-stats--near-black.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--near-black.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--near-black.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--near-black.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--near-black.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--near-black.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--near-black.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--near-black.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--near-black.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--near-black.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--white .item__header {
  background-color: #FFF; }
  .facts-stats.facts-stats--white .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--white.facts-stats--full-height {
  background-color: #FFF; }
  .facts-stats.facts-stats--white.facts-stats--full-height .item__header, .facts-stats.facts-stats--white.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--white.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--white.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--white.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--white.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--white.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--white.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--white.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--white.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--white.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--black .item__header {
  background-color: #000; }
  .facts-stats.facts-stats--black .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--black.facts-stats--full-height {
  background-color: #000; }
  .facts-stats.facts-stats--black.facts-stats--full-height .item__header, .facts-stats.facts-stats--black.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--black.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--black.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--black.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--black.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--black.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--black.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--black.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--black.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--black.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--gray .item__header {
  background-color: #F2F2F2; }
  .facts-stats.facts-stats--gray .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--gray.facts-stats--full-height {
  background-color: #F2F2F2; }
  .facts-stats.facts-stats--gray.facts-stats--full-height .item__header, .facts-stats.facts-stats--gray.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--gray.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--gray.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--gray.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--gray.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--gray.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--gray.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--gray.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--gray.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--gray.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--blue--wh-text .item__header {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue--wh-text .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--blue--wh-text.facts-stats--full-height {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__header, .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--blue--wh-text.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--blue--bl-text .item__header {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue--bl-text .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--blue--bl-text.facts-stats--full-height {
  background-color: #009FDB; }
  .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__header, .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--blue--bl-text.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-cobalt .item__header {
  background-color: #0057B8; }
  .facts-stats.facts-stats--comms-cobalt .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--comms-cobalt.facts-stats--full-height {
  background-color: #0057B8; }
  .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--comms-cobalt.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--comms-purple .item__header {
  background-color: #AF29BB; }
  .facts-stats.facts-stats--comms-purple .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--comms-purple.facts-stats--full-height {
  background-color: #AF29BB; }
  .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--comms-purple.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--comms-purple.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--comms-berry .item__header {
  background-color: #FF585D; }
  .facts-stats.facts-stats--comms-berry .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-berry.facts-stats--full-height {
  background-color: #FF585D; }
  .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-berry.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-berry.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-tangerine .item__header {
  background-color: #FFB000; }
  .facts-stats.facts-stats--comms-tangerine .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-tangerine.facts-stats--full-height {
  background-color: #FFB000; }
  .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-tangerine.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-lime .item__header {
  background-color: #91DC00; }
  .facts-stats.facts-stats--comms-lime .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-lime.facts-stats--full-height {
  background-color: #91DC00; }
  .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-lime.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-lime.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-mint .item__header {
  background-color: #49EEDC; }
  .facts-stats.facts-stats--comms-mint .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-mint.facts-stats--full-height {
  background-color: #49EEDC; }
  .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-mint.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-mint.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--blue-gradient--wh-text .item__header {
  background: #0057b8;
  background: linear-gradient(to right, #0057b8 0%, #009FDB 100%); }
  .facts-stats.facts-stats--blue-gradient--wh-text .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height {
  background: #0057b8;
  background: linear-gradient(to right, #0057b8 0%, #009FDB 100%); }
  .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__header, .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--blue-gradient--wh-text.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--blue-gradient--bl-text .item__header {
  background: #0057b8;
  background: linear-gradient(to right, #0057b8 0%, #009FDB 100%); }
  .facts-stats.facts-stats--blue-gradient--bl-text .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height {
  background: #0057b8;
  background: linear-gradient(to right, #0057b8 0%, #009FDB 100%); }
  .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__header, .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--blue-gradient--bl-text.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-cobalt-purple .item__header {
  background: #0057B8;
  background: linear-gradient(to right, #0057B8 0%, #AF29BB 100%); }
  .facts-stats.facts-stats--comms-cobalt-purple .item__header .item__text h2 {
    color: #FFF; }

.facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height {
  background: #0057B8;
  background: linear-gradient(to right, #0057B8 0%, #AF29BB 100%); }
  .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__content .item__description {
      color: #FFF; }
  .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height ul > li:before {
    background-color: #FFF; }
  .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__cta .cta--button {
    border-color: #FFF;
    color: #FFF; }
    .facts-stats.facts-stats--comms-cobalt-purple.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #FFF;
      color: #191919; }

.facts-stats.facts-stats--comms-berry-tangerine .item__header {
  background: #FF585D;
  background: linear-gradient(to right, #FF585D 0%, #FFB000 100%); }
  .facts-stats.facts-stats--comms-berry-tangerine .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height {
  background: #FF585D;
  background: linear-gradient(to right, #FF585D 0%, #FFB000 100%); }
  .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-berry-tangerine.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--comms-lime-mint .item__header {
  background: #91DC00;
  background: linear-gradient(to right, #91DC00 0%, #49EEDC 100%); }
  .facts-stats.facts-stats--comms-lime-mint .item__header .item__text h2 {
    color: #191919; }

.facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height {
  background: #91DC00;
  background: linear-gradient(to right, #91DC00 0%, #49EEDC 100%); }
  .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__header, .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__content {
    background-color: transparent; }
    .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__header .item__description, .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__content .item__text h2, .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__content .item__description {
      color: #191919; }
  .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height ul > li:before {
    background-color: #191919; }
  .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__img {
    padding-bottom: 20px; }
  .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__header + .item__content {
    padding: 0 40px 20px; }
  .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__cta .cta--button {
    border-color: #191919;
    color: #191919; }
    .facts-stats.facts-stats--comms-lime-mint.facts-stats--full-height .item__cta .cta--button:hover {
      background-color: #191919;
      color: #FFF; }

.facts-stats.facts-stats--white .item__header .item__text h2, .facts-stats.facts-stats--white.facts-stats--full-height .item__header .item__text h2, .facts-stats.facts-stats--gray .item__header .item__text h2, .facts-stats.facts-stats--gray.facts-stats--full-height .item__header .item__text h2 {
  color: #5A5A5A; }

.facts-stats .header--white .item__text h2, .facts-stats .header--gray .item__text h2 {
  color: #5A5A5A; }

/*=====
  EXPAND-COLLAPSE
  =====*/
.expand-collapse {
  width: 100%;
  margin: 0 auto 20px; }
  @media (min-width: 768px) {
    .expand-collapse {
      margin-bottom: 40px; } }
  .expand-collapse .item:last-of-type .item__label a {
    border-bottom: 1px solid #C9C9C9; }
    .region--blue-gradient--bl-text .expand-collapse .item:last-of-type .item__label a,
    .region--blue--bl-text .expand-collapse .item:last-of-type .item__label a {
      border-color: #000; }
    .region[class*="cobalt"] .expand-collapse .item:last-of-type .item__label a,
    .region[class*="purple"] .expand-collapse .item:last-of-type .item__label a,
    .region[class*="wh-text"] .expand-collapse .item:last-of-type .item__label a,
    .region--dark-blue .expand-collapse .item:last-of-type .item__label a,
    .region[class*="black"] .expand-collapse .item:last-of-type .item__label a {
      border-color: #F2F2F2; }
  .expand-collapse .item__label {
    font-size: 18px;
    font-size: 1.8rem;
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px; }
    @media (min-width: 768px) {
      .expand-collapse .item__label {
        font-size: 21px;
        font-size: 2.1rem;
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .expand-collapse .item__label {
        padding: 0; } }
    .row .expand-collapse .item__label {
      padding: 0; }
    .expand-collapse .item__label a {
      position: relative;
      display: block;
      width: 100%;
      border-top: 1px solid #C9C9C9;
      padding: 10px 25px 10px 0;
      line-height: 1.5;
      text-decoration: none;
      color: #000; }
      .region--blue-gradient--bl-text .expand-collapse .item__label a,
      .region--blue--bl-text .expand-collapse .item__label a {
        border-color: #000; }
      .region--white .expand-collapse .item__label a, .region--gray .expand-collapse .item__label a {
        color: #191919; }
      .region[class*="cobalt"] .expand-collapse .item__label a,
      .region[class*="purple"] .expand-collapse .item__label a,
      .region[class*="wh-text"] .expand-collapse .item__label a,
      .region--dark-blue .expand-collapse .item__label a,
      .region[class*="black"] .expand-collapse .item__label a {
        color: #F2F2F2;
        border-color: #F2F2F2; }
    .expand-collapse .item__label a:after {
      font-size: 26px;
      font-size: 2.6rem;
      transition: all 300ms ease;
      content: "\002B";
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%); }
      .region--white .expand-collapse--blue .expand-collapse .item__label a:after, .region--gray .expand-collapse--blue .expand-collapse .item__label a:after {
        border-color: #0568AE; }
      .region[class*="black"] .expand-collapse--blue .expand-collapse .item__label a:after {
        border-color: #009FDB; }
  .expand-collapse .item__wrap {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transform-origin: top center;
    transition: opacity 300ms ease; }
  .expand-collapse .item__content {
    background-color: transparent;
    padding-left: 0px; }
    .region--white .row--no-gutters .expand-collapse .item__content,
    .region--white .row--flush-horizontal .expand-collapse .item__content,
    .region--black .expand-collapse .item__content,
    .region--gray .expand-collapse .item__content,
    .region--blue--bl-text .expand-collapse .item__content,
    .region--blue--wh-text .expand-collapse .item__content,
    .region--comms-cobalt .expand-collapse .item__content,
    .region--blue-gradient--wh-text .expand-collapse .item__content,
    .region--blue-gradient--bl-text .expand-collapse .item__content {
      padding-left: 20px; }
  .expand-collapse .item--active .item__label a {
    border-bottom: 0 !important; }
    .expand-collapse .item--active .item__label a:after {
      font-size: 28px;
      font-size: 2.8rem;
      transition: all 300ms ease;
      transform: translateY(-50%) rotate(45deg); }
  .expand-collapse .item--active .item__wrap {
    visibility: visible;
    height: 100%;
    opacity: 1;
    transform-origin: top center;
    transition: opacity 300ms ease; }
  .expand-collapse .item__close {
    margin: 20px 20px 40px; }
    .expand-collapse .item__close .cta--link:after {
      content: 'X';
      font-weight: bold;
      margin-left: 4px; }
    @media (min-width: 768px) {
      .expand-collapse .item__close {
        display: none; } }
  .expand-collapse.expand-collapse--up-down .item__label a:after, .expand-collapse.expand-collapse--view-hide .item__label a:after {
    content: "";
    width: 10px;
    height: 10px;
    border-top: 3px solid #191919;
    border-left: 3px solid #191919;
    border-radius: 3px 3px 3px 0;
    transform: translateY(-50%) rotate(225deg); }
    .region--dark-blue .expand-collapse.expand-collapse--up-down .item__label a:after, .region--dark-blue .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--near-black .expand-collapse.expand-collapse--up-down .item__label a:after, .region--near-black .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--black .expand-collapse.expand-collapse--up-down .item__label a:after, .region--black .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--blue--wh-text .expand-collapse.expand-collapse--up-down .item__label a:after, .region--blue--wh-text .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--comms-cobalt .expand-collapse.expand-collapse--up-down .item__label a:after, .region--comms-cobalt .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--comms-purple .expand-collapse.expand-collapse--up-down .item__label a:after, .region--comms-purple .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--blue-gradient--wh-text .expand-collapse.expand-collapse--up-down .item__label a:after, .region--blue-gradient--wh-text .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
    .region--comms-cobalt-purple .expand-collapse.expand-collapse--up-down .item__label a:after, .region--comms-cobalt-purple .expand-collapse.expand-collapse--view-hide .item__label a:after {
      border-color: #FFF; }
  .expand-collapse.expand-collapse--up-down .item--active .item__label a {
    border-bottom: 1px solid #C9C9C9 !important; }
    .expand-collapse.expand-collapse--up-down .item--active .item__label a:after {
      transform: translateY(-50%) rotate(45deg); }
  .expand-collapse.expand-collapse--up-down .item--active .item__wrap {
    margin-top: 10px; }
  .expand-collapse.expand-collapse--view-hide .item {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 60px; }
    .expand-collapse.expand-collapse--view-hide .item:last-of-type .item__label a {
      border-bottom: 0; }
    .expand-collapse.expand-collapse--view-hide .item__label {
      order: 2;
      text-align: center;
      font-family: "Aleck Sans Medium", "Aleck Sans", Helvetica, Arial, sans-serif; }
      .expand-collapse.expand-collapse--view-hide .item__label a {
        display: block;
        border: none;
        padding: 0; }
      .expand-collapse.expand-collapse--view-hide .item__label a:after {
        position: relative;
        display: block;
        margin: 8px auto;
        transform: rotate(225deg); }
        @media (min-width: 768px) {
          .expand-collapse.expand-collapse--view-hide .item__label a:after {
            display: inline-block;
            margin: 0 0 4px 20px; } }
    .expand-collapse.expand-collapse--view-hide .item--active {
      margin-bottom: 60px; }
      .expand-collapse.expand-collapse--view-hide .item--active .item__label a:after {
        transform: rotate(45deg); }
        @media (min-width: 768px) {
          .expand-collapse.expand-collapse--view-hide .item--active .item__label a:after {
            margin: 0 0 0 20px; } }
      .expand-collapse.expand-collapse--view-hide .item--active .item__wrap {
        border: none; }
  .expand-collapse.expand-collapse--view-hide.expand-collapse--multi .item--active .item__label a {
    padding-bottom: 30px;
    border-bottom: 1px solid #C9C9C9 !important; }
  .expand-collapse.expand-collapse--view-hide.expand-collapse--multi .item--active .item__wrap:before {
    content: "";
    display: block;
    width: auto;
    margin: 0 20px 30px;
    border-top: 1px solid #C9C9C9; }
    @media (min-width: 768px) {
      .expand-collapse.expand-collapse--view-hide.expand-collapse--multi .item--active .item__wrap:before {
        margin: 0 55px 30px; } }
    @media (min-width: 1220px) {
      .expand-collapse.expand-collapse--view-hide.expand-collapse--multi .item--active .item__wrap:before {
        max-width: 1110px;
        margin-left: auto;
        margin-right: auto; } }
    @media (min-width: 1440px) {
      .expand-collapse.expand-collapse--view-hide.expand-collapse--multi .item--active .item__wrap:before {
        max-width: 1220px; } }
  .expand-collapse.expand-collapse--blue .item__label a {
    color: #0568AE; }
    .region--white .expand-collapse.expand-collapse--blue .item__label a, .region--gray .expand-collapse.expand-collapse--blue .item__label a {
      color: #0568AE; }
    .region[class*="black"] .expand-collapse.expand-collapse--blue .item__label a {
      color: #009FDB; }
  .region--white .expand-collapse.expand-collapse--blue.expand-collapse--up-down .item__label a:after, .region--gray .expand-collapse.expand-collapse--blue.expand-collapse--up-down .item__label a:after, .region--white .expand-collapse.expand-collapse--blue.expand-collapse--view-hide .item__label a:after, .region--gray .expand-collapse.expand-collapse--blue.expand-collapse--view-hide .item__label a:after {
    border-color: #0568AE; }
  .region[class*="black"] .expand-collapse.expand-collapse--blue.expand-collapse--up-down .item__label a:after, .region[class*="black"] .expand-collapse.expand-collapse--blue.expand-collapse--view-hide .item__label a:after {
    border-color: #009FDB; }

/*===============
  EXPANDABLE TEXT
  ===============*/
.expandable-text {
  margin-top: -20px;
  padding: 0 20px; }
  .col .expandable-text {
    padding: 0; }
  .expandable-text .text__wrap {
    display: none;
    margin-top: 40px; }
  .expandable-text .text__cta {
    margin: 0 0 20px;
    padding-top: 20px; }
    .expandable-text .text__cta .cta--link {
      font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
      font-weight: 400; }
      .expandable-text .text__cta .cta--link:after {
        content: none; }
  .expandable-text.text--footer .text__wrap {
    border-top: 1px solid #C9C9C9;
    padding-top: 45px; }
    .region--blue .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--dark-blue .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--near-black .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--white .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--black .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--gray .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--blue--wh-text .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--blue--bl-text .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-cobalt .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--comms-purple .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--comms-berry .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-tangerine .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-lime .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-mint .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--blue-gradient--wh-text .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--blue-gradient--bl-text .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-cobalt-purple .expandable-text.text--footer .text__wrap {
      border-color: #FFF; }
    .region--comms-berry-tangerine .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--comms-lime-mint .expandable-text.text--footer .text__wrap {
      border-color: #191919; }
    .region--white .expandable-text.text--footer .text__wrap, .region--gray .expandable-text.text--footer .text__wrap {
      border-color: #C9C9C9; }
  .expandable-text.text--footer .cta--link {
    font-size: 15px;
    font-size: 1.5rem; }
  .expandable-text p:last-child {
    margin-bottom: 0; }

/* Author Instance Display Fixes */
.aem-AuthorLayer-Edit .expandable-content {
  margin-top: 0; }

/*==============
  LATEST STORIES
  ==============*/
.latest-stories {
  max-width: 1220px;
  margin: 0 auto;
  padding-bottom: 40px; }
  .latest-stories .stories__filter-nav {
    display: none;
    margin-bottom: 20px; }
    .latest-stories .stories__filter-nav:after {
      content: "";
      display: table;
      clear: both; }
    @media (max-width: 767px) {
      .latest-stories .stories__filter-nav {
        background-color: transparent;
        border-bottom: 1px solid #FFF; }
        .region--blue .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--white .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--gray .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--blue--bl-text .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-berry .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-tangerine .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-lime .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-mint .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--blue-gradient--bl-text .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-berry-tangerine .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--comms-lime-mint .latest-stories .stories__filter-nav {
          border-bottom: 1px solid #000; }
        .region--white .latest-stories .stories__filter-nav,
        .region--gray .latest-stories .stories__filter-nav {
          background-color: #009FDB;
          border-bottom: 1px solid #FFF; } }
    @media (min-width: 768px) {
      .latest-stories .stories__filter-nav {
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .latest-stories .stories__filter-nav {
        padding: 0; } }
    @media (min-width: 768px) {
      .latest-stories .stories__filter-nav .filter-toggle,
      .latest-stories .stories__filter-nav .filter-tags {
        float: left; } }
    .latest-stories .stories__filter-nav .filter-toggle h4,
    .latest-stories .stories__filter-nav li a {
      text-decoration: none; }
      @media (min-width: 768px) {
        .latest-stories .stories__filter-nav .filter-toggle h4,
        .latest-stories .stories__filter-nav li a {
          font-size: 14px;
          font-size: 1.4rem;
          padding: 7px 0; } }
    .latest-stories .stories__filter-nav .filter-toggle {
      text-decoration: none;
      cursor: pointer; }
      @media (max-width: 767px) {
        .region--white .latest-stories .stories__filter-nav .filter-toggle, .region--gray .latest-stories .stories__filter-nav .filter-toggle, .region[class*="black"] .latest-stories .stories__filter-nav .filter-toggle {
          color: #FFF; } }
      @media (min-width: 768px) {
        .latest-stories .stories__filter-nav .filter-toggle {
          cursor: default;
          color: inherit; } }
      @media (max-width: 767px) {
        .latest-stories .stories__filter-nav .filter-toggle h4 {
          position: relative;
          padding: 20px;
          font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
          .latest-stories .stories__filter-nav .filter-toggle h4:after {
            content: "";
            position: absolute;
            top: 25%;
            right: 25px;
            width: 19px;
            height: 19px;
            border-top: 2px solid #FFF;
            border-left: 2px solid #FFF;
            border-radius: 3px;
            transform: rotate(-135deg); }
            .region--blue .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--white .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--gray .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--blue--bl-text .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-berry .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-tangerine .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-lime .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-mint .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #191919;
              border-left: 2px solid #191919; }
            .region--white .latest-stories .stories__filter-nav .filter-toggle h4:after, .region--gray .latest-stories .stories__filter-nav .filter-toggle h4:after {
              border-top: 2px solid #FFF;
              border-left: 2px solid #FFF; } }
      @media (min-width: 768px) {
        .region[class*="blue"] .latest-stories .stories__filter-nav .filter-toggle h4,
        .region[class*="black"] .latest-stories .stories__filter-nav .filter-toggle h4 {
          font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; } }
    .latest-stories .stories__filter-nav .filter-tags {
      display: none; }
      @media (min-width: 768px) {
        .latest-stories .stories__filter-nav .filter-tags {
          display: block !important;
          margin-left: 15px; }
          .col-6 .latest-stories .stories__filter-nav .filter-tags,
          .col-4 .latest-stories .stories__filter-nav .filter-tags,
          .col-3 .latest-stories .stories__filter-nav .filter-tags,
          .col-2 .latest-stories .stories__filter-nav .filter-tags {
            margin-left: 0; } }
      @media (min-width: 768px) and (max-width: 1023px) {
        .col-9 .latest-stories .stories__filter-nav .filter-tags {
          margin-left: 0; } }
      @media (min-width: 768px) and (max-width: 1219px) {
        .col-8 .latest-stories .stories__filter-nav .filter-tags {
          margin-left: 0; } }
      @media (max-width: 767px) {
        .latest-stories .stories__filter-nav .filter-tags li {
          border-top: 1px solid #FFF; }
          .region--blue .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--white .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--gray .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--blue--bl-text .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-berry .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-tangerine .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-lime .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-mint .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; }
          .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-tags li {
            border-top: 1px solid #000; } }
      .region--white .latest-stories .stories__filter-nav .filter-tags li, .region--gray .latest-stories .stories__filter-nav .filter-tags li {
        border-top: 1px solid #FFF; }
      @media (min-width: 768px) {
        .latest-stories .stories__filter-nav .filter-tags li {
          display: inline-block;
          margin: 0 10px; }
          .col-6 .latest-stories .stories__filter-nav .filter-tags li,
          .col-4 .latest-stories .stories__filter-nav .filter-tags li,
          .col-3 .latest-stories .stories__filter-nav .filter-tags li,
          .col-2 .latest-stories .stories__filter-nav .filter-tags li {
            margin-left: 0; } }
      @media (min-width: 1024px) {
        .latest-stories .stories__filter-nav .filter-tags li {
          margin: 0 15px; } }
      @media (min-width: 768px) and (max-width: 1219px) {
        .col-9 .latest-stories .stories__filter-nav .filter-tags li,
        .col-8 .latest-stories .stories__filter-nav .filter-tags li {
          margin-left: 0; } }
      .latest-stories .stories__filter-nav .filter-tags li a {
        font-size: 14px;
        font-size: 1.4rem;
        display: inline-block;
        font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
        .latest-stories .stories__filter-nav .filter-tags li a:hover {
          text-decoration: none; }
        @media (max-width: 767px) {
          .latest-stories .stories__filter-nav .filter-tags li a {
            width: 100%;
            padding: 20px; }
            .region--white .latest-stories .stories__filter-nav .filter-tags li a, .region--gray .latest-stories .stories__filter-nav .filter-tags li a, .region[class*="black"] .latest-stories .stories__filter-nav .filter-tags li a {
              color: #FFF; }
            .region--blue .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--dark-blue .latest-stories .stories__filter-nav .filter-tags li a.active, .region--dark-blue .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--near-black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--near-black .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--white .latest-stories .stories__filter-nav .filter-tags li a.active, .region--white .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--black .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--gray .latest-stories .stories__filter-nav .filter-tags li a.active, .region--gray .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--blue--wh-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue--wh-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--blue--bl-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue--bl-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-cobalt .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-cobalt .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--comms-purple .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-purple .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--comms-berry .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-berry .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-tangerine .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-tangerine .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-lime .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-lime .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-mint .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-mint .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--blue-gradient--wh-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue-gradient--wh-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-cobalt-purple .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-cobalt-purple .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #FFF;
              color: #191919; }
            .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-tags li a:hover {
              background-color: #191919;
              color: #FFF; }
            .region--white .latest-stories .stories__filter-nav .filter-tags li a.active, .region--gray .latest-stories .stories__filter-nav .filter-tags li a.active, .region[class*="black"] .latest-stories .stories__filter-nav .filter-tags li a.active, .region--white .latest-stories .stories__filter-nav .filter-tags li a:hover, .region--gray .latest-stories .stories__filter-nav .filter-tags li a:hover, .region[class*="black"] .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              background-color: #0090c7; } }
        @media (min-width: 768px) {
          .latest-stories .stories__filter-nav .filter-tags li a {
            padding-bottom: 5px;
            color: inherit; }
            .region--blue .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--dark-blue .latest-stories .stories__filter-nav .filter-tags li a.active, .region--dark-blue .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--near-black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--near-black .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--white .latest-stories .stories__filter-nav .filter-tags li a.active, .region--white .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--black .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--gray .latest-stories .stories__filter-nav .filter-tags li a.active, .region--gray .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--blue--wh-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue--wh-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--blue--bl-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue--bl-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-cobalt .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-cobalt .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--comms-purple .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-purple .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--comms-berry .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-berry .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-tangerine .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-tangerine .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-lime .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-lime .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-mint .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-mint .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--blue-gradient--wh-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue-gradient--wh-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-tags li a.active, .region--blue-gradient--bl-text .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-cobalt-purple .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-cobalt-purple .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #FFF;
              border-bottom: 2px solid #FFF; }
            .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-berry-tangerine .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-tags li a.active, .region--comms-lime-mint .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #191919;
              border-bottom: 2px solid #191919; }
            .region--white .latest-stories .stories__filter-nav .filter-tags li a.active, .region--black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--near-black .latest-stories .stories__filter-nav .filter-tags li a.active, .region--white .latest-stories .stories__filter-nav .filter-tags li a:hover, .region--black .latest-stories .stories__filter-nav .filter-tags li a:hover, .region--near-black .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #009FDB;
              border-bottom: 2px solid #009FDB; }
            .region--gray .latest-stories .stories__filter-nav .filter-tags li a.active, .region--gray .latest-stories .stories__filter-nav .filter-tags li a:hover {
              color: #0568AE;
              border-bottom: 2px solid #0568AE; } }
    .latest-stories .stories__filter-nav.stories__filter-nav--open .filter-toggle h4:after {
      transform: rotate(-315deg);
      top: 40%; }
    .latest-stories .stories__filter-nav.stories__filter-nav--open .filter-tags {
      display: block; }
  .latest-stories .stories__feed .row + .row {
    margin-top: 0; }
  @media (min-width: 768px) {
    .latest-stories .stories__feed .component {
      padding-bottom: 20px;
      margin-bottom: 10px; } }
  .latest-stories .stories__feed .item__media {
    max-height: 450px; }
  .latest-stories .stories__feed .item__media .item__img[data-placeholder="true"] {
    height: 100%; }
    .latest-stories .stories__feed .item__media .item__img[data-placeholder="true"] img {
      display: block; }
  .latest-stories .stories__feed .item__media .item__img[data-placeholder="true"] a:after {
    text-align: center;
    font-size: 12rem; }
    @media (min-width: 640px) {
      .latest-stories .stories__feed .item__media .item__img[data-placeholder="true"] a:after {
        font-size: 16rem; } }
  .latest-stories .stories__feed .item__title a {
    text-decoration: none;
    color: inherit; }
  .latest-stories .stories__feed .item__info {
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .latest-stories .stories__feed .item__description {
    max-width: 500px;
    margin-top: 30px; }
  .latest-stories .stories__cta {
    display: none;
    text-align: center;
    margin: 20px 0 60px; }
    @media (min-width: 1024px) {
      .latest-stories .stories__cta {
        margin: 40px auto 80px; } }
  .latest-stories.latest-stories--list .stories__heading {
    text-align: left; }
  .latest-stories.latest-stories--list .stories__feed .col {
    padding-bottom: 5px;
    max-width: none;
    flex-basis: auto; }
  .latest-stories.latest-stories--list .stories__feed .component {
    height: auto; }
    @media (min-width: 768px) {
      .latest-stories.latest-stories--list .stories__feed .component {
        padding-bottom: 0;
        margin-bottom: 10px; } }
  .latest-stories.latest-stories--list .stories__feed .item__media,
  .latest-stories.latest-stories--list .stories__feed .item__description,
  .latest-stories.latest-stories--list .stories__feed .item__cta {
    display: none; }
  .latest-stories.latest-stories--list .stories__feed .item__content,
  .col-2 .latest-stories.latest-stories--list .stories__feed .item__content {
    background-color: transparent;
    padding: 0 !important; }
  .latest-stories.latest-stories--list .stories__feed .item__content .content__wrap {
    max-width: 85%; }
  .latest-stories.latest-stories--list .stories__feed .item__info {
    color: inherit !important; }
  .region--blue .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--dark-blue .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--near-black .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--white .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--black .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--gray .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--blue--wh-text .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--blue--bl-text .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-cobalt .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--comms-purple .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--comms-berry .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-tangerine .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-lime .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-mint .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--blue-gradient--wh-text .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--blue-gradient--bl-text .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-cobalt-purple .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #FFF; }
  .region--comms-berry-tangerine .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--comms-lime-mint .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #191919; }
  .region--white .latest-stories.latest-stories--list .stories__feed .item__title a, .region--gray .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #0568AE; }
  .region[class*="black"] .latest-stories.latest-stories--list .stories__feed .item__title a {
    color: #009FDB; }
  .latest-stories.latest-stories--list .stories__feed .item__title a:hover {
    text-decoration: underline; }
  .latest-stories.latest-stories--list .stories__feed .item__info {
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .latest-stories.latest-stories--list .stories__cta {
    margin: 10px 0;
    padding: 0 20px;
    text-align: left; }
    @media (min-width: 768px) {
      .latest-stories.latest-stories--list .stories__cta {
        margin: 30px 0;
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .latest-stories.latest-stories--list .stories__cta {
        padding: 0; } }

/*========
  LIGHTBOX
  ========*/
/* Fancybox modals */
.fancybox-container {
  height: 100vh;
  width: 100vw;
  bottom: 0;
  right: 0;
  z-index: 999999999; }

.fancybox-bg {
  background: #000; }
  .fancybox-is-open .fancybox-bg {
    opacity: 0.85; }

.fancybox-content {
  max-width: 100% !important;
  background-color: #000;
  width: 100%; }

.fancybox-slide--image,
.fancybox-slide--video {
  padding: 35px 20px 20px; }
  @media (min-width: 768px) {
    .fancybox-slide--image,
    .fancybox-slide--video {
      padding: 60px 65px 30px 55px; } }
  @media (min-width: 1024px) {
    .fancybox-slide--image,
    .fancybox-slide--video {
      padding: 90px 0 45px; } }

.fancybox-slide--video .fancybox-content {
  overflow: initial !important; }

.fancybox-slide--image {
  position: relative !important; }

.fancybox-infobar,
.fancybox-toolbar {
  display: none; }

/* GVP modals */
[class^="gvp_player__closeButton"][class*="gvp_player__modal"],
.fancybox-custom-close {
  display: block;
  position: absolute;
  height: 19px;
  right: 0 !important;
  top: -35px !important;
  width: 77px;
  color: #FFF; }
  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:hover,
  .fancybox-custom-close:hover {
    text-decoration: none; }
  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:before, [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:after,
  .fancybox-custom-close:before,
  .fancybox-custom-close:after {
    position: absolute;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:before,
  .fancybox-custom-close:before {
    content: "+";
    font-size: 3.75rem;
    transform: translate(5px, -8px) rotate(45deg); }
  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:after,
  .fancybox-custom-close:after {
    content: "close";
    font-size: 2rem;
    font-weight: 300;
    right: 0; }

[class^="gvp_player__closeButton"][class*="gvp_player__modal"]:before {
  top: -8px;
  left: 0; }

[class^="gvp_player__closeButton"][class*="gvp_player__modal"]:after {
  top: -5px; }

[class^="gvp_player__view_"][class*="gvp_player__modal"] {
  background: rgba(0, 0, 0, 0.85) !important;
  padding: 0 20px; }

[class^="gvp_player__closeButton"][class*="gvp_player__modal"] {
  max-width: none !important;
  max-height: none !important; }
  [class^="gvp_player__closeButton"][class*="gvp_player__modal"] svg {
    display: none; }

/*=======
  MARQUEE
  =======*/
.marquee .marquee__wrap {
  position: relative;
  overflow: hidden; }

.marquee .marquee__slides {
  transition: all 500ms ease;
  height: 0;
  opacity: 0; }
  .marquee .marquee__slides.flickity-enabled {
    height: auto;
    opacity: 1; }

.marquee .slide {
  width: 100%; }

.marquee .component {
  margin-bottom: 0; }

/* Marquee Controls */
.marquee__controls {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  box-sizing: border-box;
  width: 100%; }
  @media (min-width: 768px) {
    .marquee__controls {
      position: absolute;
      min-height: auto;
      padding: 0 20px;
      bottom: 0; } }
  @media (min-width: 1440px) {
    .marquee__controls {
      padding: 0; } }
  .marquee__controls ul {
    max-width: calc(1220px + 20px);
    margin: 5px auto;
    /* Begin flexbox and flexbox fallback */
    display: flex;
    align-items: center;
    justify-content: center;
    /* End flexbox stuff */ }
    @media (min-width: 768px) {
      .marquee__controls ul {
        justify-content: space-between;
        align-items: flex-end; }
        .no-flexbox.no-flexboxtweener.no-flexboxlegacy .marquee__controls ul {
          display: table;
          table-layout: fixed;
          transform: translateX(-50%);
          left: 50%; } }
    @media (min-width: 768px) {
      .marquee__controls ul {
        position: absolute;
        margin: 0 auto;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .marquee__controls ul {
        padding: 0; } }
  .marquee__controls li {
    margin: 20px 6px;
    box-sizing: border-box;
    /* Add flexbox and flexbox fallback */ }
    @media (min-width: 768px) {
      .marquee__controls li {
        margin: 0;
        padding: 0 1%;
        width: 100%; } }
    @media (min-width: 1440px) {
      .marquee__controls li {
        padding: 0;
        margin: 0 10px; } }
    @media (max-width: 767px) {
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .marquee__controls li {
        float: left; } }
    @media (min-width: 768px) {
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .marquee__controls li {
        display: table-cell;
        vertical-align: bottom;
        width: auto;
        max-width: 100%;
        margin: 0;
        padding: 0 10px; } }
    .marquee__controls li.control--active button {
      background-color: #009FDB; }
      @media (max-width: 767px) {
        .marquee__controls li.control--active button {
          height: 22px;
          width: 22px; } }
      .region--blue .marquee__controls li.control--active button {
        background-color: #FFF; }
    @media (min-width: 768px) {
      .marquee__controls li.control--active button, .marquee__controls li:hover button {
        width: 100%;
        background-color: transparent;
        border-color: #009FDB;
        color: #009FDB; } }
  .marquee__controls button {
    transition: all 150ms ease;
    display: block;
    width: 20px;
    padding: 0;
    margin: 0;
    text-align: left;
    border-radius: 100%;
    background-color: #C9C9C9; }
    @media (max-width: 767px) {
      .marquee__controls button {
        height: 20px; } }
    @media (min-width: 768px) {
      .marquee__controls button {
        font-size: 14px;
        font-size: 1.4rem;
        width: 100%;
        border-radius: 0;
        background-color: transparent !important;
        border-bottom: 6px solid #F2F2F2;
        transition: all 300ms ease;
        color: #F2F2F2; }
        .region--near-black .marquee__controls button,
        .region--black .marquee__controls button {
          background-color: transparent; } }
    @media (min-width: 1220px) {
      .marquee__controls button {
        font-size: 16px;
        font-size: 1.6rem; } }
    @media (min-width: 1440px) {
      .marquee__controls button {
        font-size: 17px;
        font-size: 1.7rem;
        border-width: 8px; } }
    .marquee__controls button span {
      display: none;
      font-weight: 700; }
      @media (min-width: 768px) {
        .marquee__controls button span {
          display: block;
          max-width: 85%;
          padding-bottom: 10px; } }

/* Carousel Vendor Style Overrides */
.flickity-viewport {
  transition: height 700ms ease-in-out; }

/*=====
  MEDIA
  =====*/
.media {
  width: 100%; }

/* Media Items */
.media__img,
.media__video {
  display: none;
  max-width: 100%;
  width: 100%; }
  .media__img .frame,
  .media__video .frame {
    position: relative; }
    .media--letterboxed .media__img .frame, .media--letterboxed
    .media__video .frame {
      background-color: #000; }

/* Media Item - Image */
.media__img {
  max-width: 1220px;
  margin: 0 auto; }
  .media--letterboxed .media__img {
    max-width: none; }
  .media[data-type="image"] .media__img {
    display: block; }
  .media__img img {
    display: block;
    max-width: 100%; }
    .media--centered .media__img img,
    .media--letterboxed .media__img img {
      margin: 0 auto; }

/* Media Item - Video */
.media[data-type="video"] .media__video {
  display: block; }

.media--centered .media__video .frame,
.media--constrained .media__video .frame {
  max-width: 900px;
  margin: 0 auto; }

.media__video [class*="embed--"] {
  display: none; }
  .media__video [class*="embed--"]:after {
    content: "";
    display: table;
    clear: both; }
  .media__video [class*="embed--"]:first-child {
    display: block; }

.media__video iframe,
.media__video att-gvp {
  display: inline-block;
  margin: 0; }

/* Media Item Caption */
.media__caption {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 10px auto;
  padding: 10px 20px;
  line-height: 1.5;
  width: 100%; }
  @media (max-width: 1219px) {
    .row .media__caption {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1220px) {
    .media__caption {
      padding-left: 0;
      padding-right: 0; } }
  .media--centered .media__caption,
  .media--constrained .media__caption,
  .media--letterboxed .media__caption {
    max-width: 900px; }
  .media--centered .media__caption,
  .media--letterboxed .media__caption {
    margin: 0 auto;
    text-align: center; }
    .sidebar .media--centered .media__caption, .sidebar
    .media--letterboxed .media__caption {
      text-align: initial; }
  .region[class*="-blue"] .media__caption,
  .region[class*="-black"] .media__caption {
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .media__caption p {
    margin-bottom: 10px; }
    .media__caption p:last-child {
      margin-bottom: 0; }
  .media__caption a,
  .media__caption a:visited {
    color: #0568AE; }

/*==============
  MEDIA CAROUSEL
  ==============*/
.media-carousel {
  position: relative;
  min-height: 150px;
  /* Prev/Next Buttons */ }
  .media-carousel .carousel__wrap {
    position: relative;
    max-width: 1220px;
    margin: 0 auto;
    padding-bottom: 70px; }
    @media (min-width: 768px) {
      .media-carousel .carousel__wrap {
        padding-left: 55px;
        padding-right: 55px; }
        .col .media-carousel .carousel__wrap {
          padding-left: 0;
          padding-right: 0; } }
    @media (min-width: 1440px) {
      .media-carousel .carousel__wrap {
        padding: 0; } }
  .media-carousel .carousel__slides {
    /* Apply negative margin to negate inner padding of columns */ }
    @media (min-width: 768px) {
      .media-carousel .carousel__slides {
        margin: 0 -10px; } }
    .media-carousel .carousel__slides .slide {
      width: 100%; }
      @media (min-width: 768px) {
        .media-carousel .carousel__slides .slide {
          max-width: 50%;
          flex-basis: 50%; } }
      @media (min-width: 1024px) {
        .media-carousel .carousel__slides .slide {
          max-width: 33.33333%;
          flex-basis: 33.33333%; } }
  .media-carousel .component {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .media-carousel .featured-item {
      padding: 0 10px; } }
  .media-carousel .item__media,
  .media-carousel .item__img {
    background-color: transparent; }
  .media-carousel .item__preview {
    color: inherit;
    text-decoration: none; }
    .region--white .media-carousel .item__preview, .region[class*="black"] .media-carousel .item__preview {
      color: #009FDB; }
    .region--gray .media-carousel .item__preview {
      color: #0568AE; }
    .media-carousel .item__preview .item__thumbnail {
      overflow: hidden; }
      @media (min-width: 1024px) {
        .media-carousel .item__preview .item__thumbnail {
          height: 240px; }
          .media-carousel .item__preview .item__thumbnail img {
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%; } }
      @media (min-width: 1220px) {
        .media-carousel .item__preview .item__thumbnail {
          height: 267px; } }
      @media (min-width: 1440px) {
        .media-carousel .item__preview .item__thumbnail {
          height: 295px; } }
    .media-carousel .item__preview .item__content {
      background-color: transparent;
      padding: 10px 0 20px; }
      .media-carousel .item__preview .item__content .item__title {
        color: inherit; }
  .media-carousel .carousel__button {
    font-size: 60px;
    font-size: 6rem;
    transition: all 300ms ease;
    position: absolute;
    bottom: 10px;
    width: 50px;
    height: 50px;
    line-height: 36px;
    border-radius: 50%;
    border: 1px solid #959595;
    background-color: transparent;
    color: #959595; }
    @media (min-width: 1440px) {
      .media-carousel .carousel__button {
        top: 121px; }
        .col:not(.col-12) .media-carousel .carousel__button {
          top: auto; } }
    @media (min-width: 1600px) {
      .media-carousel .carousel__button {
        width: 60px;
        height: 60px;
        line-height: 46px; }
        .col:not(.col-12) .media-carousel .carousel__button {
          width: 50px;
          height: 50px;
          line-height: 36px; } }
    .media-carousel .carousel__button span {
      display: block;
      width: 100%;
      height: 100%; }
    .region--blue .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--blue .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--dark-blue .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--dark-blue .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--near-black .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--near-black .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--white .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--white .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--black .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--black .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--gray .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--gray .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--blue--wh-text .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--blue--wh-text .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--blue--bl-text .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--blue--bl-text .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-cobalt .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--comms-cobalt .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--comms-purple .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--comms-purple .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--comms-berry .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-berry .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-tangerine .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-tangerine .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-lime .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-lime .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-mint .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-mint .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--blue-gradient--wh-text .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--blue-gradient--wh-text .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--blue-gradient--bl-text .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--blue-gradient--bl-text .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-cobalt-purple .media-carousel .carousel__button {
      border-color: #FFF;
      color: #FFF; }
      .region--comms-cobalt-purple .media-carousel .carousel__button:hover {
        border-color: #000;
        color: #000; }
    .region--comms-berry-tangerine .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-berry-tangerine .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--comms-lime-mint .media-carousel .carousel__button {
      border-color: #000;
      color: #000; }
      .region--comms-lime-mint .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
    .region--white .media-carousel .carousel__button, .region--gray .media-carousel .carousel__button {
      border-color: #959595;
      color: #959595; }
      .region--white .media-carousel .carousel__button:hover, .region--gray .media-carousel .carousel__button:hover {
        border-color: #0568AE;
        color: #0568AE; }
    .region[class*="-black"] .media-carousel .carousel__button {
      border-color: #959595;
      color: #959595; }
      .region[class*="-black"] .media-carousel .carousel__button:hover {
        border-color: #FFF;
        color: #FFF; }
  .media-carousel .carousel__button--prev {
    left: calc(50% - 58px); }
    @media (min-width: 1440px) {
      .media-carousel .carousel__button--prev {
        left: -75px; }
        .col:not(.col-12) .media-carousel .carousel__button--prev {
          left: calc(50% - 58px); } }
    @media (min-width: 1600px) {
      .media-carousel .carousel__button--prev {
        left: -85px; } }
  .media-carousel .carousel__button--next {
    right: calc(50% - 58px); }
    @media (min-width: 1440px) {
      .media-carousel .carousel__button--next {
        right: -75px; }
        .col:not(.col-12) .media-carousel .carousel__button--next {
          right: calc(50% - 58px); } }
    @media (min-width: 1600px) {
      .media-carousel .carousel__button--next {
        right: -85px; } }

@media (min-width: 1440px) {
  .story .col:not(.col-12) .media-carousel .carousel__wrap {
    margin-bottom: 80px; } }

@media (min-width: 1440px) {
  .story .col:not(.col-12) .media-carousel .carousel__button {
    top: 100%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .blog-post .blog__wrap .media-carousel > .row,
  .media-kit-page .media-kit__wrap .media-carousel > .row {
    padding: 0 55px; } }

@media (min-width: 768px) {
  .blog-post .blog__wrap .media-carousel > .row .row__wrap,
  .media-kit-page .media-kit__wrap .media-carousel > .row .row__wrap {
    margin: 0 -10px; } }

@media (min-width: 1024px) {
  .blog-post .blog__wrap .media-carousel .carousel__wrap,
  .media-kit-page .media-kit__wrap .media-carousel .carousel__wrap {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1024px) {
  .blog-post .blog__wrap .region .media-carousel,
  .media-kit-page .media-kit__wrap .region .media-carousel {
    max-width: 1220px !important; } }

@media (min-width: 768px) and (max-width: 1439px) {
  .blog-post .blog__wrap .region .media-carousel > .row,
  .media-kit-page .media-kit__wrap .region .media-carousel > .row {
    padding: 0 55px; } }

@media (min-width: 1024px) {
  .blog-post .blog__wrap .region .media-carousel .carousel__wrap,
  .media-kit-page .media-kit__wrap .region .media-carousel .carousel__wrap {
    padding-left: 55px;
    padding-right: 55px; } }

@media (min-width: 1440px) {
  .blog-post .blog__wrap .region .media-carousel .carousel__wrap,
  .media-kit-page .media-kit__wrap .region .media-carousel .carousel__wrap {
    padding-left: 0;
    padding-right: 0; } }

/*=============
  MEDIA LIBRARY
  =============*/
.media-library .row {
  margin-top: 0; }

@media (min-width: 1220px) {
  .media-library .col-2 {
    max-width: 20%;
    flex-basis: 20%; } }

.media-library .component {
  margin-bottom: 0; }

.media-library .item__media .item__img img {
  margin: 0 auto; }

.media-library .item__media,
.media-library .item__img {
  background-color: transparent; }

.media-library .item__preview {
  text-decoration: none; }
  .media-library .item__preview:hover {
    text-decoration: none; }
  .media-library .item__preview .item__thumbnail:last-child {
    margin-bottom: 20px; }
  .media-library .item__preview .item__content {
    background-color: transparent;
    padding: 10px 0 20px; }
    @media (min-width: 768px) {
      .media-library .item__preview .item__content {
        padding-bottom: 40px; } }
    .media-library .item__preview .item__content .item__title {
      color: inherit; }
      .region--white .media-library .item__preview .item__content .item__title {
        color: #009FDB; }

.media-library .library__cta {
  text-align: center;
  margin: 40px 0; }

@media (min-width: 768px) and (max-width: 1023px) {
  .story .story__wrap .media-library .row__wrap {
    display: flex; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .blog-post .blog__wrap .media-library > .row,
  .media-kit-page .media-kit__wrap .media-library > .row {
    padding: 0 55px; } }

@media (min-width: 768px) {
  .blog-post .blog__wrap .media-library > .row .row__wrap,
  .media-kit-page .media-kit__wrap .media-library > .row .row__wrap {
    margin: 0 -10px; } }

@media (min-width: 1024px) {
  .blog-post .blog__wrap .region .media-library,
  .media-kit-page .media-kit__wrap .region .media-library {
    max-width: 1220px !important; } }

@media (min-width: 768px) and (max-width: 1439px) {
  .blog-post .blog__wrap .region .media-library > .row,
  .media-kit-page .media-kit__wrap .region .media-library > .row {
    padding: 0 55px; } }

/*=============
  MICROSITE NAV
  =============*/
.microsite-nav,
.microsite-nav__wrap {
  height: 100%; }

.microsite-nav__wrap {
  background-color: #000; }
  @media (max-width: 1023px) {
    .microsite-nav__wrap {
      border-top: 1px solid #5A5A5A;
      border-bottom: 1px solid #5A5A5A; } }
  .header--secondary .microsite-nav__wrap {
    background-color: transparent; }
    @media (max-width: 1023px) {
      .header--secondary .microsite-nav__wrap {
        border-top: 0;
        border-bottom: 0; } }

/* MICRO GLOBAL NAV */
/* ================ */
.microsite-nav__global-nav {
  display: none;
  position: relative;
  background-color: #000; }
  @media (max-width: 1023px) {
    .microsite-nav__global-nav {
      border-bottom: 1px solid #5A5A5A; } }
  .header--secondary .microsite-nav--plusGlobal .microsite-nav__global-nav {
    display: block; }

.microsite-nav__global-nav__utils {
  height: 50px; }
  @media (min-width: 1024px) {
    .microsite-nav__global-nav__utils {
      border-bottom: 1px solid #5A5A5A; } }
  .microsite-nav__global-nav__utils .wrap {
    max-width: 1220px;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px; }
    .microsite-nav__global-nav__utils .wrap:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 768px) {
      .microsite-nav__global-nav__utils .wrap {
        padding: 0 55px; } }
    @media (min-width: 1440px) {
      .microsite-nav__global-nav__utils .wrap {
        padding: 0; } }
  .microsite-nav__global-nav__utils .nav__logo .logo__icon {
    height: 30px;
    min-width: 75px; }
  .microsite-nav__global-nav__utils .nav__toggle {
    display: inline-block;
    margin-left: 15px;
    height: 14px;
    float: left; }
    .microsite-nav__global-nav__utils .nav__toggle .bar {
      height: 2px;
      width: 17px;
      margin-bottom: 3px; }
    .microsite-nav__global-nav--open .microsite-nav__global-nav__utils .nav__toggle .bar {
      position: absolute;
      background: none; }
      .microsite-nav__global-nav--open .microsite-nav__global-nav__utils .nav__toggle .bar:first-child, .microsite-nav__global-nav--open .microsite-nav__global-nav__utils .nav__toggle .bar:last-child {
        background: #FFF; }
      .microsite-nav__global-nav--open .microsite-nav__global-nav__utils .nav__toggle .bar:first-child {
        transform: rotate(-45deg); }
      .microsite-nav__global-nav--open .microsite-nav__global-nav__utils .nav__toggle .bar:last-child {
        transform: rotate(45deg); }

.microsite-nav__global-nav__wrap {
  display: none; }
  .header--secondary .microsite-nav--plusGlobal .microsite-nav__global-nav--open .microsite-nav__global-nav__wrap {
    display: block;
    position: absolute;
    width: 100%;
    background-color: #000;
    z-index: 99; }

.micro-global-nav__menu:not(.nav__menu--nested) {
  max-width: 1220px;
  margin: 0 auto;
  padding: 20px; }
  @media (min-width: 640px) {
    .micro-global-nav__menu:not(.nav__menu--nested) {
      display: flex;
      justify-content: space-around; } }
  @media (min-width: 768px) {
    .micro-global-nav__menu:not(.nav__menu--nested) {
      padding-left: 55px;
      padding-right: 55px; } }
  @media (min-width: 1440px) {
    .micro-global-nav__menu:not(.nav__menu--nested) {
      padding: 20px 0 50px; } }

.micro-global-nav__menu:not(.nav__menu--nested) > li {
  font-weight: 700; }
  @media (min-width: 640px) {
    .micro-global-nav__menu:not(.nav__menu--nested) > li {
      flex-basis: 20%; } }
  @media (min-width: 1024px) {
    .micro-global-nav__menu:not(.nav__menu--nested) > li {
      flex-grow: 1; } }
  @media (min-width: 640px) {
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .micro-global-nav__menu:not(.nav__menu--nested) > li {
      float: left;
      width: 20%; } }
  @media (max-width: 639px) {
    .micro-global-nav__menu:not(.nav__menu--nested) > li > .nav__menu-item {
      display: block;
      border-top: 1px solid #5A5A5A;
      padding-top: 15px; } }

.micro-global-nav__menu li {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Arial", "Helvetica", sans-serif;
  text-transform: uppercase;
  color: #959595; }
  .micro-global-nav__menu li .nav__menu-item {
    -webkit-font-smoothing: auto;
    display: inline-block;
    margin-bottom: 17px;
    line-height: 1.25;
    color: #959595; }
    .micro-global-nav__menu li .nav__menu-item:hover {
      text-decoration: none; }

.micro-global-nav__menu .nav__menu--nested {
  position: relative;
  width: auto;
  margin: 0;
  padding: 0; }

.micro-global-nav__menu .nav__menu--nested li {
  font-size: 11px;
  font-size: 1.1rem;
  font-weight: 400; }

.micro-global-nav__menu .micro-global-nav__menu--tertiary {
  margin-left: 15px; }

/* MICROSITE-SPECIFIC NAV */
/* ====================== */
.microsite-nav__site-nav {
  height: 100%;
  max-width: 1220px;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .header--secondary .microsite-nav__site-nav {
      transition: all 450ms ease;
      height: 72px; }
      .header--secondary .microsite-nav__site-nav:after {
        content: "";
        display: table;
        clear: both; }
    .header--fixed .microsite-nav--plusGlobal .microsite-nav__site-nav {
      height: 60px; } }
  @media (min-width: 1440px) {
    .header--secondary .microsite-nav__site-nav {
      height: 105px; }
    .header--fixed .microsite-nav__site-nav {
      height: 72px; } }

@media (min-width: 1024px) {
  #att-microsite-header:not(.header--fixed):not(.plusGlobal).header--secondary .microsite-nav__menu a {
    padding: 46px 0; }
    #att-microsite-header:not(.header--fixed):not(.plusGlobal).header--secondary .microsite-nav__menu a:before {
      bottom: -1px; }
  #att-microsite-header:not(.header--fixed):not(.plusGlobal) .microsite-nav__utils {
    height: 105px; }
    .header--secondary #att-microsite-header:not(.header--fixed):not(.plusGlobal) .microsite-nav__utils {
      height: 105px; } }

.microsite-nav__utils {
  transition: all 450ms ease;
  height: 72px;
  padding: 0 20px; }
  .microsite-nav__utils:after {
    content: "";
    display: table;
    clear: both; }
  .header--fixed .microsite-nav--plusGlobal .microsite-nav__utils {
    height: 60px; }
  @media (min-width: 768px) {
    .microsite-nav__utils {
      padding: 0 55px; } }
  @media (max-width: 768px) {
    .header--secondary .microsite-nav--plusGlobal .microsite-nav__utils, .header--fixed .microsite-nav__utils {
      height: 60px; } }
  @media (min-width: 1024px) {
    .microsite-nav__utils {
      display: none; }
      .header--secondary .microsite-nav__utils {
        display: block;
        float: left;
        height: 72px; } }
  @media (min-width: 1440px) {
    .header--secondary .microsite-nav__utils {
      padding: 0; }
    .header--secondary:not(.header--fixed) .microsite-nav--plusGlobal .microsite-nav__utils {
      height: 72px; } }
  .microsite-nav__utils .microsite-nav__logo,
  .microsite-nav__utils .microsite-nav__title {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    display: block;
    float: left; }
  .microsite-nav__utils .microsite-nav__logo {
    max-width: 100%; }
    .microsite-nav__utils .microsite-nav__logo img {
      position: relative;
      max-width: 100%;
      top: 4px; }
  .microsite-nav__utils .microsite-nav__title {
    font-size: 24px;
    font-size: 2.4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 85%;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
    color: #FFF; }
    @media (min-width: 640px) {
      .microsite-nav__utils .microsite-nav__title {
        font-size: 26px;
        font-size: 2.6rem;
        max-width: none; } }
    .microsite-nav__utils .microsite-nav__title:hover {
      text-decoration: none; }
    .microsite-nav__utils .microsite-nav__title:not(:first-child) {
      display: none; }
  .microsite-nav__utils .microsite-nav__toggle {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    position: relative;
    float: right;
    width: 26px;
    height: 26px; }
    @media (min-width: 1024px) {
      .microsite-nav__utils .microsite-nav__toggle {
        display: none; } }
    .microsite-nav__utils .microsite-nav__toggle:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      border-top: 2px solid #FFF;
      border-left: 2px solid #FFF;
      border-radius: 3px;
      transform: rotate(-135deg); }
      .microsite-nav--open .microsite-nav__utils .microsite-nav__toggle:after {
        transform: rotate(-315deg);
        top: 10px; }

.microsite-nav__menu {
  display: none; }
  .microsite-nav--open .microsite-nav__menu {
    display: block; }
  @media (min-width: 1024px) {
    .microsite-nav__menu {
      display: block; } }
  @media (min-width: 1024px) {
    .header--secondary .microsite-nav__menu {
      padding-right: 55px;
      float: right; } }
  @media (min-width: 1440px) {
    .header--secondary .microsite-nav__menu {
      padding-right: 0;
      margin-top: 0;
      height: 100%; } }
  .microsite-nav__menu ul {
    max-width: 1220px;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .microsite-nav__menu ul {
        text-align: center; } }
    .header--secondary .microsite-nav__menu ul {
      text-align: left; }
      @media (min-width: 1024px) {
        .header--secondary .microsite-nav__menu ul {
          max-width: none;
          height: 100%; } }
  .microsite-nav__menu li {
    border-top: 1px solid #5A5A5A;
    padding: 0 20px; }
    @media (min-width: 768px) {
      .microsite-nav__menu li {
        padding: 0 55px; } }
    @media (min-width: 1024px) {
      .microsite-nav__menu li {
        display: inline-block;
        padding: 0 28px;
        border-top: 0; } }
    @media (min-width: 1024px) {
      .header--secondary .microsite-nav__menu li {
        padding: 0 0 0 25px; }
        .header--secondary .microsite-nav__menu li:first-child {
          padding-left: 0; } }
    @media (min-width: 1220px) {
      .header--secondary .microsite-nav__menu li {
        padding-left: 40px;
        height: 100%; } }
    @media (min-width: 1440px) {
      .header--secondary .microsite-nav__menu li {
        padding-left: 55px; } }
  .microsite-nav__menu a {
    font-size: 14px;
    font-size: 1.4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 30px 0;
    transition: all 450ms ease;
    position: relative;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFF;
    text-decoration: none; }
    .microsite-nav__menu a:before {
      bottom: 0px; }
    .microsite-nav__menu a:hover, .microsite-nav__menu a.active {
      text-decoration: none;
      color: #009FDB; }
    @media (min-width: 1024px) {
      .microsite-nav__menu a:before {
        transition: all 300ms ease;
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        height: 5px;
        width: 100%;
        background-color: #009FDB;
        opacity: 0; }
      .microsite-nav__menu a:hover:before, .microsite-nav__menu a:focus:before, .microsite-nav__menu a.active:before {
        opacity: 1; } }
    @media (min-width: 1024px) {
      .header--secondary .microsite-nav__menu a {
        font-size: 12px;
        font-size: 1.2rem;
        padding: 30px 0; }
        .header--secondary .microsite-nav__menu a:before {
          bottom: -1px; }
      .header--secondary.header--fixed .microsite-nav__menu a {
        padding: 29px 0; }
        .header--secondary.header--fixed .microsite-nav__menu a:before {
          bottom: 0px; }
      .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a {
        padding: 24px 0; }
        .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a:before {
          bottom: -1px; } }
    @media (min-width: 1440px) {
      .header--secondary.header--fixed .microsite-nav__menu a {
        padding: 29px 0; }
        .header--secondary.header--fixed .microsite-nav__menu a:before {
          bottom: 0px; }
      .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a {
        padding: 24px 0; }
        .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a:before {
          bottom: -1px; } }
    @media (min-width: 1600px) {
      .header--secondary .microsite-nav__menu a {
        font-size: 14px;
        font-size: 1.4rem;
        padding: 45px 0; }
        .header--secondary .microsite-nav__menu a:before {
          bottom: -1px; }
      .header--secondary.header--fixed .microsite-nav__menu a {
        padding: 29px 0; }
        .header--secondary.header--fixed .microsite-nav__menu a:before {
          bottom: 0px; }
      .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a {
        font-size: 14px;
        font-size: 1.4rem;
        padding: 29px 0; }
        .header--secondary .microsite-nav--plusGlobal .microsite-nav__menu a:before {
          bottom: -1px; }
      .header--secondary.header--fixed .microsite-nav--plusGlobal .microsite-nav__menu a {
        font-size: 14px;
        font-size: 1.4rem;
        padding: 23px 0; }
        .header--secondary.header--fixed .microsite-nav--plusGlobal .microsite-nav__menu a:before {
          bottom: -1px; } }

/*================
  FEATURED STORIES
  ================*/
.featured-stories {
  padding-top: 20px; }
  @media (min-width: 768px) {
    .featured-stories {
      padding-top: 40px; } }
  .featured-stories.featured-stories--expanded .row:nth-of-type(n + 3) {
    display: block; }
    .featured-stories.featured-stories--expanded .row:nth-of-type(n + 3) .featured-item {
      opacity: 1; }
  .featured-stories .row {
    margin-top: 0; }
    @media (min-width: 768px) {
      .featured-stories .row + .row {
        margin-top: 20px; } }
    @media (min-width: 1220px) {
      .featured-stories .row + .row {
        margin-top: 40px; } }
    .featured-stories .row:nth-of-type(n + 3) {
      display: none; }
      .featured-stories .row:nth-of-type(n + 3) .featured-item {
        opacity: 0; }
  .featured-stories .component {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .featured-stories .component {
        margin-bottom: 0; } }
  .featured-stories .stories__cta {
    display: none;
    /* JavaScript will display this cta only if a mininum number of items are present in the set */
    text-align: center;
    margin: 0; }
    @media (min-width: 768px) {
      .featured-stories .stories__cta {
        margin-top: 40px; } }
    @media (min-width: 1024px) {
      .featured-stories .stories__cta {
        margin-top: 60px; } }

/*======
  PLEDGE
  ======*/
/* Counter */
/* NOTE: The variables below control the size and positioning of the counter numbers. Please edit carefully. */
.counter {
  position: relative;
  display: flex;
  justify-content: center;
  transition: opacity 1.5s ease;
  margin: 20px 0;
  min-height: 50px; }
  @media (min-width: 768px) {
    .counter {
      margin: 0 0 40px;
      min-height: 68px;
      color: #FFF; } }
  .banner__content--left .counter,
  .pledge__content--left .counter {
    justify-content: flex-start; }
  .counter--dark .counter__label {
    color: #000; }
  .counter__digits {
    font-size: 93px;
    font-size: 9.3rem;
    max-height: 85px;
    color: #009FDB;
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */
    /* Mobile Animation */
    /* Desktop Animation */ }
    .counter__digits:after {
      content: "";
      display: table;
      clear: both; }
    .counter__digits > span {
      float: left;
      height: 50px;
      margin: 0;
      padding: 0;
      text-indent: -9999px;
      background-size: 300% auto;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .counter__digits > span {
          height: 68px; } }
    .counter__digits .comma {
      width: 10px;
      margin-top: 5px;
      background-image: url("../img/sprite_counter_comma.png");
      background-position-y: bottom; }
      @media (min-width: 768px) {
        .counter__digits .comma {
          width: 20px;
          margin-top: 15px; } }
      .counter__digits .comma + .digit-2,
      .counter__digits .comma + .digit-3,
      .counter__digits .comma + .digit-4,
      .counter__digits .comma + .digit-5,
      .counter__digits .comma + .digit-8,
      .counter__digits .comma + .digit-9 {
        margin-left: 5px;
        /* prevents the comma from appearing too close to these numbers */ }
    .counter__digits .digit {
      width: 35px;
      background-image: url("../img/sprite_counter_digits.png");
      background-position-y: 0;
      transition: all 1.5s ease; }
      @media (min-width: 768px) {
        .counter__digits .digit {
          width: 50px; } }
    .counter__digits .digit-start-1 {
      background-position: 0 -70px;
      animation-name: digitSpin1;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin1;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin1;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-1 {
          background-position-y: -100px; } }
    .counter__digits .digit-1 {
      background-position-y: -70px; }
      @media (min-width: 768px) {
        .counter__digits .digit-1 {
          background-position-y: -100px; } }

@keyframes digitSpin1 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -70px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin1 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -100px; } } }
    .counter__digits .digit-start-2 {
      background-position: 0 -140px;
      animation-name: digitSpin2;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin2;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin2;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-2 {
          background-position-y: -200px; } }
    .counter__digits .digit-2 {
      background-position-y: -140px; }
      @media (min-width: 768px) {
        .counter__digits .digit-2 {
          background-position-y: -200px; } }

@keyframes digitSpin2 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -140px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin2 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -200px; } } }
    .counter__digits .digit-start-3 {
      background-position: 0 -210px;
      animation-name: digitSpin3;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin3;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin3;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-3 {
          background-position-y: -300px; } }
    .counter__digits .digit-3 {
      background-position-y: -210px; }
      @media (min-width: 768px) {
        .counter__digits .digit-3 {
          background-position-y: -300px; } }

@keyframes digitSpin3 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -210px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin3 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -300px; } } }
    .counter__digits .digit-start-4 {
      background-position: 0 -280px;
      animation-name: digitSpin4;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin4;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin4;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-4 {
          background-position-y: -400px; } }
    .counter__digits .digit-4 {
      background-position-y: -280px; }
      @media (min-width: 768px) {
        .counter__digits .digit-4 {
          background-position-y: -400px; } }

@keyframes digitSpin4 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -280px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin4 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -400px; } } }
    .counter__digits .digit-start-5 {
      background-position: 0 -350px;
      animation-name: digitSpin5;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin5;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin5;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-5 {
          background-position-y: -500px; } }
    .counter__digits .digit-5 {
      background-position-y: -350px; }
      @media (min-width: 768px) {
        .counter__digits .digit-5 {
          background-position-y: -500px; } }

@keyframes digitSpin5 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -350px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin5 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -500px; } } }
    .counter__digits .digit-start-6 {
      background-position: 0 -420px;
      animation-name: digitSpin6;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin6;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin6;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-6 {
          background-position-y: -600px; } }
    .counter__digits .digit-6 {
      background-position-y: -420px; }
      @media (min-width: 768px) {
        .counter__digits .digit-6 {
          background-position-y: -600px; } }

@keyframes digitSpin6 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -420px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin6 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -600px; } } }
    .counter__digits .digit-start-7 {
      background-position: 0 -490px;
      animation-name: digitSpin7;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin7;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin7;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-7 {
          background-position-y: -700px; } }
    .counter__digits .digit-7 {
      background-position-y: -490px; }
      @media (min-width: 768px) {
        .counter__digits .digit-7 {
          background-position-y: -700px; } }

@keyframes digitSpin7 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -490px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin7 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -700px; } } }
    .counter__digits .digit-start-8 {
      background-position: 0 -560px;
      animation-name: digitSpin8;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin8;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin8;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-8 {
          background-position-y: -800px; } }
    .counter__digits .digit-8 {
      background-position-y: -560px; }
      @media (min-width: 768px) {
        .counter__digits .digit-8 {
          background-position-y: -800px; } }

@keyframes digitSpin8 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -560px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin8 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -800px; } } }
    .counter__digits .digit-start-9 {
      background-position: 0 -630px;
      animation-name: digitSpin9;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin9;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin9;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-9 {
          background-position-y: -900px; } }
    .counter__digits .digit-9 {
      background-position-y: -630px; }
      @media (min-width: 768px) {
        .counter__digits .digit-9 {
          background-position-y: -900px; } }

@keyframes digitSpin9 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -630px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin9 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -900px; } } }
    .counter__digits .digit-start-0 {
      background-position: 0 -700px;
      animation-name: digitSpin0;
      animation-duration: 2s;
      animation-iteration-count: 1;
      -webkit-animation-name: digitSpin0;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: 1;
      -moz-animation-name: digitSpin0;
      -moz-animation-duration: 2s;
      -moz-animation-iteration-count: 1; }
      @media (min-width: 768px) {
        .counter__digits .digit-start-0 {
          background-position-y: -1000px; } }
    .counter__digits .digit-0 {
      background-position-y: -700px; }
      @media (min-width: 768px) {
        .counter__digits .digit-0 {
          background-position-y: -1000px; } }

@keyframes digitSpin0 {
  from {
    background-position-y: 0; }
  to {
    background-position-y: -700px; } }
    @media (min-width: 768px) {
      @keyframes digitSpin0 {
        from {
          background-position-y: 0; }
        to {
          background-position-y: -1000px; } } }
  .counter__label {
    font-size: 22.5px;
    font-size: 2.25rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: left;
    max-width: 100px;
    line-height: 1.2;
    margin-top: -15px;
    margin-left: 10px; }
    @media (min-width: 768px) {
      .counter__label {
        font-size: 32px;
        font-size: 3.2rem;
        margin-left: 15px; } }

/* Pledge vs. Banner Mode Toggling */
.banner__mode,
.pledge__mode {
  display: none; }
  .banner__mode .counter__label,
  .banner__mode .pledge__prompt,
  .banner__mode .pledge__thanks,
  .pledge__mode .counter__label,
  .pledge__mode .pledge__prompt,
  .pledge__mode .pledge__thanks {
    transition: opacity 1s ease;
    visibility: visible;
    position: relative;
    left: 0; }
  .banner__mode .hidden-fade,
  .pledge__mode .hidden-fade {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    left: 100%; }
  .banner__mode .pledge__prompt.hidden-fade,
  .banner__mode .pledge__thanks.hidden-fade,
  .pledge__mode .pledge__prompt.hidden-fade,
  .pledge__mode .pledge__thanks.hidden-fade {
    position: absolute; }

[data-mode="banner"] .banner__mode,
[data-mode="pledge"] .pledge__mode {
  display: block; }

/* Banner Mode */
.banner__mode .banner__img {
  display: block; }

.banner__mode .banner__headline--text {
  font-size: 45px;
  font-size: 4.5rem; }
  @media (min-width: 768px) {
    .banner__mode .banner__headline--text {
      font-size: 60px;
      font-size: 6rem; } }
  @media (min-width: 1220px) {
    .banner__mode .banner__headline--text {
      font-size: 80px;
      font-size: 8rem; } }

@media (min-width: 1220px) {
  .banner__mode .banner__level3 {
    font-size: 24px;
    font-size: 2.4rem; } }

.banner__mode .banner__cta .cta--link {
  font-family: 'Aleck Sans Medium'; }
  .banner__mode .banner__cta .cta--link:after {
    font-family: 'Aleck Sans'; }

.banner__mode .banner__text--dark + .banner__cta .cta--link {
  color: #009FDB; }

/* Pledge Mode */
.pledge__mode h1, .pledge__mode h2, .pledge__mode h3, .pledge__mode h4, .pledge__mode h5, .pledge__mode h6 {
  margin-bottom: 10px; }

.pledge__mode h3 {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: inherit;
  font-weight: bold; }

.pledge__mode h4 {
  font-size: 21px;
  font-size: 2.1rem;
  font-family: inherit;
  font-weight: bold; }

.pledge__mode p {
  margin-bottom: 20px; }

.pledge__mode .social-channels ul {
  padding-top: 0;
  list-style-type: none; }
  .pledge__mode .social-channels ul .cta--social, .pledge__mode .social-channels ul .region .cta--social {
    font-size: 35px;
    font-size: 3.5rem;
    height: 48px;
    width: 48px; }

.pledge__mode .pledge__content {
  font-size: 18px;
  font-size: 1.8rem;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  line-height: 1.6; }
  .pledge__mode .pledge__content--left {
    text-align: left;
    max-width: 1220px; }
    .pledge__mode .pledge__content--left .social-channels ul {
      text-align: left; }

.pledge__mode .pledge__button {
  margin-top: 40px; }

.pledge__mode .pledge__wrap {
  position: relative;
  overflow: hidden; }
  .pledge__mode .pledge__wrap:after {
    content: "";
    display: table;
    clear: both; }

/* Counter Color Contrast Settings */
.region--blue .counter__digits > span,
.region--blue--bl-text .counter__digits > span,
.region--comms-berry .counter__digits > span,
.region--comms-tangerine .counter__digits > span,
.region--comms-lime .counter__digits > span,
.region--comms-mint .counter__digits > span,
.region--blue-gradient--bl-text .counter__digits > span,
.region--comms-berry-tangerine .counter__digits > span,
.region--comms-lime-mint .counter__digits > span {
  background-position-x: center; }

.region--dark-blue .counter__digits > span,
.region--blue--wh-text .counter__digits > span,
.region--comms-cobalt .counter__digits > span,
.region--comms-purple .counter__digits > span,
.region--blue-gradient--wh-text .counter__digits > span,
.region--comms-cobalt-purple .counter__digits > span {
  background-position-x: right; }

/*============
  RELATED NEWS
  ============*/
.related-news .news__feed {
  max-width: 1220px;
  padding: 0 20px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .related-news .news__feed {
      padding: 0 55px; } }
  @media (min-width: 1440px) {
    .related-news .news__feed {
      padding: 0; } }
  .related-news .news__feed li {
    margin-bottom: 30px; }
  .related-news .news__feed a {
    font-size: 20px;
    font-size: 2rem;
    display: block;
    font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
    color: inherit;
    margin-bottom: 10px;
    line-height: 1.25;
    text-decoration: none; }
    .related-news .news__feed a:hover {
      text-decoration: underline; }
    .region--white .related-news .news__feed a, .region--gray .related-news .news__feed a {
      color: #0568AE; }
    .region[class*="black"] .related-news .news__feed a {
      color: #009FDB; }
  .related-news .news__feed p {
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
    margin-bottom: 0;
    line-height: 1.35; }

/*=====
  RSS Feed
	=====*/
.rss-feed {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999999999;
  display: none; }
  .rss-feed.rss-feed--active {
    display: block; }
  .rss-feed--modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh; }
  .rss-feed__bkgd {
    transition: all 1000ms ease;
    transform: translateY(500px);
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2); }
    .rss-feed--modal .rss-feed__bkgd {
      transition: all 750ms ease;
      position: fixed;
      display: flex;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.75);
      transform: translateY(0);
      opacity: 0; }
    .rss-feed--transition .rss-feed__bkgd {
      transition: all 1000ms ease;
      transform: translateY(0);
      opacity: 1; }
  .rss-feed__inner {
    background-color: #0568AE;
    position: relative;
    max-height: 100%;
    max-width: 470px;
    width: 100%;
    padding-top: 125px; }
    @media (min-width: 640px) {
      .rss-feed--popup .rss-feed__inner {
        width: 470px; } }
    .rss-feed--modal .rss-feed__inner {
      max-width: 530px; }
  .rss-feed__container {
    padding: 20px;
    background-color: #FFF; }
    .rss-feed__container h1, .rss-feed__container h2, .rss-feed__container h3, .rss-feed__container h4, .rss-feed__container h5, .rss-feed__container h6, .rss-feed__container p {
      text-align: center;
      max-width: 355px;
      margin: 0 auto;
      margin-bottom: 20px;
      color: #191919; }
      .rss-feed__container h1:last-child, .rss-feed__container h2:last-child, .rss-feed__container h3:last-child, .rss-feed__container h4:last-child, .rss-feed__container h5:last-child, .rss-feed__container h6:last-child, .rss-feed__container p:last-child {
        margin-bottom: 0; }
    .rss-feed__container p {
      font-size: 15px;
      font-size: 1.5rem;
      font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
      line-height: 1.35; }
    @media (min-width: 640px) {
      .rss-feed__container {
        padding: 30px 40px 20px; } }
    @media (min-width: 768px) {
      .rss-feed--popup .rss-feed__container h1 {
        font-size: 35px;
        font-size: 3.5rem; }
      .rss-feed--popup .rss-feed__container h2 {
        font-size: 24px;
        font-size: 2.4rem; }
      .rss-feed--popup .rss-feed__container h3 {
        font-size: 18px;
        font-size: 1.8rem; }
      .rss-feed--popup .rss-feed__container h4 {
        font-size: 16px;
        font-size: 1.6rem; } }
  .rss-feed__intro {
    position: relative; }
    .rss-feed__intro .rss-feed__icon {
      position: relative;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      text-align: center;
      background-color: white;
      width: 137px;
      height: 137px;
      border-radius: 100%;
      top: -100px; }
      .rss-feed__intro .rss-feed__icon img {
        max-width: 90px;
        margin-top: 30px;
        margin-left: -5px; }
    .rss-feed__intro .rss-feed__content {
      width: 100%;
      margin-right: 10px;
      position: relative;
      padding-top: 25px; }
  .rss-feed__cta-header {
    display: none;
    margin: 10px 0 30px;
    text-align: center; }
    .rss-feed__cta-header h3 {
      font-size: 18px;
      font-size: 1.8rem;
      font-family: 'Aleck Sans', Helvetica, Arial, sans-serif;
      font-weight: bold; }
    .rss-feed--modal .rss-feed__cta-header {
      display: block; }
    @media (min-width: 640px) {
      .rss-feed__cta-header {
        margin: 20px 0 30px; }
        .rss-feed__cta-header h3 {
          font-size: 21px;
          font-size: 2.1rem; } }
  .rss-feed__cta {
    margin: 30px 0 20px; }
    .rss-feed__cta .cta--button {
      width: 100%;
      min-width: auto;
      border-radius: 0;
      padding: 18px 30px 20px;
      background-color: #FFF;
      color: #0568AE;
      font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
      display: block;
      margin: 0 auto; }
      @media (min-width: 640px) {
        .rss-feed__cta .cta--button {
          font-size: 15px;
          font-size: 1.5rem;
          width: calc(50% - 30px);
          min-width: 150px; } }
      .rss-feed__cta .cta--button:hover {
        background-color: transparent;
        color: #191919; }
    .rss-feed__cta .cta--signup {
      position: relative;
      margin-bottom: 10px;
      padding-left: 0;
      background-color: #0568AE;
      color: #FFF; }
      .rss-feed__cta .cta--signup:after {
        content: " \2192";
        color: inherit;
        font-weight: 400; }
      .rss-feed__cta .cta--signup:after {
        content: url("/../_assets/dist/img/icon_arrow-right.png");
        position: absolute;
        top: 12px;
        display: inline-block;
        margin-left: 10px;
        font-size: 24px; }
      .rss-feed__cta .cta--signup:hover {
        background-color: #000;
        color: #FFF; }
  .rss-feed__close {
    font-size: 15px;
    font-size: 1.5rem;
    position: absolute;
    height: 40px;
    width: 40px;
    top: -20px;
    left: -20px;
    overflow: hidden;
    border-radius: 100%;
    background-color: #FFF;
    color: transparent;
    font-weight: 300;
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2); }
    .rss-feed--modal .rss-feed__close {
      right: -20px;
      left: auto; }
    .rss-feed__close:after {
      font-size: 50px;
      font-size: 5rem;
      content: "+";
      position: absolute;
      display: block;
      top: -13px;
      left: 6px;
      color: #000;
      transform: rotate(45deg); }

.flexboxtweener.no-flexboxlegacy .rss-feed__close:after {
  top: -5px; }

/*======
  SEARCH
  ======*/
.search-field {
  box-sizing: border-box;
  /* Search Form */
  /* Search Input Field */
  /* Search Submit Button */
  /* Search Results */
  /* Search CTA */ }
  .col .search-field {
    padding: 0; }
  @media (min-width: 768px) {
    .search-field {
      padding: 0; } }
  .search-field fieldset {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 30px;
    border: 1px solid;
    overflow: hidden; }
    .search-field fieldset:after {
      content: "";
      display: table;
      clear: both; }
    .region[class*="white"] .search-field fieldset,
    .region[class*="gray"] .search-field fieldset,
    .region[class*="black"] .search-field fieldset,
    .sidebar .search-field fieldset {
      border-width: 2px; }
    .region[class*="white"] .search-field fieldset,
    .sidebar .search-field fieldset {
      border-color: #EAEAEA; }
    .region[class*="gray"] .search-field fieldset {
      border-color: #C9C9C9; }
    .region[class*="black"] .search-field fieldset {
      border-color: #F2F2F2; }
  .search-field label {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    position: absolute !important;
    word-wrap: normal !important; }
    .search-field label:focus {
      clip: auto !important;
      clip-path: none;
      z-index: 100000;
      outline: 0; }
  .search-field input,
  .search-field button {
    float: left;
    height: 37px;
    color: inherit;
    background-color: transparent;
    box-sizing: border-box;
    border: 0; }
    @media (min-width: 1024px) {
      .col-2 .search-field input,
      .col-3 .search-field input,
      .col-4 .search-field input, .col-2
      .search-field button,
      .col-3
      .search-field button,
      .col-4
      .search-field button {
        height: 33px; } }
  .search-field .search__form,
  .search-field > form {
    padding: 0 20px; }
    .sidebar .search-field .search__form, .sidebar
    .search-field > form {
      padding: 0; }
  .search-field .search__input {
    padding: 6px 0 8px 15px;
    width: calc(100% - 30px); }
    @media (min-width: 768px) {
      .search-field .search__input {
        width: calc(100% - 40px); } }
    @media (min-width: 1024px) {
      .col-2 .search-field .search__input,
      .col-3 .search-field .search__input,
      .col-4 .search-field .search__input {
        padding: 4px 0 6px 15px; } }
  .search-field .search__submit {
    position: absolute;
    min-width: 25px;
    right: 5px; }
    @media (min-width: 768px) {
      .search-field .search__submit {
        right: 10px; } }
    @media (min-width: 1024px) {
      .col-2 .search-field .search__submit,
      .col-3 .search-field .search__submit,
      .col-4 .search-field .search__submit {
        right: 8px; } }
    .search-field .search__submit span {
      height: 100%;
      width: 100%; }
      .search-field .search__submit span:after {
        font-size: 30px;
        font-size: 3rem;
        top: 52%; }
        @media (min-width: 768px) {
          .search-field .search__submit span:after {
            font-size: 40px;
            font-size: 4rem; } }
        @media (min-width: 1024px) {
          .col-2 .search-field .search__submit span:after,
          .col-3 .search-field .search__submit span:after,
          .col-4 .search-field .search__submit span:after {
            font-size: 34px;
            font-size: 3.4rem; } }
        .region[class*="white"] .search-field .search__submit span:after,
        .region[class*="gray"] .search-field .search__submit span:after,
        .sidebar .search-field .search__submit span:after {
          color: #009FDB; }
        .region[class*="black"] .search-field .search__submit span:after {
          color: #F2F2F2; }
  .search-field .search__wrap {
    display: none; }
  .search-field[data-inline="true"] .search__wrap {
    display: block; }
  .search-field .search__results {
    display: none;
    max-width: 1220px;
    margin: 0 auto; }
    .search-field .search__results [class^="page"] {
      margin: 20px;
      padding: 40px 0;
      border-top: 1px solid; }
      @media (min-width: 768px) {
        .search-field .search__results [class^="page"] {
          margin-left: 55px;
          margin-right: 55px; } }
      @media (min-width: 1440px) {
        .search-field .search__results [class^="page"] {
          margin-left: 0;
          margin-right: 0; } }
    .search-field .search__results .results__heading {
      font-size: 25px;
      font-size: 2.5rem;
      margin-top: 40px;
      padding-bottom: 20px; }
    .search-field .search__results .result {
      margin-bottom: 50px; }
      .search-field .search__results .result__timestamp {
        margin-bottom: 12px; }
      .search-field .search__results .result__title {
        font-size: 20px;
        font-size: 2rem;
        font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
        font-weight: 400;
        margin-bottom: 12px;
        line-height: 1.25; }
      .search-field .search__results .result__description {
        margin-bottom: 15px;
        line-height: 1.5; }
    .search-field .search__results .no-results {
      margin: 60px 0; }
  .search-field .search__cta {
    justify-content: center; }
    .search-field .search__cta .cta--button {
      display: none;
      margin: 0 10px; }

/* Search Widget */
/*
#wgt-custom-select-search input[type="search"]::-webkit-search-decoration,
#wgt-custom-select-search input[type="search"]::-webkit-search-cancel-button,
#wgt-custom-select-search input[type="search"]::-webkit-search-results-button,
#wgt-custom-select-search input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
#wgt-custom-select-search {
	input {
		background-color: $white;
		border: 1px solid #cecece;
  }
  button:focus{
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
	[class^="span"] {
		width: 100%;
		color: $functionalBlack;
		font-family: $aleck;
		line-height: 2rem;
		box-sizing: border-box;
		padding-top: 10px;
		padding-bottom: 10px;
	}
  
	.field-group {
		position: relative;
		display: block;
  }
  
	ul {
		.hrLine {
			overflow: hidden;
			text-align: left !important;
			font-size: 15px !important;
			color: #94989c;
			margin-bottom: 20px !important;
			margin-top: 0px !important;
			padding: 4px 16px 4px 16px !important;
      font-family: $aleckMedium;
      
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				vertical-align: middle;
				border: 0px;
				padding-right: 16px;
			}
		}
		li {
			padding: 16px 16px;
      border-bottom: 1px solid #cecece;

      &:first-of-type {
        padding-top: 0;
      }

			&:last-child {
        border-bottom: 0;

        a,
        .keyspan {
					font-weight: 600;
					color: #0080b1 !important;
				}
				img {
					display: none;
				}
			}
		}
	}
	.search-list {
    list-style: none;
		position: relative;
		width: 100%;
		border: 1px solid #cecece;
		padding-top: 12px;
		padding-bottom: 25px;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		margin-top: 10px;
    display: block;
  }
  .search-list .search-list{
    border:transparent;
  }
	.form-row {
		margin: 0 auto;
		width: 100%;
		margin-top: 15px;
	}
  a, 
  .keyspan {
    @include font-size(15);
		color: #009fdb;
		text-decoration: none;
		font-family: $aleckMedium;
	}
	input[type="search"] {
    @include font-size(16);
    padding: 10px 50px 10px 15px;
    border-radius: 5px;
    width: 100%;
    height: 43px;
		padding-right: 40px;
		text-overflow: ellipsis;
	}
	button[type="submit"] {
		position: absolute;
    background-color: transparent;
    border: none;
    width: 4.4rem;
    height: 43px;
    padding: 3px 0 0 0;
    right: 0;
    margin: 0;
    outline-offset: 0;
    border-radius: 0 1.5rem 1.5rem 0;
	}
}

.story #wgt-custom-select-search {
  .form-row {
    margin-top: 0px;
  }
}

#wgt-custom-select-search-widget {
	position: relative;
}
#wgt-custom-select-searchfield-predictionBucket {
	margin: 0 auto 25px;
	width: 100%;
}

.search-widget--shortened #wgt-custom-select-search,
.search-widget--shortened #wgt-custom-select-searchfield-predictionBucket {
  max-width: 610px; 
  margin-left: auto;
  margin-right: auto; 
}
*/
/*====================
  SOCIAL CHANNEL/SHARE
  ====================*/
.social-channels:after {
  content: "";
  display: table;
  clear: both; }

.social-channels ul {
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
  padding: 40px 0; }
  .component--border-bottom .social-channels ul {
    padding-bottom: 0; }

.social-channels li {
  display: inline-block;
  margin: 0 10px; }
  @media (min-width: 768px) {
    .social-channels li {
      margin: 0 12.5px; } }

.social-channels a:hover {
  text-decoration: none; }

/*============
  STOCK TICKER
  ============*/
.row--equal-heights .stock-ticker, .row--equal-heights
.stock-ticker__wrap {
  height: 100%; }

.stock-ticker__wrap {
  box-sizing: border-box;
  padding: 20px;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  text-align: center;
  color: #000;
  /* NOTE: Stock ticker background color can vary based on the page category (theme). Default treatment is silver. */
  background: #EDECEC;
  background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
  background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
  background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
  .stock-ticker__wrap:after {
    content: "";
    display: table;
    clear: both; }
  .stock-ticker__wrap .cta--link {
    text-decoration: none; }
  .no-cssgradients .stock-ticker__wrap {
    filter: none; }
  .stock-ticker__wrap .cta--link {
    color: #0568AE; }
  .row--equal-heights .stock-ticker__wrap {
    position: relative;
    transform: translateY(-50%);
    top: 50%; }
  .stock-ticker__wrap div {
    position: relative;
    text-align: center; }
    .stock-ticker__wrap div:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 640px) {
      .stock-ticker__wrap div {
        display: inline-block; } }
  .stock-ticker__wrap span {
    display: inline-block; }
    @media (min-width: 480px) {
      .stock-ticker__wrap span {
        float: left; } }

/* Ticker Price */
.stock-ticker__price {
  font-size: 22px;
  font-size: 2.2rem;
  display: inline-block;
  font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
  margin-bottom: 10px; }
  @media (min-width: 640px) {
    .stock-ticker__price {
      margin-bottom: 0; } }
  @media (min-width: 768px) {
    .stock-ticker__price {
      margin-right: 15px; } }

/* Ticker Change */
.stock-ticker__change {
  font-size: 22px;
  font-size: 2.2rem;
  display: inline-block;
  margin: 0 10px 10px 15px; }
  @media (min-width: 640px) {
    .stock-ticker__change {
      margin-bottom: 0; } }
  .stock-ticker__change .change__symbol {
    position: relative;
    top: -2.5px; }
    @media (min-width: 640px) {
      .stock-ticker__change .change__symbol {
        margin-right: 5px; } }

/* Ticker Timestamp */
.stock-ticker__timestamp {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 0 15px 10px 0; }
  @media (min-width: 480px) {
    .stock-ticker__timestamp {
      display: inline-block;
      top: -1px; } }
  @media (min-width: 640px) {
    .stock-ticker__timestamp {
      margin-bottom: 0; } }
  .stock-ticker__timestamp .exchange__date {
    margin-right: 8px; }

/* Ticker Info */
.stock-ticker__info {
  margin-left: -25px; }
  @media (min-width: 640px) {
    .stock-ticker__info {
      display: inline-block;
      margin-left: 15px;
      top: -3px; } }
  .stock-ticker__info .cta--link {
    font-size: 15px;
    font-size: 1.5rem; }
    .region[class*="blue"] .stock-ticker__info .cta--link {
      color: #0568AE; }

/*============
  STORY ASSETS
  ============*/
.story__assets select {
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  padding: 7px;
  max-width: 100%;
  border-radius: 5px;
  color: #5A5A5A;
  border: 1px solid #C9C9C9;
  background-color: transparent;
  margin-top: -7px; }

/*=====
  TABLE
  =====*/
.SNR_table {
  max-width: 1220px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden; }
  .SNR_table .dataTables_wrapper {
    padding: 0 20px; }
    @media (min-width: 768px) {
      .SNR_table .dataTables_wrapper {
        padding: 0px 55px; } }
    @media (min-width: 1440px) {
      .SNR_table .dataTables_wrapper {
        padding: 0; } }
  .SNR_table .dataTable {
    margin-bottom: 20px;
    border-bottom: 1px solid #C9C9C9;
    line-height: 1.45; }
    @media (max-width: 767px) {
      .SNR_table .dataTable tr[role="row"] {
        border-top: 1px solid #C9C9C9;
        display: block; }
        .SNR_table .dataTable tr[role="row"] td {
          display: block; } }
    .SNR_table .dataTable thead tr[role="row"] {
      border: 0;
      padding: 20px 25px; }
    .SNR_table .dataTable thead th {
      font-size: 18px;
      font-size: 1.8rem;
      text-align: left;
      font-family: 'Aleck Sans Black', 'Aleck Sans', Arial, Helvetica, sans-serif;
      font-weight: normal;
      border: 0;
      padding: 0;
      vertical-align: bottom; }
      @media (min-width: 768px) {
        .SNR_table .dataTable thead th {
          font-size: 21px;
          font-size: 2.1rem;
          padding: 15px 15px; } }
    .SNR_table .dataTable tbody tr {
      background-color: transparent; }
    .SNR_table .dataTable tbody td {
      font-size: 18px;
      font-size: 1.8rem;
      padding: 15px 20px; }
      @media (min-width: 768px) {
        .SNR_table .dataTable tbody td {
          font-size: 21px;
          font-size: 2.1rem;
          border-top: 1px solid #C9C9C9;
          border-left: 1px solid #C9C9C9; }
          .SNR_table .dataTable tbody td.all {
            border-left: 0; } }
    .SNR_table .dataTable tr.child {
      padding: 0; }
    .SNR_table .dataTable td.child {
      padding: 0 25px 10px;
      line-height: 1.45;
      border: 0; }
      .SNR_table .dataTable td.child .dtr-details {
        padding-left: 30px; }
      .SNR_table .dataTable td.child .dtr-details li {
        border-bottom: 0; }
      .SNR_table .dataTable td.child .dtr-title {
        display: block;
        font-family: 'Aleck Sans Black', 'Aleck Sans', Arial, Helvetica, sans-serif; }
    .SNR_table .dataTable th .component,
    .SNR_table .dataTable td .component,
    .SNR_table .dataTable th .text .text__wrap p:first-child:last-child,
    .SNR_table .dataTable td .text .text__wrap p:first-child:last-child {
      margin-bottom: 0; }
    .SNR_table .dataTable th .text,
    .SNR_table .dataTable td .text {
      padding: 0;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto; }
  .region--blue .SNR_table.table--zebra-stripe tr.even {
    background-color: #00aeef; }
  .region--dark-blue .SNR_table.table--zebra-stripe tr.even {
    background-color: #045c9a; }
  .region--near-black .SNR_table.table--zebra-stripe tr.even {
    background-color: #0f0f0f; }
  .region--white .SNR_table.table--zebra-stripe tr.even {
    background-color: white; }
  .region--black .SNR_table.table--zebra-stripe tr.even {
    background-color: black; }
  .region--gray .SNR_table.table--zebra-stripe tr.even {
    background-color: #fcfcfc; }
  .region--blue--wh-text .SNR_table.table--zebra-stripe tr.even {
    background-color: #0090c7; }
  .region--blue--bl-text .SNR_table.table--zebra-stripe tr.even {
    background-color: #00aeef; }
  .region--comms-cobalt .SNR_table.table--zebra-stripe tr.even {
    background-color: #004da4; }
  .region--comms-purple .SNR_table.table--zebra-stripe tr.even {
    background-color: #9f25aa;
    background-color: #a326ae; }
  .region--comms-berry .SNR_table.table--zebra-stripe tr.even {
    background-color: #ff6c71;
    background-color: #ff494e; }
  .region--comms-tangerine .SNR_table.table--zebra-stripe tr.even {
    background-color: #ffb614;
    background-color: #f0a500; }
  .region--comms-lime .SNR_table.table--zebra-stripe tr.even {
    background-color: #9ef000;
    background-color: #87cd00; }
  .region--comms-mint .SNR_table.table--zebra-stripe tr.even {
    background-color: #5cf0e0;
    background-color: #1aead3; }
  .region--white .SNR_table.table--zebra-stripe tr.even {
    background-color: #F2F2F2; }
  .region[class*="black"] .SNR_table.table--zebra-stripe tr.even {
    background-color: #333333; }
  .SNR_table.table--zebra-stripe tr.even + tr.child {
    display: block;
    margin-top: 10px; }
  .region--blue .SNR_table .dataTable, .region--blue .SNR_table tbody td, .region--blue .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--dark-blue .SNR_table .dataTable, .region--dark-blue .SNR_table tbody td, .region--dark-blue .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--near-black .SNR_table .dataTable, .region--near-black .SNR_table tbody td, .region--near-black .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--white .SNR_table .dataTable, .region--white .SNR_table tbody td, .region--white .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--black .SNR_table .dataTable, .region--black .SNR_table tbody td, .region--black .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--gray .SNR_table .dataTable, .region--gray .SNR_table tbody td, .region--gray .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--blue--wh-text .SNR_table .dataTable, .region--blue--wh-text .SNR_table tbody td, .region--blue--wh-text .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--blue--bl-text .SNR_table .dataTable, .region--blue--bl-text .SNR_table tbody td, .region--blue--bl-text .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-cobalt .SNR_table .dataTable, .region--comms-cobalt .SNR_table tbody td, .region--comms-cobalt .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--comms-purple .SNR_table .dataTable, .region--comms-purple .SNR_table tbody td, .region--comms-purple .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--comms-berry .SNR_table .dataTable, .region--comms-berry .SNR_table tbody td, .region--comms-berry .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-tangerine .SNR_table .dataTable, .region--comms-tangerine .SNR_table tbody td, .region--comms-tangerine .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-lime .SNR_table .dataTable, .region--comms-lime .SNR_table tbody td, .region--comms-lime .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-mint .SNR_table .dataTable, .region--comms-mint .SNR_table tbody td, .region--comms-mint .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--blue-gradient--wh-text .SNR_table .dataTable, .region--blue-gradient--wh-text .SNR_table tbody td, .region--blue-gradient--wh-text .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--blue-gradient--bl-text .SNR_table .dataTable, .region--blue-gradient--bl-text .SNR_table tbody td, .region--blue-gradient--bl-text .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-cobalt-purple .SNR_table .dataTable, .region--comms-cobalt-purple .SNR_table tbody td, .region--comms-cobalt-purple .SNR_table tr[role="row"] {
    border-color: #FFF; }
  .region--comms-berry-tangerine .SNR_table .dataTable, .region--comms-berry-tangerine .SNR_table tbody td, .region--comms-berry-tangerine .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--comms-lime-mint .SNR_table .dataTable, .region--comms-lime-mint .SNR_table tbody td, .region--comms-lime-mint .SNR_table tr[role="row"] {
    border-color: #191919; }
  .region--white .SNR_table .dataTable, .region--white .SNR_table tbody td, .region--white .SNR_table tr[role="row"], .region--gray .SNR_table .dataTable, .region--gray .SNR_table tbody td, .region--gray .SNR_table tr[role="row"] {
    border-color: #C9C9C9; }
  .region--white .SNR_table .dataTable td.child, .region--gray .SNR_table .dataTable td.child {
    border: 0; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  padding-left: 55px; }
  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
    font-size: 26px;
    font-size: 2.6rem;
    transition: all 300ms ease;
    color: inherit;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-family: 'Aleck Sans', Arial, Helvetica, sans-serif;
    line-height: 12px; }

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '+';
  font-size: 28px;
  font-size: 2.8rem;
  transition: all 300ms ease;
  transform: translateY(-50%) rotate(45deg); }

/*=====
  TABS
  =====*/
.tabs {
  width: 100%;
  max-width: 1220px;
  padding: 0 20px;
  margin: 20px auto 0; }
  @media (min-width: 768px) {
    .tabs {
      padding: 0 55px;
      margin: 40px auto 0; } }
  @media (min-width: 1440px) {
    .tabs {
      padding: 0; } }
  .row .tabs {
    padding: 0; }
  .tabs__container {
    display: block;
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .tabs__container {
        display: flex;
        flex-wrap: wrap; } }

.tab__title {
  display: block;
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .tab__title {
      margin: 0 40px 0 0; }
      .tab__title:last-of-type {
        margin-right: 0; }
      .tabs--box .tab__title {
        align-self: flex-end;
        margin-right: 20px;
        width: 100%;
        max-width: 185px; }
      .tabs--bar .tab__title {
        align-self: flex-end;
        width: 100%; } }
  .tab__title a {
    font-size: 21px;
    font-size: 2.1rem;
    position: relative;
    font-family: "Aleck Sans", Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-decoration: none;
    word-break: break-word; }
    @media (min-width: 768px) {
      .tab__title a {
        font-family: "Aleck Sans Medium", "Aleck Sans", Arial, Helvetica, sans-serif;
        font-weight: normal; }
        .tabs--box .tab__title a {
          font-size: 16px;
          font-size: 1.6rem;
          display: block;
          width: 100%;
          margin-bottom: -1px;
          border: 1px solid #FFF;
          padding: 20px 10px;
          font-family: "Aleck Sans", "Aleck Sans", Arial, Helvetica, sans-serif;
          text-align: center;
          color: inherit; }
          .region--white .tabs--box .tab__title a, .region--gray .tabs--box .tab__title a {
            border-color: #C9C9C9; }
          .region--blue--bl-text .tabs--box .tab__title a,
          .region--blue-gradient--bl-text .tabs--box .tab__title a,
          .region--comms-lime .tabs--box .tab__title a,
          .region--comms-mint .tabs--box .tab__title a,
          .region--comms-berry .tabs--box .tab__title a,
          .region--comms-tangerine .tabs--box .tab__title a,
          .region--comms-berry-tangerine .tabs--box .tab__title a,
          .region--comms-lime-mint .tabs--box .tab__title a {
            border-color: #000; }
        .tabs--bar .tab__title a {
          font-size: 18px;
          font-size: 1.8rem;
          font-family: "Aleck Sans", "Aleck Sans", Arial, Helvetica, sans-serif;
          font-weight: bold;
          color: #5A5A5A; }
          .region--blue .tabs--bar .tab__title a,
          .region[class*="blue--"] .tabs--bar .tab__title a,
          .region[class*="blue-gradient"] .tabs--bar .tab__title a {
            color: #414141; }
          .region[class*="cobalt"] .tabs--bar .tab__title a,
          .region[class*="purple"] .tabs--bar .tab__title a,
          .region[class*="black"] .tabs--bar .tab__title a,
          .region--dark-blue .tabs--bar .tab__title a {
            color: #F2F2F2; } }
    .tab__title a:after {
      content: '';
      position: relative;
      display: none;
      width: 100%;
      height: 0; }
      @media (min-width: 768px) {
        .tabs--bar .tab__title a:after {
          display: inline-block;
          border: 2px solid #5A5A5A;
          margin: 0; }
          .region--blue .tabs--bar .tab__title a:after,
          .region[class*="blue--"] .tabs--bar .tab__title a:after,
          .region[class*="blue-gradient"] .tabs--bar .tab__title a:after {
            border-color: #414141; }
          .region[class*="cobalt"] .tabs--bar .tab__title a:after,
          .region[class*="purple"] .tabs--bar .tab__title a:after,
          .region[class*="black"] .tabs--bar .tab__title a:after,
          .region--dark-blue .tabs--bar .tab__title a:after {
            border-color: #F2F2F2; } }
    @media (min-width: 768px) {
      .tabs--box .tab__title a:hover, .tabs--box .tab__title a:focus {
        background-color: #F2F2F2;
        color: #191919; }
        .region--gray .tabs--box .tab__title a:hover, .region--gray .tabs--box .tab__title a:focus {
          background-color: #C9C9C9; } }
    @media (min-width: 768px) {
      .tab__title a:hover:after, .tab__title a:focus:after {
        display: inline-block;
        border: 2px solid #0568AE;
        border-radius: 0;
        margin: 0px -4px 10px 0; }
        .region--dark-blue .tab__title a:hover:after,
        .region--comms-cobalt .tab__title a:hover:after,
        .region--comms-purple .tab__title a:hover:after,
        .region--comms-cobalt-purple .tab__title a:hover:after, .region--dark-blue .tab__title a:focus:after,
        .region--comms-cobalt .tab__title a:focus:after,
        .region--comms-purple .tab__title a:focus:after,
        .region--comms-cobalt-purple .tab__title a:focus:after {
          border-color: #FFF; }
        .tabs--box .tab__title a:hover:after, .tabs--box .tab__title a:focus:after {
          display: none; }
        .tabs--bar .tab__title a:hover:after, .tabs--bar .tab__title a:focus:after {
          border-color: #0568AE;
          margin: 0; }
          .region--blue .tabs--bar .tab__title a:hover:after,
          .region[class*="blue--"] .tabs--bar .tab__title a:hover:after,
          .region[class*="blue-gradient"] .tabs--bar .tab__title a:hover:after, .region--blue .tabs--bar .tab__title a:focus:after,
          .region[class*="blue--"] .tabs--bar .tab__title a:focus:after,
          .region[class*="blue-gradient"] .tabs--bar .tab__title a:focus:after {
            border-color: #F2F2F2; }
          .region[class*="cobalt"] .tabs--bar .tab__title a:hover:after,
          .region[class*="purple"] .tabs--bar .tab__title a:hover:after,
          .region[class*="black"] .tabs--bar .tab__title a:hover:after,
          .region--dark-blue .tabs--bar .tab__title a:hover:after, .region[class*="cobalt"] .tabs--bar .tab__title a:focus:after,
          .region[class*="purple"] .tabs--bar .tab__title a:focus:after,
          .region[class*="black"] .tabs--bar .tab__title a:focus:after,
          .region--dark-blue .tabs--bar .tab__title a:focus:after {
            border-color: #009FDB; } }
  .tab__title--active a {
    color: inherit; }
    .tabs--box .tab__title--active a {
      background-color: #5A5A5A !important;
      color: #FFF !important; }
      .region[class*="blue"] .tabs--box .tab__title--active a,
      .region[class*="cobalt"] .tabs--box .tab__title--active a,
      .region[class*="purple"] .tabs--box .tab__title--active a {
        background-color: #414141 !important; }
    .tabs--bar .tab__title--active a {
      color: #0568AE; }
      .region[class*="cobalt"] .tabs--bar .tab__title--active a,
      .region[class*="purple"] .tabs--bar .tab__title--active a,
      .region[class*="black"] .tabs--bar .tab__title--active a,
      .region--dark-blue .tabs--bar .tab__title--active a {
        color: #009FDB; }
      .region--blue .tabs--bar .tab__title--active a,
      .region[class*="blue--"] .tabs--bar .tab__title--active a,
      .region[class*="blue-gradient"] .tabs--bar .tab__title--active a {
        color: #F2F2F2; }
    @media (min-width: 768px) {
      .tab__title--active a:after {
        display: inline-block;
        border: 2px solid #191919;
        border-radius: 0;
        margin: 0px -4px 10px 0; }
        .region--dark-blue .tab__title--active a:after {
          border-color: #FFF; }
        .region--near-black .tab__title--active a:after {
          border-color: #FFF; }
        .region--black .tab__title--active a:after {
          border-color: #FFF; }
        .region--blue--wh-text .tab__title--active a:after {
          border-color: #FFF; }
        .region--comms-cobalt .tab__title--active a:after {
          border-color: #FFF; }
        .region--comms-purple .tab__title--active a:after {
          border-color: #FFF; }
        .region--blue-gradient--wh-text .tab__title--active a:after {
          border-color: #FFF; }
        .region--comms-cobalt-purple .tab__title--active a:after {
          border-color: #FFF; }
        .region--white .tab__title--active a:after, .region--gray .tab__title--active a:after {
          border-color: #0568AE; }
        .region[class*="black"] .tab__title--active a:after {
          border-color: #009FDB; }
        .tabs--box .tab__title--active a:after {
          display: none; }
        .tabs--bar .tab__title--active a:after {
          border-color: #0568AE; }
          .region--blue .tabs--bar .tab__title--active a:after,
          .region[class*="blue--"] .tabs--bar .tab__title--active a:after,
          .region[class*="blue-gradient"] .tabs--bar .tab__title--active a:after {
            border-color: #F2F2F2; }
          .region[class*="cobalt"] .tabs--bar .tab__title--active a:after,
          .region[class*="purple"] .tabs--bar .tab__title--active a:after,
          .region[class*="black"] .tabs--bar .tab__title--active a:after,
          .region--dark-blue .tabs--bar .tab__title--active a:after {
            border-color: #009FDB; } }
  .tab__title + .tab__wrap {
    visibility: hidden;
    flex-basis: 100%;
    height: 0;
    opacity: 0;
    transition: opacity 500ms ease; }
    .tab__title + .tab__wrap:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 768px) {
      .tab__title + .tab__wrap {
        order: 99; } }
  .tab__title--active + .tab__wrap {
    visibility: visible;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity 500ms ease; }
    @media (min-width: 768px) {
      .tab__title--active + .tab__wrap {
        order: 98;
        padding-top: 40px; }
        .tabs--box .tab__title--active + .tab__wrap {
          border-top: 1px solid #FFF; }
          .region--white .tabs--box .tab__title--active + .tab__wrap, .region--gray .tabs--box .tab__title--active + .tab__wrap {
            border-color: #C9C9C9; }
          .region--blue--bl-text .tabs--box .tab__title--active + .tab__wrap,
          .region--blue-gradient--bl-text .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-lime .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-mint .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-berry .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-tangerine .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-berry-tangerine .tabs--box .tab__title--active + .tab__wrap,
          .region--comms-lime-mint .tabs--box .tab__title--active + .tab__wrap {
            border-color: #000; } }

.tab__cta {
  margin: 20px 0 40px; }
  .tab__cta .cta--link:after {
    content: 'X';
    font-weight: bold;
    margin-left: 4px; }
  @media (min-width: 768px) {
    .tab__cta {
      display: none; } }

.tabs .row,
.tabs .tabs__heading,
.tabs .component .text,
.tabs .component [class*="__heading"],
.tabs .share,
.tabs [class*="__share"],
.tabs .latest-stories .stories__filter-nav,
.tabs .media-carousel .carousel__wrap {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0; }

/*====
  TEXT
  ====*/
.narrow-text .col-12 .component > .text,
.narrow-text .region__wrap > .text .component > .text {
  max-width: 1024px;
  margin: 0 auto; }

.component > .text {
  font-size: 18px;
  font-size: 1.8rem;
  position: relative;
  max-width: 1220px;
  line-height: 1.6;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  /* Headings */
  /* Images */
  /* Horiztonal Rules */
  /* Tables */
  /* Modifications */ }
  .col .component > .text {
    padding: 0; }
  .component > .text.text-cta--center .text__cta {
    text-align: center; }
  .component > .text.text-cta--right .text__cta {
    text-align: right; }
  @media (min-width: 768px) {
    .component > .text {
      padding: 0 55px; }
      .col-9 .component > .text,
      .col-8 .component > .text,
      .col-6 .component > .text {
        padding-right: 50px; }
      .col-4 .component > .text,
      .col-3 .component > .text,
      .col-2 .component > .text {
        padding-right: 30px; } }
  @media (min-width: 1024px) {
    .col-9 .component > .text,
    .col-8 .component > .text,
    .col-6 .component > .text,
    .col-4 .component > .text {
      padding-right: 60px; }
    .col-3 .component > .text {
      padding-right: 30px; } }
  @media (min-width: 1220px) {
    .component > .text {
      font-size: 21px;
      font-size: 2.1rem;
      line-height: 1.6; }
      .col-3 .component > .text {
        padding-right: 50px; }
      .col-2 .component > .text {
        padding-right: 0; } }
  @media (min-width: 1440px) {
    .component > .text {
      padding: 0; } }
  .component > .text h1,
  .component > .text h2,
  .component > .text h3,
  .component > .text h4,
  .component > .text h5,
  .component > .text h6,
  .component > .text p {
    margin-bottom: 20px; }
  .component:not(.component--no-margin-bottom) .component > .text h1:last-child:not(:only-child), .component:not(.component--no-margin-bottom)
  .component > .text h2:last-child:not(:only-child), .component:not(.component--no-margin-bottom)
  .component > .text h3:last-child:not(:only-child), .component:not(.component--no-margin-bottom)
  .component > .text h4:last-child:not(:only-child), .component:not(.component--no-margin-bottom)
  .component > .text h5:last-child:not(:only-child), .component:not(.component--no-margin-bottom)
  .component > .text h6:last-child:not(:only-child) {
    margin-bottom: 0; }
  .component > .text p + h1,
  .component > .text p + h2,
  .component > .text p + h3,
  .component > .text p + h4,
  .component > .text p + h5,
  .component > .text p + h6,
  .component > .text ul + h1,
  .component > .text ul + h2,
  .component > .text ul + h3,
  .component > .text ul + h4,
  .component > .text ul + h5,
  .component > .text ul + h6 {
    margin-top: 35px; }
    @media (min-width: 1440px) {
      .component > .text p + h1,
      .component > .text p + h2,
      .component > .text p + h3,
      .component > .text p + h4,
      .component > .text p + h5,
      .component > .text p + h6,
      .component > .text ul + h1,
      .component > .text ul + h2,
      .component > .text ul + h3,
      .component > .text ul + h4,
      .component > .text ul + h5,
      .component > .text ul + h6 {
        margin-top: 55px; } }
  .region[class*="-black"] .component > .text h1 {
    color: #009FDB; }
  .component > .text h2 {
    line-height: 1.35; }
  .component > .text img {
    max-width: 100%; }
  .component > .text hr {
    border: 0;
    border-top: 4px;
    border-color: #009FDB;
    border-style: solid;
    border-radius: 30px;
    width: 60%;
    max-width: 300px;
    margin: 50px auto; }
    .region--blue .component > .text hr {
      border-color: #191919; }
    .region--dark-blue .component > .text hr {
      border-color: #FFF; }
    .region--near-black .component > .text hr {
      border-color: #FFF; }
    .region--white .component > .text hr {
      border-color: #191919; }
    .region--black .component > .text hr {
      border-color: #FFF; }
    .region--gray .component > .text hr {
      border-color: #191919; }
    .region--blue--wh-text .component > .text hr {
      border-color: #FFF; }
    .region--blue--bl-text .component > .text hr {
      border-color: #191919; }
    .region--comms-cobalt .component > .text hr {
      border-color: #FFF; }
    .region--comms-purple .component > .text hr {
      border-color: #FFF; }
    .region--comms-berry .component > .text hr {
      border-color: #191919; }
    .region--comms-tangerine .component > .text hr {
      border-color: #191919; }
    .region--comms-lime .component > .text hr {
      border-color: #191919; }
    .region--comms-mint .component > .text hr {
      border-color: #191919; }
    .region--blue-gradient--wh-text .component > .text hr {
      border-color: #FFF; }
    .region--blue-gradient--bl-text .component > .text hr {
      border-color: #191919; }
    .region--comms-cobalt-purple .component > .text hr {
      border-color: #FFF; }
    .region--comms-berry-tangerine .component > .text hr {
      border-color: #191919; }
    .region--comms-lime-mint .component > .text hr {
      border-color: #191919; }
    .region--white .component > .text hr, .region--gray .component > .text hr, .region--black .component > .text hr, .region--near-black .component > .text hr {
      border-color: #009FDB; }
    @media (min-width: 1440px) {
      .component > .text hr {
        margin: 80px auto; } }
  .component > .text table {
    margin: 45px 0; }
    .component > .text table th,
    .component > .text table td {
      padding: 10px; }
      @media (min-width: 1220px) {
        .component > .text table th,
        .component > .text table td {
          padding: 10px 20px; } }
    .component > .text table th {
      font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
      text-align: left; }
    .component > .text table td {
      border: 1px solid #C9C9C9;
      border-left-width: 0;
      border-right-width: 0; }
      .region--blue .component > .text table td {
        border-color: #191919; }
      .region--dark-blue .component > .text table td {
        border-color: #FFF; }
      .region--near-black .component > .text table td {
        border-color: #FFF; }
      .region--white .component > .text table td {
        border-color: #191919; }
      .region--black .component > .text table td {
        border-color: #FFF; }
      .region--gray .component > .text table td {
        border-color: #191919; }
      .region--blue--wh-text .component > .text table td {
        border-color: #FFF; }
      .region--blue--bl-text .component > .text table td {
        border-color: #191919; }
      .region--comms-cobalt .component > .text table td {
        border-color: #FFF; }
      .region--comms-purple .component > .text table td {
        border-color: #FFF; }
      .region--comms-berry .component > .text table td {
        border-color: #191919; }
      .region--comms-tangerine .component > .text table td {
        border-color: #191919; }
      .region--comms-lime .component > .text table td {
        border-color: #191919; }
      .region--comms-mint .component > .text table td {
        border-color: #191919; }
      .region--blue-gradient--wh-text .component > .text table td {
        border-color: #FFF; }
      .region--blue-gradient--bl-text .component > .text table td {
        border-color: #191919; }
      .region--comms-cobalt-purple .component > .text table td {
        border-color: #FFF; }
      .region--comms-berry-tangerine .component > .text table td {
        border-color: #191919; }
      .region--comms-lime-mint .component > .text table td {
        border-color: #191919; }
      .region--white .component > .text table td, .region--gray .component > .text table td, .region--black .component > .text table td, .region--near-black .component > .text table td {
        border-color: #C9C9C9; }
      .component > .text table td + td {
        border-left-width: 1px; }
  .component > .text.text--headline-blue h1,
  .component > .text.text--headline-blue h2,
  .component > .text.text--headline-blue h3,
  .component > .text.text--headline-blue h4,
  .component > .text.text--headline-blue h5,
  .component > .text.text--headline-blue h6 {
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
    border-bottom: 1px solid #5A5A5A;
    padding-bottom: 5px; }
    .region--blue .component > .text.text--headline-blue h1, .region--blue
    .component > .text.text--headline-blue h2, .region--blue
    .component > .text.text--headline-blue h3, .region--blue
    .component > .text.text--headline-blue h4, .region--blue
    .component > .text.text--headline-blue h5, .region--blue
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--dark-blue .component > .text.text--headline-blue h1, .region--dark-blue
    .component > .text.text--headline-blue h2, .region--dark-blue
    .component > .text.text--headline-blue h3, .region--dark-blue
    .component > .text.text--headline-blue h4, .region--dark-blue
    .component > .text.text--headline-blue h5, .region--dark-blue
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--near-black .component > .text.text--headline-blue h1, .region--near-black
    .component > .text.text--headline-blue h2, .region--near-black
    .component > .text.text--headline-blue h3, .region--near-black
    .component > .text.text--headline-blue h4, .region--near-black
    .component > .text.text--headline-blue h5, .region--near-black
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--white .component > .text.text--headline-blue h1, .region--white
    .component > .text.text--headline-blue h2, .region--white
    .component > .text.text--headline-blue h3, .region--white
    .component > .text.text--headline-blue h4, .region--white
    .component > .text.text--headline-blue h5, .region--white
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--black .component > .text.text--headline-blue h1, .region--black
    .component > .text.text--headline-blue h2, .region--black
    .component > .text.text--headline-blue h3, .region--black
    .component > .text.text--headline-blue h4, .region--black
    .component > .text.text--headline-blue h5, .region--black
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--gray .component > .text.text--headline-blue h1, .region--gray
    .component > .text.text--headline-blue h2, .region--gray
    .component > .text.text--headline-blue h3, .region--gray
    .component > .text.text--headline-blue h4, .region--gray
    .component > .text.text--headline-blue h5, .region--gray
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--blue--wh-text .component > .text.text--headline-blue h1, .region--blue--wh-text
    .component > .text.text--headline-blue h2, .region--blue--wh-text
    .component > .text.text--headline-blue h3, .region--blue--wh-text
    .component > .text.text--headline-blue h4, .region--blue--wh-text
    .component > .text.text--headline-blue h5, .region--blue--wh-text
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--blue--bl-text .component > .text.text--headline-blue h1, .region--blue--bl-text
    .component > .text.text--headline-blue h2, .region--blue--bl-text
    .component > .text.text--headline-blue h3, .region--blue--bl-text
    .component > .text.text--headline-blue h4, .region--blue--bl-text
    .component > .text.text--headline-blue h5, .region--blue--bl-text
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-cobalt .component > .text.text--headline-blue h1, .region--comms-cobalt
    .component > .text.text--headline-blue h2, .region--comms-cobalt
    .component > .text.text--headline-blue h3, .region--comms-cobalt
    .component > .text.text--headline-blue h4, .region--comms-cobalt
    .component > .text.text--headline-blue h5, .region--comms-cobalt
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--comms-purple .component > .text.text--headline-blue h1, .region--comms-purple
    .component > .text.text--headline-blue h2, .region--comms-purple
    .component > .text.text--headline-blue h3, .region--comms-purple
    .component > .text.text--headline-blue h4, .region--comms-purple
    .component > .text.text--headline-blue h5, .region--comms-purple
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--comms-berry .component > .text.text--headline-blue h1, .region--comms-berry
    .component > .text.text--headline-blue h2, .region--comms-berry
    .component > .text.text--headline-blue h3, .region--comms-berry
    .component > .text.text--headline-blue h4, .region--comms-berry
    .component > .text.text--headline-blue h5, .region--comms-berry
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-tangerine .component > .text.text--headline-blue h1, .region--comms-tangerine
    .component > .text.text--headline-blue h2, .region--comms-tangerine
    .component > .text.text--headline-blue h3, .region--comms-tangerine
    .component > .text.text--headline-blue h4, .region--comms-tangerine
    .component > .text.text--headline-blue h5, .region--comms-tangerine
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-lime .component > .text.text--headline-blue h1, .region--comms-lime
    .component > .text.text--headline-blue h2, .region--comms-lime
    .component > .text.text--headline-blue h3, .region--comms-lime
    .component > .text.text--headline-blue h4, .region--comms-lime
    .component > .text.text--headline-blue h5, .region--comms-lime
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-mint .component > .text.text--headline-blue h1, .region--comms-mint
    .component > .text.text--headline-blue h2, .region--comms-mint
    .component > .text.text--headline-blue h3, .region--comms-mint
    .component > .text.text--headline-blue h4, .region--comms-mint
    .component > .text.text--headline-blue h5, .region--comms-mint
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--blue-gradient--wh-text .component > .text.text--headline-blue h1, .region--blue-gradient--wh-text
    .component > .text.text--headline-blue h2, .region--blue-gradient--wh-text
    .component > .text.text--headline-blue h3, .region--blue-gradient--wh-text
    .component > .text.text--headline-blue h4, .region--blue-gradient--wh-text
    .component > .text.text--headline-blue h5, .region--blue-gradient--wh-text
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--blue-gradient--bl-text .component > .text.text--headline-blue h1, .region--blue-gradient--bl-text
    .component > .text.text--headline-blue h2, .region--blue-gradient--bl-text
    .component > .text.text--headline-blue h3, .region--blue-gradient--bl-text
    .component > .text.text--headline-blue h4, .region--blue-gradient--bl-text
    .component > .text.text--headline-blue h5, .region--blue-gradient--bl-text
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-cobalt-purple .component > .text.text--headline-blue h1, .region--comms-cobalt-purple
    .component > .text.text--headline-blue h2, .region--comms-cobalt-purple
    .component > .text.text--headline-blue h3, .region--comms-cobalt-purple
    .component > .text.text--headline-blue h4, .region--comms-cobalt-purple
    .component > .text.text--headline-blue h5, .region--comms-cobalt-purple
    .component > .text.text--headline-blue h6 {
      border-color: #FFF; }
    .region--comms-berry-tangerine .component > .text.text--headline-blue h1, .region--comms-berry-tangerine
    .component > .text.text--headline-blue h2, .region--comms-berry-tangerine
    .component > .text.text--headline-blue h3, .region--comms-berry-tangerine
    .component > .text.text--headline-blue h4, .region--comms-berry-tangerine
    .component > .text.text--headline-blue h5, .region--comms-berry-tangerine
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--comms-lime-mint .component > .text.text--headline-blue h1, .region--comms-lime-mint
    .component > .text.text--headline-blue h2, .region--comms-lime-mint
    .component > .text.text--headline-blue h3, .region--comms-lime-mint
    .component > .text.text--headline-blue h4, .region--comms-lime-mint
    .component > .text.text--headline-blue h5, .region--comms-lime-mint
    .component > .text.text--headline-blue h6 {
      border-color: #191919; }
    .region--white .component > .text.text--headline-blue h1, .region--gray .component > .text.text--headline-blue h1, .region--black .component > .text.text--headline-blue h1, .region--near-black .component > .text.text--headline-blue h1, .region--white
    .component > .text.text--headline-blue h2, .region--gray
    .component > .text.text--headline-blue h2, .region--black
    .component > .text.text--headline-blue h2, .region--near-black
    .component > .text.text--headline-blue h2, .region--white
    .component > .text.text--headline-blue h3, .region--gray
    .component > .text.text--headline-blue h3, .region--black
    .component > .text.text--headline-blue h3, .region--near-black
    .component > .text.text--headline-blue h3, .region--white
    .component > .text.text--headline-blue h4, .region--gray
    .component > .text.text--headline-blue h4, .region--black
    .component > .text.text--headline-blue h4, .region--near-black
    .component > .text.text--headline-blue h4, .region--white
    .component > .text.text--headline-blue h5, .region--gray
    .component > .text.text--headline-blue h5, .region--black
    .component > .text.text--headline-blue h5, .region--near-black
    .component > .text.text--headline-blue h5, .region--white
    .component > .text.text--headline-blue h6, .region--gray
    .component > .text.text--headline-blue h6, .region--black
    .component > .text.text--headline-blue h6, .region--near-black
    .component > .text.text--headline-blue h6 {
      color: #009FDB;
      border-color: #5A5A5A; }
  .component > .text.text--headline-blue h2 {
    font-size: 38px;
    font-size: 3.8rem; }
  .component > .text.text--headline-blue h3 {
    font-size: 24px;
    font-size: 2.4rem; }
  .component > .text.blog-heading {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300; }
    .component > .text.blog-heading b,
    .component > .text.blog-heading strong {
      font-weight: 700;
      color: #000; }
      .region--blue .component > .text.blog-heading b, .region--blue
      .component > .text.blog-heading strong {
        color: #000; }
      .region--dark-blue .component > .text.blog-heading b,
      .region[class*="-black"] .component > .text.blog-heading b, .region--dark-blue
      .component > .text.blog-heading strong,
      .region[class*="-black"]
      .component > .text.blog-heading strong {
        color: #FFF; }
    .component > .text.blog-heading i {
      color: #009FDB; }
    .region--blue .component > .text.blog-heading, .region--blue--bl-text .component > .text.blog-heading, .region--blue--wh-text .component > .text.blog-heading, .region--comms-cobalt .component > .text.blog-heading, .region--blue-gradient--wh-text .component > .text.blog-heading, .region--blue-gradient--bl-text .component > .text.blog-heading {
      font-size: 24px;
      font-size: 2.4rem;
      font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
      font-weight: 300; }
      .region--blue .component > .text.blog-heading b,
      .region--blue .component > .text.blog-heading strong, .region--blue--bl-text .component > .text.blog-heading b,
      .region--blue--bl-text .component > .text.blog-heading strong, .region--blue--wh-text .component > .text.blog-heading b,
      .region--blue--wh-text .component > .text.blog-heading strong, .region--comms-cobalt .component > .text.blog-heading b,
      .region--comms-cobalt .component > .text.blog-heading strong, .region--blue-gradient--wh-text .component > .text.blog-heading b,
      .region--blue-gradient--wh-text .component > .text.blog-heading strong, .region--blue-gradient--bl-text .component > .text.blog-heading b,
      .region--blue-gradient--bl-text .component > .text.blog-heading strong {
        font-weight: 700;
        color: #000; }
        .region--blue .region--blue .component > .text.blog-heading b, .region--blue
        .region--blue .component > .text.blog-heading strong, .region--blue .region--blue--bl-text .component > .text.blog-heading b, .region--blue
        .region--blue--bl-text .component > .text.blog-heading strong, .region--blue .region--blue--wh-text .component > .text.blog-heading b, .region--blue
        .region--blue--wh-text .component > .text.blog-heading strong, .region--blue .region--comms-cobalt .component > .text.blog-heading b, .region--blue
        .region--comms-cobalt .component > .text.blog-heading strong, .region--blue .region--blue-gradient--wh-text .component > .text.blog-heading b, .region--blue
        .region--blue-gradient--wh-text .component > .text.blog-heading strong, .region--blue .region--blue-gradient--bl-text .component > .text.blog-heading b, .region--blue
        .region--blue-gradient--bl-text .component > .text.blog-heading strong {
          color: #000; }
        .region--dark-blue .region--blue .component > .text.blog-heading b,
        .region[class*="-black"] .region--blue .component > .text.blog-heading b, .region--dark-blue
        .region--blue .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--blue .component > .text.blog-heading strong, .region--dark-blue .region--blue--bl-text .component > .text.blog-heading b,
        .region[class*="-black"] .region--blue--bl-text .component > .text.blog-heading b, .region--dark-blue
        .region--blue--bl-text .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--blue--bl-text .component > .text.blog-heading strong, .region--dark-blue .region--blue--wh-text .component > .text.blog-heading b,
        .region[class*="-black"] .region--blue--wh-text .component > .text.blog-heading b, .region--dark-blue
        .region--blue--wh-text .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--blue--wh-text .component > .text.blog-heading strong, .region--dark-blue .region--comms-cobalt .component > .text.blog-heading b,
        .region[class*="-black"] .region--comms-cobalt .component > .text.blog-heading b, .region--dark-blue
        .region--comms-cobalt .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--comms-cobalt .component > .text.blog-heading strong, .region--dark-blue .region--blue-gradient--wh-text .component > .text.blog-heading b,
        .region[class*="-black"] .region--blue-gradient--wh-text .component > .text.blog-heading b, .region--dark-blue
        .region--blue-gradient--wh-text .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--blue-gradient--wh-text .component > .text.blog-heading strong, .region--dark-blue .region--blue-gradient--bl-text .component > .text.blog-heading b,
        .region[class*="-black"] .region--blue-gradient--bl-text .component > .text.blog-heading b, .region--dark-blue
        .region--blue-gradient--bl-text .component > .text.blog-heading strong,
        .region[class*="-black"]
        .region--blue-gradient--bl-text .component > .text.blog-heading strong {
          color: #FFF; }
      .region--blue .component > .text.blog-heading i, .region--blue--bl-text .component > .text.blog-heading i, .region--blue--wh-text .component > .text.blog-heading i, .region--comms-cobalt .component > .text.blog-heading i, .region--blue-gradient--wh-text .component > .text.blog-heading i, .region--blue-gradient--bl-text .component > .text.blog-heading i {
        color: #FFF; }
  .component > .text.text--featured h1,
  .component > .text.text--featured h2,
  .component > .text.text--featured h3 {
    font-weight: 700; }
  .component > .text.text--featured strong,
  .component > .text.text--featured b {
    font-weight: 700; }
  .component > .text.text--special-bold h1,
  .component > .text.text--special-bold h2 {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300; }
    .component > .text.text--special-bold h1 b,
    .component > .text.text--special-bold h1 strong,
    .component > .text.text--special-bold h2 b,
    .component > .text.text--special-bold h2 strong {
      font-weight: 700;
      color: #009FDB; }
      .region--blue .component > .text.text--special-bold h1 b, .region--blue
      .component > .text.text--special-bold h1 strong, .region--blue
      .component > .text.text--special-bold h2 b, .region--blue
      .component > .text.text--special-bold h2 strong {
        color: #000; }
      .region--dark-blue .component > .text.text--special-bold h1 b,
      .region[class*="-black"] .component > .text.text--special-bold h1 b, .region--dark-blue
      .component > .text.text--special-bold h1 strong,
      .region[class*="-black"]
      .component > .text.text--special-bold h1 strong, .region--dark-blue
      .component > .text.text--special-bold h2 b,
      .region[class*="-black"]
      .component > .text.text--special-bold h2 b, .region--dark-blue
      .component > .text.text--special-bold h2 strong,
      .region[class*="-black"]
      .component > .text.text--special-bold h2 strong {
        color: #FFF; }
  .component > .text.text--footer {
    font-size: 13px;
    font-size: 1.3rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    line-height: 1.8;
    color: inherit; }
    @media (min-width: 768px) {
      .component > .text.text--footer {
        padding-top: 20px;
        padding-bottom: 10px; } }
    @media (min-width: 1024px) {
      .component > .text.text--footer {
        padding-top: 0;
        padding-bottom: 0; } }
    @media (min-width: 1220px) {
      .component > .text.text--footer {
        font-size: 15px;
        font-size: 1.5rem; } }
    .component > .text.text--footer h1,
    .component > .text.text--footer h2 {
      font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
      line-height: 1.25; }
    .component > .text.text--footer h1 {
      font-size: 26px;
      font-size: 2.6rem; }
    .component > .text.text--footer h2 {
      font-size: 20px;
      font-size: 2rem; }
    .component > .text.text--footer h3 {
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 10px; }
    .component > .text.text--footer ul,
    .component > .text.text--footer ol {
      margin: 0 0 20px; }
      .component > .text.text--footer ul > li,
      .component > .text.text--footer ol > li {
        margin-bottom: 10px; }
        .component > .text.text--footer ul > li:before,
        .component > .text.text--footer ol > li:before {
          content: none; }
        .region[class*="-blue"] .component > .text.text--footer ul > li a:not(:hover), .region[class*="-blue"]
        .component > .text.text--footer ol > li a:not(:hover) {
          text-decoration: none; }
    .component > .text.text--footer hr {
      margin: 40px 0;
      border-top-width: 2px; }

/*Editable Template Publish Date */
.component > .story--pub-date p {
  font-size: 16px;
  font-size: 1.6rem; }

/*Editable Template Story */
.component > .story--title h1 {
  font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }

@media (min-width: 1220px) {
  .component > .story--title h1 {
    font-size: 42px;
    font-size: 4.2rem; } }

/*Editable Template Story Description */
.component > .story--description p {
  font-weight: 900; }

@media (min-width: 1220px) {
  .component > .story--description p {
    font-size: 18px;
    font-size: 1.8rem; } }

/* Blockqoutes */
.text--blockquote .text__wrap {
  position: relative;
  margin: 30px 0 20px;
  font-family: "Aleck Slab", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
  .text--blockquote .text__wrap h1 {
    color: inherit; }
  .text--blockquote .text__wrap p:last-child {
    margin-bottom: 0; }
  .text--blockquote .text__wrap a {
    text-decoration: underline; }
  .region--white .text--blockquote .text__wrap, .region--gray .text--blockquote .text__wrap, .region--black .text--blockquote .text__wrap, .region--near-black .text--blockquote .text__wrap {
    color: #009FDB; }
  @media (min-width: 1024px) {
    .text--blockquote .text__wrap {
      margin: 25px 0;
      max-width: 90%; } }
  @media (min-width: 768px) {
    .text--blockquote .text__wrap:before {
      position: relative;
      transform: translateY(-50%);
      top: 50%;
      font-size: 62px;
      font-size: 6.2rem;
      content: "";
      display: block;
      position: absolute;
      height: calc(100% - 15px);
      width: 65px;
      border-right: 1px solid #C9C9C9;
      margin-right: 15px;
      right: 100%;
      font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif;
      text-align: center;
      line-height: 1;
      color: #C9C9C9;
      /* To accomodate blue backgrounds */
      /* To center the icon */
      display: flex;
      align-items: center; } }
  @media (min-width: 768px) and (min-width: 1220px) {
    .text--blockquote .text__wrap:before {
      content: ""; } }
  @media (min-width: 768px) {
      .region--blue .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--dark-blue .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--near-black .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--white .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--black .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--gray .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--blue--wh-text .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--blue--bl-text .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-cobalt .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--comms-purple .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--comms-berry .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-tangerine .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-lime .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-mint .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--blue-gradient--wh-text .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--blue-gradient--bl-text .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-cobalt-purple .text--blockquote .text__wrap:before {
        border-color: #FFF;
        color: #FFF; }
      .region--comms-berry-tangerine .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--comms-lime-mint .text--blockquote .text__wrap:before {
        border-color: #191919;
        color: #191919; }
      .region--white .text--blockquote .text__wrap:before,
      .region--gray .text--blockquote .text__wrap:before {
        border-color: #C9C9C9;
        color: #C9C9C9; }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .text--blockquote .text__wrap:before {
        display: block;
        line-height: .65; } }

/* Text with Icons */
[class*="text--icon-"] .text__wrap {
  font-size: 15px;
  font-size: 1.5rem;
  position: relative;
  padding-left: 75px;
  line-height: 1.75; }
  [class*="text--icon-"] .text__wrap:before {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    font-size: 62px;
    font-size: 6.2rem;
    display: block;
    position: absolute;
    height: calc(100% - 15px);
    width: 70px;
    border-right: 1px solid #C9C9C9;
    margin-right: 20px;
    left: -15px;
    font-family: "ATT_Icons", "Arial", "Helvetica", sans-serif;
    line-height: 1;
    color: #C9C9C9;
    /* To accomodate blue/dark backgrounds */
    /* To center the icon */
    display: flex;
    align-items: center; }
    .region[class*="-blue"] [class*="text--icon-"] .text__wrap:before,
    .region[class*="-black"] [class*="text--icon-"] .text__wrap:before {
      border-color: #FFF;
      color: #FFF; }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy [class*="text--icon-"] .text__wrap:before {
      display: block;
      line-height: .65; }

[class*="text--icon-"].text--blue-icons .text__wrap:before {
  color: #0057B8; }

.text--icon-alert .text__wrap:before {
  content: ""; }

.text--icon-att-logo .text__wrap:before {
  content: ""; }

.text--icon-att-logo-text .text__wrap:before {
  content: ""; }

.text--icon-comment .text__wrap:before {
  content: ""; }

.text--icon-download .text__wrap:before {
  content: ""; }

.text--icon-email .text__wrap:before {
  content: ""; }

.text--icon-facebook .text__wrap:before {
  content: ""; }

.text--icon-faq .text__wrap:before {
  content: ""; }

.text--icon-file .text__wrap:before {
  content: ""; }

.text--icon-info .text__wrap:before {
  content: ""; }

.text--icon-info2 .text__wrap:before {
  content: ""; }

.text--icon-instagram .text__wrap:before {
  content: ""; }

.text--icon-link .text__wrap:before {
  content: ""; }

.text--icon-linkedin .text__wrap:before {
  content: ""; }

.text--icon-notification .text__wrap:before {
  content: ""; }

.text--icon-minus .text__wrap:before {
  content: ""; }

.text--icon-play .text__wrap:before {
  content: ""; }

.text--icon-plus .text__wrap:before {
  content: ""; }

.text--icon-quote .text__wrap:before {
  content: ""; }

.text--icon-search .text__wrap:before {
  content: ""; }

.text--icon-twitter .text__wrap:before {
  content: ""; }

.text--icon-youtube .text__wrap:before {
  content: ""; }

.text--icon-twitter-x .text__wrap:before {
  content: ""; }

.text--icon-arrow-down .text__wrap:before {
  content: ""; }

.text--icon-arrow-left .text__wrap:before {
  content: ""; }

.text--icon-arrow-right .text__wrap:before {
  content: ""; }

.text--icon-pause .text__wrap:before {
  content: ""; }

.text--icon-new-play .text__wrap:before {
  content: ""; }

.text--blockquote .text__wrap,
[class*="text--icon-"] .text__wrap {
  /* To reposition for narrow columns */ }
  .col-2 .text--blockquote .text__wrap,
  .col-3 .text--blockquote .text__wrap,
  .col-4 .text--blockquote .text__wrap, .col-2
  [class*="text--icon-"] .text__wrap,
  .col-3
  [class*="text--icon-"] .text__wrap,
  .col-4
  [class*="text--icon-"] .text__wrap {
    padding-left: 0; }
    .col-2 .text--blockquote .text__wrap:before,
    .col-3 .text--blockquote .text__wrap:before,
    .col-4 .text--blockquote .text__wrap:before, .col-2
    [class*="text--icon-"] .text__wrap:before,
    .col-3
    [class*="text--icon-"] .text__wrap:before,
    .col-4
    [class*="text--icon-"] .text__wrap:before {
      position: relative;
      transform: translateX(-50%);
      left: 50%;
      display: block;
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      top: 0;
      text-align: center;
      border-right: 0;
      border-bottom: 1px solid #C9C9C9; }

/* CTAs */
@media (max-width: 1023px) {
  .text__cta {
    margin-top: 40px; } }

/*====
  TILE
  ====*/
@media (min-width: 768px) {
  .row--equal-heights .tile {
    height: 100%; } }

.tile__wrap {
  display: block;
  position: relative;
  overflow: hidden; }
  @media (min-width: 768px) {
    .row--equal-heights .tile__wrap {
      height: 100%; } }

.tile__img {
  overflow: hidden; }
  @media (min-width: 768px) {
    .row--equal-heights .tile__img {
      height: 100%; } }
  .row--equal-heights .tile__img.compat-object-fit {
    transition: all 1s ease; }
    .row--equal-heights .tile__img.compat-object-fit img {
      opacity: 0; }
    @media (min-width: 768px) {
      .row--equal-heights .tile__img.compat-object-fit {
        background-size: cover;
        background-position: left; } }
  .row--equal-heights .tile:hover .tile__img.compat-object-fit {
    transform: scale3d(1.1, 1.1, 1) rotate(0.1deg);
    max-width: none; }
  .tile__img > div {
    height: 100%; }
  .tile__img img {
    transition: all 1s ease;
    display: block;
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 768px) {
      .row--equal-heights .tile__img img {
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        object-fit: cover;
        object-position: left; } }
    .tile:hover .tile__img img {
      transform: scale3d(1.1, 1.1, 1) rotate(0.1deg);
      max-width: none; }

.tile__content {
  position: absolute;
  padding: 0 25px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: #FFF;
  /* Bottom color bar */
  /**/ }
  .tile__content:after {
    content: "";
    position: absolute;
    display: block;
    height: 8px;
    width: 100.5%;
    bottom: 0;
    left: 0;
    /* NOTE: Bottom color bar can vary based on the page category (theme). Default treatment is silver. */
    background: #EDECEC;
    background: -moz-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: -webkit-linear-gradient(left, #edecec 0%, #f9f9f9 100%);
    background: linear-gradient(to right, #edecec 0%, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#EDECEC', endColorstr='#F9F9F9', GradientType=1); }
    .no-cssgradients .tile__content:after {
      filter: none; }
    .tile__content:after .cta--link {
      color: #0568AE; }
  .tile__content .tile__headline {
    font-size: 25px;
    font-size: 2.5rem;
    max-width: 80%;
    font-weight: 300;
    font-style: italic;
    position: absolute;
    bottom: 60px;
    line-height: 1.15; }
    @media (min-width: 640px) {
      .tile__content .tile__headline {
        font-size: 30px;
        font-size: 3rem; } }
    @media (min-width: 1024px) {
      .tile__content .tile__headline {
        font-size: 38px;
        font-size: 3.8rem;
        max-width: 70%;
        bottom: 85px; } }
    @media (min-width: 1024px) {
      .col-2 .tile__content .tile__headline,
      .col-3 .tile__content .tile__headline,
      .col-4 .tile__content .tile__headline {
        font-size: 30px;
        font-size: 3rem;
        max-width: 80%;
        bottom: 65px; } }
    @media (min-width: 1220px) {
      .col-4 .tile__content .tile__headline {
        font-size: 38px;
        font-size: 3.8rem; } }
    @media (min-width: 1440px) {
      .col-4 .tile__content .tile__headline {
        max-width: 70%; } }
    @media (min-width: 1024px) {
      .col-9 + .col-3 .tile__content .tile__headline,
      .col-8 + .col-4 .tile__content .tile__headline {
        bottom: 85px; } }
  .tile__content .tile__subheadline {
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 700;
    position: absolute;
    bottom: 20px; }
    @media (min-width: 768px) {
      .tile__content .tile__subheadline {
        font-size: 14px;
        font-size: 1.4rem; } }
    @media (min-width: 1024px) {
      .tile__content .tile__subheadline {
        font-size: 19px;
        font-size: 1.9rem; } }
    @media (min-width: 1024px) {
      .col-2 .tile__content .tile__subheadline,
      .col-3 .tile__content .tile__subheadline {
        font-size: 14px;
        font-size: 1.4rem; } }
    @media (min-width: 1440px) {
      .col-2 .tile__content .tile__subheadline,
      .col-3 .tile__content .tile__subheadline {
        font-size: 16px;
        font-size: 1.6rem; } }
    @media (min-width: 1024px) {
      .col-4 .tile__content .tile__subheadline {
        font-size: 16px;
        font-size: 1.6rem; } }
  @media (min-width: 1024px) and (min-width: 1220px) {
    .col-4 .tile__content .tile__subheadline {
      font-size: 19px;
      font-size: 1.9rem; } }

/*===========
  USER SURVEY
  ===========*/
.user-survey {
  position: relative; }
  .user-survey[data-response-type="rating-scale"] .survey__choices,
  .user-survey[data-response-type="multi-choice"] .survey__rating {
    display: none; }
  .user-survey[data-mode="popup"], .user-survey[data-mode="videos"] {
    display: none;
    background-color: #FFF;
    max-width: 946px !important;
    margin-bottom: 0; }
  .user-survey .survey__instructions {
    width: 50%; }
    @media (max-width: 767px) {
      .user-survey .survey__instructions {
        width: 90% !important;
        margin-top: 25px; } }
  .user-survey .user-survey__wrap {
    min-height: 450px; }
  .user-survey[data-mode="videos"] .user-survey__wrap {
    min-height: auto; }
    @media (min-width: 768px) {
      .user-survey[data-mode="videos"] .user-survey__wrap {
        min-height: 532px; } }
  .user-survey.user-survey--silver .user-survey__wrap {
    background-color: #F2F2F2; }
  .user-survey.user-survey--blue .user-survey__wrap {
    background-color: #009FDB; }
  .user-survey.user-survey--gradient .user-survey__wrap {
    background: #009FDB;
    background: linear-gradient(to right, #0079b1 0%, #00C9FF 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0079b1', endColorstr='#00C9FF', GradientType=1); }
    .cssgradients .user-survey.user-survey--gradient .user-survey__wrap {
      filter: none; }
  .user-survey.user-survey--cobalt .user-survey__wrap {
    background: #0057B8;
    color: #FFF; }
  .user-survey.user-survey--black .user-survey__wrap {
    background: #000;
    color: #FFF; }

.survey__prompt,
.survey__thanks {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  display: none;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
  color: #000; }
  .user-survey--cobalt .survey__prompt,
  .user-survey--black .survey__prompt, .user-survey--cobalt
  .survey__thanks,
  .user-survey--black
  .survey__thanks {
    color: #FFF; }

.user-survey--submitted .survey__thanks,
.user-survey--not-submitted .survey__prompt {
  display: block; }

.user-survey--submitted .survey__prompt,
.user-survey--not-submitted .survey__thanks {
  display: none; }

.survey__question,
.survey__thanks h1,
.survey__thanks h2 {
  font-size: 26px;
  font-size: 2.6rem;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.25; }
  .user-survey[data-mode="popup"] .survey__question,
  .user-survey[data-mode="videos"] .survey__question, .user-survey[data-mode="popup"]
  .survey__thanks h1,
  .user-survey[data-mode="videos"]
  .survey__thanks h1, .user-survey[data-mode="popup"]
  .survey__thanks h2,
  .user-survey[data-mode="videos"]
  .survey__thanks h2 {
    font-weight: 300; }
  @media (min-width: 768px) {
    .survey__question,
    .survey__thanks h1,
    .survey__thanks h2 {
      font-size: 33px;
      font-size: 3.3rem; } }

.survey__question:focus,
.survey__thanks:focus {
  outline: 0; }

.survey__answer {
  margin-bottom: 10px; }
  .survey__answer label {
    font-size: 18px;
    font-size: 1.8rem;
    vertical-align: top; }

.survey__choices {
  display: flex;
  display: -webkit-inline-box;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  -ms-flex-align: center;
  -ms-flex-positive: 1; }
  @media (min-width: 767px) {
    .survey__choices {
      padding-bottom: 30px; } }
  .survey__choices .option {
    margin-bottom: 20px;
    text-align: left;
    -ms-flex-align: center;
    min-width: 250px;
    width: 100%;
    display: inline-block; }
    .survey__choices .option:last-of-type {
      margin-bottom: 0; }
    .survey__choices .option input {
      border-color: #d2d2d2;
      margin-left: 40%; }
      .survey__choices .option input:focus {
        outline: 2px dotted #009fdb;
        outline-offset: 0; }
        .user-survey--blue .survey__choices .option input:focus,
        .user-survey--gradient .survey__choices .option input:focus,
        .user-survey--cobalt .survey__choices .option input:focus,
        .user-survey--black .survey__choices .option input:focus {
          outline: 2px dotted #FFF; }
    @media (max-width: 767px) {
      .survey__choices .option input {
        margin-left: 35%; } }
  .survey__choices .user_survey_input {
    border-radius: 5px;
    display: block;
    margin-top: 10px;
    width: 250px;
    height: 30px;
    color: #000 !important; }
    .survey__choices .user_survey_input:focus {
      outline: 2px dotted #009fdb;
      outline-offset: 0; }
      .user-survey--blue .survey__choices .user_survey_input:focus,
      .user-survey--gradient .survey__choices .user_survey_input:focus,
      .user-survey--cobalt .survey__choices .user_survey_input:focus,
      .user-survey--black .survey__choices .user_survey_input:focus {
        outline: 2px dotted #FFF; }
    .survey__choices .user_survey_input.hidden {
      display: none !important; }
  .survey__choices input {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0; }

.survey__rating {
  position: relative;
  max-width: 850px;
  margin: 50px auto 0; }
  .survey__rating .rating-numbers {
    position: relative;
    display: flex;
    height: 30px;
    width: 60%;
    margin: 0 auto; }
    @media (min-width: 640px) {
      .survey__rating .rating-numbers {
        height: 50px;
        width: 75%; } }
    @media (min-width: 1220px) {
      .survey__rating .rating-numbers {
        width: 82%; } }
    .survey__rating .rating-numbers li span {
      font-size: 20px;
      font-size: 2rem;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      transition: all 300ms ease;
      font-weight: 300;
      color: #5A5A5A; }
      @media (min-width: 1220px) {
        .survey__rating .rating-numbers li span {
          font-size: 30px;
          font-size: 3rem; } }
      .user-survey--blue .survey__rating .rating-numbers li span,
      .user-survey--gradient .survey__rating .rating-numbers li span {
        color: #000; }
      .user-survey--cobalt .survey__rating .rating-numbers li span,
      .user-survey--black .survey__rating .rating-numbers li span {
        color: #FFF; }
    .survey__rating .rating-numbers li {
      position: relative;
      width: 100%; }
      .survey__rating .rating-numbers li.active span {
        font-size: 35px;
        font-size: 3.5rem;
        font-weight: 700;
        color: #009FDB; }
        @media (min-width: 640px) {
          .survey__rating .rating-numbers li.active span {
            font-size: 45px;
            font-size: 4.5rem; } }
        @media (min-width: 1220px) {
          .survey__rating .rating-numbers li.active span {
            font-size: 55px;
            font-size: 5.5rem; } }
        .user-survey--blue .survey__rating .rating-numbers li.active span,
        .user-survey--gradient .survey__rating .rating-numbers li.active span {
          color: #000; }
        .user-survey--cobalt .survey__rating .rating-numbers li.active span {
          color: #71C5E8; }
      .survey__rating .rating-numbers li.near-active-1 span {
        font-size: 25px;
        font-size: 2.5rem;
        font-weight: 600; }
        @media (min-width: 640px) {
          .survey__rating .rating-numbers li.near-active-1 span {
            font-size: 35px;
            font-size: 3.5rem; } }
        @media (min-width: 1220px) {
          .survey__rating .rating-numbers li.near-active-1 span {
            font-size: 45px;
            font-size: 4.5rem; } }
      .survey__rating .rating-numbers li.near-active-2 span {
        font-size: 20px;
        font-size: 2rem; }
        @media (min-width: 640px) {
          .survey__rating .rating-numbers li.near-active-2 span {
            font-size: 30px;
            font-size: 3rem; } }
        @media (min-width: 1220px) {
          .survey__rating .rating-numbers li.near-active-2 span {
            font-size: 40px;
            font-size: 4rem; } }
      .survey__rating .rating-numbers li.near-active-3 span {
        font-size: 20px;
        font-size: 2rem; }
        @media (min-width: 640px) {
          .survey__rating .rating-numbers li.near-active-3 span {
            font-size: 25px;
            font-size: 2.5rem; } }
        @media (min-width: 1220px) {
          .survey__rating .rating-numbers li.near-active-3 span {
            font-size: 35px;
            font-size: 3.5rem; } }
  .survey__rating .rating-scale {
    position: relative;
    padding: 15px 0; }
    .survey__rating .rating-scale .rating-slider {
      position: relative;
      height: 40px; }
    .survey__rating .rating-scale input[type="range"] {
      position: relative;
      transform: translateY(-50%);
      top: 50%;
      width: 60%;
      height: 70px;
      cursor: pointer;
      -webkit-appearance: none;
      background: transparent;
      /* Slider Track */
      /* Slider Thumb */ }
      .survey__rating .rating-scale input[type="range"]:focus {
        outline: 2px dotted #009fdb;
        outline-offset: 0; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]:focus,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]:focus,
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]:focus,
        .user-survey--black .survey__rating .rating-scale input[type="range"]:focus {
          outline: 2px dotted #FFF; }
      @media (min-width: 640px) {
        .survey__rating .rating-scale input[type="range"] {
          width: 70%; } }
      @media (min-width: 1220px) {
        .survey__rating .rating-scale input[type="range"] {
          width: 76%; } }
      .survey__rating .rating-scale input[type="range"]::-webkit-slider-runnable-track {
        background: #009FDB;
        width: 100%;
        height: 4px;
        border-radius: 4px; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-webkit-slider-runnable-track,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-webkit-slider-runnable-track {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-webkit-slider-runnable-track {
          background: #71C5E8; }
      .survey__rating .rating-scale input[type="range"]::-moz-range-track {
        background: #009FDB;
        width: 100%;
        height: 4px;
        border-radius: 4px; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-moz-range-track,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-moz-range-track {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-moz-range-track {
          background: #71C5E8; }
      .survey__rating .rating-scale input[type="range"]::-ms-track {
        background: #009FDB;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        height: 50px;
        margin-top: 35px;
        border: 0;
        border-color: transparent;
        background: transparent;
        color: transparent;
        cursor: pointer; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-ms-track,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-ms-track {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-ms-track {
          background: #71C5E8; }
      .survey__rating .rating-scale input[type="range"]::-ms-fill-lower {
        background: #009FDB;
        height: 4px;
        margin-top: -20px; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-ms-fill-lower,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-ms-fill-lower {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-ms-fill-lower {
          background: #71C5E8; }
      .survey__rating .rating-scale input[type="range"]::-ms-fill-upper {
        background: #009FDB;
        height: 4px;
        margin-top: -20px; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-ms-fill-upper,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-ms-fill-upper {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-ms-fill-upper {
          background: #71C5E8; }
      .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
        border: 2px solid #009FDB;
        height: 28px;
        width: 18px;
        border-radius: 6px;
        background: #FFF;
        margin-top: -12px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        -webkit-appearance: none; }
        @media (min-width: 1024px) {
          .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
            height: 32px;
            width: 20px;
            margin-top: -14px; } }
        .user-survey--silver .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          background: #F2F2F2; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          border-color: #000; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          background: #009FDB; }
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          background: #01A2D9; }
        .user-survey--black .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-webkit-slider-thumb {
          border-color: #009FDB;
          background: #0057B8; }
      .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
        border: 2px solid #009FDB;
        height: 28px;
        width: 18px;
        border-radius: 6px;
        background: #FFF;
        margin-top: -12px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); }
        @media (min-width: 1024px) {
          .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
            height: 32px;
            width: 20px;
            margin-top: -14px; } }
        .user-survey--silver .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          background: #F2F2F2; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-moz-range-thumb,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          border-color: #000; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          background: #009FDB; }
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          background: #01A2D9; }
        .user-survey--black .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-moz-range-thumb {
          border-color: #009FDB;
          background: #0057B8; }
      .survey__rating .rating-scale input[type="range"]::-ms-thumb {
        border: 2px solid #009FDB;
        height: 28px;
        width: 18px;
        border-radius: 6px;
        background: #FFF;
        margin-top: -12px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        margin-top: -30px;
        height: 11px; }
        @media (min-width: 1024px) {
          .survey__rating .rating-scale input[type="range"]::-ms-thumb {
            height: 32px;
            width: 20px;
            margin-top: -14px; } }
        .user-survey--silver .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          background: #F2F2F2; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-ms-thumb,
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          border-color: #000; }
        .user-survey--blue .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          background: #009FDB; }
        .user-survey--gradient .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          background: #01A2D9; }
        .user-survey--black .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          background: #000; }
        .user-survey--cobalt .survey__rating .rating-scale input[type="range"]::-ms-thumb {
          border-color: #009FDB;
          background: #0057B8; }
        @media (min-width: 1024px) {
          .survey__rating .rating-scale input[type="range"]::-ms-thumb {
            margin-top: -30px;
            height: 11px; } }
  .survey__rating .rating-label {
    font-size: 15px;
    font-size: 1.5rem;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
    position: absolute;
    width: 20%; }
    @media (min-width: 640px) {
      .survey__rating .rating-label {
        width: 15%; } }
    @media (min-width: 1220px) {
      .survey__rating .rating-label {
        width: 12%; } }
    .survey__rating .rating-label.rating-label--low {
      left: 0;
      padding-right: 10px;
      text-align: right; }
    .survey__rating .rating-label.rating-label--high {
      right: 0;
      padding-left: 10px;
      text-align: left; }
  .survey__rating .rating-instructions:focus {
    outline: 0; }

.survey__submit {
  position: relative; }
  .survey__submit .cta--button-new {
    border: 1px solid black;
    background-color: transparent;
    color: black;
    text-decoration: none;
    display: block;
    max-width: 130px;
    margin-left: auto;
    margin-right: auto; }
    .survey__submit .cta--button-new:hover {
      background-color: black;
      color: white; }
    .user-survey--black .survey__submit .cta--button-new,
    .user-survey--cobalt .survey__submit .cta--button-new {
      border: 1px solid white;
      background-color: transparent;
      color: white;
      text-decoration: none; }
      .user-survey--black .survey__submit .cta--button-new:hover,
      .user-survey--cobalt .survey__submit .cta--button-new:hover {
        background-color: white;
        color: black; }

.survey__error {
  font-size: 14px;
  font-size: 1.4rem;
  display: none;
  position: relative;
  padding-bottom: 2px;
  margin: 0 auto 20px;
  font-weight: 700;
  font-style: italic;
  color: #FF585D; }
  .survey__error.submit-error {
    bottom: -30px;
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .survey__error.submit-error {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: -50px; } }
  .survey__error:focus {
    outline: 0; }
  .survey__error.visible {
    display: inline-block; }
  .region[class*="-blue"] .survey__error,
  .user-survey--blue .survey__error,
  .user-survey--cobalt .survey__error,
  .user-survey--gradient .survey__error {
    color: #FFF; }

.survey__instructions {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
  position: relative;
  padding-bottom: 2px;
  margin: 0 auto 20px;
  font-weight: 700;
  font-style: italic;
  color: #000; }
  .survey__instructions:focus {
    outline: 0; }
  .survey__instructions.visible {
    display: inline-block; }
  .region[class*="-blue"] .survey__instructions,
  .user-survey--blue .survey__instructions,
  .user-survey--cobalt .survey__instructions,
  .user-survey--gradient .survey__instructions {
    color: #FFF; }

.survey__notification:focus {
  outline: 0; }

.survey__thanks h3,
.survey__thanks h4 {
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 20px; }

@media (min-width: 768px) {
  .survey__thanks p {
    font-size: 21px;
    font-size: 2.1rem; } }

.survey__thanks a {
  text-decoration: underline;
  color: #0568AE; }

.user-survey--blue .survey__thanks a,
.user-survey--gradient .survey__thanks a {
  color: #000; }

.user-survey--black .survey__thanks a,
.user-survey--cobalt .survey__thanks a {
  color: #FFF; }

/* Popup & Video Mode-specfiic Styles */
.user-survey.fancybox-content {
  padding: 0; }
  .user-survey.fancybox-content .survey__instructions {
    width: 85%; }
    @media (max-width: 767px) {
      .user-survey.fancybox-content .survey__instructions {
        width: 75% !important;
        margin-top: 25px; } }
  @media (max-width: 639px) {
    .user-survey.fancybox-content {
      width: 100% !important; } }

@media (max-width: 639px) {
  .user-survey.fancybox-content,
  .user-survey.fancybox-content .user-survey__wrap {
    min-height: 400px !important; } }

.survey__close {
  padding: 10px 15px;
  position: absolute;
  right: 0; }
  .survey__close .cta--close {
    text-decoration: none;
    font-weight: 300;
    color: #000; }
    .survey__close .cta--close:focus {
      outline: 2px dotted #009fdb;
      outline-offset: 0; }
      .user-survey--blue .survey__close .cta--close:focus,
      .user-survey--gradient .survey__close .cta--close:focus,
      .user-survey--cobalt .survey__close .cta--close:focus,
      .user-survey--black .survey__close .cta--close:focus {
        outline: 2px dotted #FFF; }
    .user-survey--black .survey__close .cta--close,
    .user-survey--cobalt .survey__close .cta--close {
      color: #FFF; }
    .survey__close .cta--close span {
      font-size: 25px;
      font-size: 2.5rem;
      position: relative;
      top: 3px; }

/*==================
  USER SURVEY REPORT
  ==================*/
.user-survey-report {
  padding: 0 20px; }
  .user-survey-report h2 {
    padding-bottom: 20px;
    border-bottom: 1px solid #0568AE; }
  .user-survey-report .report__options {
    min-width: 300px; }
    @media screen and (min-width: 1024px) {
      .user-survey-report .report__options {
        width: 20%;
        padding-right: 30px; } }
    .user-survey-report .report__options form {
      margin: 30px 0; }
      .user-survey-report .report__options form .field {
        position: relative;
        margin-bottom: 30px; }
        .user-survey-report .report__options form .field:last-of-type {
          padding-top: 20px;
          display: flex;
          align-items: center; }
      .user-survey-report .report__options form label {
        font-size: 18px;
        font-size: 1.8rem;
        display: inline-block;
        position: relative;
        margin: 0 10px 10px 0; }
        .user-survey-report .report__options form label.required::after {
          content: "*";
          color: #009FDB;
          position: absolute;
          right: -10px; }
      .user-survey-report .report__options form select, .user-survey-report .report__options form input {
        font-size: 15px;
        font-size: 1.5rem;
        height: 30px;
        width: calc(100% - 130px);
        padding: 0px 5px;
        line-height: 30px;
        min-width: 220px;
        font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
        border: 1px solid #959595; }
        @media screen and (min-width: 1024px) {
          .user-survey-report .report__options form select, .user-survey-report .report__options form input {
            width: 100%; } }
      .user-survey-report .report__options form .date-picker {
        margin-bottom: 10px; }
      .user-survey-report .report__options form .report__submit {
        font-size: 18px;
        font-size: 1.8rem;
        border-radius: 0; }
        .user-survey-report .report__options form .report__submit:disabled {
          background-color: #C9C9C9;
          color: #F2F2F2;
          cursor: default; }
  .user-survey-report .report__loading {
    display: inline-block;
    content: url("./../img/icon_loading.gif");
    height: 28px;
    width: 28px;
    margin-left: 15px;
    opacity: 0;
    transition: all 600ms ease; }
  .user-survey-report .report__error {
    font-size: 14px;
    font-size: 1.4rem;
    position: absolute;
    display: none;
    max-width: 320px;
    font-style: italic;
    font-weight: 700;
    line-height: 1.8rem;
    color: #FF585D; }
    .user-survey-report .report__error:focus {
      outline: 0; }
    .user-survey-report .report__error.visible {
      display: block; }
    .user-survey-report .report__error.submit-error {
      top: 125%; }
  .user-survey-report.loading .report__loading {
    opacity: 1; }
  @media screen and (min-width: 1024px) {
    .user-survey-report .report__wrap {
      display: flex; } }
  .user-survey-report .report__results {
    padding-top: 20px;
    border-top: 1px solid #C9C9C9; }
    @media screen and (min-width: 1024px) {
      .user-survey-report .report__results {
        width: 80%;
        padding: 30px;
        padding-right: 0;
        border-left: 1px solid #C9C9C9;
        border-top: 0; } }
    .user-survey-report .report__results .results__wrap {
      transition: all 600ms ease;
      opacity: 0; }
      .user-survey-report .report__results .results__wrap h3 {
        font-size: 23px;
        font-size: 2.3rem;
        margin-bottom: 10px; }
      .user-survey-report .report__results .results__wrap h4, .user-survey-report .report__results .results__wrap h5 {
        font-size: 18px;
        font-size: 1.8rem; }
      .user-survey-report .report__results .results__wrap h5 {
        font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif; }
      .user-survey-report .report__results .results__wrap .results__info {
        padding-bottom: 25px;
        border-bottom: 1px solid #0568AE;
        font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif; }
      .user-survey-report .report__results .results__wrap .results__table {
        padding-top: 20px;
        max-width: 100%;
        max-height: 800px;
        overflow: scroll; }
      .user-survey-report .report__results .results__wrap table {
        width: auto;
        text-align: left;
        border-collapse: collapse; }
        .user-survey-report .report__results .results__wrap table th {
          font-size: 18px;
          font-size: 1.8rem;
          background-color: #F2F2F2;
          font-weight: 700; }
        .user-survey-report .report__results .results__wrap table th.header--question {
          min-width: 300px; }
        .user-survey-report .report__results .results__wrap table th, .user-survey-report .report__results .results__wrap table td {
          border: 1px solid #959595;
          padding: 10px;
          vertical-align: middle; }
        .user-survey-report .report__results .results__wrap table th.total,
        .user-survey-report .report__results .results__wrap table th.response,
        .user-survey-report .report__results .results__wrap table td.total,
        .user-survey-report .report__results .results__wrap table td.response {
          text-align: center; }
        .user-survey-report .report__results .results__wrap table tr:nth-child(odd) {
          background-color: #F2F2F2; }
    .user-survey-report .report__results.shown .results__wrap {
      opacity: 1; }
    .user-survey-report .report__results .poll-report {
      margin-bottom: 20px;
      padding: 15px 0 25px;
      border-bottom: 1px solid #0568AE; }
      .user-survey-report .report__results .poll-report:last-child {
        border-bottom: 0; }
      .user-survey-report .report__results .poll-report a:hover {
        text-decoration: underline; }
    .user-survey-report .report__results .poll-page {
      margin: 20px 0 35px;
      padding-left: 15px; }
    .user-survey-report .report__results .poll-question {
      display: flex;
      align-items: center;
      background-color: #F2F2F2;
      border: 1px solid #959595;
      margin: 15px 0; }
    .user-survey-report .report__results .poll-question-header,
    .user-survey-report .report__results .poll-responses {
      flex: 1; }
    .user-survey-report .report__results .poll-question-header h5 {
      flex: 2;
      padding: 15px; }
      .user-survey-report .report__results .poll-question-header h5 + h5 {
        font-size: 16px;
        font-size: 1.6rem;
        flex: 1;
        border-left: 1px solid #959595; }
    .user-survey-report .report__results .poll-responses {
      border-left: 1px solid #959595; }
      .user-survey-report .report__results .poll-responses li {
        padding: 10px 15px;
        background-color: #FFF;
        border-top: 1px solid #959595;
        text-align: center; }
        .user-survey-report .report__results .poll-responses li:first-child {
          background-color: #F2F2F2;
          border-top: 0; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #009FDB;
  border-color: #009FDB; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #009FDB;
  box-shadow: -10px 0 0 #009FDB; }

/*=============
  VIDEO LIBRARY
  =============*/
.video-library {
  max-width: 1220px;
  margin: 0 auto;
  padding-bottom: 40px; }
  .video-library .videos__heading {
    text-align: center; }
  .video-library .assets__filter-nav {
    font-size: 15px;
    font-size: 1.5rem;
    position: relative;
    max-width: 300px;
    margin: 0 auto 20px;
    z-index: 9; }
    @media (min-width: 640px) {
      .video-library .assets__filter-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: none;
        padding: 0 20px; }
        .no-flexbox.no-flexboxtweener.no-flexboxlegacy .video-library .assets__filter-nav:after {
          content: "";
          display: table;
          clear: both; } }
    @media (min-width: 768px) {
      .video-library .assets__filter-nav {
        margin-bottom: 30px;
        padding: 0 55px;
        max-width: 770px; } }
    @media (min-width: 1440px) {
      .video-library .assets__filter-nav {
        margin-bottom: 50px;
        padding: 0; } }
    .video-library .assets__filter-nav .asset-type,
    .video-library .assets__filter-nav .asset-search {
      margin-top: 30px;
      margin-bottom: 30px; }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .video-library .assets__filter-nav .asset-type, .no-flexbox.no-flexboxtweener.no-flexboxlegacy
      .video-library .assets__filter-nav .asset-search {
        float: left; }
      @media (min-width: 1440px) {
        .video-library .assets__filter-nav .asset-type,
        .video-library .assets__filter-nav .asset-search {
          margin-top: 40px;
          margin-bottom: 10px; } }
    .video-library .assets__filter-nav .asset-type {
      position: relative;
      min-width: 230px;
      z-index: 9; }
    .video-library .assets__filter-nav .type-toggle {
      display: block;
      position: relative;
      padding: 0 25px 0 15px;
      border-radius: 30px;
      background-color: #191919;
      color: #FFF;
      height: 37px;
      line-height: 37px;
      text-decoration: none; }
      .region--white .video-library .assets__filter-nav .type-toggle, .region--gray .video-library .assets__filter-nav .type-toggle, .region--black .video-library .assets__filter-nav .type-toggle, .region--near-black .video-library .assets__filter-nav .type-toggle {
        background-color: #009FDB; }
      .video-library .assets__filter-nav .type-toggle:after {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: 55%;
        right: 15px;
        border-style: solid;
        border-width: 7px 4px 0 4px;
        border-color: #FFF transparent transparent transparent; }
    .video-library .assets__filter-nav .type-select {
      display: none;
      position: absolute;
      background-color: #FFF;
      border: 1px solid #C9C9C9;
      border-radius: 3px;
      min-width: 230px;
      top: 40px;
      left: 0; }
      .video-library .assets__filter-nav .type-select a {
        display: block;
        padding: 10px 12px;
        color: #000;
        text-decoration: none; }
        .video-library .assets__filter-nav .type-select a:hover {
          background-color: #009FDB;
          text-decoration: none;
          color: #FFF; }
        .video-library .assets__filter-nav .type-select a.active {
          display: none; }
    .video-library .assets__filter-nav.assets__filter-nav--open .type-select {
      display: block; }
    .video-library .assets__filter-nav.assets__filter-nav--open .type-toggle:after {
      transform: rotateX(180deg);
      top: 45%; }
    .video-library .assets__filter-nav .asset-search {
      flex-grow: 1; }
    @media (min-width: 640px) {
      .video-library .assets__filter-nav .asset-type + .asset-search {
        margin-left: 20px; } }
    @media (min-width: 1220px) {
      .video-library .assets__filter-nav .asset-type + .asset-search {
        margin-left: 40px; } }
    .video-library .assets__filter-nav .search-field {
      padding: 0; }
    .video-library .assets__filter-nav .search-field .search__clear {
      position: relative;
      display: none;
      margin: 0 0 0 -28px;
      padding-right: 6px; }
      .video-library .assets__filter-nav .search-field .search__clear:after {
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        content: "";
        width: 50px;
        height: calc(100% - 4px);
        right: 0;
        z-index: 1; }
        .region--white .video-library .assets__filter-nav .search-field .search__clear:after {
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 32%, white 99%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
          /* IE6-9 */ }
      .video-library .assets__filter-nav .search-field .search__clear .icon--clear {
        display: block;
        position: relative;
        background-color: #000;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        z-index: 2; }
        .region--white .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--black .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--comms-cobalt .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--comms-purple .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--comms-cobalt-purple .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--blue--wh-text .video-library .assets__filter-nav .search-field .search__clear .icon--clear,
        .region--blue-gradient--wh-text .video-library .assets__filter-nav .search-field .search__clear .icon--clear {
          background-color: transparent;
          border: 1px solid #FFF; }
      .video-library .assets__filter-nav .search-field .search__clear .icon--clear:after {
        font-size: 27px;
        font-size: 2.7rem;
        position: absolute;
        display: inline-block;
        content: "+";
        transform: translate(-50%, -50%) rotate(45deg);
        top: 48%;
        left: 55%;
        font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
        font-weight: 300;
        color: #FFF; }
  .video-library .assets__feed .row + .row {
    margin-top: 0; }
  @media (min-width: 1024px) {
    .video-library .assets__feed .col.col-2 {
      max-width: 20%;
      flex-basis: 20%; } }
  @media (min-width: 768px) {
    .video-library .assets__feed .component {
      padding-bottom: 20px;
      margin-bottom: 10px; } }
  .video-library .assets__feed .item__media a {
    display: block;
    height: 100%;
    width: 100%; }
  @media (min-width: 1024px) {
    .video-library .assets__feed .item__media .item__video > * {
      height: 100%; } }
  .region--near-black .video-library .assets__feed .item__media .item__video .item__thumbnail,
  .region--black .video-library .assets__feed .item__media .item__video .item__thumbnail {
    border: 1px solid #5A5A5A; }
  @media (min-width: 1024px) {
    .video-library .assets__feed .item__media .item__video .item__preview img {
      max-width: none; } }
  @media (min-width: 1220px) {
    .video-library .assets__feed .item__media .item__video .item__preview img {
      height: auto;
      width: 100%; } }
  .video-library .assets__feed .item__media .item__video .item__thumbnail:after,
  .video-library .assets__feed .item__media .item__img--b-roll .item__thumbnail:after {
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05); }
  .video-library .assets__feed .item__content {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0; }
    .region:not(.region--white) .video-library .assets__feed .item__content {
      background-color: transparent; }
  @media (min-width: 1024px) {
    .video-library .assets__feed .item__title {
      min-height: 45px; } }
  .video-library .assets__feed .item__title a {
    text-decoration: none; }
    .region--white .video-library .assets__feed .item__title a, .video-library .assets__feed .item__title a .region[class*="black"] {
      color: #009FDB; }
  .video-library .assets__cta {
    display: none;
    text-align: center;
    margin: 20px 0 0; }
    @media (min-width: 1024px) {
      .video-library .assets__cta {
        margin: 40px auto 0; } }

/*=========
  Templates
  =========*/
/*==============
  STORY TEMPLATE
  ==============*/
/* Story Header */
.story__header {
  position: relative; }
  .story__header .featured-image,
  .story__header .featured__image {
    background-color: #000;
    max-height: 483px;
    overflow: hidden; }
    .story__header .featured-image img,
    .story__header .featured__image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
  .story__header.story__header--light .featured-image,
  .story__header.story__header--light .featured__image {
    background-color: #FFF; }

/* Story Preface */
.story__preface > *:last-child {
  margin-bottom: 30px; }

.story__preface:first-child + .story__wrap {
  padding-top: 40px; }
  @media (min-width: 1024px) {
    .story__preface:first-child + .story__wrap {
      padding-top: 0; } }

/* Search Widget */
.widget--search .component:first-child {
  margin-bottom: 0; }

/* Top Videos Widget */
.widget--videos .widget__items:after {
  content: "";
  display: table;
  clear: both; }

@media (min-width: 768px) and (max-width: 1023px) {
  .widget--videos .widget__items {
    margin: 0 -10px; } }

@media (min-width: 640px) and (max-width: 767px) {
  .widget--videos .widget__items .component {
    max-width: 50%;
    flex-basis: 50%;
    float: left;
    padding-right: 10px;
    padding-left: 10px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .widget--videos .widget__items .component {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
    float: left;
    padding-right: 10px;
    padding-left: 10px; } }

.widget--videos .widget__items .component:last-child {
  margin-bottom: 0; }

/* Component Style Overrides */
.story {
  /* Article */
  /* Sidebar */ }
  .story .story__wrap .row__wrap .col-12 {
    margin-left: auto;
    margin-right: auto; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .story .story__wrap .row__wrap {
      display: block; } }
  @media (min-width: 1220px) {
    .story .article.col-12 {
      padding-left: 0px; } }
  @media (min-width: 1440px) {
    .story .article.col-12 {
      padding-left: 0px; } }
  .story .article {
    padding-bottom: 20px;
    /* Article Banner Components */ }
    @media (min-width: 1220px) {
      .story .article {
        padding-left: 55px; } }
    @media (min-width: 1440px) {
      .story .article {
        padding-left: 105px; } }
    .story .article > .content__wrap {
      padding-bottom: 40px; }
    .story .article .banner .banner__content {
      padding-bottom: 0; }
    .story .article .banner .banner__cta {
      margin-top: 20px; }
    .story .article .text.story--pub-date p {
      margin-bottom: 0px; }
    .story .article .text.story-info {
      margin-top: -23px;
      font-size: 16px;
      font-size: 1.6rem; }
  .story .article.col-12 .story-title .component {
    margin-bottom: 0px; }
  .story .sidebar {
    padding-top: 20px;
    /* Sidebar Components */
    /* Sidebar Featured Item Components */
    /* Sidebar Media Components */
    /* Sidebar Text Components */ }
    .story .sidebar .sidebar__content .component {
      margin-bottom: 40px; }
      .story .sidebar .sidebar__content .component.component--no-margin-bottom {
        margin-bottom: 20px; }
      @media (min-width: 1220px) {
        .story .sidebar .sidebar__content .component.component--border-bottom > div {
          padding-bottom: 40px;
          margin-bottom: 50px; } }
      .story .sidebar .sidebar__content .component.component--border-bottom > div:after {
        width: 60%;
        right: auto; }
    .story .sidebar .featured-item {
      overflow: inherit; }
    .story .sidebar .item__content {
      padding-left: 0;
      padding-top: 0;
      margin-bottom: 20px; }
    .story .sidebar .component:last-child .item__content {
      margin-bottom: 0; }
    .story .sidebar .item__media + .item__content {
      padding-top: 20px; }
    .story .sidebar .item__media {
      max-height: none; }
    @media (min-width: 768px) {
      .story .sidebar .item__preview {
        display: block;
        max-height: 160px;
        overflow: hidden; } }
    @media (min-width: 1024px) {
      .story .sidebar .item__preview {
        max-height: none; } }
    .story .sidebar .widget--videos .item__media {
      background-color: transparent; }
    @media (min-width: 1024px) {
      .story .sidebar .widget--videos [class^="item__video"] {
        min-height: 150px; } }
    @media (min-width: 1440px) {
      .story .sidebar .widget--videos [class^="item__video"] {
        min-height: 165px; } }
    .story .sidebar .widget--videos .item__content {
      padding-right: 0; }
      @media (min-width: 1220px) {
        .story .sidebar .widget--videos .item__content {
          padding-top: 15px; } }
    .story .sidebar .media__caption {
      font-size: 16px;
      font-size: 1.6rem; }
    .story .sidebar .text--blockquote .text__wrap,
    .story .sidebar .text blockquote {
      font-size: 18px;
      font-size: 1.8rem;
      margin-bottom: 0; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .story .article,
    .story .sidebar {
      max-width: none; } }
  @media (min-width: 1024px) {
    .story .article,
    .story .sidebar {
      padding-top: 65px;
      padding-bottom: 65px; } }
  .story .article .component > .text,
  .story .sidebar .component > .text {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .story .story__preface .alert {
      padding-left: 55px;
      padding-right: 55px; } }
  @media (min-width: 1220px) {
    .story .story__preface .alert {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1220px) {
    .story .story__preface .alert__message {
      padding-left: calc(105px - 10px);
      padding-right: calc(105px - 10px); } }

/*==============
  BLOG TEMPLATES
  ==============*/
/* General Blog Elements */
@media (max-width: 1219px) {
  .article [class^="blog__"] .col.col-8 {
    max-width: none; } }

.blog__header {
  position: relative; }
  @media (min-width: 1220px) {
    .header--transparent + .content .blog__header .article__header {
      height: calc(100% - 105px); } }
  .blog__header .article__header .featured-category {
    padding: 40px 0 30px;
    color: #009FDB;
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300; }
    @media (min-width: 768px) {
      .blog__header .article__header .featured-category {
        padding: 50px 0; } }
    @media (min-width: 1024px) {
      .blog__header .article__header .featured-category {
        padding: 80px 0 50px; } }
    .blog__header .article__header .featured-category b,
    .blog__header .article__header .featured-category strong {
      font-weight: 700;
      color: #FFF; }
      .region--blue .blog__header .article__header .featured-category b, .region--blue
      .blog__header .article__header .featured-category strong {
        color: #000; }
      .region--dark-blue .blog__header .article__header .featured-category b,
      .region[class*="-black"] .blog__header .article__header .featured-category b, .region--dark-blue
      .blog__header .article__header .featured-category strong,
      .region[class*="-black"]
      .blog__header .article__header .featured-category strong {
        color: #FFF; }
    .blog__header .article__header .featured-category em {
      color: #009FDB; }
    .blog__header .article__header .featured-category a {
      color: #000; }
  .blog__header .article__header .story--pub-date p {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 0px; }
  .blog__header .article__header .story-title .component {
    margin-bottom: 0px; }
  .blog__header .article__header .story--description p {
    max-width: 100%;
    font-size: 18px;
    font-size: 1.8rem; }
  .blog__header .article__header .text.story-info p {
    margin-top: -23px;
    font-size: 16px;
    font-size: 1.6rem; }
  .blog__header .article__header .blog--author p {
    font-size: 16px;
    font-size: 1.6rem;
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px; }
  .blog__header .featured-article {
    padding-top: 30px; }
    @media (min-width: 1220px) {
      .header--transparent + .content .blog__header .featured-article {
        height: calc(100% - 105px); } }
    .blog__header .featured-article .featured-category {
      color: #009FDB;
      font-size: 24px;
      font-size: 2.4rem;
      font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
      font-weight: 300; }
      .blog__header .featured-article .featured-category b,
      .blog__header .featured-article .featured-category strong {
        font-weight: 700;
        color: #FFF; }
        .region--blue .blog__header .featured-article .featured-category b, .region--blue
        .blog__header .featured-article .featured-category strong {
          color: #000; }
        .region--dark-blue .blog__header .featured-article .featured-category b,
        .region[class*="-black"] .blog__header .featured-article .featured-category b, .region--dark-blue
        .blog__header .featured-article .featured-category strong,
        .region[class*="-black"]
        .blog__header .featured-article .featured-category strong {
          color: #FFF; }
      .blog__header .featured-article .featured-category em {
        color: #009FDB; }
      .blog__header .featured-article .featured-category a {
        color: #000; }
    .blog__header .featured-article p {
      font-size: 21px;
      font-size: 2.1rem;
      line-height: 1.45; }
      @media (min-width: 1220px) {
        .blog__header .featured-article p {
          max-width: 40%; } }
  .blog__header .featured-article__cta {
    margin: 25px 0; }
    .blog__header .featured-article__cta .cta--link {
      color: #009FDB; }
      @media (min-width: 1220px) {
        .blog__header .featured-article__cta .cta--link {
          font-size: 21px;
          font-size: 2.1rem;
          font-weight: 400; } }
  .blog__header.blog__header--light .featured-category {
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300; }
    .blog__header.blog__header--light .featured-category b,
    .blog__header.blog__header--light .featured-category strong {
      font-weight: 700;
      color: #000; }
      .region--blue .blog__header.blog__header--light .featured-category b, .region--blue
      .blog__header.blog__header--light .featured-category strong {
        color: #000; }
      .region--dark-blue .blog__header.blog__header--light .featured-category b,
      .region[class*="-black"] .blog__header.blog__header--light .featured-category b, .region--dark-blue
      .blog__header.blog__header--light .featured-category strong,
      .region[class*="-black"]
      .blog__header.blog__header--light .featured-category strong {
        color: #FFF; }
    .blog__header.blog__header--light .featured-category em {
      color: #009FDB; }

.blog-main.aem-GridColumn .blog__header .article__header {
  padding-bottom: 0px; }

/* Blog Landing Styles */
/* =================== */
.blog-landing .blog__header {
  padding: 0;
  background-color: #000;
  color: #FFF; }
  .blog-landing .blog__header.blog__header--light {
    background-color: #FFF;
    color: #000; }
  .blog-landing .blog__header .featured-image {
    overflow: hidden; }
    .blog-landing .blog__header .featured-image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
      @media (max-width: 1023px) {
        .blog-landing .blog__header .featured-image img {
          width: 100%; } }
  .blog-landing .blog__header .featured-article {
    padding: 40px 20px; }
    @media (min-width: 768px) {
      .blog-landing .blog__header .featured-article {
        padding-left: 55px;
        padding-right: 55px; } }
    @media (min-width: 768px) {
      .blog-landing .blog__header .featured-article {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        max-width: 1220px;
        width: 100%;
        height: 100%; } }
    @media (min-width: 1440px) {
      .blog-landing .blog__header .featured-article {
        padding-top: 65px;
        padding-left: 0;
        padding-right: 0; } }
    .blog-landing .blog__header .featured-article .featured-headline {
      font-size: 35px;
      font-size: 3.5rem;
      padding-top: 30px;
      line-height: 1.15; }
      @media (min-width: 1024px) {
        .blog-landing .blog__header .featured-article .featured-headline {
          font-size: 50px;
          font-size: 5rem;
          padding-top: 60px; } }
      @media (min-width: 1220px) {
        .blog-landing .blog__header .featured-article .featured-headline {
          font-size: 60px;
          font-size: 6rem;
          padding-bottom: 50px; } }
      @media (min-width: 1440px) {
        .blog-landing .blog__header .featured-article .featured-headline {
          max-width: 50%; } }
    @media (min-width: 768px) and (max-width: 1219px) {
      .blog-landing .blog__header .featured-article .featured-headline,
      .blog-landing .blog__header .featured-article .featured-description {
        max-width: 70%; } }

/* Blog Post Styles */
/* ================ */
.blog-post .blog__header .featured-article {
  padding: 40px 0 30px; }
  @media (min-width: 768px) {
    .blog-post .blog__header .featured-article {
      padding: 50px 0; } }
  @media (min-width: 1024px) {
    .blog-post .blog__header .featured-article {
      padding: 80px 0; } }

.blog-post .blog__header .featured-category b,
.blog-post .blog__header .featured-category strong {
  color: #000; }

.blog-post .blog__header .featured-category strong em {
  color: #009FDB; }

.blog-post .blog__header .featured-headline {
  padding-bottom: 20px; }

.blog-post .blog__header .blog__authors .author__meta:first-child {
  border-top: 0px;
  padding-top: 20px; }

.blog-post .blog__header .blog__authors .author__meta {
  margin-bottom: 20px; }

.blog-post .blog__header .blog__authors .author__meta:last-child {
  margin-bottom: 0px; }

.blog__authors {
  padding-bottom: 20px; }
  .blog__authors .author__meta {
    margin-bottom: 40px; }
    .blog__authors .author__meta:first-child {
      border-top: 1px solid #C9C9C9;
      padding-top: 40px; }
    .blog__authors .author__meta:last-child {
      margin-bottom: 0; }
    .blog__authors .author__meta .author {
      margin-bottom: 5px; }
    .blog__authors .author__meta .title,
    .blog__authors .author__meta .info {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 1.6; }
    .blog__authors .author__meta .info:not(:last-child) {
      margin-bottom: 15px; }
    .blog__authors .author__meta .title {
      margin-bottom: 10px; }
    .blog__authors .author__meta .social-links__wrapper {
      display: flex;
      flex-direction: column; }
      @media (min-width: 480px) {
        .blog__authors .author__meta .social-links__wrapper {
          flex-direction: row; } }
      .blog__authors .author__meta .social-links__wrapper .social-link__item {
        display: flex;
        align-items: center;
        padding-bottom: 10px; }
        @media (min-width: 480px) {
          .blog__authors .author__meta .social-links__wrapper .social-link__item {
            padding-right: 15px;
            padding-bottom: 0px; } }
        .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link__title {
          font-weight: 700; }
        .blog__authors .author__meta .social-links__wrapper .social-link__item span {
          margin-bottom: 0; }
        .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link:hover {
            text-decoration: underline; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter, .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook, .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin {
            color: #0568AE; }
            .region--black .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter, .region--black .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook, .region--black .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin {
              color: #FFF; }
            .region--blue--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter,
            .region--blue-gradient--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter, .region--blue--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook,
            .region--blue-gradient--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook, .region--blue--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin,
            .region--blue-gradient--bl-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin {
              color: #000; }
            .region--blue--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter,
            .region--comms-cobalt .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter,
            .region--blue-gradient--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter, .region--blue--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook,
            .region--comms-cobalt .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook,
            .region--blue-gradient--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook, .region--blue--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin,
            .region--comms-cobalt .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin,
            .region--blue-gradient--wh-text .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin {
              color: #FFF; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter i {
            background-color: #000; }
            .region--black .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.twitter i {
              background-color: #FFF;
              color: #000; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.facebook i {
            background-color: #0766ff; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link.linkedin i {
            background-color: #0b66c2; }
          .blog__authors .author__meta .social-links__wrapper .social-link__item .social-link i {
            min-width: 20px;
            max-width: 20px;
            height: 20px;
            margin-right: 3px;
            font-size: 16px;
            color: #FFF;
            background-color: #009FDB;
            border-radius: 50%; }

/* Component Style Overrides */
.blog {
  /* Article */ }
  .blog .article {
    padding-bottom: 40px; }
    @media (min-width: 1024px) {
      .blog .article {
        padding-bottom: 80px; } }
  .blog .blog__header .col,
  .blog .blog__authors .col {
    margin-left: auto;
    margin-right: auto; }
  .blog .article__meta {
    margin-bottom: 30px; }
    @media (min-width: 1024px) {
      .blog .article__meta {
        margin-bottom: 60px; } }
    .blog .article__meta .meta__info {
      max-width: none; }
      .blog .article__meta .meta__info .date {
        font-weight: 700; }

/*===================
  MEDIA KIT TEMPLATES
  ===================*/
/* General Media Kit Elements */
@media (max-width: 1219px) {
  .article [class^="media-kit__"] .col.col-8 {
    max-width: none; } }

.media-kit__header {
  position: relative; }
  .media-kit__header .featured-article {
    padding-top: 30px; }
    @media (min-width: 1220px) {
      .header--transparent + .content .media-kit__header .featured-article {
        height: calc(100% - 105px); } }
    .media-kit__header .featured-article p {
      font-size: 21px;
      font-size: 2.1rem;
      line-height: 1.45; }
      @media (min-width: 1220px) {
        .media-kit__header .featured-article p {
          max-width: 40%; } }
  .media-kit__header .featured-article__cta {
    margin: 25px 0; }
    .media-kit__header .featured-article__cta .cta--link {
      color: #009FDB; }
      @media (min-width: 1220px) {
        .media-kit__header .featured-article__cta .cta--link {
          font-size: 21px;
          font-size: 2.1rem;
          font-weight: 400; } }
  .media-kit__header .featured-category {
    text-transform: uppercase;
    color: #FFF;
    font-size: 24px;
    font-size: 2.4rem;
    font-family: "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 300; }
    .media-kit__header .featured-category b,
    .media-kit__header .featured-category strong {
      font-weight: 700;
      color: #009FDB; }
      .region--blue .media-kit__header .featured-category b, .region--blue
      .media-kit__header .featured-category strong {
        color: #000; }
      .region--dark-blue .media-kit__header .featured-category b,
      .region[class*="-black"] .media-kit__header .featured-category b, .region--dark-blue
      .media-kit__header .featured-category strong,
      .region[class*="-black"]
      .media-kit__header .featured-category strong {
        color: #FFF; }
  .media-kit__header.media-kit__header--light .featured-category {
    color: #000; }

/* Media Kit Landing Styles */
/* ======================== */
.media-kit-landing .media-kit__header {
  padding: 0;
  background-color: #000;
  color: #FFF; }
  .media-kit-landing .media-kit__header.media-kit__header--light {
    background-color: #FFF;
    color: #000; }
  .media-kit-landing .media-kit__header .featured-image {
    overflow: hidden; }
    .media-kit-landing .media-kit__header .featured-image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
      @media (max-width: 1023px) {
        .media-kit-landing .media-kit__header .featured-image img {
          width: 100%; } }
  .media-kit-landing .media-kit__header .featured-article {
    padding: 40px 20px; }
    @media (min-width: 768px) {
      .media-kit-landing .media-kit__header .featured-article {
        padding-left: 55px;
        padding-right: 55px; } }
    @media (min-width: 768px) {
      .media-kit-landing .media-kit__header .featured-article {
        position: relative;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        max-width: 1220px;
        width: 100%;
        height: 100%; } }
    @media (min-width: 1440px) {
      .media-kit-landing .media-kit__header .featured-article {
        padding-top: 65px;
        padding-left: 0;
        padding-right: 0; } }
    .media-kit-landing .media-kit__header .featured-article .featured-headline {
      font-size: 35px;
      font-size: 3.5rem;
      padding-top: 30px;
      line-height: 1.15; }
      @media (min-width: 1024px) {
        .media-kit-landing .media-kit__header .featured-article .featured-headline {
          font-size: 50px;
          font-size: 5rem;
          padding-top: 60px; } }
      @media (min-width: 1220px) {
        .media-kit-landing .media-kit__header .featured-article .featured-headline {
          font-size: 60px;
          font-size: 6rem;
          padding-bottom: 50px; } }
      @media (min-width: 1440px) {
        .media-kit-landing .media-kit__header .featured-article .featured-headline {
          max-width: 50%; } }
    @media (min-width: 768px) and (max-width: 1219px) {
      .media-kit-landing .media-kit__header .featured-article .featured-headline,
      .media-kit-landing .media-kit__header .featured-article .featured-description {
        max-width: 70%; } }

.media-kit-landing .media-kit__wrap .featured-stories {
  padding-top: 20px;
  padding-bottom: 20px; }
  @media (min-width: 1220px) {
    .media-kit-landing .media-kit__wrap .featured-stories {
      padding-bottom: 60px; } }

/* Media Kit Page Styles */
/* ===================== */
.media-kit-page .media-kit__header .featured-article {
  padding: 40px 0 30px; }
  @media (min-width: 768px) {
    .media-kit-page .media-kit__header .featured-article {
      padding: 50px 0; } }
  @media (min-width: 1024px) {
    .media-kit-page .media-kit__header .featured-article {
      padding: 80px 0; } }

.media-kit-page .media-kit__header .featured-category {
  color: #000; }

.media-kit-page .media-kit__header .featured-category b,
.media-kit-page .media-kit__header .featured-category strong {
  color: #009FDB; }

.media-kit-page .media-kit__header .featured-headline {
  padding-bottom: 20px; }

.media-kit-page .media-kit__header .col {
  margin-left: auto;
  margin-right: auto; }

/* Component Style Overrides */
.media-kit {
  /* Article */ }
  .media-kit .article {
    padding-bottom: 40px; }
    @media (min-width: 1024px) {
      .media-kit .article {
        padding-bottom: 80px; } }
  .media-kit .article__header {
    border-bottom: 0; }
  .media-kit .article__meta {
    margin-bottom: 20px; }
    .media-kit .article__meta .meta__info {
      max-width: none; }
      .media-kit .article__meta .meta__info .date {
        font-weight: 700; }
  .media-kit .article__content {
    padding-top: 0; }

/*=================================
  ALL POSTS/SEARCH RESULTS TEMPLATE
  =================================*/
.all-posts .region__wrap {
  max-width: 810px;
  padding: 0 20px; }
  @media (min-width: 768px) {
    .all-posts .region__wrap {
      padding: 0 55px; } }
  @media (min-width: 1440px) {
    .all-posts .region__wrap {
      padding: 0; } }

.all-posts .headline {
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 40px; }

.all-posts .search-controls {
  margin: 40px 0; }
  .all-posts .search-controls:after {
    content: "";
    display: table;
    clear: both; }

.all-posts form input {
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 1024px) {
    .all-posts form input {
      font-size: 16px;
      font-size: 1.6rem; } }

.all-posts .filters {
  margin-bottom: 10px; }
  @media (min-width: 640px) {
    .all-posts .filters {
      float: none;
      margin-bottom: 0;
      padding: 5px 0px 30px 0px; } }
  .all-posts .filters form label {
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 5px; }
  .all-posts .filters form select {
    min-height: 25px;
    min-width: 100px; }

.all-posts .search {
  position: relative;
  float: left; }
  @media (min-width: 640px) {
    .all-posts .search {
      border-left: 1px solid #C9C9C9;
      padding-left: 18px; } }
  .all-posts .search form label {
    display: none; }
  .all-posts .search form input.txt {
    height: 23px;
    line-height: 23px;
    padding-left: 5px;
    border-radius: 4px;
    border: 1px solid #C9C9C9;
    font-family: "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif;
    font-weight: 400; }
    @media (min-width: 640px) {
      .all-posts .search form input.txt {
        min-width: 200px; } }
  .all-posts .search form .search__submit {
    position: absolute;
    height: 23px;
    width: 23px;
    right: 0;
    top: 0;
    background-color: transparent; }
    .all-posts .search form .search__submit .icon--search {
      font-size: 20px;
      font-size: 2rem;
      color: #009FDB; }
      .all-posts .search form .search__submit .icon--search:after {
        transform: translate(-50%, -50%) rotateY(-180deg); }

.all-posts .text {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0; }

.all-posts .posts {
  border-top: 1px solid #C9C9C9;
  padding: 40px 0; }
  .all-posts .posts [class^="page"] {
    margin-bottom: 60px; }
  .all-posts .posts .timestamp {
    margin-bottom: 12px; }
  .all-posts .posts .meta,
  .all-posts .posts .categories {
    display: none; }
  .all-posts .posts h2 {
    font-size: 20px !important;
    font-family: "Aleck Sans Black", "Aleck Sans Medium", "Aleck Sans", "Arial", "Helvetica", sans-serif !important;
    font-weight: 400 !important;
    margin-bottom: 12px !important;
    line-height: 1.25 !important; }
  .all-posts .posts h2 + p {
    margin-bottom: 15px;
    line-height: 1.35; }
  .all-posts .posts a {
    color: #009FDB; }

.all-posts .search-cta {
  text-align: center; }
  .all-posts .search-cta .cta--button {
    margin: 0 10px; }
    @media (max-width: 639px) {
      .all-posts .search-cta .cta--button {
        min-width: 120px;
        padding: 15px 25px 17px; } }

/*==============
  EDITABLE TEMPLATES
  ==============*/
/* Three Column Items */
.article-header--row .item__content {
  padding: 0px; }

.article-header--row .item__cta {
  margin: 0px; }

.article-header--row .share {
  margin-top: 15px; }

.article-header--row .story__assets {
  margin-top: 15px; }
  .article-header--row .story__assets select {
    max-width: 85%;
    min-width: 200px;
    font-size: 16px;
    font-size: 1.6rem; }

@media (min-width: 768px) {
  .article-header--row .featureditem {
    text-align: right; } }

@media (max-width: 767px) {
  .article-header--row .share {
    width: 100% !important;
    margin-top: 0px; }
  .article-header--row .story__assets {
    width: 100% !important;
    margin-top: 0px; }
    .article-header--row .story__assets select {
      max-width: 33%; }
  .article-header--row .featureditem {
    width: 100% !important;
    margin-top: 6px; } }
