/*=======================
  CALL-TO-ACTION ELEMENTS
  =======================*/

button {
  font-family: $aleck;
}

/* Base CTA Wrappers */

[class$="__cta"] {
  margin: $air--small 0 $air--xSmall;

  // @media (min-width: $breakpoint--medium) {
  //   margin: $air--small 0 $air--xSmall;
  // }

  // @media (min-width: $breakpoint--xxLarge) {
  //   margin-top: $air--large;
  // }
}

/* Base CTA */

.cta--button,
.cta--social {
  // @include no-font-smoothing;
  text-decoration: none;
}

/* Button-style CTAs */

.cta--button,
.cta--button-new {
  @include transitionAll(300ms);
  display: inline-block;
  padding: 15px 30px 17px;
  text-decoration: none;
  text-align: center;
  font-family: $aleck;
  line-height: 1;
  cursor: pointer;

  @media (min-width: $breakpoint--medium) {
    @include font-size(19);
    min-width: 170px;
  }

  .col-2 & {
    @media (min-width: $breakpoint--medium) {
      min-width: 100%;
    }
  }

  .col-3 & {
    @media (min-width: $breakpoint--medium) and (max-width: $breakpoint--xLarge - 1) {
      min-width: 100%;
    }
  }

  .col-4 & {
    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
      min-width: 100%;
    }
  }
}

.cta--button {
  @include font-size(16);
  @include cta("blue");
  min-width: 150px;
  border-radius: 45px;
  font-weight: 700;

  @each $name, $color in $backgroundColors {
    .region--#{$name} & {
      @if (nth($color, 2) == $functionalBlack) {
        @include cta("black");
      }
      @if (nth($color, 2) == $white) {
        @include cta("white");
      }
    }
  }
  .region--white &, .region--gray &, .region--black &, .region--near-black & {
    @include cta("blue");
  }
}

.cta--button-new {
  @include font-size(14);
  @include accent-arrow("right");
  min-width: 130px;
}

/* Link-style CTAs */

.cta--link {
  @include font-size(16);
  @include accent-arrow("right");
  position: relative;
  color: $color--link;
  text-decoration: none;
  font-family: $aleck;
  font-weight: 700;
  line-height: 2.3rem;

  &:hover {
    text-decoration: underline;
  }

  .region--blue & {
    color: $color--copy;
  }

  .region--dark-blue & {
    color: $white;
  }
}

/* Social-style CTAs */

.cta--social, .region .cta--social {
  @include cta("black");
  @include font-size(30);
  @include transitionAll(300ms);
  @include font-smoothing;
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 100%;

  @media (min-width: $breakpoint--small) {
    @include font-size(45);
    height: 64px;
    width: 64px;
  }
  .region--white &, .region--gray &, .region--black &, .region--near-black & {
    @include cta("blue");
  }
}