/*======
  SEARCH
  ======*/

  .search-field {
    box-sizing: border-box;

    .col & {
      padding: 0;
    }

    @media (min-width: $breakpoint--small) {
      padding: 0;
    }

    fieldset {
      @include clearfix;
      position: relative;
      max-width: 500px;
      margin: 0 auto;
      border-radius: 30px;
      border: 1px solid;
      overflow: hidden;

      .region[class*="white"] &,
      .region[class*="gray"] &,
      .region[class*="black"] &,
      .sidebar & {
        border-width: 2px;
      }

      .region[class*="white"] &,
      .sidebar & {
        border-color: #EAEAEA;
      }

      .region[class*="gray"] & {
        border-color: $regGray;
      }

      .region[class*="black"] & {
        border-color: $lgtGray;
      }
    }

    label {
      @include hidden-spoken;
    }

    input,
    button {
      float: left;
      height: 37px;
      color: inherit;
      background-color: transparent;
      box-sizing: border-box;
      border: 0;

      @media (min-width: $breakpoint--medium) {
        .col-2 &,
        .col-3 &,
        .col-4 & {
          height: 33px;
        }
      }
    }

    /* Search Form */

    .search__form,
    & > form {
      padding: 0 20px;

      .sidebar & {
        padding: 0;
      }
    }

    /* Search Input Field */

    .search__input {
      padding: 6px 0 8px 15px;
      width: calc(100% - 30px);

      @media (min-width: $breakpoint--small) {
        width: calc(100% - 40px);
      }

      @media (min-width: $breakpoint--medium) {
        .col-2 &,
        .col-3 &,
        .col-4 & {
          padding: 4px 0 6px 15px;
        }
      }
    }

    /* Search Submit Button */

    .search__submit {
      position: absolute;
      min-width: 25px;
      right: 5px;

      @media (min-width: $breakpoint--small) {
        right: 10px;
      }

      @media (min-width: $breakpoint--medium) {
        .col-2 &,
        .col-3 &,
        .col-4 & {
          right: 8px;
        }
      }

      span {
        height: 100%;
        width: 100%;

        &:after {
          @include font-size(30);
          top: 52%;

          @media (min-width: $breakpoint--small) {
            @include font-size(40);
          }

          @media (min-width: $breakpoint--medium) {
            .col-2 &,
            .col-3 &,
            .col-4 & {
              @include font-size(34);
            }
          }

          .region[class*="white"] &,
          .region[class*="gray"] &,
          .sidebar & {
            color: $color--cta;
          }

          .region[class*="black"] & {
            color: $lgtGray;
          }
        }
      }
    }

    .search__wrap {
      display: none;
    }

    &[data-inline="true"] .search__wrap {
      display: block;
    }

    /* Search Results */

    .search__results {
      display: none;
      max-width: $maxContentWidth;
      margin: 0 auto;

      [class^="page"] {
        margin: 20px;
        padding: 40px 0;
        border-top: 1px solid;

        @media (min-width: $breakpoint--small) {
          margin-left: $gutter--small;
          margin-right: $gutter--small;
        }

        @media (min-width: $breakpoint--xLarge) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .results__heading {
        @include font-size(25);
        margin-top: 40px;
        padding-bottom: 20px;
      }

      .result {
        margin-bottom: 50px;

        &__timestamp {
          margin-bottom: 12px;
        }

        &__title {
          @include font-size(20);
          font-family: $aleckBlack;
          font-weight: 400;
          margin-bottom: 12px;
          line-height: 1.25;
        }

        &__description {
          margin-bottom: 15px;
          line-height: 1.5;
        }
      }

      .no-results {
        margin: $air--large 0;
      }

    }

    /* Search CTA */

    .search__cta {
      justify-content: center;

      .cta--button {
        display: none;
        margin: 0 10px;
      }
    }
  }

/* Search Widget */
/*
#wgt-custom-select-search input[type="search"]::-webkit-search-decoration,
#wgt-custom-select-search input[type="search"]::-webkit-search-cancel-button,
#wgt-custom-select-search input[type="search"]::-webkit-search-results-button,
#wgt-custom-select-search input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
#wgt-custom-select-search {
	input {
		background-color: $white;
		border: 1px solid #cecece;
  }
  button:focus{
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  }
	[class^="span"] {
		width: 100%;
		color: $functionalBlack;
		font-family: $aleck;
		line-height: 2rem;
		box-sizing: border-box;
		padding-top: 10px;
		padding-bottom: 10px;
	}
  
	.field-group {
		position: relative;
		display: block;
  }
  
	ul {
		.hrLine {
			overflow: hidden;
			text-align: left !important;
			font-size: 15px !important;
			color: #94989c;
			margin-bottom: 20px !important;
			margin-top: 0px !important;
			padding: 4px 16px 4px 16px !important;
      font-family: $aleckMedium;
      
			&:after {
				content: "";
				display: inline-block;
				position: relative;
				vertical-align: middle;
				border: 0px;
				padding-right: 16px;
			}
		}
		li {
			padding: 16px 16px;
      border-bottom: 1px solid #cecece;

      &:first-of-type {
        padding-top: 0;
      }

			&:last-child {
        border-bottom: 0;

        a,
        .keyspan {
					font-weight: 600;
					color: #0080b1 !important;
				}
				img {
					display: none;
				}
			}
		}
	}
	.search-list {
    list-style: none;
		position: relative;
		width: 100%;
		border: 1px solid #cecece;
		padding-top: 12px;
		padding-bottom: 25px;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		margin-top: 10px;
    display: block;
  }
  .search-list .search-list{
    border:transparent;
  }
	.form-row {
		margin: 0 auto;
		width: 100%;
		margin-top: 15px;
	}
  a, 
  .keyspan {
    @include font-size(15);
		color: #009fdb;
		text-decoration: none;
		font-family: $aleckMedium;
	}
	input[type="search"] {
    @include font-size(16);
    padding: 10px 50px 10px 15px;
    border-radius: 5px;
    width: 100%;
    height: 43px;
		padding-right: 40px;
		text-overflow: ellipsis;
	}
	button[type="submit"] {
		position: absolute;
    background-color: transparent;
    border: none;
    width: 4.4rem;
    height: 43px;
    padding: 3px 0 0 0;
    right: 0;
    margin: 0;
    outline-offset: 0;
    border-radius: 0 1.5rem 1.5rem 0;
	}
}

.story #wgt-custom-select-search {
  .form-row {
    margin-top: 0px;
  }
}

#wgt-custom-select-search-widget {
	position: relative;
}
#wgt-custom-select-searchfield-predictionBucket {
	margin: 0 auto 25px;
	width: 100%;
}

.search-widget--shortened #wgt-custom-select-search,
.search-widget--shortened #wgt-custom-select-searchfield-predictionBucket {
  max-width: 610px; 
  margin-left: auto;
  margin-right: auto; 
}
*/
