/*========
  LIGHTBOX
  ========*/

  /* Fancybox modals */

  .fancybox-container {
    height: 100vh;
    width: 100vw;
    bottom: 0;
    right: 0;
    z-index: 999999999;
  }

  .fancybox-bg {
    background: $black;

    .fancybox-is-open & {
      opacity: $lightboxOpacity;
    }
  }

  .fancybox-content {
    max-width: 100% !important;
    background-color: $black;
    width: 100%;
  }

  .fancybox-slide--image,
  .fancybox-slide--video {
    padding: 35px 20px 20px;

    @media (min-width: $breakpoint--small) {
      padding: 60px 65px 30px 55px;
    }

    @media (min-width: $breakpoint--medium) {
      padding: 90px 0 45px;
    }
  }

  .fancybox-slide--video .fancybox-content {
    overflow: initial !important;
  }

  .fancybox-slide--image {
    position: relative !important;
  }

  .fancybox-infobar,
  .fancybox-toolbar {
    display: none;
  }

  /* GVP modals */

  [class^="gvp_player__closeButton"][class*="gvp_player__modal"],
  .fancybox-custom-close {
    display: block;
    position: absolute;
    height: 19px;
    right: 0 !important;
    top: -35px !important;
    width: 77px;
    color: $white;

    &:hover {
      text-decoration: none;
    }

    &:before,
    &:after {
      position: absolute;
      font-family: $aleck;
    }

    &:before {
      content: "+";
      font-size: 3.75rem;
      transform: translate(5px, -8px) rotate(45deg);
    }

    &:after {
      content: "close";
      font-size: 2rem;
      font-weight: 300;
      right: 0;
    }
  }

  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:before {
    top: -8px;
    left: 0;
  }

  [class^="gvp_player__closeButton"][class*="gvp_player__modal"]:after {
    top: -5px;
  }

  [class^="gvp_player__view_"][class*="gvp_player__modal"] {
    background: rgba(0, 0, 0, $lightboxOpacity) !important;
    padding: 0 20px;
  }

  [class^="gvp_player__closeButton"][class*="gvp_player__modal"] {
    max-width: none !important;
    max-height: none !important;

    svg {
      display: none;
    }
  }
