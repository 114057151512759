/**
 * AT&T About Us for AEM
 * by FleishmanHillard, c. 2018
 *
 * NOTE: ATTN DEVS!
 * Please use this stylesheet for importing SASS partials only. Do not author any SASS or CSS directly in this file.
 * Also, please first refer to the _abstracts partials to review the existing global style settings and utilities before creating new variables, mixins, etc.
 *
 * For style guidelines and coding conventions for this project,
 * please consult the README.md in the root of the project.
 */

/*====
  MAIN
  ====*/

  @charset 'utf-8';

  /* Import core component styles statically */
  @import url("core_components.css");

/*=========
  Abstracts
  =========*/

  @import '_abstracts/variables';
  @import '_abstracts/mixins';

/*====
  Base
  ====*/

  @import '_base/reset';
  @import '_base/base';
  @import '_base/typography';
  @import '_base/cta';
  @import '_base/bookmark';

/*======
  Layout
  ======*/

  @import '_layout/layout';
  @import '_layout/header';
  @import '_components/nav';
  @import '_layout/footer';
  @import '_layout/article';
  @import '_layout/share';
  @import '_layout/form';
  @import '_layout/modal';

/*======
  Vendor
  ======*/

  @import '_vendor/flatpickr';
  @import '_vendor/flickity';
  @import '_vendor/jquery.fancybox';
  @import '_vendor/jquery.dataTables.scss';
  @import '_vendor/responsive.dataTables.scss';

/*==========
  Components
  ==========*/

  @import '_components/alert';
  @import '_components/banner';
  @import '_components/brand-assets';
  @import '_components/featured-item';
  @import '_components/facts-stats';
  @import '_components/expand-collapse';
  @import '_components/expandable-text';
  @import '_components/latest-stories';
  @import '_components/lightbox';
  @import '_components/marquee';
  @import '_components/media';
  @import '_components/media-carousel';
  @import '_components/media-library';
  @import '_components/microsite-nav';
  @import '_components/ordered-featured-items';
  @import '_components/pledge';
  @import '_components/related-news';
  @import '_components/rss-feed';
  @import '_components/search';
  @import '_components/social-channels';
  @import '_components/stock-ticker';
  @import '_components/story-assets';
  @import '_components/table';
  @import '_components/tabs';
  @import '_components/text';
  @import '_components/tile';
  @import '_components/user-survey';
  @import '_components/user-survey-report';
  @import '_components/video-library';

/*=========
  Templates
  =========*/

  @import '_templates/story';
  @import '_templates/blog';
  @import '_templates/media-kit';
  @import '_templates/all-posts-search-results';
  @import '_templates/editable';
