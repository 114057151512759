/*==============
  LATEST STORIES
  ==============*/

.latest-stories {
  max-width: $maxRowWidth;
  margin: 0 auto;
  padding-bottom: 40px;

  .stories__filter-nav {
    @include clearfix;
    display: none;
    margin-bottom: 20px;

    @media (max-width: $breakpoint--small - 1) {
      background-color: transparent;
      border-bottom: 1px solid $white;
      @each $name, $color in $backgroundColors {
        .region--#{$name} & {
          @if (nth($color, 2) == $functionalBlack) {
            border-bottom: 1px solid $black;
          }
        }
      }
      .region--white &,
      .region--gray & {
        background-color: $attBlue;
        border-bottom: 1px solid $white;
      }
    }

    @media (min-width: $breakpoint--small) {
      padding: 0 $gutter--small;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }

    .filter-toggle,
    .filter-tags {
      @media (min-width: $breakpoint--small) {
        float: left;
      }
    }

    .filter-toggle h4,
    li a {
      text-decoration: none;
      @media (min-width: $breakpoint--small) {
        @include font-size(14);
        padding: 7px 0;
      }
    }

    .filter-toggle {
      text-decoration: none;
      cursor: pointer;
      @media (max-width: $breakpoint--small - 1) {
        .region--white &, .region--gray &, .region[class*="black"] & {
          color:$white;
        }
      }

      @media (min-width: $breakpoint--small) {
        cursor: default;
        color: inherit;
      }

      h4 {
        @media (max-width: $breakpoint--small - 1) {
          position: relative;
          padding: 20px;
          font-family: $aleckMedium;

          &:after {
            content: "";
            position: absolute;
            top: 25%;
            right: 25px;
            width: 19px;
            height: 19px;
            border-top: 2px solid $white;
            border-left: 2px solid $white;
            border-radius: 3px;
            transform: rotate(-135deg);
            @each $name, $color in $backgroundColors {
              .region--#{$name} & {
                @if (nth($color, 2) == $functionalBlack) {
                  border-top: 2px solid $functionalBlack;
                  border-left: 2px solid $functionalBlack;
                }
              }
            }
            .region--white &, .region--gray & {
              border-top: 2px solid $white;
              border-left: 2px solid $white;
            }
          }
        }

        @media (min-width: $breakpoint--small) {
          .region[class*="blue"] &,
          .region[class*="black"] & {
            font-family: $aleckMedium;
          }
        }
      }
    }

    .filter-tags {
      display: none;

      @media (min-width: $breakpoint--small) {
        display: block !important;
        margin-left: 15px;

        .col-6 &,
        .col-4 &,
        .col-3 &,
        .col-2 & {
          margin-left: 0;
        }
      }

      @media (min-width: $breakpoint--medium) {

      }

      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        .col-9 & {
          margin-left: 0;
        }
      }

      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
        .col-8 & {
          margin-left: 0;
        }
      }

      li {
        @media (max-width: $breakpoint--small - 1) {
          border-top: 1px solid $white;
          @each $name, $color in $backgroundColors {
            .region--#{$name} & {
              @if (nth($color, 2) == $functionalBlack) {
                border-top: 1px solid $black;
              }
            }
          }
        }
        .region--white &, .region--gray & {
          border-top: 1px solid $white;
        }

        @media (min-width: $breakpoint--small) {
          display: inline-block;
          margin: 0 10px;

          .col-6 &,
          .col-4 &,
          .col-3 &,
          .col-2 & {
            margin-left: 0;
          }
        }

        @media (min-width: $breakpoint--medium) {
          margin: 0 15px;
        }

        @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
          .col-9 &,
          .col-8 & {
            margin-left: 0;
          }
        }
      }

      li a {
        @include font-size(14);
        display: inline-block;
        font-family: $aleckMedium;

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $breakpoint--small - 1) {
          width: 100%;
          padding: 20px;
          .region--white &, .region--gray &, .region[class*="black"] & {
            color:$white;
          }

          &.active,
          &:hover {
            @each $name, $color in $backgroundColors {
              .region--#{$name} & {
                @if (nth($color, 2) == $functionalBlack) {
                  background-color: $functionalBlack;
                  color:$white;
                }
                @if (nth($color, 2) == $white) {
                  background-color: $white;
                  color:$functionalBlack;
                }
              }
            }
            .region--white &, .region--gray &, .region[class*="black"] & {
              color:$white;
              background-color: $color--cta-hover;
            }
          }
        }

        @media (min-width: $breakpoint--small) {
          padding-bottom: 5px;
          color: inherit;

          &.active,
          &:hover {
            @each $name, $color in $backgroundColors {
              .region--#{$name} & {
                @if (nth($color, 2) == $functionalBlack) {
                  color:$functionalBlack;
                   border-bottom: 2px solid $functionalBlack;
                }
                @if (nth($color, 2) == $white) {
                  color:$white;
                   border-bottom: 2px solid $white;
                }
              }
            }
            .region--white &, .region--black &, .region--near-black & {
              color: $color--cta;
              border-bottom: 2px solid $color--cta;
            }
            .region--gray & {
              color: $color--link;
               border-bottom: 2px solid $color--link;
            }
          }
        }
      }
    }

    &.stories__filter-nav--open {
      .filter-toggle h4:after {
        transform: rotate(-315deg);
        top: 40%;
      }

      .filter-tags {
        display: block;
      }
    }
  }

  .stories__feed {
    .row + .row {
      margin-top: 0;
    }

    .col {
    //   @media (min-width: $breakpoint--small) {
    //     @include column(6);
    //     // padding-bottom: 20px;

    //     .col-6 &,
    //     .col-4 &,
    //     .col-3 &,
    //     .col-2 & {
    //       max-width: none;
    //     }
    //   }

    //   @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
    //     .col-9 &,
    //     .col-8 & {
    //       max-width: none;
    //     }
    //   }

      @media (min-width: $breakpoint--large) {
        // padding-bottom: 40px;
      }

    }

    .component {
      @media (min-width: $breakpoint--small) {
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
      //
      // @media (min-width: $breakpoint--medium) {
      //   margin-bottom: 40px;
      // }
    }

    .item__media {
      max-height: 450px;
    }

    .item__media .item__img[data-placeholder="true"] {
      // min-height: 85px;
      height: 100%;

      img {
        display: block;
      }
    }

    .item__media .item__img[data-placeholder="true"] a:after {
      text-align: center;
      font-size: 12rem;

      @media (min-width: $breakpoint--xSmall) {
        font-size: 16rem;
      }
    }

    .item__title a {
      text-decoration: none;
      color: inherit;
    }

    .item__info {
      @include font-size(16);
      font-family: $aleckMedium;
    }

    .item__description {
      max-width: 500px;
      margin-top: 30px;
    }
  }

  .stories__cta {
    display: none;
    text-align: center;
    margin: 20px 0 60px;

    @media (min-width: $breakpoint--medium) {
      margin: 40px auto 80px;
    }
  }

  &.latest-stories--list {
    // .stories__filter-nav {
    //   display: none;
    // }

    .stories__heading {
      text-align: left;
    }

    .stories__feed {
      .col {
        padding-bottom: 5px;
        max-width: none;
        flex-basis: auto;
      }

      .component {
        height: auto;

        @media (min-width: $breakpoint--small) {
          padding-bottom: 0;
          margin-bottom: 10px;
        }
      }

      .item__media,
      .item__description,
      .item__cta {
        display: none;
      }

      .item__content,
      .col-2 & .item__content {
        background-color: transparent;
        padding: 0 !important;
      }

      .item__content .content__wrap {
        max-width: 85%;
      }

      .item__info {
        color: inherit !important;
      }

      .item__title a {
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              color: $functionalBlack;
            }
            @if (nth($color, 2) == $white) {
             color: $white;
            }
          }
        }
        .region--white &, .region--gray &, {
          color: $color--link;
        }
        .region[class*="black"] & {
          color: $color--cta;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .item__info {
        font-family: $aleckMedium;
      }
    }

    .stories__cta {
      margin: 10px 0;
      padding: 0 $air--xSmall;
      text-align: left;

      @media (min-width: $breakpoint--small) {
        margin: 30px 0;
        padding: 0 $gutter--small;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }
  }
}
