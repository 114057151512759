/*=====
  TABLE
  =====*/

 .SNR_table {
    max-width: $maxContentWidth;
    margin: 0 auto;
    width:100%;
    overflow: hidden;

    // .table__heading {
    //   @media(max-width: $breakpoint--small - 1) {
    //     margin-bottom: 0;
    //   }
    // }

    .dataTables_wrapper {
      padding:0 20px;
      @media (min-width: $breakpoint--small) {
        padding: 0px 55px;
      }
      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }

    .dataTable {
      margin-bottom:20px;
      border-bottom: 1px solid $regGray;
      line-height:1.45;
      @media (min-width: $breakpoint--small) {
        //margin:0 -20px;
      }
      tr[role="row"] {
        @media(max-width: $breakpoint--small - 1) {
          border-top: 1px solid $regGray;
          display:block;
          td {
            display:block;
          }
        }
      }
      thead {
        tr[role="row"] {
          border:0;
          padding: 20px 25px;
        }
        th {
          @include font-size(18);
          text-align: left;
          font-family:'Aleck Sans Black', 'Aleck Sans', Arial, Helvetica, sans-serif;
          font-weight:normal;
          border:0;
          padding:0;
          vertical-align: bottom;
          @media (min-width:768px) {
            @include font-size(21);
            padding: 15px 15px;
          }
        }
      } 
      tbody {
        tr {
          background-color:transparent;
        }
        td {
          @include font-size(18);
          padding: 15px 20px;
          @media (min-width:768px) {
            @include font-size(21);
            //width:100px;
            border-top: 1px solid $regGray;
            border-left: 1px solid $regGray;
            //border-collapse: collapse;
            &.all {
              border-left:0;
            }
          }
        }
      } 
      tr.child {
        padding:0;
      }
      td.child {
        padding: 0 25px 10px; 
        line-height:1.45;
        border: 0;

        .dtr-details {
          padding-left: 30px;
        }
        .dtr-details li {
          border-bottom:0;
        }
        .dtr-title {
          display:block;
          font-family:'Aleck Sans Black', 'Aleck Sans', Arial, Helvetica, sans-serif;
        }
      }
      th .component,
      td .component,
      th .text .text__wrap p:first-child:last-child,
      td .text .text__wrap p:first-child:last-child {
        margin-bottom: 0;
      }
      th .text,
      td .text {
        padding: 0;
        -ms-word-break: break-all;
     word-break: break-all;
     word-break: break-word;
-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
      }
    }
    &.table--zebra-stripe {
      tr.even {
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (length($color) == 2 and $name != "comms-lime-mint" and $name != "comms-berry-tangerine" and $name != "comms-cobalt-purple") {
              @if (nth($color, 2) == $functionalBlack) {
                background-color: adjust-color(nth($color, 1), $lightness: 4%);
              }
              @if (nth($color, 2) == $white) {
                background-color: adjust-color(nth($color, 1), $lightness: -4%);
              }
              @if (length($color) == 2 and $name == "comms-purple" or $name == "comms-berry" or $name == "comms-tangerine" or $name == "comms-lime") {
                background-color: adjust-color(nth($color, 1), $lightness: -3%);
              }
              @if (length($color) == 2 and $name == "comms-mint") {
                background-color: adjust-color(nth($color, 1), $lightness: -10%);
              }
            }
          }
        }
        .region--white & {
          background-color:$lgtGray;
        }
        .region[class*="black"] & {
          background-color:#333333;
        }
        + tr.child {
          display:block;
          margin-top:10px;
        }
      }

      
    }
    @each $name, $color in $backgroundColors {
      .region--#{$name} & {
        @if (nth($color, 2) == $functionalBlack) {
          .dataTable, tbody td, tr[role="row"] {
            border-color: $functionalBlack;
          }
        }
        @if (nth($color, 2) == $white) {
          .dataTable, tbody td, tr[role="row"] {
            border-color: $white;
          }
        }
      }
    }
    .region--white &, .region--gray & {
      .dataTable, tbody td, tr[role="row"] {
        border-color: $regGray;
      }
      .dataTable td.child {
        border:0;
      }
    }
  }
    
//First col in each row
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  padding-left: 55px;

  &:before {
    //Plus/Minus for rows in mobile
    @include font-size(26);
    @include transitionAll(300ms);
    color:inherit;
    border:0;
    background-color:transparent;
    box-shadow:none;
    position:absolute;
    top:50%;
    left: 20px;
    transform: translateY(-50%);
    font-family:'Aleck Sans', Arial, Helvetica, sans-serif;
    line-height:12px;
  }
}

//Plus sign when row is expanded
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: '+';
  @include font-size(28);
  @include transitionAll(300ms);
  transform: translateY(-50%) rotate(45deg);
}