/*======
  LAYOUT
  ======*/

  .content,
  .region,
  .region__wrap,
  .row {
    width: 100%;
    margin: 0 auto;
  }

  .region,
  .region__wrap,
  .row,
  .col {
    box-sizing: border-box;
    position: relative;
  }

  .region__wrap,
  .row__wrap,
  .col {
    min-height: 20px; /* for empty parsys containers that might otherwise collapse */
  }

  /* Page */

  .content {
    position: relative;
  }

  /* Sections (note that the class name had to be changed from "section" to "region") */

  .region {
    overflow: hidden;
    padding: 30px 0;

    @media (min-width: $breakpoint--small) {
      padding: $air--small 0;
    }

    @media (min-width: $breakpoint--large) {
      padding: $air--xLarge 0;
    }
  
    @each $size, $height in $sectionSpacing {
      .#{$size}-spacing & {
        padding: nth($height, 1) 0;
        @media (min-width: $breakpoint--small) {
          padding: nth($height, 2) 0;
        }
        @media (min-width: $breakpoint--large) {
          padding: nth($height, 3) 0;
        }
      } 
    }

    /* Make sure sections inside of the equal-height rows are full height sections */

    .row--equal-heights & {
      @media (min-width: $breakpoint--small) {
        height: 100%;
      }
    }

    /* Remove padding from vertically flush sections */

    &.region--flush-vertical {
      padding: 0;

      & .section:last-of-type > .row:last-of-type > .col:last-of-type > .component:last-of-type,
      & .section:last-of-type > .component:last-of-type,
      & .region__wrap > .component:last-of-type {
        margin-bottom: 0;
      }
    }

    /* Apply any bottom borders to sections, as long as they aren't vertically flush sections */

    &.region--border-bottom {
      &:after {
        @include rule("horizontal");
        background-color: $regGray;
        max-width: $maxContentWidth;
        bottom: 0;
        width: calc(100% - 40px);
        height: 1px;

        @media (min-width: $breakpoint--small) {
          width: calc(100% - 110px);
        }

        @media (min-width: $breakpoint--large) {
          width: auto;
        }
      }
    }

    &.region--border-bottom.region--flush-vertical {
      &:after {
        max-width: none;
      }
    }

    /* Loop through possible section background colors */

   @each $name, $color in $backgroundColors {
      @if (length($color) == 3) {
        &.region--#{$name} {
          background:nth($color, 1);
          background:linear-gradient(to right, nth($color, 1) 0%, nth($color, 3) 100%);
          color:nth($color, 2);
        }

        // Add an additional special case for comms blue gradients to change the gradient angle

        @if($name == 'blue-gradient--wh-text' or 'blue-gradient--bl-text' ) {
          &.region--#{$name} {
            background:linear-gradient(125deg, nth($color, 1) 0%, nth($color, 3) 100%);
          }
        }

      }
      @else {
        &.region--#{$name} {
          background-color: nth($color, 1);
          color:nth($color, 2);
        }
      }
    }
  }

  /* Constrain the width of the section wrapper to the maximum page width */

  .region__wrap {
    max-width: $maxPageWidth;
  }

  /* Rows */

  .row {
    @include row($maxRowWidth);
    margin: 0 auto;

    @media (min-width: $breakpoint--small) {
      margin-top: $air--xSmall;
      padding: 0 $gutter--small;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }

    /* Remove top margin from first row in section */

    .region &:first-of-type {
      margin-top: 0;
    }

    /* Remove all side margins from horizontally flushed rows */

    &.row--flush-horizontal {
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }

    /* Remove top margin from top-flushed rows */

    &.row--flush-top {
      margin-top: 0;
    }

    /* Remove gutters from columns inside no-gutter rows */

    &.row--no-gutters {
      .row__wrap {
        margin-left: 0;
        margin-right: 0;
      }
      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    /* Add gutter borders to columns inside gutter-border rows */

    &.row--gutter-borders {
      .col {
        position: relative;

        /* Start all gutter borders as horizontal borders */

        @media (max-width: $breakpoint--small - 1) {
          margin-bottom: $air--large;
          padding-bottom: $air--large;

          &:not(:last-child):before {
            @include rule("horizontal");
          }
        }

        /* At larger viewport size, switch them to the vertical format */

        @media (min-width: $breakpoint--small) {
          &.col-6:not(:first-child):before {
            @include rule("vertical");
          }
        }

        @media (min-width: $breakpoint--medium) {

          &.col-4:not(:first-child):before,
          &.col-3:not(:first-child):before {
            @include rule("vertical");
            background-color: $regGray;
            max-height: none;
            width: 1px;
            height: 100%;
          }

          /* 1/3 width columns */

          &.col-4 {
            flex-basis: 31%;
            max-width: 31%;
          }
          &.col-4 + .col-4:before {
            left: -6%;
          }
          &.col-8 + .col-4:before {
            left: -8%;
          }

          /* 1/4 width columns */

          &.col-3 {
            flex-basis: 23%;
            max-width: 23%;
          }
          &.col-3 + .col-3:before {
            left: -6%;
          }
          &.col-9 + .col-3:before {
            left: -12%;
          }
        }

        @media (min-width: $breakpoint--large) {

          &.col-2:not(:first-child):before {
            @include rule("vertical");
            background-color: $regGray;
            max-height: none;
            width: 1px;
            height: 100%;
          }

          /* 1/5 width columns */

          &.col-2 + .col-2:before {
            left: -13%;
          }
        }

        /* Prevent gutter borders from appearing next to left-most columns when the columns start to wrap responsively */

        // @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        //   &.col-2:nth-child(3n+1):before,
        //   &.col-3:nth-child(odd):before {
        //     content: none;
        //   }
        // }
      }
    }

    /* Rows inside of other rows/columns */

    .col & {
      padding: 0;
    }
  }

  .row__wrap {
    margin: 0;

    @media (min-width: $breakpoint--small) {

      /* Apply negative margin to negate inner padding of columns */

      margin: 0 -10px;

      /* Begin flexbox/flexbox fallback */

      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        @include clearfix;
        display: block;
      }

      /* End flexbox stuff */
    }

    /* If we have 5 columns in a row or gutter borders, justify the columns */

    .row--justified &,
    .row--gutter-borders & {
      @media (min-width: $breakpoint--medium) {
        justify-content: space-between;
      }
    }

    /* If row is horizontally flush, apply additional negative margin to bring the row to full-width */

    .row--flush-horizontal & {
      margin-right: -20px;
      margin-left: -20px;

      @media (min-width: $breakpoint--medium) {
        margin-right: -10px;
        margin-left: -10px;
      }
    }

    /* Row wraps inside of other rows/columns (Featured/Latest Stories) */

    .col & {
      margin: 0 -20px;

      @media (min-width: $breakpoint--small) {
        margin: 0 -10px;
      }
    }
  }

  /* Columns */

  .col {
    width: 100%;
    max-width: 100%; /* All components are full-width at mobile viewports */
    padding: 0 $air--xSmall;

    @media (min-width: $breakpoint--small) {
      padding-right: 10px;
      padding-left: 10px;

      /* Apply flexbox fallback */

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        float: left;
      }
    }

    /* Loop through the column sizer map to size the columns */

    @each $column, $width in $columnSizer {
      $smallContext: nth($width, 2);
      $mediumContext: nth($width, 3);
      $largeContext: nth($width, 4);

      /* Tablet Widths */
      @media (min-width: $breakpoint--small) {
        &.col-#{$column} {
          @include column($smallContext);
        }
      }

      /* Small Desktop Widths */
      @media (min-width: $breakpoint--medium) {
        &.col-#{$column} {
          @include column($mediumContext);
        }
      }

      /* Large Desktop Widths */
      @media (min-width: $breakpoint--large) {
        &.col-#{$column} {
          @include column($largeContext);
        }
      }
    }

    /* Make sure columns inside of the equal-height rows are full height components */

    .row--equal-heights & > div {
      @media (min-width: $breakpoint--small) {
        height: 100%;
      }
    }

    .aem-AuthorLayer-Edit .row--equal-heights & > div {
      @media (min-width: $breakpoint--small) {
        min-height: 45px;
        height: auto;
      }
    }
  }

  /* Components */

  .component {
    @include clearfix;
    width: 100%;
    margin-bottom: $air--xSmall;

    @media (min-width: $breakpoint--small) {
      // padding-left: 0;
      // padding-right: 0;

      // &:last-of-type {
      //   margin-bottom: 0;
      // }
    }

    @media (min-width: $breakpoint--medium) {
      // &:last-of-type {
      //   margin-bottom: 0;
      // }
    }

    &.component--no-margin-bottom,
    &.component--border-bottom,
    .row--gutter-borders &,
    .row--no-gutters & {
      margin-bottom: 0;
    }

    &.component--border-bottom > div {
      position: relative;
      margin-bottom: $air--large;
      padding-bottom: $air--large;

      @media (min-width: $breakpoint--small) {
        margin-bottom: $air--xLarge;
        padding-bottom: $air--xLarge;
      }

      @media (min-width: $breakpoint--large) {
        margin-bottom: $air--xxLarge;
        padding-bottom: $air--xxLarge;
      }

      .marquee & {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &:after {
        @include rule("horizontal");
      }

      .marquee &:after {
        content: none;
      }
    }

    /* Make sure components inside of the equal-height rows are full height components */

    .row--equal-heights & {
      @media (min-width: $breakpoint--small) {
        height: 100%;
        .component {
          margin-bottom:0;
        }
      }
    }

    .aem-AuthorLayer-Edit .row--equal-heights & {
      @media (min-width: $breakpoint--small) {
        height: auto;
      }
    }

    [class*="__heading"] {
      max-width: $maxRowWidth;
      margin: 0 auto 25px;
      padding: 0 20px;

      @media (min-width: $breakpoint--small) {
        padding: 0 55px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }

      .col & {
        padding: 0;
      }
    }
  }
