/*====
  BASE
  ====*/

  html {
    box-sizing: border-box;

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
  }

  body {
    &.compensate-for-scrollbar {
      margin-right: 0;
      overflow: auto !important;
    }
  }

  /* Helper Classes */

  .hidden {
    display: none;
  }

  .hidden-spoken {
    @include hidden-spoken;
  }

  .hidden-desktop {
    @media (min-width: $breakpoint--medium) {
      display: none;
    }
  }

  .hidden-mobile {
    @media (max-width: $breakpoint--medium - 1) {
      display: none;
    }
  }

  .editor,
  .editor-title {
    display: none;

    .cq-Editable-dom & {
      display: block;
    }

    sup {
      font-style: italic;
    }
  }

  .no-results {
    @include font-size(16);
    text-align: center;
  }


  /* Icons */

  [class^="icon--"] {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    font-family: $att-icons;

    &:after {
      font-family: inherit;
      color: inherit;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @each $label, $icon in $attIcons {
    [class="icon--#{$label}"] {
      &:after {
        content: $icon;
      }
    }
  }

  /* ============== */
  /* YouTube Embeds */
  /* ============== */

  .embed--youtube,
  .asset--youtube {
    @include clearfix;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    clear: both;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 !important;
    }
  }

  /* ========== */
  /* GVP Embeds */
  /* ========== */

  /* preview/player wrappers */
  body > div[role="dialog"] {
    z-index: 999999999 !important;
  }
  att-gvp > div {
    border-radius: 0 !important;
  }

  [class^="gvp_player__view_"],
  [class^="gvp_cta__ctaContainer"] {
    border-radius: 0 !important;
  }

  [class^="gvp_player__view_"],
  [class^="gvp_player__viewport"] {
    .embed--gvp & {
      background: transparent !important;
      padding: 0 !important;
    }
  }

  [class^="gvp_player__player"] {
    justify-content: flex-start !important;
    width: 100%;

    &[class*="gvp_player__modal"] {
      background-color: transparent !important;
      max-width: 946px !important;
      width: 100% !important;
      padding: 0 !important;

      @media (max-width: $breakpoint--xxSmall - 1) {
        max-height: 300px !important;
      }
    }
  }

  [class^="gvp_player__viewport"] {
    position: relative;
    max-width: 100% !important;
    width: 100% !important;
  }

  [class^="gvp_player__video"] {
    max-width: 100% !important;
  }

  /* playback controls */
  [class^="gvp_player__controller"] {
    background: $black;
    padding: 0 10px !important;
  }

  /* play button */
  [class^="gvp_cta__ctaPlayButton"] {
    @include icon-overlay("play");
    max-width: none !important;
    max-height: none !important;
    fill: none !important;

    circle {
      display: none !important;
    }
  }

  [class^="gvp_player__title"],
  [class^="gvp_player__closeButton"] {
    transition: opacity 300ms ease !important;

    @media (min-width: $breakpoint--medium) {
      opacity: 0 !important;

      .embed--gvp:hover &,
      [class^="gvp_player__view_"]:hover & {
        opacity: 1 !important;
      }
    }
  }

  /* close button */
  [class^="gvp_player__closeButton"] {
    margin: 0 !important;
    fill: $black !important;
    z-index: 99 !important;

    .component & {
      width: 35px !important;
      height: 35px !important;
    }

    &:hover {
      path {
        fill: $black !important;
      }
    }

    &:focus {
      box-shadow: 0 !important;
    }

    circle {
      fill: $white;
    }
  }

  /* video title */
  [class^="gvp_player__title"] {
    position: absolute !important;
    top: 0;
    font-size: 16px !important;
    font-family: $aleck !important;
    font-weight: 300 !important;
    text-shadow: 1px 1px 5px #333 !important;
    color: $white !important;
    padding: 10px !important;
    min-height: 20% !important;
    width: 100% !important;
    z-index: 9;

    background: -moz-linear-gradient(top, rgba(0,0,0,0.55) 0%, rgba(31,104,164,0) 76%, rgba(41,137,216,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.55) 0%,rgba(31,104,164,0) 76%,rgba(41,137,216,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.55) 0%,rgba(31,104,164,0) 76%,rgba(41,137,216,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c000000', endColorstr='#00000000',GradientType=0 );

    @media (min-width: $breakpoint--medium) {
      font-size: 18px !important;
      padding: 15px 20px !important;
    }
  }

  [class^="gvp_cta__ctaTitle"] {
    display: none;
  }

  /* video duration */
  [class^="gvp_cta__ctaDuration"] {
    display: none;
  }
