/*======
  PLEDGE
  ======*/

/* Counter */

/* NOTE: The variables below control the size and positioning of the counter numbers. Please edit carefully. */

$counterSpeed: 2s;
$digitWidth: 50px;
$digitWidthMobile: 35px;
$digitHeight: 68px;
$digitHeightMobile: 50px;
$digitPositions: (
  1: ("-70px", "-100px"), /* first value represents the mobile background position, second value is for desktop) */
  2: ("-140px", "-200px"),
  3: ("-210px", "-300px"),
  4: ("-280px", "-400px"),
  5: ("-350px", "-500px"),
  6: ("-420px", "-600px"),
  7: ("-490px", "-700px"),
  8: ("-560px", "-800px"),
  9: ("-630px", "-900px"),
  0: ("-700px", "-1000px"),
);

.counter {
  position: relative;
  display: flex;
  justify-content: center;
  transition: opacity 1.5s ease;
  margin: 20px 0;
  min-height: $digitHeightMobile;



  @media (min-width: $breakpoint--small) {
    margin: 0 0 40px;
    min-height: $digitHeight;
    color: $white;
  }

  .banner__content--left &,
  .pledge__content--left & {
    justify-content: flex-start;
  }

  &--dark {
    // .counter__digits {
    //   [class^="digit-"] {
    //     background-position-x: 50px;
    //   }

    //   .comma {
    //     background-position-x: 30px;
    //   }
    // }

    .counter__label {
      color: $black;
    }
  }

  &__digits {
    @include font-size(93);
    @include clearfix();
    max-height: 85px;
    color: $attBlue;

    & > span {
      float:left;
      height:$digitHeightMobile;
      margin:0;
			padding:0;
      text-indent:-9999px;
      background-size: 300% auto;
      background-repeat: no-repeat;
      
      @media (min-width: $breakpoint--small) {
        height:$digitHeight;
      }
    }

    .comma {
      width: 10px;
      margin-top: 5px;
      background-image: url('../img/sprite_counter_comma.png');
      background-position-y: bottom;

      @media (min-width: $breakpoint--small) {
        width: 20px;
        margin-top: 15px;
      }

      & + .digit-2,
      & + .digit-3,
      & + .digit-4,
      & + .digit-5,
      & + .digit-8,
      & + .digit-9 {
        margin-left: 5px; /* prevents the comma from appearing too close to these numbers */
      }
    }

    .digit {
      width: $digitWidthMobile;
      background-image:url('../img/sprite_counter_digits.png');
      background-position-y: 0;
      transition: all 1.5s ease;

      @media (min-width: $breakpoint--small) {
        width:$digitWidth;
      }
    }

    @each $digit, $position in $digitPositions {
      .digit-start-#{$digit} {
          background-position:0 #{nth($position, 1)};
          animation-name: digitSpin#{$digit};
          animation-duration: $counterSpeed;
          animation-iteration-count: 1;
      
          -webkit-animation-name: digitSpin#{$digit};
          -webkit-animation-duration: $counterSpeed;
          -webkit-animation-iteration-count: 1;
      
          -moz-animation-name: digitSpin#{$digit};
          -moz-animation-duration: $counterSpeed;
          -moz-animation-iteration-count: 1;

          @media (min-width: $breakpoint--small) {
            background-position-y:#{nth($position, 2)};
          }
      }
      .digit-#{$digit} {
          background-position-y:#{nth($position, 1)};

          @media (min-width: $breakpoint--small) {
            background-position-y:#{nth($position, 2)};
          }
      }

      /* Mobile Animation */
      @keyframes digitSpin#{$digit} {
          from { background-position-y:0; }
          to { background-position-y:#{nth($position, 1)}; }
      }

      /* Desktop Animation */
      @media (min-width: $breakpoint--small) {
        @keyframes digitSpin#{$digit} {
          from { background-position-y:0; }
          to { background-position-y:#{nth($position, 2)}; }
        }
      }
    } 
  }

  &__label {
    @include font-size(22.5);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: left;
    max-width: 100px;
    line-height: 1.2;
    margin-top: -15px;
    margin-left: 10px;

    @media (min-width: $breakpoint--small) {
      @include font-size(32);
      margin-left: 15px;
    }
  }
}

/* Pledge vs. Banner Mode Toggling */

.banner__mode,
.pledge__mode {
  display: none;

  .counter__label,
  .pledge__prompt,
  .pledge__thanks {
    transition: opacity 1s ease;
    visibility: visible;
    position: relative;
    left: 0;
  }

  .hidden-fade {
    opacity: 0;
    visibility: hidden;
    pointer-events:none;
    left: 100%;
  }

  .pledge__prompt.hidden-fade,
  .pledge__thanks.hidden-fade {
    position: absolute;
  }
}

[data-mode="banner"] .banner__mode,
[data-mode="pledge"] .pledge__mode {
  display: block;
}

/* Banner Mode */

.banner__mode {
  .banner__img {
    display: block;
  }

  .banner__headline--text {
    @include font-size(45);

    @media (min-width: $breakpoint--small) {
      @include font-size(60);
    }
    @media (min-width: $breakpoint--large) {
      @include font-size(80);
    }
  }

  .banner__level3 {
    @media (min-width: $breakpoint--large) {
      @include font-size(24);
    }
  }

  .banner__cta {

    .cta--link {
      font-family: 'Aleck Sans Medium';

      &:after {
        font-family: 'Aleck Sans';
      }
    }
  }

  .banner__text--dark + .banner__cta .cta--link {
    color: $attBlue;
  }
}

/* Pledge Mode */

.pledge__mode {
  h1, h2, h3, h4, h5, h6 {
      margin-bottom: 10px;
  }
  h3 {
    @include font-size(24);
    font-family: inherit;
    font-weight: bold;
  }
  h4 {
    @include font-size(21);
    font-family: inherit;
    font-weight: bold;
  }
  p {
    margin-bottom: 20px;
  }
  .social-channels ul {
    padding-top: 0;
    list-style-type: none;

    .cta--social, .region .cta--social {
      @include font-size(35);
      height: 48px;
      width: 48px;
    }
  }
  .pledge__content {
    @include font-size(18);
    max-width: 860px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    line-height: 1.6;

    // @media (min-width: $breakpoint--medium) {
    //   @include font-size(21);
    // }

    &--left {
      text-align: left;
      max-width: 1220px;

      .social-channels ul {
        text-align: left;
      }
    }

  }
  .pledge__button {
    margin-top: 40px;
  }
  .pledge__wrap {
    @include clearfix();
    position: relative;
    overflow: hidden;
  }
}

/* Counter Color Contrast Settings */

.region--blue .counter__digits > span,
.region--blue--bl-text .counter__digits > span,
.region--comms-berry .counter__digits > span,
.region--comms-tangerine .counter__digits > span,
.region--comms-lime .counter__digits > span,
.region--comms-mint .counter__digits > span,
.region--blue-gradient--bl-text .counter__digits > span,
.region--comms-berry-tangerine .counter__digits > span,
.region--comms-lime-mint .counter__digits > span {
  background-position-x: center;
}
.region--dark-blue .counter__digits > span,
.region--blue--wh-text .counter__digits > span,
.region--comms-cobalt .counter__digits > span,
.region--comms-purple .counter__digits > span,
.region--blue-gradient--wh-text .counter__digits > span,
.region--comms-cobalt-purple .counter__digits > span {
  background-position-x: right;
}