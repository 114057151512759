/*============
  BRAND ASSETS
  ============*/

@mixin focusStyles {
  outline: 1px dashed $medGray;
}

.brand-assets {
  max-width: $maxRowWidth;
  margin: 0 auto;
  padding-bottom: 40px;

  @media (min-width: $breakpoint--small) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: $breakpoint--xLarge) {
    padding-left: 0;
    padding-right: 0;
  }

  .assets__panels {
    margin-bottom: 70px;

    @media (min-width: $breakpoint--medium) {
      display: flex;
      justify-content: center;
    }

    & > div {
      @media (min-width: $breakpoint--medium) {
        flex: 1
      }
    }

    .assets__panel-left {
      border-bottom: 1px solid $regGray;
      margin-bottom: 30px;

      @media (min-width: $breakpoint--medium) {
        max-width: 370px;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .assets__panel-right {
      @media (min-width: $breakpoint--medium) {
        max-width: 835px;
        border-left: 1px solid $regGray;
      }
    }
  }

  .assets__filter-nav {
    padding: 0 20px;

    @media (min-width: $breakpoint--xLarge) {
      padding-left: 0;
    }

    div[class^="asset-"] {
      margin-bottom: 30px;

      @media (min-width: $breakpoint--medium) {
        margin-bottom: 60px;
      }
    }

    h3 {
      @include font-size(18);
      margin-bottom: 15px;
    }

    .tag-toggle,
    .type-toggle {
      @media (min-width: $breakpoint--medium) {
        display: none;
      }
    }

    .asset-check-group-toggle {
      display: block;
      font-weight: 600;
      margin-bottom: 20px;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        @include focusStyles;
      }

      @media (min-width: $breakpoint--medium) {
        display: none;
      }
    }

    .asset-check-group {
      display: none;

      &.open {
        display: flex;
      }

      @media (min-width: $breakpoint--medium) {
        display: flex;
        flex-direction: column;
      }

      & > div {
        flex: 1;
      }
    }

    .checkbox {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      input[type="checkbox"] {
        opacity: 0;
        height: 26px;
        width: 26px;
        margin: 0 8px 0 0;

        &:focus + label,
        &:focus + label::before {
          @include focusStyles;
        }
      }

      input[type="checkbox"] + label {
        @include font-size(14);

        &::before {
          content: "";
          position: absolute;
          display: block;
          border: 1px solid $drkGray;
          border-radius: 2px;
          height: 26px;
          width: 26px;
          left: 0;
          top: 0;
        }

        &::after {
          content: none;
          position: absolute;
          display: inline-block;
          height: 9px;
          width: 14px;
          top: 6.5px;
          left: 6.5px;
          border-left: 3px solid;
          border-bottom: 3px solid;
          transform: rotate(-45deg);
          color: $white;
        }
      }

      input[type="checkbox"]:checked + label {
        &::before {
          border-color: $cobalt;
          background-color: $cobalt;
        }

        &::after {
          content: "";
        }
      }
    }
  }

  .asset-search {
    flex-grow: 1;
    padding: 0;

    & > form {
      padding: 0;
    }

    fieldset {
      border-radius: 3px;
      border: 1px solid $drkGray !important;
      max-width: none;
      margin: 0;
      
      // .region--white &, 
      // .region--gray &, 
      // .region--black &, 
      // .region--near-black & {
      //   background-color: $attBlue;
      // }
    }

    .search__input {
      @include font-size(14);
      position: relative;
      background-color: $white;
      height: 45px;
      width: calc(100% - 50px);
      margin: 2px;
      padding: 5px 0 7px 14px;

      &:focus {
        @include focusStyles;
      }
    }

    .search__clear {
      position: relative;
      display: none;
      margin: 0 0 0 -28px;
      padding-right: 6px;
      height:49px;

      &:after {
        @include centerVertically;
        position: absolute;
        content: "";
        width: 50px;
        height: calc(100% - 4px);
        right: 0;
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 32%, rgba(255,255,255,1) 99%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 32%,rgba(255,255,255,1) 99%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 32%,rgba(255,255,255,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        z-index: 1;
      }

      .icon--clear {
        display: block;
        position: relative;
        background-color: #d2d2d2;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        z-index: 2;
      }

      .icon--clear:after {
        @include font-size(27);
        position: absolute;
        display: inline-block;
        content: "+";
        transform: translate(-50%, -50%) rotate(45deg);
        top: 48%;
        left: 55%;
        font-family: $aleck;
        font-weight: 300;
        color: $white;
      }
    }

    .search__submit {
      height: calc(100% - 4px);
      width: 45px;
      right: 0;
      margin: 2px;

      &:focus {
        @include focusStyles;
      }
    }

    .search__submit span:after {
      @include font-size(35);
      color: $cobalt !important;
    }
  }

  .assets__feed {

    .row + .row {
      margin-top: 0;
    }

    .row {
      @media (min-width: $breakpoint--small) {
        padding: 0 20px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding-right: 0;
      }
    }

    .row__wrap {
      @media (min-width: $breakpoint--xLarge) {
        margin-right: -20px;
      }
    }

    .col {
      @media (min-width: 500px) and (max-width: $breakpoint--small - 1) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: $breakpoint--small) {
        @include column(6);
      }
    }

    .component {
      @media (min-width: $breakpoint--small) {
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
    }

    .item__media {
      position: relative;
      overflow: hidden;

      @media (min-width: $breakpoint--medium) {
        height: 240px;
      }

      @media (min-width: $breakpoint--large) {
        height: 295px;
      }
    }

    .item__media > div {

      @media (min-width: $breakpoint--medium) {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .item__media .item__preview {
      display: block;
      height: 100%;
      width: 100%;

      &:focus {
        @include focusStyles;
        max-height: calc(100% - 4px);
        max-width: calc(100% - 4px);
        margin: 2px;
      }
    }

    .item__media .item__video > * {
      @media (min-width: $breakpoint--medium) {
        height: 100%;
      }
    }

    .item__media .item__video .item__preview img {
      @media (min-width: $breakpoint--medium) {
        max-width: none;
      }

      @media (min-width: $breakpoint--large) {
        height: auto;
        width: 100%;
      }
    }

    .item__media .item__video .item__thumbnail,
    .item__media .item__img--b-roll .item__thumbnail {
      &:after {
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
      }
    }

    .item__media .item__img--b-roll .item__thumbnail {
      @include icon-overlay("download");
    }

    .item__media .item__img .item__thumbnail {
      height: 100%;
    }

    .item__media .item__img .item__thumbnail img {
      width: auto;

      @media (max-width: $breakpoint--medium - 1) {
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: $breakpoint--medium) {
        position: relative;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    .item__title {
      @media (min-width: $breakpoint--medium) {
        min-height: 45px;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .item__description {
      max-width: 500px;
      margin-top: 30px;
    }

    .item__cta {
      margin: 15px 0 0;

      &:first-child {
        margin-top: 0;
      }

      a {
        @include font-smoothing;
        display: inline-block;
        font-family: $aleckMedium;
        padding-bottom: 2px;
        color: $color--link;
      }

      .read-more {
        margin-right: 6px;
        padding-left: 10px;
        border-left: 1px solid $color--copy;

        &:focus {
          @include focusStyles;
        }

        &:first-child {
          padding-left: 0;
          border-left: 0;
        }
      }

      /* Fixes for GVP watch links */

      att-gvp {
        display: inline-block !important;
        width: auto !important;

        & > div {
          overflow: initial !important;
        }
      }
    }
  }

  .assets__cta {
    display: none;
    text-align: center;
    margin: 20px 0 0;

    @media (min-width: $breakpoint--medium) {
      margin: 40px auto 0;
    }
  }
}
