/*================
  FEATURED STORIES
  ================*/

.featured-stories {
  padding-top: 20px;

  @media (min-width: $breakpoint--small) {
    padding-top: 40px;
  }

  &.featured-stories--expanded {
    .row {
      &:nth-of-type(n + 3) {
        display: block;

        .featured-item {
          opacity: 1;
        }
      }
    }
  }

  .row {

    margin-top: 0;

    @media (min-width: $breakpoint--small) {
      & + .row {
        margin-top: 20px;
      }
    }

    @media (min-width: $breakpoint--large) {
      & + .row {
        margin-top: 40px;
      }

    }

    &:nth-of-type(n + 3) {
      display: none;

      .featured-item {
        opacity: 0;
        // transition: opacity 300ms ease;
      }
    }
  }

  .component {
    margin-bottom: 20px;

    @media (min-width: $breakpoint--small) {
      margin-bottom: 0;
    }
  }

  .stories__cta {
    display: none; /* JavaScript will display this cta only if a mininum number of items are present in the set */
    text-align: center;
    margin: 0;

    @media (min-width: $breakpoint--small) {
      margin-top: 40px;
    }

    @media (min-width: $breakpoint--medium) {
      margin-top: 60px;
    }
  }
}
