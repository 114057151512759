/*==============
  MEDIA CAROUSEL
  ==============*/

  .media-carousel {
    position: relative;
    min-height: 150px;

    .carousel__wrap {
      position: relative;
      max-width: $maxRowWidth;
      margin: 0 auto;
      padding-bottom: 70px;

      @media (min-width: $breakpoint--small) {
        padding-left: 55px;
        padding-right: 55px;

        .col & {
          padding-left: 0;
          padding-right: 0;
        }
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }

    .carousel__slides {

      /* Apply negative margin to negate inner padding of columns */

      @media (min-width: $breakpoint--small) {
        margin: 0 -10px;
      }

      .slide {
        width: 100%;

        @media (min-width: $breakpoint--small) {
          @include column(6);
        }

        @media (min-width: $breakpoint--medium) {
          @include column(4);
        }
      }
    }

    .component {
      margin-bottom: 0;
    }

    .featured-item {
      @media (min-width: $breakpoint--small) {
        padding: 0 10px;
      }
    }

    .item__media,
    .item__img {
      background-color: transparent;
    }

    .item__preview {
      color: inherit;
      text-decoration: none;

      .region--white &, .region[class*="black"] & {
        color:$color--cta;
      }
      .region--gray & {
        color:$color--link;
      }

      .item__thumbnail {
        // border: 1px solid $regGray;
        overflow: hidden;
        @media (min-width: $breakpoint--medium) {
          height:240px;
          img {
            position:relative;
            transform:translate(-50%, -50%);
            top:50%;
            left:50%;
          }
        }
        @media (min-width: $breakpoint--large) {
          height:267px;
        }
        @media (min-width: $breakpoint--xLarge) {
          height:295px;
        }
        


        // max-height: 260px;

        // @media (min-width: $breakpoint--small) {
        //   height: 150px;
        // }

        // .region--blue & {
        //   border-color: $medGray;
        // }
        //
        // .region--dark-blue &,
        // .region[class*="-black"] & {
        //   border-color: $drkGray;
        // }
      }

      .item__content {
        background-color: transparent;
        padding: 10px 0 20px;

        .item__title {
          color:inherit;
        }
      }
    }

    /* Prev/Next Buttons */

    .carousel__button {
      @include font-size(60);
      @include transitionAll(300ms);
      position: absolute;
      bottom: 10px;
      width: 50px;
      height: 50px;
      line-height: 36px;
      border-radius: 50%;
      border: 1px solid $medGray;
      background-color: transparent;
      color: $medGray;

      @media (min-width: $breakpoint--xLarge) {
      //KB-New
        top: 121px;

        .col:not(.col-12) & {
          top: auto;
        }
      }

      @media (min-width: $breakpoint--xxLarge) {
        width: 60px;
        height: 60px;
        line-height: 46px;

        .col:not(.col-12) & {
          width: 50px;
          height: 50px;
          line-height: 36px;
        }
      }
      span {
        display: block;
        width: 100%;
        height: 100%;
      }
      @each $name, $color in $backgroundColors {
        .region--#{$name} & {
          @if (nth($color, 2) == $functionalBlack) {
            border-color: $black;
            color: $black;
            &:hover {
              border-color: $white;
              color: $white;
            }
          }
          @if (nth($color, 2) == $white) {
            border-color: $white;
            color: $white;
            &:hover {
              border-color: $black;
              color: $black;
            }
          }
        }
      }
      
      .region--white &, .region--gray & {
        border-color: $medGray;
        color: $medGray;
        &:hover {
          border-color: $color--link;
          color: $color--link;
        }
      }
      .region[class*="-black"] & {
        border-color: $medGray;
        color: $medGray;
        &:hover {
          border-color: $white;
          color: $white;
        }
      }


    }

    .carousel__button--prev {
      left: calc(50% - 58px);

      @media (min-width: $breakpoint--xLarge) {
        left: -75px;

        .col:not(.col-12) & {
          left: calc(50% - 58px);
        }
      }

      @media (min-width: $breakpoint--xxLarge) {
        left: -85px;
      }
    }

    .carousel__button--next {
      right: calc(50% - 58px);

      @media (min-width: $breakpoint--xLarge) {
        right: -75px;

        .col:not(.col-12) & {
          right: calc(50% - 58px);
        }
      }

      @media (min-width: $breakpoint--xxLarge) {
        right: -85px;
      }
    }
  }

  .story .col:not(.col-12) .media-carousel .carousel__wrap {
    @media (min-width: $breakpoint--xLarge) {
      margin-bottom: 80px;
    }
  }
  .story .col:not(.col-12) .media-carousel .carousel__button {
    @media (min-width: $breakpoint--xLarge) {
      top: 100%;
    }
  }

  .blog-post .blog__wrap .media-carousel,
  .media-kit-page .media-kit__wrap .media-carousel {
    & > .row {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        padding: 0 55px;
      }
    }
    & > .row .row__wrap {
      @media (min-width: $breakpoint--small) {
        margin: 0 -10px;
      }
    }
    .carousel__wrap {
      @media (min-width: $breakpoint--medium) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .blog-post .blog__wrap .region .media-carousel,
  .media-kit-page .media-kit__wrap .region .media-carousel {
    @media (min-width: $breakpoint--medium) {
      max-width: $maxContentWidth !important;
    }

    & > .row {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--xLarge - 1) {
        padding: 0 55px;
      }
    }
    .carousel__wrap {
      @media (min-width: $breakpoint--medium) {
        padding-left: 55px;
        padding-right: 55px;
      }
      @media (min-width: $breakpoint--xLarge) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
