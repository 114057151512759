/*==========
  TYPOGRAPHY
  ==========*/

  html {
    font-family: $arial;
    font-size: 62.5%;
  }

  body {
    @include font-size(16);
    color: $color--copy;
  }

  strong, b {
    font-weight: 700;
  }

  em, i:not([class*="icon--"]) {
    font-style: italic;
  }

  sup {
    position: relative;
    vertical-align: top;
    font-size: .7em;
    top: 2px;
  }
  sub {
    vertical-align: sub;
    font-size: .7em;
  }

  sup sub, sub sup {
    font-size:inherit;
    vertical-align: baseline;
  }

  a {
    color: $color--link;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @each $name, $color in $backgroundColors {
      @if (nth($color, 2) == $functionalBlack) {
        .region--#{$name} & {
          color:$functionalBlack;
          text-decoration: underline;
        }
      }
      @if (nth($color, 2) == $white) {
        .region--#{$name} & {
          color:$white;
          text-decoration: underline;
        }
      }
    } 
    .region--black &, .region--near-black & {
      color: $attBlue;
      text-decoration: none;
    }
    .region--white &, .region--gray & {
      color: $color--link;
      text-decoration: none;
    }
  }

  .content {
    @include font-smoothing;
    font-family: $aleck;

    h1, h2 {
      font-weight: 300;
      line-height: 1.15;
    }

    h3, h4, h5, h6 {
      font-weight: normal;
      line-height: 1.25;
    }

    h1 {
      @include font-size(35);

      @media (min-width: $breakpoint--large) {
        @include font-size(45);
      }

      @media (min-width: $breakpoint--xLarge) {
        @include font-size(60);
      }
    }

    h2 {
      @include font-size(25);

      @media (min-width: $breakpoint--small) {
        @include font-size(30);
      }
    }

    h3 {
      @include font-size(18);
      font-family: $aleckBlack;

      @media (min-width: $breakpoint--large) {
        @include font-size(21);
      }
    }

    h4 {
      @include font-size(16);
      font-family: $aleckMedium;

      @media (min-width: $breakpoint--large) {
        @include font-size(20);
      }
    }

    h5 {
      @include font-size(16);
      font-family: $aleckMedium;
    }

    h6 {
      @include font-size(15);
      font-family: "Aleck Sans Medium";
    }
  }

  .featured-item,
  .text {

    /* Lists */

    ul,
    ol {
      margin: 25px 0 25px 20px;

      ul,
      ol {
        margin: 15px 0 15px 15px;

        @media (min-width: $breakpoint--large) {
          margin: 25px 0 25px 20px;
        }

        & > li {
          margin-left: 15px;

          @media (min-width: $breakpoint--large) {
            margin-left: 25px;
          }
        }
      }
    }

    ul {
      & > li:before {
        content: "";
        display: block;
        position: absolute;
        top: 15px;
        left: -15px;
        background-color: $black;
        height: 4px;
        width: 4px;
        border-radius: 50%;
      }

      &>li {
        ul {
          &>li:before {
            background-color: $black;
            height: 4px;
            width: 4px;
            border-radius: 50%;
          }
        }
      }
    }

    ol {
      list-style-type: decimal;
    }

    li {
      position: relative;
      margin-bottom: 10px;
    }
  }
  .text ul > li:before {
    @each $name, $color in $backgroundColors {
      @if (nth($color, 2) == $white) {
        .region--#{$name} & {
          background-color: $white; 
        }
      }
    } 
  }
