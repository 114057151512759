/*=============
  VIDEO LIBRARY
  =============*/

.video-library {
  max-width: $maxRowWidth;
  margin: 0 auto;
  padding-bottom: 40px;

  .videos__heading {
    text-align: center;
  }

  .assets__filter-nav {
    @include font-size(15);
    position: relative;
    max-width: 300px;
    margin: 0 auto 20px;
    z-index: 9;

    @media (min-width: $breakpoint--xSmall) {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: none;
      padding: 0 20px;

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        @include clearfix;
      }
    }

    @media (min-width: $breakpoint--small) {
      margin-bottom: 30px;
      padding: 0 55px;
      max-width: 770px;
    }

    @media (min-width: $breakpoint--xLarge) {
      margin-bottom: 50px;
      padding: 0;
    }

    .asset-type,
    .asset-search {
      margin-top: 30px;
      margin-bottom: 30px;

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        float: left;
      }

      @media (min-width: $breakpoint--xLarge) {
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }

    .asset-type {
      position: relative;
      min-width: 230px;
      z-index: 9;

      @media (min-width: $breakpoint--small) {}
    }

    .type-toggle {
      display: block;
      position: relative;
      padding: 0 25px 0 15px;
      border-radius: 30px;
      background-color: $functionalBlack;
      color: $white;
      height: 37px;
      line-height: 37px;
      text-decoration: none;

      .region--white &, .region--gray &, .region--black &, .region--near-black & {
        background-color: $attBlue;
      }

      &:after {
        @include centerVertically;
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: 55%;
        right: 15px;
        border-style: solid;
        border-width: 7px 4px 0 4px;
        border-color: $white transparent transparent transparent;
      }
    }

    .type-select {
      display: none;
      position: absolute;
      background-color: $white;
      border: 1px solid $regGray;
      border-radius: 3px;
      min-width: 230px;
      top: 40px;
      left: 0;

      a {
        display: block;
        padding: 10px 12px;
        color: $black;
        text-decoration: none;

        &:hover {
          background-color: $attBlue;
          text-decoration: none;
          color: $white;
        }

        &.active {
          display: none;
        }
      }
    }

    &.assets__filter-nav--open {
      .type-select {
        display: block;
      }

      .type-toggle:after {
        transform: rotateX(180deg);
        top: 45%;
      }
    }

    .asset-search {
      flex-grow: 1;
    }

    .asset-type+.asset-search {
      @media (min-width: $breakpoint--xSmall) {
        margin-left: 20px;
      }

      @media (min-width: $breakpoint--large) {
        margin-left: 40px;
      }
    }

    .search-field {
      padding: 0;
    }

    .search-field .search__clear {
      position: relative;
      display: none;
      margin: 0 0 0 -28px;
      padding-right: 6px;

      &:after {
        @include centerVertically;
        position: absolute;
        content: "";
        width: 50px;
        height: calc(100% - 4px);
        right: 0;
        z-index: 1;

        .region--white & {
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 99%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 99%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 32%, rgba(255, 255, 255, 1) 99%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
        /* IE6-9 */
        }
      }

      .icon--clear {
        display: block;
        position: relative;
        background-color: $black;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        z-index: 2;

        .region--white &,
        .region--black &,
        .region--comms-cobalt &,
        .region--comms-purple &,
        .region--comms-cobalt-purple &,
        .region--blue--wh-text &,
        .region--blue-gradient--wh-text & {
          background-color: transparent;
          border: 1px solid $white;
        }

      }

      .icon--clear:after {
        @include font-size(27);
        position: absolute;
        display: inline-block;
        content: "+";
        transform: translate(-50%, -50%) rotate(45deg);
        top: 48%;
        left: 55%;
        font-family: $aleck;
        font-weight: 300;
        color: $white;
      }
    }
  }

  .assets__feed {

    .row+.row {
      margin-top: 0;
    }

    .col.col-2 {
      @media (min-width: $breakpoint--medium) {
        @include column(2.4);
      }
    }

    .component {
      @media (min-width: $breakpoint--small) {
        padding-bottom: 20px;
        margin-bottom: 10px;
      }
    }

    // .item__media {
    //   position: relative;
    //   overflow: hidden;

    //   @media (min-width: $breakpoint--medium) {
    //     height: 240px;
    //   }

    //   @media (min-width: $breakpoint--large) {
    //     height: 270px;
    //   }

    //   @media (min-width: $breakpoint--xLarge) {
    //     height: 295px;
    //   }
    // }

    // .item__media>div {
    //   @media (min-width: $breakpoint--medium) {
    //     position: absolute;
    //     overflow: hidden;
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //   }
    // }

    .item__media a {
      display: block;
      height: 100%;
      width: 100%;
    }

    .item__media .item__video>* {
      @media (min-width: $breakpoint--medium) {
        height: 100%;
      }
    }

    .item__media .item__video .item__thumbnail {

      .region--near-black &,
      .region--black & {
        border: 1px solid $drkGray;
      }
    }

    .item__media .item__video .item__preview img {
      @media (min-width: $breakpoint--medium) {
        max-width: none;
      }

      @media (min-width: $breakpoint--large) {
        height: auto;
        width: 100%;
      }
    }

    .item__media .item__video .item__thumbnail,
    .item__media .item__img--b-roll .item__thumbnail {
      &:after {
        text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
      }
    }

    .item__content {
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;

      .region:not(.region--white) & {
        background-color: transparent;
      }
    }

    .item__title {
      @media (min-width: $breakpoint--medium) {
        min-height: 45px;
      }

      a {
        text-decoration: none;

        .region--white &, .region[class*="black"] {
          color: $attBlue;
        }
      }
    }
  }

  .assets__cta {
    display: none;
    text-align: center;
    margin: 20px 0 0;

    @media (min-width: $breakpoint--medium) {
      margin: 40px auto 0;
    }
  }
}