/*===================
  MEDIA KIT TEMPLATES
  ===================*/

  /* General Media Kit Elements */

  .article [class^="media-kit__"]{
    .col.col-8 {
      @media (max-width: $breakpoint--large - 1) {
        max-width: none;
      }
    }
  }

  .media-kit__header {
    position: relative;

    .featured-article {
      padding-top: 30px;

      @media (min-width: $breakpoint--large) {
        .header--transparent + .content & {
          height: calc(100% - #{$desktopNavHgt});
        }
      }

      p {
        @include font-size(21);
        line-height: 1.45;

        @media (min-width: $breakpoint--large) {
          max-width: 40%;
        }
      }
    }

    .featured-article__cta {
      margin: 25px 0;

      .cta--link {
        color: $attBlue;

        @media (min-width: $breakpoint--large) {
          @include font-size(21);
          font-weight: 400;
        }

      }
    }

    .featured-category {
      text-transform: uppercase;
      color: $white;
      @include special-bold($attBlue);
    }

    &.media-kit__header--light .featured-category {
      color: $black;
    }
  }

  /* Media Kit Landing Styles */
  /* ======================== */

  .media-kit-landing .media-kit__header {
    padding: 0;
    background-color: $black;
    color: $white;

    &.media-kit__header--light {
      background-color: $white;
      color: $black;
    }

    .featured-image {
      overflow: hidden;

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;

        @media (max-width: $breakpoint--medium - 1) {
          width: 100%;
        }
      }
    }

    .featured-article {
      padding: 40px 20px;

      @media (min-width: $breakpoint--small) {
        padding-left: $gutter--small;
        padding-right: $gutter--small;
      }

      @media (min-width: $breakpoint--small) {
        @include centerHorizontally;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        max-width: $maxContentWidth;
        width: 100%;
        height: 100%;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding-top: 65px;
        padding-left: 0;
        padding-right: 0;
      }

      .featured-headline {
        @include font-size(35);
        padding-top: 30px;
        line-height: 1.15;

        @media (min-width: $breakpoint--medium) {
          @include font-size(50);
          padding-top: 60px;
        }

        @media (min-width: $breakpoint--large) {
          @include font-size(60);
          padding-bottom: 50px;
        }

        @media (min-width: $breakpoint--xLarge) {
          max-width: 50%;
        }
      }

      .featured-headline,
      .featured-description {
        @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
          max-width: 70%;
        }
      }
    }
  }

  .media-kit-landing .media-kit__wrap {
    .featured-stories {
      padding-top: 20px;
      padding-bottom: 20px;

      @media (min-width: $breakpoint--large) {
        padding-bottom: 60px;
      }
    }
  }

  /* Media Kit Page Styles */
  /* ===================== */

  .media-kit-page .media-kit__header {
    .featured-article {
      padding: 40px 0 30px;

      @media (min-width: $breakpoint--small) {
        padding: 50px 0;
      }

      @media (min-width: $breakpoint--medium) {
        padding: 80px 0;
      }
    }

    .featured-category {
      color: $black;
    }

    .featured-category b,
    .featured-category strong {
      color: $attBlue;
    }

    .featured-headline {
      padding-bottom: 20px;
    }

    .col {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* Component Style Overrides */

  .media-kit {

    /* Article */

    .article {
      padding-bottom: 40px;

      @media (min-width: $breakpoint--medium) {
        padding-bottom: 80px;
      }
    }

    .article__header {
      border-bottom: 0;
    }

    .article__meta {
      margin-bottom: 20px;

      .meta__info {
        max-width: none;

        .date {
          font-weight: 700;
        }
      }
    }

    .article__content {
      padding-top: 0;
    }
  }
