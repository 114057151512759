/*========
  ARTICLES
  ========*/

/* Featured Headline */

.featured-headline {
  padding: 0 0 30px;

  .story__header & {
    padding: 30px 20px;

    @media (min-width: $breakpoint--small) {
      padding-left: $gutter--small;
      padding-right: $gutter--small;
    }

    @media (min-width: $breakpoint--medium) {
      @include centerHorizontally;
      position: absolute;
      bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 auto;
      max-width: $maxContentWidth;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $breakpoint--large) {
      padding-left: calc(#{$gutter--large} - 10px);
      padding-right: calc(#{$gutter--large} - 10px);

      .header--transparent+.content & {
        height: calc(100% - #{$desktopNavHgt});
      }
    }
  }

  .article__content[data-ftImg="true"] & {
    display: none;
  }
}

.featured-headline h1,
.meta__info h1,
.meta__info h2 {
  font-family: $aleckBlack;
  line-height: 1.25;
}

.featured-headline h1 {
  @media (min-width: $breakpoint--medium) {
    .story__header & {
      @include centerVertically;
      max-width: 50%;
      color: $white;
    }

    .story__header--light & {
      color: $black;
    }
  }
}

.featured-headline h1,
.meta__info h1 {
  @media (min-width: $breakpoint--large) {

    .story &,
    .blog-post & {
      @include font-size(40);
    }
  }
}

.meta__info h2 {
  @media (min-width: $breakpoint--large) {
    .media-kit-post & {
      @include font-size(40);
      max-width: 95%;
    }
  }
}

.featured-asset {
  [class*="asset"] {
    margin: 10px 0 40px;
  }

  img {
    max-width: 100%;
  }
}

/* Article */

.article__header {
  @include clearfix;
  padding-bottom: 15px;
  border-bottom: 1px solid $regGray;
}

.article.col-12 {
  .article__header {
    padding-bottom: 0px;
  }
}

.author__meta {
  .meta__img {
    img {
      clip-path: circle();
    }
  }
}

.article__meta,
.author__meta {
  @include clearfix;
  margin-bottom: 40px;

  .meta__img {
    float: left;
    margin-right: 15px;
    min-height: 85px;
    width: 85px;
    overflow: hidden;

    @media (min-width: $breakpoint--xSmall) {
      margin-right: 20px;
    }

    img {
      max-width: 100%;
    }
  }

  .meta__info {
    float: left;
    max-width: calc(100% - 108px);

    span {
      display: block;
      margin-bottom: 8px;
    }

    .author {
      font-weight: 700;
    }
  }
}

.article__share {
  @media (min-width: $breakpoint--xxSmall) {
    float: left;
  }
}

.article__assets {
  margin-top: 10px;

  @media (min-width: $breakpoint--xxSmall) {
    margin-top: 0;
    margin-right: 15px;
    float: right;
  }

  .cta--link {
    @include accent-arrow("down");
  }
}

/* Article Body Content */

.article__content {
  padding-top: 40px;

  .section:first-child>.region:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  // .media {
  //   padding: 10px 20px 20px;
  //
  //   @media (min-width: $breakpoint--xxSmall) {
  //     padding-left: 0;
  //     padding-right: 0;
  //   }
  // }

  h1,
  h2 {
    font-family: $aleckBlack;
    line-height: 1.25;
  }

  .region h1,
  .region h2 {
    font-family: $aleck;
  }

  /* Components */

  .component.component--border-bottom>div {
    margin-bottom: calc(#{$air--small} + 10px);
    padding-bottom: $air--small;

    @media (min-width: $breakpoint--large) {
      margin-bottom: calc(#{$air--large} + 10px);
      padding-bottom: $air--large;
    }
  }
}

.blog-post .blog__wrap,
.media-kit-page .media-kit__wrap {

  .row {
    margin-top: 0;

    @media (min-width: $breakpoint--small) {
      padding: 0;
    }

    @media (min-width: $breakpoint--medium) {
      max-width: $maxPageWidth;
    }
  }

  .row__wrap {
    @media (min-width: $breakpoint--small) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .article__content {
    @media (min-width: $breakpoint--medium) {
      width: 100%;
    }
  }

  /* Sections */

  .region {
    border-top: 1px solid $regGray;
    border-bottom: 1px solid $regGray;
    margin: 0 0 20px;

    &[class*="region--"]:not(.region--white) {
      border-top: 0;
      border-bottom: 0;
    }

    &.region--flush-vertical {
      border-top: 0;
      border-bottom: 0;
      padding: 0;
    }

    @media (min-width: $breakpoint--small) {
      margin-bottom: 40px;
      padding: 80px 0;
    }

    @media (min-width: $breakpoint--medium) {
      margin-bottom: 50px;
      // padding: 100px 0;
    }

    // @media (min-width: $breakpoint--xLarge) {
    //   padding: 150px 0;
    // }
  }

  /* Components */

  .component,
  .region {
    @media (min-width: $breakpoint--medium) {
      max-width: $maxContentWidth;
      margin-left: auto;
      margin-right: auto;
    }

    &>div {

      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakpoint--small) {
        padding: 0 55px 20px;
      }

      @media (min-width: $breakpoint--medium) {
        @include column(8);
        margin: 0 auto;
        padding: 0 0 20px;
      }

      .component { 
        &>div {
          padding: 0px; //Removes additional space on the inner component
          @media (min-width: $breakpoint--medium) {
            max-width: 100%;
          }
        }
      }
    }
  }

  .component [class*="__heading"] {
    @media (min-width: $breakpoint--medium) {
      padding: 0;
    }
  }

  .region .component {
    margin-bottom: 0;

    @media (min-width: $breakpoint--medium) {
      max-width: none;
    }

    &>div {
      padding: 0;

      @media (min-width: $breakpoint--medium) {
        max-width: none;
      }
    }
  }

  .region .component [class*="__heading"] {
    @media (min-width: $breakpoint--medium) {
      padding: 0 55px;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }
  }

  .region .component .text,
  .region .component .media__caption {
    padding: 0 20px;

    @media (min-width: $breakpoint--small) {
      padding: 0 55px;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .region .component .text {
    max-width: $maxContentWidth;
    padding: 0px;
  }

  .region .component .text.text--featured {
    @media (min-width: $breakpoint--medium) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .region .component .media__caption {
    @media (min-width: $breakpoint--medium) {
      padding: 0;
    }

    p {
      @media (min-width: $breakpoint--medium) {
        @include column(8);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  /* Text Components */

  .text.text--featured h2 {
    @include font-size(30);

    @media (min-width: $breakpoint--xLarge) {
      @include font-size(38);
    }
  }

  /* Media Components */

  .media {
    float: none;
  }

  .region .component .media__img {
    max-width: none;
  }

  .media__img img {
    margin: 0 auto;
  }

  .media__caption {
    padding: 0;
  }

  .component>.media-library,
  .component>.media-carousel,
  .component>.media-library .component>div,
  .component>.media-carousel .component>div {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .component>.media-library .component>div,
  .component>.media-carousel .component>div {
    @media (min-width: $breakpoint--medium) {
      max-width: none;
    }
  }

  .component>.media-carousel .component>div {
    padding: 0 20px;

    @media (min-width: $breakpoint--small) {
      padding: 0 10px;
    }
  }
}

.blog__header,
.blog__authors,
.media-kit__header,
.media-kit__authors,
.story__wrap {
  .row .row__wrap .col-12 {
    @media (min-width: $breakpoint--medium) {
      @include column(9);
    }


    @media (min-width: $breakpoint--xLarge) {
      @include column(8);
    }
  }
  
  //.article-header--row{
  //  margin-top:40px;
  //} 
}

/* Sidebar */

.sidebar {

  @media (min-width: $breakpoint--medium) {
    padding-left: 40px;
  }

  @media (min-width: $breakpoint--large) {
    padding-left: 60px;
    padding-right: 60px;
  }

  .sidebar__widget {
    margin-bottom: 40px;
  }

  .search-field fieldset {
    margin: 0;

    @media (min-width: $breakpoint--medium) {
      max-width: 245px;
    }
  }
}

/* Related Articles */

[class*="__related"] {
  .featured-item {
    .item__info {
      @include font-size(16);
      font-family: $aleckMedium;
      margin: 15px 0 30px;
    }

    .item__description {
      @media (max-width: $breakpoint--medium - 1) {
        display: none;
      }
    }

    // .item__img[data-placeholder="true"] a:after {
    //   content: "";
    // }
  }
}

.blog-post .blog__wrap .region {
  border: none;
}