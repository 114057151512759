  /*=====
    FACTS & STATS
    =====*/

  .facts-stats {
    .item {
      &__header {
        background-color: $lgtGray;
      }
      &__icon, &__text {
        width:100%;
        height:200px;
        padding:40px;
        text-align:center;
        word-wrap: break-word;
        overflow:hidden;
        img {
          @include centerVertically();
          display:block;
          margin: 0 auto;
          max-width:100%;
        }
        h2 {
          @include centerVertically();
          @include font-size(40);
          display:block;
          color: $drkGray;
        }
      }
      &__img {
        background-color:transparent;
        img {
          margin: 0 auto;
        }
      }
      &__content {
        padding:40px;
        .col-3 &, .col-2 & {
          padding: 20px;
        }
        h1, h2, h3, h4, h5, h6, p {
          margin-top:0;
          margin-bottom:10px;
        }
        .content__wrap {
          @media (min-width: $breakpoint--xxLarge) {
            max-width:100%;
          }
        }
      }
      &__header + .item__content {
        padding: 20px 40px;
      }
      &__cta {
        margin: 45px 0 0;
        text-align:center;
        .cta--button {
          @include accent-arrow("right");
          @include font-size(16);
          margin:0 auto;
          border:1px solid $color--copy;
          border-radius: 0;
          background-color: transparent;
          color: $color--copy;
          font-weight:normal;
          &:hover {
            background-color:$color--copy;
            color: $white;
          }
        }
      }
    }
    @each $name, $color in $backgroundColors {
      &.facts-stats--#{$name} { 
        .item__header { //only header
          @if (length($color) == 3) {
            background:nth($color, 1);
            background:linear-gradient(to right, nth($color, 1) 0%, nth($color, 3) 100%);
          }
          @else {
            background-color: nth($color, 1);
          }
          .item__text h2 {
            color:nth($color, 2);
          }
        }
        &.facts-stats--full-height { //full height
          @if (length($color) == 3) {
            background:nth($color, 1);
            background:linear-gradient(to right, nth($color, 1) 0%, nth($color, 3) 100%);
          }
          @else {
            background-color: nth($color, 1);
          }
          .item__header, .item__content {
            background-color: transparent;
            .item__text h2, .item__description {
              color:nth($color, 2);
            }
          }
          ul > li:before {
            background-color:nth($color, 2);
          }
          .item__img {
            padding-bottom:20px;
          }
          .item__header + .item__content {
            padding: 0 40px 20px;
          }
          @if (nth($color, 2) == $functionalBlack) {
            .item__cta .cta--button {
              border-color: $color--copy;
              color: $color--copy;
              &:hover {
                background-color: $color--copy;
                color: $white;
              }
            }
          } @else {
            .item__cta .cta--button {
              border-color: $white;
              color: $white;
              &:hover {
                background-color: $white;
                color: $color--copy;
              }
            }
          }
        }
      }
    }
    &.facts-stats--white, &.facts-stats--gray {
      .item__header .item__text h2, &.facts-stats--full-height .item__header .item__text h2 {
        color:$drkGray;
      }
    } 
    .header--white .item__text h2, .header--gray .item__text h2 {
      color:$drkGray;
    }
  }