/*=================================
  ALL POSTS/SEARCH RESULTS TEMPLATE
  =================================*/

  .all-posts {
    .region__wrap {
      max-width: 810px;
      padding: 0 20px;

      @media (min-width: $breakpoint--small) {
        padding: 0 $gutter--small;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }

    .headline {
      @include font-size(30);
      margin-bottom: 40px;
    }

    .search-controls {
      @include clearfix;
      margin: 40px 0;
    }

    form {
      input {
        @include font-size(16);

        @media (min-width: $breakpoint--medium) {
          @include font-size(16);
        }
      }
    }

    .filters {
      margin-bottom: 10px;

      @media (min-width: $breakpoint--xSmall) {
        float: none;
        margin-bottom: 0;
        padding: 5px 0px 30px 0px;
      }

      form {
        label {
          @include font-size(15);
          font-weight: 700;
          margin-right: 5px;
        }

        select {
          min-height: 25px;
          min-width: 100px;
        }
      }
    }

    .search {
      position: relative;
      float: left;

      @media (min-width: $breakpoint--xSmall) {
        border-left: 1px solid $regGray;
        padding-left: 18px;
      }

      form {
        label {
          display: none;
        }

        input.txt {
          height: 23px;
          line-height: 23px;
          padding-left: 5px;
          border-radius: 4px;
          border: 1px solid $regGray;
          font-family: $aleckMedium;
          font-weight: 400;

          @media (min-width: $breakpoint--xSmall) {
            min-width: 200px;
          }
        }

        .search__submit {
          position: absolute;
          height: 23px;
          width: 23px;
          right: 0;
          top: 0;
          background-color: transparent;

          .icon--search {
            @include font-size(20);
            color: $color--cta;

            &:after {
              transform: translate(-50%, -50%) rotateY(-180deg);
            }
          }
        }
      }
    }

    .text {
      @include font-size(16);
      padding: 0;
    }

    .posts {
      border-top: 1px solid $regGray;
      padding: 40px 0;

      [class^="page"] {
        margin-bottom: 60px;
      }

      .timestamp {
        margin-bottom: 12px;
      }

      .meta,
      .categories {
        display: none;
      }

      h2 {
        font-size: 20px !important;
        font-family: $aleckBlack !important;
        font-weight: 400 !important;
        margin-bottom: 12px !important;
        line-height: 1.25 !important;
      }

      h2 + p {
        margin-bottom: 15px;
        line-height: 1.35;
      }

      a {
        color: $color--cta;
      }
    }

    .search-cta {
      text-align: center;

      .cta--button {
        margin: 0 10px;

        @media (max-width: $breakpoint--xSmall - 1) {
          min-width: 120px;
          padding: 15px 25px 17px;
        }
      }
    }
  }
