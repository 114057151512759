/*=============
  MICROSITE NAV
  =============*/

  @mixin navPadding($padding, $bottom) { 
    padding: $padding;
    &:before {
      bottom: $bottom;
    }
  }

  .microsite-nav,
  .microsite-nav__wrap {
    height: 100%;
  }

  .microsite-nav__wrap {
    background-color: $black;

    @media (max-width: $breakpoint--medium - 1) {
      border-top: 1px solid $drkGray;
      border-bottom: 1px solid $drkGray;
    }

    .header--secondary & {
      background-color: transparent;

      @media (max-width: $breakpoint--medium - 1) {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }


  /* MICRO GLOBAL NAV */
  /* ================ */

  .microsite-nav__global-nav {
    display: none;
    position: relative;
    background-color: $black;

    @media (max-width: $breakpoint--medium - 1) {
      border-bottom: 1px solid $drkGray;
    }

    .header--secondary .microsite-nav--plusGlobal & {
      display: block;
    }
  }

  .microsite-nav__global-nav__utils {
    height: $micrositeCollapsedGlobalNav;

    @media (min-width: $breakpoint--medium) {
      border-bottom: 1px solid $drkGray;
    }

    .wrap {
      @include clearfix;
      max-width: $maxContentWidth;
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;

      @media (min-width: $breakpoint--small) {
        padding: 0 $gutter--small;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }

    .nav__logo .logo__icon {
      height: 30px;
      min-width: 75px;
    }

    .nav__toggle {
      display: inline-block;
      margin-left: 15px;
      height: 14px;
      float: left;

      .bar {
        height: 2px;
        width: 17px;
        margin-bottom: 3px;
      }

      .microsite-nav__global-nav--open & .bar {
        position: absolute;
        background: none;

        &:first-child,
        &:last-child {
          background: $white;
        }

        &:first-child {
          transform: rotate(-45deg);
        }

        &:last-child {
          transform: rotate(45deg);
        }
      }
    }
  }

  .microsite-nav__global-nav__wrap {
    display: none;

    .header--secondary .microsite-nav--plusGlobal .microsite-nav__global-nav--open & {
      display: block;
      position: absolute;
      // top: $mobileNavHgt;
      width: 100%;
      background-color: $black;
      z-index: 99;
    }
  }

  .micro-global-nav__menu {

    &:not(.nav__menu--nested) {
      max-width: $maxContentWidth;
      margin: 0 auto;
      padding: 20px;

      @media (min-width: $breakpoint--xSmall) {
        display: flex;
        justify-content: space-around;
      }

      @media (min-width: $breakpoint--small) {
        padding-left: 55px;
        padding-right: 55px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 20px 0 50px;
      }
    }

    &:not(.nav__menu--nested) > li {
      font-weight: 700;

      @media (min-width: $breakpoint--xSmall) {
        flex-basis: 20%;
      }

      @media (min-width: $breakpoint--medium) {
        flex-grow: 1;
      }

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        @media (min-width: $breakpoint--xSmall) {
          float: left;
          width: 20%;
        }
      }

      & > .nav__menu-item {
        @media (max-width: $breakpoint--xSmall - 1) {
          display: block;
          border-top: 1px solid $drkGray;
          padding-top: 15px;
        }
      }
    }

    li {
      @include font-size(14);
      font-family: $arial;
      text-transform: uppercase;
      color: $medGray;

      .nav__menu-item {
        @include no-font-smoothing;
        display: inline-block;
        margin-bottom: 17px;
        line-height: 1.25;
        color: $medGray;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .nav__menu--nested {
      position: relative;
      width: auto;
      margin: 0;
      padding: 0;
    }

    .nav__menu--nested li {
      @include font-size(11);
      font-weight: 400;
    }

    .micro-global-nav__menu--tertiary {
      margin-left: 15px;
    }
  }

  /* MICROSITE-SPECIFIC NAV */
  /* ====================== */
  //Specifically the site nav, not the site & global together
  .microsite-nav__site-nav {
    height: 100%; //height needs to be 100% in mobile for nav dropdown
    max-width: $maxContentWidth;
    margin: 0 auto;

    @media (min-width: $breakpoint--medium) {
      .header--secondary & {
        @include clearfix; 
        @include transitionAll(450ms); //Added transition so change in size would match rest of nav
        height: $mobileNavHgt; //Specific height set, originally 100%
      }
      .header--fixed .microsite-nav--plusGlobal & {
        height: $micrositeMobileNavHgt; //originally calc(100% - #{$micrositeCollapsedGlobalNav});
      }
    }
    //New
    @media (min-width: $breakpoint--xLarge) {
      .header--secondary & {
        height: $desktopNavHgt; 
      }
      .header--fixed & {
        height: $mobileNavHgt;
      }
    }
  }
  @media (min-width: $breakpoint--medium) {
  #att-microsite-header:not(.header--fixed):not(.plusGlobal){
    &.header--secondary .microsite-nav__menu a{
      @include navPadding(46px 0, -1px);
    }
     .microsite-nav__utils{
       height: $desktopNavHgt;
        .header--secondary & {
          height: $desktopNavHgt;
      }
    }
  }
}

  .microsite-nav__utils {
    @include clearfix;
    @include transitionAll(450ms);
    height: $mobileNavHgt;
    padding: 0 20px;
    .header--fixed .microsite-nav--plusGlobal & {
      height: $micrositeMobileNavHgt; //originally calc(100% - #{$micrositeCollapsedGlobalNav});
    }

    @media (min-width: $breakpoint--small) {
      padding: 0 $gutter--small;
    }
    @media (max-width: $breakpoint--small){
            //new
            .header--secondary .microsite-nav--plusGlobal &, .header--fixed & {
              height:$micrositeMobileNavHgt;
            }
    }

    @media (min-width: $breakpoint--medium) {
      display: none; //only affects midpage nav - midpage nav doesnt show title in desktop
      
      .header--secondary & { //removed @include centerVertically
        display: block;
        float: left;
        height: $mobileNavHgt;
      } 
    }

    @media (min-width: $breakpoint--xLarge) {
      //TO DO: check if this works
      .header--secondary & {
        padding:0;
      }
      .header--secondary:not(.header--fixed) .microsite-nav--plusGlobal & {
        height:$mobileNavHgt;
      }
      
    }
    
    .microsite-nav__logo,
    .microsite-nav__title {
      @include centerVertically;
      display: block;
      float: left;
    }

    .microsite-nav__logo {
      max-width: 100%;

      img {
        position: relative;
        max-width: 100%;
        top: 4px;
      }
    }

    .microsite-nav__title {
      @include font-size(24);
      @include font-smoothing;
      max-width: 85%;
      font-family: $aleck;
      font-weight: 700;
      color: $white;

      @media (min-width: $breakpoint--xSmall) {
        @include font-size(26);
        max-width: none;
      }

      &:hover {
        text-decoration: none;
      }

      &:not(:first-child) {
        display: none;
      }
    }

    .microsite-nav__toggle {
      @include centerVertically;
      position: relative;
      float: right;
      width: 26px;
      height: 26px;

      @media (min-width: $breakpoint--medium) {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 19px;
        height: 19px;
        border-top: 2px solid $white;
        border-left: 2px solid $white;
        border-radius: 3px;
        transform: rotate(-135deg);

        .microsite-nav--open & {
          transform: rotate(-315deg);
          top: 10px;
        }
      }
    }
  }

  .microsite-nav__menu {
    display: none;

    .microsite-nav--open & {
      display: block;
    }

    @media (min-width: $breakpoint--medium) {
      display: block;
    }

    .header--secondary & {
      @media (min-width: $breakpoint--medium) {
        padding-right: 55px;
        float: right;
      }

      @media (min-width: $breakpoint--xLarge) {
        //@include centerVertically;
        padding-right: 0;
        margin-top: 0;
        height: 100%;
      }
    }

    ul {
      max-width: $maxContentWidth;
      margin: 0 auto;

      @media (min-width: $breakpoint--medium) {
        text-align: center;
      }

      .header--secondary & {
        text-align: left;

        @media (min-width: $breakpoint--medium) {
          max-width: none;
          height: 100%;
        }
      }
    }

    li {
      border-top: 1px solid $drkGray;
      padding: 0 20px;

      @media (min-width: $breakpoint--small) {
        padding: 0 $gutter--small;
      }

      @media (min-width: $breakpoint--medium) {
        display: inline-block;
        padding: 0 28px;
        border-top: 0;
      }

      .header--secondary & {
        @media (min-width: $breakpoint--medium) {
          padding: 0 0 0 25px;

          &:first-child {
            padding-left: 0;
          }
        }

        @media (min-width: $breakpoint--large) {
          padding-left: 40px;
          height: 100%;
        }

        @media (min-width: $breakpoint--xLarge) {
          padding-left: 55px;
        }
      }
    }
    //TO DO: make all these padding changes variables
    a {
      @include font-size(14);
      @include font-smoothing;
      @include navPadding(30px 0, 0px);
      @include transitionAll(450ms); //new
      position: relative;
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
      text-decoration:none;

      &:hover,
      &.active {
        text-decoration: none;
        color: $color--cta;
      }

      @media (min-width: $breakpoint--medium) {
        @include highlight-bar($highlightBar);
        &:hover:before,
        &:focus:before,
        &.active:before {
          opacity: 1;
        }
      }
      
      @media (min-width: $breakpoint--medium) {
        .header--secondary & {
          @include font-size(12);
          @include navPadding(30px 0, -1px);
        }
        .header--secondary.header--fixed & {
          @include navPadding(29px 0, 0px);
        }
        .header--secondary .microsite-nav--plusGlobal & {
          @include navPadding(24px 0, -1px);
        }
      }
      @media (min-width: $breakpoint--xLarge) {
        .header--secondary.header--fixed & {
          @include navPadding(29px 0, 0px);
        }
        .header--secondary .microsite-nav--plusGlobal & {
          @include navPadding(24px 0, -1px);
        }
      }
      //new
      @media (min-width: $breakpoint--xxLarge) {
        .header--secondary & {
          @include font-size(14);
          @include navPadding(45px 0, -1px);
        }
        .header--secondary.header--fixed & {
          @include navPadding(29px 0, 0px);
        }
        .header--secondary .microsite-nav--plusGlobal &{
          @include font-size(14);
          @include navPadding(29px 0, -1px);
        }
        .header--secondary.header--fixed .microsite-nav--plusGlobal &{
          @include font-size(14);
          @include navPadding(23px 0, -1px);
        }
      }
    }
  }
