/*=====
  ALERT
  =====*/

  $alertColors: (
    black: $black,
    blue: $attBlue,
    tier-low: $cobalt,
    tier-med: $tangerine,
    tier-high: $berry
  );

  .alert {
    padding: 20px;
    color: $functionalBlack;
    &.alert--centered {
      text-align: center;
    }

    .row--equal-heights & {
      height: 100%;
    }

    /* Loop through possible section background colors. */
    @each $name, $color in $alertColors {
      &.alert--#{$name} {
        background-color: $color;
      }

      &.alert--silver {
        @include bkg-silver;
      }
      
      &.alert--black,
      &.alert--tier-low, {
        color: $white;
      }
    }

    /* Special handling for bottom border */
    .component.component--border-bottom & {
      padding-bottom: 20px;

      &:after {
        bottom: -#{$air--large};

        @media (min-width: 768px) {
          bottom: -#{$air--xLarge};
        }

        @media (min-width: 1220px) {
          bottom: -#{$air--xxLarge};
        }
      }
    }

    &--geo-targeted {
      display: none;
    }
  }

  .alert__message {
    transition: opacity 600ms ease;
    font-family: $aleckBlack;
    max-width: $maxContentWidth;
    max-height: 52px;
    overflow: hidden;
    width: 100%;
    opacity: 0;

    .col:not(.col-12) & {
      max-height: none;
    }

    .row--equal-heights & {
      @include centerVertically;
    }

    @media (min-width: $breakpoint--large) {
      margin: 0 auto;
    }

    &.alert__message--revealed {
      opacity: 1;
    }

    .message--mobile {
      @include font-size(18);
      line-height: 1.35;

      @media (min-width: $breakpoint--small) {
        display: none;
      }
    }

    .message--desktop {
      @include font-size(20);
      line-height: 1.25;
      display: none;

      @media (min-width: $breakpoint--small) {
        display: block;
      }
    }

    a {
      display: inline-block;
      color: $color--cta;
      text-decoration: underline;
      margin: 0;
      zoom: 1;

      // @media (max-width: $breakpoint--small - 1) {
      //   display: inline-block;
      // }

      .alert--blue &, .alert[class*="tier"] & {
        color: inherit;
      }
    }
  }
