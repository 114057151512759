/*============
  STORY ASSETS
  ============*/

@mixin focusStyles {
  outline: 1px dashed $medGray;
}

.story__assets select {
    font-family: $aleck;
    padding:7px;
    max-width:100%;
    border-radius:5px;
    color:$drkGray;
    border:1px solid $regGray;
    background-color:transparent;
    margin-top:-7px;
}