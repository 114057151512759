/*============
  STOCK TICKER
  ============*/

  .stock-ticker,
  .stock-ticker__wrap {
    .row--equal-heights & {
      height: 100%;
    }
  }

  .stock-ticker__wrap {
    @include clearfix;
    box-sizing: border-box;
    padding: $air--xSmall;
    font-family: $aleck;
    text-align: center;
    color: $black;
    .cta--link {
      text-decoration: none;
    }

    /* NOTE: Stock ticker background color can vary based on the page category (theme). Default treatment is silver. */

    @include bkg-silver;
    @each $category, $color in $themeColors {
      $colorName: nth($color, 1);
      $colorValue: nth($color, 2);

      .theme--#{$category} & {

        @if $colorName == "silver" {
          @include bkg-silver;
          color: $black !important;

        } @else {
          background: none;
          background-color: $colorValue;
          color: $white !important;

          @if $colorName == "blue" {
            .stock-ticker__timestamp {
              font-family: $aleckMedium;
            }
            .cta--link {
              color: $white !important;
            }
          } @else {
            .cta--link {
              color: $color--cta;
            }
          }
        }
      }
    }

    .row--equal-heights & {
      @include centerVertically;
    }

    div {
      @include clearfix;
      position: relative;
      text-align: center;

      @media (min-width: $breakpoint--xSmall) {
        display: inline-block;
      }
    }

    span {
      display: inline-block;

      @media (min-width: $breakpoint--xxSmall) {
        float: left;
      }
    }
  }

  /* Ticker Price */

  .stock-ticker__price {
    @include font-size(22);
    display: inline-block;
    font-family: $aleckMedium;
    margin-bottom: 10px;

    @media (min-width: $breakpoint--xSmall) {
      margin-bottom: 0;
    }

    @media (min-width: $breakpoint--small) {
      margin-right: 15px;
    }
  }

  /* Ticker Change */

  .stock-ticker__change {
    @include font-size(22);
    display: inline-block;
    margin: 0 10px 10px 15px;

    @media (min-width: $breakpoint--xSmall) {
      margin-bottom: 0;
    }

    .change__symbol {
      position: relative;
      top: -2.5px;

      @media (min-width: $breakpoint--xSmall) {
        margin-right: 5px;
      }
    }
  }

  /* Ticker Timestamp */

  .stock-ticker__timestamp {
    @include font-size(15);
    margin: 0 15px 10px 0;

    @media (min-width: $breakpoint--xxSmall) {
      display: inline-block;
      top: -1px;
    }

    @media (min-width: $breakpoint--xSmall) {
      margin-bottom: 0;
    }

    .exchange__date {
      margin-right: 8px;
    }
  }

  /* Ticker Info */

  .stock-ticker__info {
    margin-left: -25px;

    @media (min-width: $breakpoint--xSmall) {
      display: inline-block;
      margin-left: 15px;
      top: -3px;
    }

    .cta--link {
      @include font-size(15);

      .region[class*="blue"] & {
        color: $color--link;
      }
    }
  }
