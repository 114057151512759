/*===============
  EXPANDABLE TEXT
  ===============*/

  .expandable-text {
    margin-top: -20px;
    padding: 0 20px;

    .col & {
      padding: 0;
    }

    .text__wrap {
      display: none;
      margin-top: 40px;
    }

    .text__cta {
      margin: 0 0 20px;
      padding-top: 20px;

      .cta--link {
        font-family: $aleckMedium;
        font-weight: 400; 

        &:after {
          content: none;
        }
      }
    }

    &.text--footer {
      .text__wrap {
        border-top: 1px solid $regGray;
        padding-top: 45px;

        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              border-color: $functionalBlack;
            }
            @if (nth($color, 2) == $white) {
              border-color: $white;
            }
          }
        }

        .region--white &, .region--gray & {
          border-color: $regGray;
        }

      }

      .cta--link {
        @include font-size(15);
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  /* Author Instance Display Fixes */

  .aem-AuthorLayer-Edit {
    .expandable-content {
      margin-top: 0;
    }
  }
