/*=============
  MEDIA LIBRARY
  =============*/

  .media-library {

    .row {
      margin-top: 0;
    }

    .col-2 {
      @media (min-width: $breakpoint--large) {
        @include column(2.4);
      }
    }

    .component {
      margin-bottom: 0;
    }

    .item__media {
      // min-height: 220px;

      .item__img img {
        margin: 0 auto;
      }
    }

    .item__media,
    .item__img {
      background-color: transparent;
    }

    .item__preview {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      
      // .item__thumbnail {
      //   border: 1px solid $regGray;
      //
      //   .region--blue & {
      //     border-color: $medGray;
      //   }
      //
      //   .region--dark-blue &,
      //   .region[class*="-black"] & {
      //     border-color: $drkGray;
      //   }
      // }

      .item__thumbnail:last-child {
        margin-bottom: 20px;
      }

      .item__content {
        background-color: transparent;
        padding: 10px 0 20px;

        @media (min-width: $breakpoint--small) {
          padding-bottom: 40px;
        }
        .item__title {
          color:inherit;
	        .region--white & {
            color:$attBlue; 
      	  } 
        }
	
      }
    }

    .library__cta {
      text-align: center;
      margin: 40px 0;

      // @media (min-width: $breakpoint--medium) {
      //   margin-top: 60px;
      // }
    }
  }

  .story .story__wrap .media-library .row__wrap {
    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
      display: flex;
    }
  }

  .blog-post .blog__wrap .media-library,
  .media-kit-page .media-kit__wrap .media-library {
    & > .row {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
        padding: 0 55px;
      }
    }
    & > .row .row__wrap {
      @media (min-width: $breakpoint--small) {
        margin: 0 -10px;
      }
    }
  }

  .blog-post .blog__wrap .region .media-library,
  .media-kit-page .media-kit__wrap .region .media-library {
    @media (min-width: $breakpoint--medium) {
      max-width: $maxContentWidth !important;
    }

    & > .row {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--xLarge - 1) {
        padding: 0 55px;
      }
    }
  }
