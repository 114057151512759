/*===========
  USER SURVEY
  ===========*/

@mixin focusStyles {
  &:focus {
    outline: 2px dotted #009fdb;
    outline-offset: 0;

    .user-survey--blue &,
    .user-survey--gradient &,
    .user-survey--cobalt &,
    .user-survey--black & {
      outline: 2px dotted $white;
    }
  }
}

@mixin sliderTrackStyles {
  background: $attBlue;
  width: 100%;
  height: 4px;
  border-radius: 4px;

  .user-survey--blue &,
  .user-survey--gradient & {
    background: $black;
  }

  .user-survey--cobalt & {
    background: $attLgtBlue;
  }
}

@mixin sliderThumbStyles {
  border: 2px solid $attBlue;
  height: 28px;
  width: 18px;
  border-radius: 6px;
  background: $white;
  margin-top: -12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  @media (min-width: $breakpoint--medium) {
    height: 32px;
    width: 20px;
    margin-top: -14px;
  }

  .user-survey--silver & {
    background: $lgtGray;
  }

  .user-survey--blue &,
  .user-survey--gradient & {
    border-color: $black;
  }

  .user-survey--blue & {
    background: $attBlue;
  }

  .user-survey--gradient & {
    background: #01A2D9;
  }

  .user-survey--black & {
    background: $black;
  }

  .user-survey--cobalt & {
    border-color: $attBlue;
    background: $cobalt;
  }
}

.user-survey {
  position: relative;

  &[data-response-type="rating-scale"] .survey__choices,
  &[data-response-type="multi-choice"] .survey__rating {
    display: none;
  }

  &[data-mode="popup"],
  &[data-mode="videos"] {
    display: none;
    background-color: $white;
    max-width: 946px !important; // matches lightbox video width
    margin-bottom: 0;
  }

  .survey__instructions {
    width: 50%;
    @media (max-width: $breakpoint--small - 1) {
      width: 90% !important;
      margin-top: 25px;
    }
  }

  .user-survey__wrap {
    min-height: 450px;
  }

  &[data-mode="videos"] .user-survey__wrap {
    min-height: auto;

    @media (min-width: $breakpoint--small) {
      min-height: 532px;
    }
  }

  &.user-survey--silver .user-survey__wrap {
    background-color: $lgtGray;
  }

  &.user-survey--blue .user-survey__wrap {
    background-color: $attBlue;
  }

  &.user-survey--gradient .user-survey__wrap {
    @include gradientBkgd("blue", right);
  }

  &.user-survey--cobalt .user-survey__wrap {
    background: $cobalt;
    color: $white;
  }

  &.user-survey--black .user-survey__wrap {
    background: $black;
    color: $white;
  }
}

.survey__prompt,
.survey__thanks {
  @include centerVertically;
  display: none;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  font-family: $aleck;
  color: $black;

  .user-survey--cobalt &,
  .user-survey--black & {
    color: $white;
  }
}

.user-survey--submitted .survey__thanks,
.user-survey--not-submitted .survey__prompt {
  display: block;
}

.user-survey--submitted .survey__prompt,
.user-survey--not-submitted .survey__thanks {
  display: none;
}

.survey__question,
.survey__thanks h1,
.survey__thanks h2 {
  @include font-size(26);
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.25;

  .user-survey[data-mode="popup"] &,
  .user-survey[data-mode="videos"] & {
    font-weight: 300;
  }

  @media (min-width: $breakpoint--small) {
    @include font-size(33);
  }
}

.survey__question:focus,
.survey__thanks:focus {
  outline: 0;
}

.survey__answer {
  margin-bottom: 10px;

  label {
    @include font-size(18);
    vertical-align: top;
  }
}

.survey__choices {
  display: flex;
  display: -webkit-inline-box;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  -ms-flex-align: center;
  -ms-flex-positive: 1;

  @media (min-width: $breakpoint--small - 1) {
    padding-bottom: 30px;
  }

  .option {
    margin-bottom: 20px;
    text-align: left;

    -ms-flex-align: center;
    min-width: 250px;
    width:100%;
    display:inline-block;

    &:last-of-type {
      margin-bottom: 0;
    }

    input {
      @include focusStyles;
      border-color: #d2d2d2;
      margin-left:40%;
    }
    @media (max-width: $breakpoint--small - 1) {
      input {
        margin-left:35%;
      }
    }
  }

  .user_survey_input {
    @include focusStyles;
    border-radius: 5px;
    display: block;
    margin-top: 10px;
    width: 250px;
    height: 30px;
    color: #000 !important;

    &.hidden {
      display: none !important;
    }
  }

  // &.survey__choices--multi {
  //   @media (min-width: $breakpoint--small) {
  //     flex-direction: row;

  //     .option {
  //       margin: 0 18px 20px;
  //     }
  //   }
  // }

  input {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }
}

.survey__rating {
  position: relative;
  max-width: 850px;
  margin: 50px auto 0;

  .rating-numbers {
    position: relative;
    display: flex;
    height: 30px;
    width: 60%;
    margin: 0 auto;

    @media (min-width: $breakpoint--xSmall) {
      height: 50px;
      width: 75%;
    }

    @media (min-width: $breakpoint--large) {
      width: 82%;
    }

    li span {
      @include font-size(20);
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      transition: all 300ms ease;
      font-weight: 300;
      color: $drkGray;

      @media (min-width: $breakpoint--large) {
        @include font-size(30);
      }

      .user-survey--blue &,
      .user-survey--gradient & {
        color: $black;
      }

      .user-survey--cobalt &,
      .user-survey--black & {
        color: $white;
      }
    }

    li {
      position: relative;
      width: 100%;

      &.active span {
        @include font-size(35);
        font-weight: 700;
        color: $attBlue;

        @media (min-width: $breakpoint--xSmall) {
          @include font-size(45);
        }

        @media (min-width: $breakpoint--large) {
          @include font-size(55);
        }

        .user-survey--blue &,
        .user-survey--gradient & {
          color: $black;
        }

        .user-survey--cobalt & {
          color: $attLgtBlue;
        }
      }

      &.near-active-1 span {
        @include font-size(25);
        font-weight: 600;

        @media (min-width: $breakpoint--xSmall) {
          @include font-size(35);
        }

        @media (min-width: $breakpoint--large) {
          @include font-size(45);
        }
      }

      &.near-active-2 span {
        @include font-size(20);
        //font-weight: 400;

        @media (min-width: $breakpoint--xSmall) {
          @include font-size(30);
        }

        @media (min-width: $breakpoint--large) {
          @include font-size(40);
        }
      }

      &.near-active-3 span {
        @include font-size(20);
        //font-weight: 400;

        @media (min-width: $breakpoint--xSmall) {
          @include font-size(25);
        }

        @media (min-width: $breakpoint--large) {
          @include font-size(35);
        }
      }
    }
  }

  .rating-scale {
    position: relative;
    padding: 15px 0;

    // @media (min-width: $breakpoint--medium) {
    //   padding: 50px 0;
    // }

    .rating-slider {
      position: relative;
      height: 40px;
    }

    input[type="range"] {
      @include centerVertically;
      @include focusStyles;
      width: 60%;
      height: 70px;
      cursor: pointer;
      -webkit-appearance: none;
      background: transparent;

      @media (min-width: $breakpoint--xSmall) {
        width: 70%;
      }

      @media (min-width: $breakpoint--large) {
        width: 76%;
      }

      /* Slider Track */

      &::-webkit-slider-runnable-track {
        @include sliderTrackStyles;
      }

      &::-moz-range-track {
        @include sliderTrackStyles;
      }

      &::-ms-track {
        @include sliderTrackStyles;
        height: 50px;
        margin-top: 35px;
        border: 0;
        border-color: transparent;
        background: transparent;
        color: transparent;
        cursor: pointer;
      }

      &::-ms-fill-lower {
        background: $attBlue;
        height: 4px;
        margin-top: -20px;

        .user-survey--blue &,
        .user-survey--gradient & {
          background: $black;
        }

        .user-survey--cobalt & {
          background: $attLgtBlue;
        }
      }

      &::-ms-fill-upper {
        background: $attBlue;
        height: 4px;
        margin-top: -20px;

        .user-survey--blue &,
        .user-survey--gradient & {
          background: $black;
        }

        .user-survey--cobalt & {
          background: $attLgtBlue;
        }
      }

      /* Slider Thumb */

      &::-webkit-slider-thumb {
        @include sliderThumbStyles;
        -webkit-appearance: none;
      }

      &::-moz-range-thumb {
        @include sliderThumbStyles;
      }

      &::-ms-thumb {
        @include sliderThumbStyles;
        margin-top: -30px;
        height: 11px;

        @media (min-width: $breakpoint--medium) {
          margin-top: -30px;
          height: 11px;
        }
      }
    }
  }

  .rating-label {
    @include font-size(15);
    @include centerVertically;
    font-family: $aleckMedium;
    position: absolute;
    width: 20%;

    @media (min-width: $breakpoint--xSmall) {
      width: 15%;
    }

    @media (min-width: $breakpoint--large) {
      width: 12%;
    }

    &.rating-label--low {
      left: 0;
      padding-right: 10px;
      text-align: right;
    }

    &.rating-label--high {
      right: 0;
      padding-left: 10px;
      text-align: left;
    }
  }

  .rating-instructions:focus {
    outline: 0;
  }
}

.survey__submit {
  position: relative;

  .cta--button-new {
    @include cta-new(black, white);
    display: block;
    max-width: 130px;
    margin-left: auto;
    margin-right: auto;

    .user-survey--black &,
    .user-survey--cobalt & {
      @include cta-new(white, black);
    }
  }
}

.survey__error {
  @include font-size(14);
  display: none;
  position: relative;
  padding-bottom: 2px;
  margin: 0 auto 20px;
  font-weight: 700;
  font-style: italic;
  color: $berry;

  &.submit-error {
    bottom: -30px;
    margin-bottom: 0;

    @media (min-width: $breakpoint--medium) {
      @include centerHorizontally;
      position: absolute;
      bottom: -50px;
    }
  }

  &:focus {
    outline: 0;
  }

  &.visible {
    display: inline-block;
  }

  .region[class*="-blue"] &,
  .user-survey--blue &,
  .user-survey--cobalt &,
  .user-survey--gradient & {
    color: $white;
  }
}

.survey__instructions {
  @include font-size(14);
  display: block;
  position: relative;
  padding-bottom: 2px;
  margin: 0 auto 20px;
  font-weight: 700;
  font-style: italic;
  color: $black;

  &:focus {
    outline: 0;
  }

  &.visible {
    display: inline-block;
  }

  .region[class*="-blue"] &,
  .user-survey--blue &,
  .user-survey--cobalt &,
  .user-survey--gradient & {
    color: $white;
  }
}

.survey__notification:focus {
  outline: 0;
}

.survey__thanks {

  h3,
  h4 {
    @include font-size(24);
    margin-bottom: 20px;
  }

  p {
    @media (min-width: $breakpoint--small) {
      @include font-size(21);
    }
  }

  a {
    text-decoration: underline;
    color: $color--link;
  }

  .user-survey--blue & a,
  .user-survey--gradient & a {
    color: $black;
  }

  .user-survey--black & a,
  .user-survey--cobalt & a {
    color: $white;
  }

}

/* Popup & Video Mode-specfiic Styles */

.user-survey.fancybox-content {
  padding: 0;

  .survey__instructions {
    width: 85%;
    @media (max-width: $breakpoint--small - 1) {
      width: 75% !important;
      margin-top: 25px;
    }
  }

  @media (max-width: $breakpoint--xSmall - 1) {
    width: 100% !important;
  }
}

.user-survey.fancybox-content,
.user-survey.fancybox-content .user-survey__wrap {
  @media (max-width: $breakpoint--xSmall - 1) {
    min-height: 400px !important;
  }
}

.survey__close {
  padding: 10px 15px;
  position: absolute;
  right: 0;

  // .user-survey[data-mode="videos"] & {
  //   display: none;
  // }

  .cta--close {
    @include focusStyles;
    text-decoration: none;
    font-weight: 300;
    color: $black;

    .user-survey--black &,
    .user-survey--cobalt & {
      color: $white;
    }

    span {
      @include font-size(25);
      position: relative;
      top: 3px;
    }
  }
}