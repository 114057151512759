/*====
  TILE
  ====*/

.tile {
  .row--equal-heights & {
    @media (min-width: $breakpoint--small) {
      height: 100%;
    }
  }


}

.tile__wrap {
  display: block;
  position: relative;
  overflow: hidden;

  .row--equal-heights & {
    @media (min-width: $breakpoint--small) {
      height: 100%;
    }
  }
}

.tile__img {
  overflow: hidden;

  .row--equal-heights & {
    @media (min-width: $breakpoint--small) {
      height: 100%;
    }
  }

  .row--equal-heights & {
    &.compat-object-fit {
      @include transitionAll(1s);

      img {
        opacity: 0;
      }

      @media (min-width: $breakpoint--small) {
        background-size: cover;
        background-position: left;
      }
    }
  }

  .row--equal-heights .tile:hover &.compat-object-fit {
    transform: scale3d(1.1, 1.1, 1) rotate(0.1deg);
    max-width: none;
  }

  &>div {
    height: 100%;
  }

  img {
    @include transitionAll(1s);
    display: block;
    margin: 0 auto;
    width: 100%;

    .row--equal-heights & {
      @media (min-width: $breakpoint--small) {
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        object-fit: cover;
        object-position: left;
      }
    }

    .tile:hover & {
      transform: scale3d(1.1, 1.1, 1) rotate(0.1deg);
      max-width: none;
    }
  }
}

.tile__content {
  position: absolute;
  padding: 0 25px;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: $white;

  /* Bottom color bar */
  &:after {
    content: "";
    position: absolute;
    display: block;
    height: 8px;
    width: 100.5%;
    bottom: 0;
    left: 0;

    /* NOTE: Bottom color bar can vary based on the page category (theme). Default treatment is silver. */

    @include bkg-silver;

    @each $category,
    $color in $themeColors {
      $colorName: nth($color, 2);

      .theme--#{$category} & {
        background: $colorName;
        filter: none;
      }

      @if $colorName=="silver" {
        @include bkg-silver;
      }

      @elseif $colorName==$attBlue {
        .theme--#{$category} .region--blue & {
          background: darken($attBlue, 8%);
        }
      }
    }
  }

  /**/

  .tile__headline {
    @include font-size(25);
    max-width: 80%;
    font-weight: 300;
    font-style: italic;
    position: absolute;
    bottom: 60px;
    line-height: 1.15;

    @media (min-width: $breakpoint--xSmall) {
      @include font-size(30);
    }

    @media (min-width: $breakpoint--medium) {
      @include font-size(38);
      max-width: 70%;
      bottom: 85px;
    }

    .col-2 &,
    .col-3 &,
    .col-4 & {
      @media (min-width: $breakpoint--medium) {
        @include font-size(30);
        max-width: 80%;
        bottom: 65px;
      }
    }

    .col-4 & {
      @media (min-width: $breakpoint--large) {
        @include font-size(38);
      }

      @media (min-width: $breakpoint--xLarge) {
        max-width: 70%;
      }
    }

    .col-9+.col-3 &,
    .col-8+.col-4 & {
      @media (min-width: $breakpoint--medium) {
        bottom: 85px;
      }
    }
  }

  .tile__subheadline {
    @include font-size(16);
    font-family: $aleck;
    font-weight: 700;
    position: absolute;
    bottom: 20px;

    @media (min-width: $breakpoint--small) {
      @include font-size(14);
    }

    @media (min-width: $breakpoint--medium) {
      @include font-size(19);
    }

    .col-2 &,
    .col-3 & {
      @media (min-width: $breakpoint--medium) {
        @include font-size(14);
      }

      @media (min-width: $breakpoint--xLarge) {
        @include font-size(16);
      }
    }

    .col-4 & {
      @media (min-width: $breakpoint--medium) {
        @include font-size(16);

        @media (min-width: $breakpoint--large) {
          @include font-size(19);
        }
      }
    }
  }
}