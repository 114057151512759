/*==============
  BLOG TEMPLATES
  ==============*/

/* General Blog Elements */

.article [class^="blog__"] {
  .col.col-8 {
    @media (max-width: $breakpoint--large - 1) {
      max-width: none;
    }
  }
}

.blog__header {
  position: relative;

  .article__header {
    @media (min-width: $breakpoint--large) {
      .header--transparent+.content & {
        height: calc(100% - #{$desktopNavHgt});
      }
    }

    .featured-category {
      padding: 40px 0 30px;

      @media (min-width: $breakpoint--small) {
        padding: 50px 0;
      }

      @media (min-width: $breakpoint--medium) {
        padding: 80px 0 50px;
      }

      color: $attBlue;
      @include special-bold($white);

      em {
        color: $attBlue;
      }

      a {
        color: $black;
      }
    }
    .story--pub-date {
      p {
        @include font-size(16);
        //font-weight: 700;
        margin-bottom: 0px;
      }
    }

    .story-title{
      .component{
        margin-bottom:0px;
      }
    }

    .story--description{
      p{
        max-width:100%;
        @include font-size(18);
      }
    }

    .text.story-info p {
      margin-top:-23px;
      @include font-size(16);
    }

    //p {
    //  @include font-size(21);
    //  line-height: 1.45;

    //  @media (min-width: $breakpoint--large) {
    //    max-width: 40%;
    //  }
    //}

    .blog--author {
      p {
        @include font-size(16);
        width: 100%;
        max-width: 100%;
        margin-bottom: 40px;
      }
    }
  }
  
  .featured-article {
    padding-top: 30px;

    @media (min-width: $breakpoint--large) {
      .header--transparent+.content & {
        height: calc(100% - #{$desktopNavHgt});
      }
    }

    .featured-category {
      color: $attBlue;
      @include special-bold($white);

      em {
        color: $attBlue;
      }

      a {
        color: $black;
      }
    }

    p {
      @include font-size(21);
      line-height: 1.45;

      @media (min-width: $breakpoint--large) {
        max-width: 40%;
      }
    }
  }

  .featured-article__cta {
    margin: 25px 0;

    .cta--link {
      color: $attBlue;

      @media (min-width: $breakpoint--large) {
        @include font-size(21);
        font-weight: 400;
      }

    }
  }

  &.blog__header--light .featured-category {
    @include special-bold($black);

    em {
      color: $attBlue;
    }
  }
}

//this will impact only editable blog template headers
.blog-main.aem-GridColumn { 
  .blog__header .article__header {
    padding-bottom: 0px;
  }
}

/* Blog Landing Styles */
/* =================== */

.blog-landing .blog__header {
  padding: 0;
  background-color: $black;
  color: $white;

  &.blog__header--light {
    background-color: $white;
    color: $black;
  }

  .featured-image {
    overflow: hidden;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;

      @media (max-width: $breakpoint--medium - 1) {
        width: 100%;
      }
    }
  }

  .featured-article {
    padding: 40px 20px;

    @media (min-width: $breakpoint--small) {
      padding-left: $gutter--small;
      padding-right: $gutter--small;
    }

    @media (min-width: $breakpoint--small) {
      @include centerHorizontally;
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      max-width: $maxContentWidth;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-top: 65px;
      padding-left: 0;
      padding-right: 0;
    }

    .featured-headline {
      @include font-size(35);
      padding-top: 30px;
      line-height: 1.15;

      @media (min-width: $breakpoint--medium) {
        @include font-size(50);
        padding-top: 60px;
      }

      @media (min-width: $breakpoint--large) {
        @include font-size(60);
        padding-bottom: 50px;
      }

      @media (min-width: $breakpoint--xLarge) {
        max-width: 50%;
      }
    }

    .featured-headline,
    .featured-description {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
        max-width: 70%;
      }
    }
  }
}

/* Blog Post Styles */
/* ================ */

.blog-post .blog__header {
  .featured-article {
    padding: 40px 0 30px;

    @media (min-width: $breakpoint--small) {
      padding: 50px 0;
    }

    @media (min-width: $breakpoint--medium) {
      padding: 80px 0;
    }
  }

  .featured-category b,
  .featured-category strong {
    color: $black;
  }

  .featured-category strong em {
    color: $attBlue;
  }

  .featured-headline {
    padding-bottom: 20px;
  }

  .blog__authors .author__meta:first-child {
    border-top:0px;
    padding-top: 20px;
  }
  
  .blog__authors .author__meta {
    margin-bottom: 20px;
}
.blog__authors .author__meta:last-child {
  margin-bottom: 0px;
}
}

.blog__authors {
  padding-bottom: 20px;

  .author__meta {
    margin-bottom: 40px;

    &:first-child {
      border-top: 1px solid $regGray;
      padding-top: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .author {
      margin-bottom: 5px;
    }

    .title,
    .info {
      @include font-size(15);
      line-height: 1.6;
    }

    .info{
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .title {
      margin-bottom: 10px;
    }

    .social-links__wrapper {
      display: flex;
      flex-direction: column;
      
      @media (min-width: $breakpoint--xxSmall) {
        flex-direction: row;
      }

      .social-link__item {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        
        @media (min-width: $breakpoint--xxSmall) {
          padding-right: 15px;
          padding-bottom: 0px;
        }

        .social-link__title {
          font-weight: 700;
        }

        span {
          margin-bottom: 0;
        }

        .social-link {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &.twitter,
          &.facebook,
          &.linkedin {
            color: $attDrkBlue;

            .region--black & {
              color: $white;
            }

            .region--blue--bl-text &,
            .region--blue-gradient--bl-text & {
              color: $black;
            }

            .region--blue--wh-text &,
            .region--comms-cobalt &,
            .region--blue-gradient--wh-text & {
              color: $white;
            }
          }

          &.twitter {
            i {
              background-color: $black;

              .region--black & {
                background-color: $white;
                color: $black;
              }
            }
          }

          &.facebook {
            i {
              background-color: #0766ff;
            }
          }

          &.linkedin {
            i {
              background-color: #0b66c2;
            }
          }

          i {
            min-width: 20px;
            max-width: 20px;
            height: 20px;
            margin-right: 3px;
            font-size: 16px;
            color: $white;
            background-color: $attBlue;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

/* Component Style Overrides */

.blog {

  /* Article */

  .article {
    padding-bottom: 40px;

    @media (min-width: $breakpoint--medium) {
      padding-bottom: 80px;
    }
  }

  .blog__header .col,
  .blog__authors .col {
    margin-left: auto;
    margin-right: auto;
  }

  .article__meta {
    margin-bottom: 30px;

    @media (min-width: $breakpoint--medium) {
      margin-bottom: 60px;
    }

    .meta__info {
      max-width: none;

      .date {
        font-weight: 700;
      }
    }
  }
}