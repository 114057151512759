/*=====
  FORMS
  =====*/

input {
  @include font-size(16);
  font-family: $aleck;
  line-height: 1;
  color: inherit;

  @media (min-width: $breakpoint--medium) {
    @include font-size(18);
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $drkGray;

    .region[class*="black"] &,
      .region[class*="blue"] &,
      .region[class*="cobalt"] &,
      .region[class*="purple"] & {
        color: $lgtGray;
      }
  }
  &::-moz-placeholder {
    color: $drkGray;

    .region[class*="black"] &,
      .region[class*="blue"] &,
      .region[class*="cobalt"] &,
      .region[class*="purple"] & {
        color: $lgtGray;
      }
  }
  &::-ms-input-placeholder {
    color: $drkGray;

    .region[class*="black"] &,
      .region[class*="blue"] &,
      .region[class*="cobalt"] &,
      .region[class*="purple"] &{
        color: $lgtGray;
      }
  }
  &::-ms-input-placeholder  {
    color: $drkGray;

    .region[class*="black"] &,
      .region[class*="blue"] &,
      .region[class*="cobalt"] &,
      .region[class*="purple"] & {
        color: $lgtGray;
      }
  }

  .region[class*="white"] &,
      .region[class*="gray"] &,
      .sidebar & {
        color: $drkGray;
      }
}

input,
textarea,
select {
  &:focus {
    outline-offset: 0;
  }

  &:-internal-autofill-selected {
    background-color: transparent !important;
  }

  &:-webkit-autofill {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }

  &:-webkit-text-fill-color {
    color: $functionalBlack !important;
  }
}

/*======================
    Submission Form Styles
    ======================*/

.section form[method=POST] {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;

  .col:not(.col-12) & {
    margin: 0;
  }

  @media (min-width: $breakpoint--xSmall) {
    padding: 0;
  }

  .form_leftcol {
    height: 0;
    line-height: inherit;
  }

  .form_leftcolmark {
    color: #b30a3c;
    font-weight: 700;
    padding-left: 2px;
  }

  .form_leftcollabel {
    float: left;
    margin-bottom: 10px;
  }

  .form_leftcolnobr {
    float: left;
    margin-bottom: 15px;
  }

  .form_rightcol {
    clear: both;
  }

  .form_rightcolnobr {
    clear: none;
    float: right;
    margin-bottom: 15px;
  }

  .form_rightcolnooverflow {
    overflow: hidden;
  }

  .title {
    padding-top: 10px;
  }

  .form_row {
    @include font-size(16);
    clear: both;
    display: block;
    padding-bottom: 5px;
  }

  label,
  .form_row_description {
    font-family: $aleckMedium;
  }

  .form_row_description {
    @include font-size(14);
    clear: both;
    color: $drkGray;
    line-height: 16px;
  }

  .form_field_checkbox,
  .form_field_radio {
    border: none;
    margin-left: 20px;
  }

  .form_field_text,
  .form_field_textarea {
    background: #f9f9f9;
    border-radius: 3px;
    border-color: $regGray;
    border-style: solid;
    border-width: 1px;
    color: $drkGray;
    font-size: 100%;
    line-height: 34px;
    margin: 0;
    max-width: 500px;
    padding: 0 5px;
    width: 100%;
  }

  .form_field_select {
    @include font-size(16);
    max-width: 500px;
    width: 100%;
  }

  .form_button_submit {
    @include font-size(16);
    background-color: $attBlue;
    color: $white;
    transition: all 300ms ease;
    display: inline-block;
    min-width: 150px;
    padding: 15px 30px 17px;
    text-decoration: none;
    text-align: center;
    border: 0;
    border-radius: 45px;
    font-family: $aleck;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;

    @media (min-width: $breakpoint--medium) {
      @include font-size(19);
      min-width: 170px;
    }
  }

  .customer_survey_submit {
    float: right;
    margin-top: 28px;
  }

  .form_field_text.form_field_multivalued {
    width: 314px;
  }

  .form_error {
    @include font-size(15);
    color: #f00;
    display: inline-block;
    padding: 5px 0;
  }

  p.form_error {
    padding: 0 0 10px;
  }

  div.section {
    margin-bottom: 30px;
  }

  div.colctrl.section {
    padding-bottom: 0 !important;
  }

  span.mr_write {
    display: inline-block;
    text-align: right;
    vertical-align: top;
    width: 16px;
  }
}

/* Submission Form Captcha */

.section form[method=POST] .form_captcha_input {
  float: left;
  width: 170px;

  input {
    width: 170px;
  }

  .form_captcha_img {
    float: left;
    padding-left: 16px;
  }

  .form_captchatimer {
    border: 1px solid $regGray;
    float: left;
  }

  .form_captchatimer_bar {
    background-color: $regGray;
    float: left;
    height: 8px;
  }
}