/*=====
  SHARE
  =====*/

  .share,
  [class*="__share"] {
    @include clearfix;
    position: relative;
    max-width: $maxContentWidth;
    overflow: hidden;
    margin: 0 20px;

    @media (min-width: $breakpoint--small) {
      margin-left: $gutter--small;
      margin-right: $gutter--small;
    }

    @media (min-width: $breakpoint--large) {
      margin-left: auto;
      margin-right: auto;
      padding-left: 55px;
      padding-right: 55px;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-left: 0;
      padding-right: 0;
    }

    .col & {
      margin-left: 0;
      margin-right: 0;

      @media (min-width: $breakpoint--small) {
        margin-left: 0;
        margin-right: 0;
      }

      @media (min-width: $breakpoint--large) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .component > & {
      padding-bottom: 10px;
      float: none;

      &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $regGray;
        bottom: 0;
        left: 0;

        @media (min-width: $breakpoint--large) and (max-width: $breakpoint--xLarge - 1) {
          width: calc(100% - (#{$gutter--small} * 2));
          left: $gutter--small;
        }

        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              background-color: $black;
            }
            @if (nth($color, 2) == $white) {
              background-color: $white;
            }
          }
        }
        .region--white &, .region--gray & {
          background-color: $regGray;
        }
      }
      &.no-bottom-border:before {
        content:none;
      }
    }

    .component--border-bottom & {
      text-align: center;
    }

    h4,
    ul {
      float: left;
    }

    &.share--right {
      h4, ul {
        float: right;
      }
    }

    h4 {
      height: 20px;
      line-height: 20px;
      text-transform: lowercase;
      font-family: inherit;
      font-size: inherit;
    }

    ul {
      margin: 0 10px;

      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 5px;
      }

      a:hover {
        text-decoration: none;
      }

      i {
        @include font-size(25);
        color: $drkGray;
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              color: $black;
            }
            @if (nth($color, 2) == $white) {
              color: $white;
            }
          }
        }
        .region--white &, .region--gray & {
          color:$drkGray;
        }
        .region[class*="black"] {
          color:$white;
        }
        &:hover {
          @each $name, $color in $backgroundColors {
            .region--#{$name} & {
              @if (nth($color, 2) == $functionalBlack) {
                color: $white;
              }
              @if (nth($color, 2) == $white) {
                color: $black;
              }
            }
          }
          .region--white &, .region--gray &, .region--black &, .region--near-black & {
            color:$color--cta;
          }
        }
      }
    }
  }
