/*=====
  RSS Feed
	=====*/
	
	$maxPopupWidth: 470px;

  .rss-feed {
  	position:fixed;
  	bottom: 15px;
    right: 15px;
  	z-index: 999999999;
		display:none;

		&.rss-feed--active {
			display:block;
		}

  	&--modal {
			top:0;
			left:0;
			right: 0;
			bottom: 0;
			width: 100vw; 
			height: 100vh;
  	}

  	&__bkgd {
  		@include transitionAll(1000ms);
			transform:translateY(500px);
			box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);

			.rss-feed--modal & {
				@include transitionAll(750ms);
				position:fixed;
	    	display: flex;
	    	top:0;
	  		bottom:0;
	  		left:0;
	  		right:0;
	    	align-items: center;
	    	justify-content: center;	//adding to see if it fixes in IE
				background:rgba(0,0,0,0.75);
				transform:translateY(0);
				opacity:0;
	    }

	    .rss-feed--transition & {
				@include transitionAll(1000ms);
  			transform:translateY(0);
  			opacity:1;
	    }
  	}

  	&__inner {
			background-color: $attDrkBlue;
			position: relative;
			max-height:100%; 
  		max-width: $maxPopupWidth;
			width:100%;
			padding-top: 125px;

      @media (min-width: $breakpoint--xSmall) {
        .rss-feed--popup & {
          width: $maxPopupWidth;
        }
      }

  		.rss-feed--modal & {
        max-width:530px;
  		}
  	}

    &__container {
    	padding: 20px;
    	background-color: $white;    

  	  h1,h2,h3,h4,h5,h6,p {
				text-align: center;
				max-width: 355px;
				margin: 0 auto;
		    margin-bottom: $air--xSmall;
				color:#191919;
				
        &:last-child {
          margin-bottom:0;
        }
		  }

		  p {
				@include font-size(15);
				font-family: $aleckMedium;
		  	line-height: 1.35;
		  }

		  @media (min-width: $breakpoint--xSmall) {
				padding: 30px 40px 20px;
		  }
      @media (min-width: $breakpoint--small) {
        .rss-feed--popup & {
          h1 {
            @include font-size(35);
          }
          h2 {
            @include font-size(24);
          }
          h3 {
            @include font-size(18);
          }
          h4 {
            @include font-size(16);
          }
        }
      }
    }

    &__intro {	
			position: relative;

			.rss-feed__icon {
				@include centerHorizontally;
				position: absolute;
				text-align:center;
				background-color: white;
				width: 137px;
				height: 137px;
				border-radius: 100%;
				top: -100px;
				 
        img {
					max-width:90px;
					margin-top: 30px;
    			margin-left: -5px;
        }
      }
      .rss-feed__content {
        width:100%;
				margin-right: 10px;
				position: relative;
    		padding-top: 25px;
      }
    }
    
      
    
    &__cta-header {
    	display:none;
    	margin:10px 0 30px;
    	text-align: center;

	    h3 {
	    	@include font-size(18);
	    	font-family: 'Aleck Sans', Helvetica, Arial, sans-serif;
	    	font-weight:bold;
	    }

	    .rss-feed--modal & {
	    	display: block;
	    }

	    @media (min-width: $breakpoint--xSmall) {
	    	margin:20px 0 30px;

	    	h3 {
		    	@include font-size(21);
		    }
	    }
    }

    &__cta {
    	margin: 30px 0 20px;

    	.cta--button {
    		width:100%;
    		min-width:auto;
    		border-radius: 0;
    		padding: 18px 30px 20px;
    		background-color: $white;
    		color: $attDrkBlue;
				font-family: $aleckMedium;
				display: block;
				margin: 0 auto;
    		
    		@media (min-width: $breakpoint--xSmall) {
    			@include font-size(15);
    			width:calc(50% - 30px);
    			min-width:150px;
				}
				
				&:hover {
					background-color: transparent;
					color: $functionalBlack;
				}
    	}

    	.cta--signup {
    		@include accent-arrow("right");
    		margin-bottom: 10px;
    		padding-left:0;
    		background-color: $attDrkBlue;
    		color:$white;

    		&:after {
					content: url("/../_assets/dist/img/icon_arrow-right.png");
    			position:absolute;
    			top:12px;
    			display: inline-block;
    			margin-left:10px;
    			font-size:24px;
				}
				
				&:hover {
					background-color: $black;
					color: $white;
				}
    	}
    }

    &__close {
			@include font-size(15);
			position: absolute;
			height: 40px;
			width: 40px;
			top: -20px;
			left: -20px;
			overflow: hidden;
			border-radius: 100%;
			background-color: $white;
			color: transparent;
			font-weight: 300;
			box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.2);

			.rss-feed--modal & {
				right: -20px;
				left: auto;
			}
    	
    	&:after {
    		@include font-size(50);
				content: "+";
				position: absolute;
				display: block;
				top: -13px;
    		left: 6px;
				color: $black;
				transform: rotate(45deg);
    	}

    	// @media (min-width: $breakpoint--xSmall) {
      //   @include font-size(16);
  			
  		// 	&:after {
    	// 		@include font-size(24);
    	// 	}

    	// 	.rss-feed--modal & {
    	// 		margin:13px 35px 0 0;
    	// 	}
  		// }
    }
  }
	//IE10 & 11 Fixes
	.flexboxtweener.no-flexboxlegacy {
    .rss-feed__close:after {
			top: -5px;
		}
  }