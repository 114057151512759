/*=============
  FEATURED ITEM
  =============*/

  @mixin ftItemTextColor($color) {
    .item__title,
    .item__info,
    .item__description {
      color: $color;
    }
  }

  .featured-item {
    @include clearfix;
    // overflow: hidden;
    padding: 0 20px;

    .col & {
      padding: 0;
    }

    @media (min-width: $breakpoint--small) {
      .row--equal-heights & {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    @media (min-width: $breakpoint--medium) {
      // .col-2 & { /* Tiny orientation for smallest columns */
      //   max-width: 190px;
      //   margin: 0 auto;
      // }

      padding: 0;

      .row--equal-heights & {
        display: flex;
        flex-direction: column;
      }
    }

    .col-12 &.featured-item--left,
    .col-12 &.featured-item--right {
      @media (min-width: $breakpoint--small) {
        display: flex;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .col-12 &.featured-item--right {
      @media (min-width: $breakpoint--small) {
        flex-direction: row-reverse;
      }
    }

    &.featured-item--alternate {

      @media (min-width: $breakpoint--xSmall) {
        padding-left: 10%;
        padding-right: 10%;

        .col-4 & {
          padding-left: 0;
          padding-right: 0;
        }
      }

      @media (min-width: $breakpoint--large) {
        padding-left: $gutter--large;
        padding-right: $gutter--large;

        .col-2 &,
        .col-3 & {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    }
  }

  /* Featured Item Media */

  .item__media {
    // background-color: $black;
    overflow: hidden;

    .featured-item--alternate & {
      background-color: transparent;
    }

    .featured-item--border & {
      border: 1px solid $drkGray;
    }

    .col-12 .featured-item--left &,
    .col-12 .featured-item--right & {
      @media (min-width: $breakpoint--small) {
        width: 50%;
        max-width: 470px;

        // & > div {
        //   @include centerVertically;
        // }
      }
    }

    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col-12 .featured-item--left & {
      @media (min-width: $breakpoint--small) {
        float: left;
      }
    }

    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .col-12 .featured-item--right & {
      @media (min-width: $breakpoint--small) {
        float: right;
      }
    }

    /* General Preview Images */

    .item__preview {
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: 0;
      }
    }

    /* Image */

    .item__img {
      position: relative;
      background-color: $white;

      .featured-item--alternate & {
        background-color: transparent;
      }

      .col-2 & {
        @media (min-width: $breakpoint--small) {
          background-color: transparent;
        }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;

        &:not(.item__preview) {
          transition: opacity 200ms ease;

          .featured-item &:hover,
          .featured-item &:focus {
            opacity: 0.9;
          }
        }
      }

      &[data-placeholder="true"] {
        height: 35px;
        background-color: $black;

        .region[class*="-black"] & {
          background-color: $functionalBlack;
        }

        img {
          display: none;
        }

        a:hover {
          text-decoration: none;
        }

        // a:after {
        //   content: map-get($attIcons, att-logo);
        //   position: absolute;
        //   width: 100%;
        //   top: 50%;
        //   transform: translate(0, -50%);
        //   font-family: $att-icons;
        //   font-size: 6.5rem;
        //   text-indent: 5px;
        //   color: $white;
        // }
      }

      img {
        display: block;
        max-width: 100%;

        @media (max-width: $breakpoint--small - 1) {
          width: 100%;
        }

        .featured-item--alternate & {
          margin: 0 auto;
          width: auto;
        }
      }
    }

    /* Video */

    .item__video {

      &[data-type="youtube"] {
        .item__video--gvp {
          display: none;
        }
      }

      &[data-type="gvp"] {
        .item__video--youtube {
          display: none;
        }
      }

      .item__preview {
        img {
          display: block;
          max-width: 100%;
          width: 100%;
        }
      }

      .item__thumbnail {
        @include icon-overlay("play");
        @include font-smoothing;
      }
    }

    [class*="embed--"] {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  /* Item Text Content */

  @each $name, $color in $backgroundColors {
    @if (nth($color, 2) == $white) {
      .region--#{$name} {
        .item__content {
          @include ftItemTextColor($functionalBlack);
          .cta--link {
            color:$functionalBlack;
          }
          .cta--button {
            @include cta("black");
          }
        }
        .featured-item--alternate {
          .item__content {
            .cta--link {
              color:$white !important;
              text-decoration: none !important;
            }
            .cta--button {
              @include cta("white");
            }
          }
        }
        .col-12 .featured-item--left,
        .col-12 .featured-item--right,
        .col-2 {
         @media (min-width: $breakpoint--small) {
            .item__content {
              @include ftItemTextColor($white);
              .cta--link {
                color:$white;
              }
              .cta--button {
                @include cta("white");
              }
            }
          }
        }
      }
    }
  }

  .item__content {
    flex: auto;
    padding: 20px 0px;
    background-color: $white;

    .region--white .row--no-gutters &,
    .region--white .row--flush-horizontal &,
    .region--black &,
    .region--gray &,
    .region--blue--bl-text &,
    .region--blue--wh-text &,
    .region--comms-cobalt &,
    .region--blue-gradient--wh-text &,
    .region--blue-gradient--bl-text &
     {
      padding-left: 20px;
    }
    
    .cta--link {
      text-decoration: none;
      .region--white &, .region--gray &, .region--black &, .region--near-black &, .region[class*="blue"] &, .article & {
        color:$color--link;
        text-decoration: none;
      }
      .featured-item--alternate & {
        .region--white &, .region--gray & {
          color:$color--link;
          text-decoration: none;
        }
        .region--black &, .region--near-black & {
          color:$attBlue;
          text-decoration: none;
        }
      }
    }
    .cta--button {
      .region--white &, .region--gray &, .region--black &, .region--near-black & {
        @include cta("blue");
      }
      .featured-item--alternate & {
        .region--white &, .region--gray &, .region--black &, .region--near-black & {
          @include cta("blue");
        }
      }
    }

    .col-12 .featured-item--left &,
    .col-12 .featured-item--right & {
      @media (min-width: $breakpoint--small) {
        padding: 0;
        width: 50%;
        background-color: transparent;

        .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
          float: left;
        }
      }
    }

    .col-12 .featured-item--left & {
      @media (min-width: $breakpoint--small) {
        padding-left: 45px;
      }
    }

    .col-12 .featured-item--right & {
      @media (min-width: $breakpoint--small) {
        padding-right: 45px;
      }
    }

    .featured-item--alternate & {
      background-color: transparent;
      padding-left: 0;
    }

    @media (min-width: $breakpoint--small) {
      .col-2 & { /* Tiny orientation for smallest columns */
        background-color: transparent;
        padding: 10px 0 20px !important;
      }

      // .col-12 & { /* Horizontal orientation for full-width columns */
      //   padding: 35px 20px 0 45px;
      // }

    }

    .content__wrap {
      @media (min-width: $breakpoint--xxLarge) {
        max-width: 95%;
      }

      .featured-item--alternate & {
        max-width: none;
      }

      .col-12 .featured-item--left &,
      .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          max-width: none;
        }
      }

      .col-2 & {
        @media (min-width: $breakpoint--medium) {
          max-width: none;
        }
      }
    }

    .item__title,
    .item__info,
    .item__description {
      .featured-item--alternate & {
        color: inherit !important;
      }
    }

    .item__title {
      @include font-size(20);
      @include font-smoothing;

      .col-12 .featured-item--left &,
      .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          @include font-size(30);
          line-height: 1.3;
        }
      }

      .featured-item--alternate & {
        max-width: 90%;

        @media (min-width: $breakpoint--small) {
          @include font-size(25);
        }

        @media (min-width: $breakpoint--medium) {
          @include font-size(30);
        }

        .col-4 & {
          @include font-size(20);
          max-width: none;
        }
      }

      .col-2 .featured-item:not(.featured-item--alternate) & { /* Tiny orientation for smallest columns */
        @include font-size(16);
        line-height: 1.25;
      }
    }

    .item__info {
      @include font-size(13);
      font-family: $aleck;
      margin: 10px 0 15px;

      &:first-child {
        margin-top: 0;
      }

      .featured-item--alternate & {
        @include font-size(16);
      }

      .col-12 .featured-item--left &,
      .col-12 .featured-item--right &,
      .featured-item--alternate & {
        @media (min-width: $breakpoint--small) {
          @include font-size(18);
        }
      }
    }

    .item__description {
      line-height: 1.45;

      .col-12 .featured-item--left &,
      .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          @include font-size(21);
          line-height: 1.6;
        }
      }

      .featured-item--alternate & {
        @include font-size(18);

        @media (min-width: $breakpoint--medium) {
          @include font-size(21);
          line-height: 1.6;
        }
      }

      p {
        margin-top: 10px;
      }

      ul,
      ol {
        margin: 15px 0 15px 20px;

        ul,
        ol {
          margin: 15px 0 15px 15px;

          & > li {
            margin-left: 15px;

            @media (min-width: $breakpoint--large) {
              margin-left: 25px;
            }
          }
        }
      }

      ul > li:before {
        top: 12px;
      }

      li {
        margin-bottom: 5px;
      }

      &:first-child p:first-child {
        margin-top: 0;
      }

      a {
        color: $color--link;
      }
    }

    .item__cta {
      margin: 20px 0 0;

      .featured-item--alternate & {
        margin-top: 50px;
        text-align: center;
      }

      /* Links on black backgrounds */

      .region[class*="-black"] .col-2 &,
      .region[class*="-black"] .col-12 .featured-item--left &,
      .region[class*="-black"] .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          .cta--link {
            color: $color--cta;
          }
        }
      }


      .region--blue .col-2 &,
      .region--blue .col-12 .featured-item--left &,
      .region--blue .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          .cta--link {
            color: $color--copy;
            text-decoration: none;
          }
        }
      }

      .region--dark-blue .col-2 &,
      .region--dark-blue .col-12 .featured-item--left &,
      .region--dark-blue .col-12 .featured-item--right & {
        @media (min-width: $breakpoint--small) {
          .cta--link {
            color: $white;
            text-decoration: none;
          }
        }
      }

      .col-12 .featured-item--left &,
      .col-12 .featured-item--right & {

        @media (min-width: $breakpoint--medium) {
          .cta--link {
            @include font-size(21);
            font-family: $aleckMedium;
            font-weight: 400;
          }
        }
      }
    }
  }
