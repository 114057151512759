/*=====
  MODAL
  =====*/

  .privacy-modal.fancybox-content {
    position: relative;
    background-color: transparent;
    
    @media (min-width: 560px - 1) {
      padding: 0 30px 0 24px;
    }

  .modal__wrap {
      position: relative;
      background-color: $white;
      margin: 0 auto;
      padding: 30px;
      border-top: 15px solid $attBlue;

      @media (min-width: $breakpoint--small) {
        padding: 35px;
        max-width: 600px;
      }

      @media (min-width: $breakpoint--medium) {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .modal__close {
      position: absolute;
      top: 0px;
      right: 15px;

      // @media (min-width: $breakpoint--small) {
      //   top: 10px;
      //   right: 25px;
      // }

      .cta--close {
        font-size: 50px;
        font-weight: 300;
        
        span {
          display: inline-block;
          transform: rotate(45deg);
        }

        &:hover {
          text-decoration: none;
        }
        &:focus,
        &:active {
          outline: 0;
        }
      }
    }

    .modal__content {
      text-align: left;
      font-family: $aleck;

      @media (min-width: $breakpoint--large) {
        max-width: 95%;
      }

      h2 {
        @include font-size(22);
        color: $attBlue;
        font-weight: 400;
        margin: 0 0 20px;
        line-height: 1.25;

        @media (max-width: $breakpoint--large - 1) {
          max-width: 90%;
        }
      }
      h3, p {
        @include font-size(14.5);
        line-height: 1.7;
      }
      h3 {
        margin-bottom: 25px;
      }
    }

    .cta--button {
      // @include accent-arrow("right");
      font-weight: 600;

      &:hover {
        background-color: $black;
        text-decoration: none;
      }
    }

  }

  