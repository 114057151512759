/*=======
  MARQUEE
  =======*/

  .marquee {
    // display: none;

    .marquee__wrap {
      position: relative;
      overflow: hidden;
    }

    .marquee__slides {
      @include transitionAll(500ms);
      height: 0;
      opacity: 0;

      &.flickity-enabled {
        height: auto;
        opacity: 1;
      }
    }

    .slide {
      width: 100%;
    }

    .component {
      margin-bottom: 0;
    }

  }

  /* Marquee Controls */

  .marquee__controls {
    @include centerHorizontally;
    box-sizing: border-box;
    width: 100%;

    @media (min-width: $breakpoint--small) {
      position: absolute;
      min-height: auto;
      padding: 0 $air--xSmall;
      bottom: 0;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }

    ul {
      max-width: calc(#{$maxContentWidth} + 20px);
      margin: 5px auto;

      /* Begin flexbox and flexbox fallback */

      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $breakpoint--small) {
        justify-content: space-between;
        align-items: flex-end;

        .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
          display: table;
          table-layout: fixed;
          transform: translateX(-50%);
          left: 50%;
        }
      }

      /* End flexbox stuff */

      @media (min-width: $breakpoint--small) {
        position: absolute;
        margin: 0 auto;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 55px;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
      }
    }

    li {
      margin: 20px 6px;
      box-sizing: border-box;

      @media (min-width: $breakpoint--small) {
        margin: 0;
        padding: 0 1%;
        width: 100%;
      }

      @media (min-width: $breakpoint--xLarge) {
        padding: 0;
        margin: 0 10px;
        // max-width: 392px;
      }

      /* Add flexbox and flexbox fallback */

      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        @media (max-width: $breakpoint--small - 1) {
          float: left;
        }

        @media (min-width: $breakpoint--small) {
          display: table-cell;
          vertical-align: bottom;
          width: auto;
          max-width: 100%;
          margin: 0;
          padding: 0 10px;
        }
      }

      &.control--active {
        button {
          background-color: $color--cta;

          @media (max-width: $breakpoint--small - 1) {
            height: 22px;
            width: 22px;
          }

          .region--blue & {
            background-color: $white;
          }
        }
      }

      &.control--active,
      &:hover {
        button {
          @media (min-width: $breakpoint--small) {
            width: 100%;
            background-color: transparent;
            border-color: $color--cta;
            color: $color--cta;
          }
        }
      }
    }

    button {
      @include transitionAll(150ms);
      display: block;
      width: 20px;
      padding: 0;
      margin: 0;
      text-align: left;
      border-radius: 100%;
      background-color: $regGray;

      @media (max-width: $breakpoint--small - 1) {
        height: 20px;
      }

      @media (min-width: $breakpoint--small) {
        @include font-size(14);
        width: 100%;
        border-radius: 0;
        background-color: transparent !important;
        border-bottom: 6px solid $lgtGray;
        transition: all 300ms ease;
        color: $lgtGray;

        .region--near-black &,
        .region--black & {
          background-color: transparent;
        }
      }

      @media (min-width: $breakpoint--large) {
        @include font-size(16);
      }

      @media (min-width: $breakpoint--xLarge) {
        @include font-size(17);
        border-width: 8px;
      }

      span {
        display: none;
        font-weight: 700;

        @media (min-width: $breakpoint--small) {
          display: block;
          max-width: 85%;
          padding-bottom: 10px;
        }
      }
    }
  }

  /* Carousel Vendor Style Overrides */

  .flickity-viewport {
    transition: height 700ms ease-in-out;
  }
