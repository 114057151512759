/*=====
  TABS
  =====*/

.tabs {
  width:100%; 
  max-width: $maxRowWidth;
  padding: 0 20px;
  margin: 20px auto 0;

  @media (min-width: $breakpoint--small) {
    padding:0 55px;
    margin: 40px auto 0;
  }
  @media (min-width: $breakpoint--xLarge) {
    padding:0;
  }
  .row & {
    padding:0;
  }

  &__container {
    display: block; 
    position:relative;
    width:100%; 
    @media (min-width:$breakpoint--small) {
      display: flex;
      flex-wrap: wrap;
      //align-items: space-between;
    }
  }
}

.tab {
  &__title {
    display:block;
    margin-bottom: 25px;

    @media (min-width:$breakpoint--small) {
      margin: 0 40px 0 0;
      &:last-of-type {
        margin-right:0;
      }
      .tabs--box & {
        align-self:flex-end;
        margin-right: 20px;
        width:100%;
        max-width:185px;
      }
      .tabs--bar & {
        align-self:flex-end;
        width:100%; //will be updated via JS
      }
    }
    a {
      @include font-size(21);
      position:relative;
      font-family: "Aleck Sans", Arial, Helvetica, sans-serif;
      font-weight:bold;
      text-decoration: none;
      word-break: break-word;

      @media (min-width:$breakpoint--small) {
        font-family: "Aleck Sans Medium", "Aleck Sans", Arial, Helvetica, sans-serif;
        font-weight:normal;
        .tabs--box & {
          @include font-size(16);
          display:block;
          width:100%;
          //margin-right:20px;
          margin-bottom:-1px;
          border: 1px solid $white;
          padding: 20px 10px;
          font-family: "Aleck Sans", "Aleck Sans", Arial, Helvetica, sans-serif;
          text-align:center;
          color: inherit;

          .region--white &, .region--gray & {
              border-color: $regGray;
            }

          .region--blue--bl-text &,
          .region--blue-gradient--bl-text &,
          .region--comms-lime &,
          .region--comms-mint &,
          .region--comms-berry &,
          .region--comms-tangerine &,
          .region--comms-berry-tangerine &,
          .region--comms-lime-mint & {
            border-color: $black;
          }
        }
        .tabs--bar & {
          @include font-size(18);
          font-family: "Aleck Sans", "Aleck Sans", Arial, Helvetica, sans-serif;
          font-weight:bold;
          color: $drkGray;

            .region--blue &,
            .region[class*="blue--"] &,
            .region[class*="blue-gradient"] & {
              color: darken($drkGray, 10%);
            }

            .region[class*="cobalt"] &,
            .region[class*="purple"] &,
            .region[class*="black"] &,
            .region--dark-blue & {
              color: $lgtGray;
            }
        }
      }
      &:after {
        content: '';
        position:relative;
        display: none;
        width:100%;
        height:0;
        @media (min-width:$breakpoint--small) {
          .tabs--bar & {
            display:inline-block;
            border: 2px solid $drkGray;
            margin:0;

            .region--blue &,
            .region[class*="blue--"] &,
            .region[class*="blue-gradient"] & {
              border-color: darken($drkGray, 10%);
            }

            .region[class*="cobalt"] &,
            .region[class*="purple"] &,
            .region[class*="black"] &,
            .region--dark-blue & {
              border-color: $lgtGray;
            }
          }
        }
      }
      &:hover, &:focus {
        @media (min-width:$breakpoint--small) {
          .tabs--box & {
            background-color: $lgtGray;
            color: $color--copy;

            .region--gray & {
              background-color: $regGray;
            }
          }
        }
      }
      &:hover:after, &:focus:after {
        @media (min-width:$breakpoint--small) {
          display:inline-block;
          border: 2px solid $color--link;
          border-radius: 0;
          margin:0px -4px 10px 0;

          .region--dark-blue &,
          .region--comms-cobalt &,
          .region--comms-purple &,
          .region--comms-cobalt-purple & {
            border-color: $white;
          }
          
          .tabs--box & {
            display:none;
          }
          .tabs--bar & {
            border-color: $color--link;
            margin:0;

            .region--blue &,
            .region[class*="blue--"] &,
            .region[class*="blue-gradient"] & {
              border-color: $lgtGray;
            }

              .region[class*="cobalt"] &,
              .region[class*="purple"] &,
              .region[class*="black"] &,
              .region--dark-blue & {
                border-color: $attBlue;
              }
          }
        }
      }
    }

    &--active {
      @media (min-width:$breakpoint--small) {
  
      }
      a {
        color:inherit;
          .tabs--box & {
            background-color: $drkGray !important;
            color: $white !important;

              .region[class*="blue"] &,
            .region[class*="cobalt"] &,
            .region[class*="purple"] & {
              background-color: darken($drkGray, 10%) !important;
            }
          }
          .tabs--bar & {
            color: $color--link;

            .region[class*="cobalt"] &,
            .region[class*="purple"] &,
            .region[class*="black"] &,
            .region--dark-blue & {
              color: $attBlue;
            }

            .region--blue &,
            .region[class*="blue--"] &,
            .region[class*="blue-gradient"] & {
              color: $lgtGray;
            }
          }
        &:after {
          @media (min-width:$breakpoint--small) {
            display:inline-block;
            border: 2px solid $color--copy;
            border-radius: 0;
            margin:0px -4px 10px 0;
            @each $name, $color in $backgroundColors {
              .region--#{$name} & {
                @if (nth($color, 2) == $white) {
                  border-color: $white;
                }
              }
            }
            .region--white &, .region--gray & {
              border-color: $color--link;
            }
            .region[class*="black"] & {
              border-color: $attBlue;
            }
            .tabs--box & {
              display:none;
            }
            .tabs--bar & {
              border-color: $color--link;

              .region--blue &,
            .region[class*="blue--"] &,
            .region[class*="blue-gradient"] & {
              border-color: $lgtGray;
            }

              .region[class*="cobalt"] &,
              .region[class*="purple"] &,
              .region[class*="black"] &,
              .region--dark-blue & {
                border-color: $attBlue;
              }
            }
          }
        }
      }
    }
    
    + .tab__wrap {
      @include clearfix();
      visibility:hidden;
      flex-basis: 100%;
      height:0;
      opacity:0;
      transition: opacity 500ms ease;
      @media (min-width: $breakpoint--small) {
        order: 99;
        //transition: opacity 500ms ease;
      }

    }
    &--active + .tab__wrap {
      visibility:visible;
      height:100%;
      width:100%;
      opacity:1;
      transition: opacity 500ms ease;
      @media (min-width: $breakpoint--small) {
        order: 98;
        padding-top:40px;
        .tabs--box & {
          border-top: 1px solid $white;

          .region--white &, .region--gray & {
              border-color: $regGray;
            }

          .region--blue--bl-text &,
          .region--blue-gradient--bl-text &,
          .region--comms-lime &,
          .region--comms-mint &,
          .region--comms-berry &,
          .region--comms-tangerine &,
          .region--comms-berry-tangerine &,
          .region--comms-lime-mint & {
            border-color: $black;
          }
        }
      }
    }
  }

  &__cta {
    margin: 20px 0 40px;
    .cta--link:after {
      content:'X';
      font-weight:bold;
      margin-left:4px;
    }
    @media (min-width: $breakpoint--small) {
      display:none;
    }
  }
}

.tabs {
  .row, 
  .tabs__heading,
  .component .text,
  .component [class*="__heading"],
  .share,
  [class*="__share"],
  .latest-stories .stories__filter-nav,
  .media-carousel .carousel__wrap {
    margin-left:0;
    margin-right:0;
    padding-left:0;
    padding-right:0;
  }
}