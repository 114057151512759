/*=====
  MEDIA
  =====*/

  .media {
    width: 100%;
  }

  /* Media Items */

  .media__img,
  .media__video {
    display: none;
    max-width: 100%;
    width: 100%;

    .frame {
      position: relative;

      .media--letterboxed & {
        background-color: $black;
      }
    }
  }

  /* Media Item - Image */

  .media__img {
    max-width: $maxContentWidth;
    margin: 0 auto;

    .media--letterboxed & {
      max-width: none;
    }

    .media[data-type="image"] & {
      display: block;
    }

    img {
      display: block;
      max-width: 100%;

      .media--centered &,
      .media--letterboxed & {
        margin: 0 auto;
      }
    }
  }

  /* Media Item - Video */

  .media__video {

    .media[data-type="video"] & {
      display: block;
    }

    .frame {
      .media--centered &,
      .media--constrained & {
        max-width: 900px;
        margin: 0 auto;
      }
    }

    [class*="embed--"] {
      @include clearfix;
      display: none;

      &:first-child {
        display: block;
      }
    }

    iframe,
    att-gvp {
      display: inline-block;
      margin: 0;
    }
  }

  /* Media Item Caption */

  .media__caption {
    @include font-size(15);
    margin: 10px auto;
    padding: 10px 20px;
    line-height: 1.5;
    width: 100%;

    @media (max-width: $breakpoint--large - 1) {
      .row & {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (min-width: $breakpoint--large) {
      padding-left: 0;
        padding-right: 0;
    }

    .media--centered &,
    .media--constrained &,
    .media--letterboxed & {
      max-width: 900px;
    }

    .media--centered &,
    .media--letterboxed & {
        margin: 0 auto;
      text-align: center;

      .sidebar & {
        text-align: initial;
      }
    }


    .region[class*="-blue"] &,
    .region[class*="-black"] & {
      font-family: $aleckMedium;
    }

    p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a,
    a:visited {
      color: $color--link;
    }
  }
