/*======
  FOOTER
  ======*/

  #att-about-footer {
    background-color: $functionalBlack;
  }

  #att-about-footer .footer__wrap {
    font-family: "Arial", "Helvetica", sans-serif;
    font-size: 11px;
    line-height: 1;
    max-width: $maxContentWidth;
    margin: 0 auto;
    padding: $air--xSmall;
    color: $medGray;

    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--xLarge - 1) {
      padding-left: $gutter--small;
      padding-right: $gutter--small;
    }

    @media (min-width: $breakpoint--medium) {
      padding-bottom: 50px;
    }

    @media (min-width: $breakpoint--xLarge) {
      padding-right: 0;
      padding-left: 0;
    }

    a {
      color: inherit;
    }
  }

  #att-about-footer .footer__menu {
    margin: 10px 0 20px;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 16px;
      margin-bottom: .5rem;
    }

    a {
      font-weight: 300;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #att-about-footer .footer__copyright {
    margin: 20px 0;

    p {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }
  }
  #att-about-footer .footer-icon-left{
    width:32px; 
    margin-left:5px;
    vertical-align: middle;
  }
  #att-about-footer .footer-icon-right{
    width:32px; 
    margin-right:5px;
    vertical-align: middle;
  }
