/*==============
  EDITABLE TEMPLATES
  ==============*/

  /* Three Column Items */
  .article-header--row {
    .item__content {
        padding:0px
    }

    .item__cta {
      margin: 0px;  
    }

    .share {
        margin-top:15px;
    }

    .story__assets {
        margin-top: 15px;
    
        select{
            max-width:85%;
            min-width:200px;
            @include font-size(16);
        } 
    }
    @media(min-width:$breakpoint--small){
        .featureditem {
            text-align:right;
        }
    }   
  }
 @media(max-width:$breakpoint--small - 1) {
    .article-header--row {
        .share {
            width:100% !important;
            margin-top:0px;
        }
        .story__assets {
            width:100% !important;
            margin-top:0px;
            select {
                max-width: 33%;
            }
        }
        .featureditem {
            width:100% !important;
            margin-top: 6px;
        }

    }

 }