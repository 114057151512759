/*====================
  SOCIAL CHANNEL/SHARE
  ====================*/

  .social-channels {
    @include clearfix;

    ul {
      @include centerHorizontally;
      text-align: center;
      padding: $air--small 0;

      .component--border-bottom & {
        padding-bottom: 0;
      }
    }

    li {
      display: inline-block;
      margin: 0 10px;

      @media (min-width: $breakpoint--small) {
        margin: 0 12.5px;
      }
    }

    a:hover {
      text-decoration: none;
    }

  }
