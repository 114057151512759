/*==================
  USER SURVEY REPORT
  ==================*/

  .user-survey-report {
    padding: 0 20px; 

    h2 {
      padding-bottom: 20px;
      border-bottom: 1px solid $attDrkBlue;
    }

    // Report Options

    .report__options {
      min-width: 300px;

      @media screen and (min-width: $breakpoint--medium) {
        width: 20%;
        padding-right: 30px;
      }

      form {
        margin: 30px 0;
  
        .field {
          position: relative;
          margin-bottom: 30px;
  
          &:last-of-type {
            padding-top: 20px;
            display: flex;
            align-items: center;
          }
        }
  
        label {
          @include font-size(18);
          display: inline-block;
          position: relative;
          margin: 0 10px 10px 0;

          &.required::after {
            content: "*";
            color: $attBlue;
            position: absolute;
            right: -10px;
          }
        }
  
        select, input {
          @include font-size(15);
          height: 30px;
          width: calc(100% - 130px);
          padding: 0px 5px;
          line-height: 30px;
          min-width: 220px;
          font-family: $aleck;
          border: 1px solid $medGray;

          @media screen and (min-width: $breakpoint--medium) {
            width: 100%;
          }          
        }
  
        .date-picker {
          margin-bottom: 10px;
        }
  
        .report__submit {
          @include font-size(18);
          border-radius: 0;
  
          &:disabled {
            background-color: $regGray;
            color: $lgtGray;
            cursor: default;
          }
        }
      }
    }

    .report__loading {
      display: inline-block;
      content: url('./../img/icon_loading.gif');
      height: 28px;
      width: 28px;
      margin-left: 15px;
      opacity: 0;
      transition: all 600ms ease;
    }

    .report__error {
      @include font-size(14);
      position: absolute;
      display: none;
      max-width: 320px;
      font-style: italic;
      font-weight: 700;
      line-height: 1.8rem;
      color: $berry;
    
      &:focus {
        outline: 0;
      }

      &.visible {
        display: block;
      }

      &.submit-error {
        top: 125%;
      }
    }

    &.loading .report__loading {
      opacity: 1;
    }

    .report__wrap {
      @media screen and (min-width: $breakpoint--medium) {
        display: flex;
      }
    }

    // Report Results

    .report__results {
      padding-top: 20px;
      border-top: 1px solid $regGray;

      @media screen and (min-width: $breakpoint--medium) {
        width: 80%;
        padding: 30px;
        padding-right: 0;
        border-left: 1px solid $regGray;
        border-top: 0;
      }

      .results__wrap {
        transition: all 600ms ease;
        opacity: 0;

        h3 {
          @include font-size(23);
          margin-bottom: 10px;
        }

        h4, h5 {
          @include font-size(18);
        }

        h5 {
          font-family: $aleck;
        }

        .results__info {
          padding-bottom: 25px;
          border-bottom: 1px solid $attDrkBlue;
          font-family: $aleckMedium;
        }

        .results__table {
          padding-top: 20px;
          max-width: 100%;
          max-height: 800px;
          overflow: scroll;
        }

        table {
          width: auto;
          text-align: left;
          border-collapse: collapse;

          th {
            @include font-size(18);
            background-color: $lgtGray;
            font-weight: 700;
          }

          th.header--question {
            min-width: 300px;
          }

          th, td {
            border: 1px solid $medGray;
            padding: 10px;
            vertical-align: middle;
          }

          th.total,
          th.response,
          td.total,
          td.response {
            text-align: center;
          }

          tr:nth-child(odd) {
            background-color: $lgtGray;
          }
        }
      }

      &.shown .results__wrap {
        opacity: 1;
      }

      .poll-report {
        margin-bottom: 20px;
        padding: 15px 0 25px;
        border-bottom: 1px solid $attDrkBlue;

        &:last-child {
          border-bottom: 0;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .poll-page {
        margin: 20px 0 35px;
        padding-left: 15px;
      }

      .poll-question {
        display: flex;
        align-items: center;
        background-color: $lgtGray;
        border: 1px solid $medGray;
        margin: 15px 0;
      }

      .poll-question-header,
      .poll-responses {
        flex: 1;
      }

      .poll-question-header {
        h5 {
          flex: 2;
          padding: 15px;

          & + h5 {
            @include font-size(16);
            flex: 1;
            border-left: 1px solid $medGray;
          }
        }
      }

      .poll-responses {
        border-left: 1px solid $medGray;

        li {
          padding: 10px 15px;
          background-color: $white;
          border-top: 1px solid $medGray;
          text-align: center;

          &:first-child {
            background-color: $lgtGray;
            border-top: 0;
          }
        }
      }
    }
  }

  // Flatpickr overrides

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $attBlue;
    border-color: $attBlue;
  }

  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 $attBlue;
            box-shadow: -10px 0 0 $attBlue;
  }

  // .flatpickr-day.inRange,
  // .flatpickr-day.prevMonthDay.inRange,
  // .flatpickr-day.nextMonthDay.inRange,
  // .flatpickr-day.today.inRange,
  // .flatpickr-day.prevMonthDay.today.inRange,
  // .flatpickr-day.nextMonthDay.today.inRange,
  // .flatpickr-day:hover,
  // .flatpickr-day.prevMonthDay:hover,
  // .flatpickr-day.nextMonthDay:hover,
  // .flatpickr-day:focus,
  // .flatpickr-day.prevMonthDay:focus,
  // .flatpickr-day.nextMonthDay:focus {
  //   background: $lgtGray;
  //   border-color: $lgtGray;
  // }

  // .flatpickr-day.inRange {
  //   -webkit-box-shadow: -5px 0 0 $lgtGray, 5px 0 0 $lgtGray;
  //           box-shadow: -5px 0 0 $lgtGray, 5px 0 0 $lgtGray;
  // }