/*====
  TEXT
  ====*/

  $textMargin--large: 45px;

  .narrow-text .col-12 .component>.text,
  .narrow-text .region__wrap>.text .component>.text {
    max-width: 1024px;
    margin: 0 auto;
  }
  
  .component>.text {
    @include font-size(18);
    position: relative;
    max-width: $maxContentWidth;
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $air--xSmall;
  
    .col & {
      padding: 0;
    }
    &.text-cta--center{
      .text__cta{
        text-align: center;
      }
    }
    &.text-cta--right{
      .text__cta{
        text-align: right;
      }
    }
    @media (min-width: $breakpoint--small) {
      padding: 0 $gutter--small;
  
      .col-9 &,
      .col-8 &,
      .col-6 & {
        padding-right: 50px;
      }
  
      .col-4 &,
      .col-3 &,
      .col-2 & {
        padding-right: 30px;
      }
    }
  
    @media (min-width: $breakpoint--medium) {
  
      .col-9 &,
      .col-8 &,
      .col-6 &,
      .col-4 & {
        padding-right: 60px;
      }
  
      .col-3 & {
        padding-right: 30px;
      }
    }
  
    @media (min-width: $breakpoint--large) {
      @include font-size(21);
      line-height: 1.6;
  
      .col-3 & {
        padding-right: 50px;
      }
  
      .col-2 & {
        padding-right: 0;
      }
    }
  
    @media (min-width: $breakpoint--xLarge) {
      padding: 0;
    }
  
    /* Headings */
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: $air--xSmall;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      .component:not(.component--no-margin-bottom) &:last-child:not(:only-child) {
        margin-bottom: 0;
      }
    }
  
    p+h1,
    p+h2,
    p+h3,
    p+h4,
    p+h5,
    p+h6,
    ul+h1,
    ul+h2,
    ul+h3,
    ul+h4,
    ul+h5,
    ul+h6 {
      margin-top: 35px;
  
      @media (min-width: $breakpoint--xLarge) {
        margin-top: $gutter--small;
      }
    }
  
    h1 {
      .region[class*="-black"] & {
        color: $attBlue;
      }
    }
  
    h2 {
      line-height: 1.35;
    }
  
    /* Images */
  
    img {
      max-width: 100%;
    }
  
    /* Horiztonal Rules */
  
    hr {
      border: 0;
      border-top: 4px;
      border-color: $attBlue;
      border-style: solid;
      border-radius: 30px;
      width: 60%;
      max-width: 300px;
      margin: 50px auto;
  
      @each $name, $color in $backgroundColors {
        .region--#{$name} & {
          @if (nth($color, 2) == $functionalBlack) {
            border-color: $functionalBlack;
          }
          @if (nth($color, 2) == $white) {
            border-color: $white;
          }
        }
      }
      .region--white &, .region--gray &, .region--black &, .region--near-black & {
        border-color: $attBlue;
      }
  
      @media (min-width: $breakpoint--xLarge) {
        margin: 80px auto;
      }
    }
  
    /* Tables */
  
    table {
      margin: 45px 0;
  
      th,
      td {
        padding: 10px;
  
        @media (min-width: $breakpoint--large) {
          padding: 10px 20px;
        }
      }
  
      th {
        font-family: $aleckBlack;
        text-align: left;
      }
  
      td {
        border: 1px solid $regGray;
        border-left-width: 0;
        border-right-width: 0;
  
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              border-color: $functionalBlack;
            }
            @if (nth($color, 2) == $white) {
              border-color: $white;
            }
          }
        }
        .region--white &, .region--gray &, .region--black &, .region--near-black & {
          border-color: $regGray;
        }
  
        &+td {
          border-left-width: 1px;
        }
      }
    }
  
    /* Modifications */
  
    &.text--headline-blue {
  
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $aleck;
        font-weight: 700;
        border-bottom: 1px solid $drkGray;
        padding-bottom: 5px;
  
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              border-color: $functionalBlack;
            }
            @if (nth($color, 2) == $white) {
              border-color: $white;
            }
          }
        }
        .region--white &, .region--gray &, .region--black &, .region--near-black & {
          color: $attBlue;
          border-color: $drkGray;
        }
      }
  
      h2 {
        @include font-size(38);
      }
  
      h3 {
        @include font-size(24);
      }
    }
  //Custom class for blog landing page styles
    &.blog-heading{
      @include special-bold($black);
      i{
        color:$attBlue;
      }
  
      .region--blue &, .region--blue--bl-text &, .region--blue--wh-text &, .region--comms-cobalt &, .region--blue-gradient--wh-text &, .region--blue-gradient--bl-text & {
        @include special-bold($black);
        i{
          color:$white;
        }
      }
    }
  
    &.text--featured {
  
      h1,
      h2,
      h3 {
        font-weight: 700;
      }
  
      strong,
      b {
        font-weight: 700;
      }
    }
  
    &.text--special-bold {
  
      h1,
      h2 {
        @include special-bold($attBlue);
      }
    }
  
    &.text--footer {
      @include font-size(13);
      font-family: $aleck;
      line-height: 1.8;
      color: inherit;
  
      @media (min-width: $breakpoint--small) {
        padding-top: 20px;
        padding-bottom: 10px;
      }
  
      @media (min-width: $breakpoint--medium) {
        padding-top: 0;
        padding-bottom: 0;
      }
  
      @media (min-width: $breakpoint--large) {
        @include font-size(15);
      }
  
      h1,
      h2 {
        font-family: $aleck;
        line-height: 1.25;
      }
  
      h1 {
        @include font-size(26);
      }
  
      h2 {
        @include font-size(20);
      }
  
      h3 {
        @include font-size(14);
        font-weight: 700;
        margin-bottom: 10px;
      }
  
      ul,
      ol {
        margin: 0 0 20px;
  
        &>li {
          margin-bottom: 10px;
  
          &:before {
            content: none;
          }
  
          a {
  
            .region[class*="-blue"] &:not(:hover) {
              text-decoration: none;
            }
          }
        }
      }
  
      hr {
        margin: 40px 0;
        border-top-width: 2px;
      }
    }
  }

  /*Editable Template Publish Date */
.component>.story--pub-date {
  p {
    @include font-size(16);
  }
}
/*Editable Template Story */
.component>.story--title{
  h1{
    font-family:$aleckBlack;
  }
  @media (min-width:$breakpoint--large) {
    h1 {
      @include font-size(42);
    }
  }
}
/*Editable Template Story Description */
.component>.story--description {
  p{
    font-weight:900;
  }
    @media (min-width: $breakpoint--large) {
      p {
        @include font-size(18);
      }
    }
  }
  
  /* Blockqoutes */
  
  .text--blockquote .text__wrap {
    position: relative;
    margin: 30px 0 20px;
    font-family: $aleckSlab;
  
    h1 {
      color: inherit;
    }
  
    p:last-child {
      margin-bottom: 0;
    }
  
    a {
      text-decoration: underline;
    }
  
    
    .region--white &, .region--gray &, .region--black &, .region--near-black & {
      color: $attBlue;
    }
  
    @media (min-width: $breakpoint--medium) {
      margin: 25px 0;
      max-width: 90%;
    }
  
    @media (min-width: $breakpoint--small) {
      &:before {
        @include centerVertically;
        @include font-size(62);
        content: "";
        display: block;
        position: absolute;
        height: calc(100% - 15px);
        width: 65px;
        border-right: 1px solid $regGray;
        margin-right: 15px;
        right: 100%;
        font-family: $att-icons;
        text-align: center;
        line-height: 1;
        color: $regGray;
  
        @media (min-width: $breakpoint--large) {
          content: map-get($attIcons, quote);
        }
  
        /* To accomodate blue backgrounds */
  
        @each $name, $color in $backgroundColors {
          .region--#{$name} & {
            @if (nth($color, 2) == $functionalBlack) {
              border-color: $functionalBlack;
              color: $functionalBlack;
            }
            @if (nth($color, 2) == $white) {
              border-color: $white;
              color: $white;
            }
          }
        }
  
        .region--white &,
        .region--gray & {
          border-color: $regGray;
          color: $regGray;
        }
  
        /* To center the icon */
        display: flex;
        align-items: center;
  
        .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
          display: block;
          line-height: .65;
        }
      }
    }
  }
  
  /* Text with Icons */
  
  [class*="text--icon-"] .text__wrap {
    @include font-size(15);
    position: relative;
    padding-left: 75px;
    line-height: 1.75;
  
    &:before {
      @include centerVertically;
      @include font-size(62);
      display: block;
      position: absolute;
      height: calc(100% - 15px);
      width: 70px;
      border-right: 1px solid $regGray;
      margin-right: 20px;
      left: -15px;
      font-family: $att-icons;
      line-height: 1;
      color: $regGray;
  
      /* To accomodate blue/dark backgrounds */
  
      .region[class*="-blue"] &,
      .region[class*="-black"] & {
        border-color: $white;
        color: $white;
      }
  
      /* To center the icon */
  
      display: flex;
      align-items: center;
  
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
        display: block;
        line-height: .65;
      }
    }
  }
  
  [class*="text--icon-"].text--blue-icons .text__wrap:before {
    color: $cobalt;
  }
  
  @each $label,
  $icon in $attIcons {
    .text--icon-#{$label} .text__wrap {
      &:before {
        content: $icon;
      }
    }
  }
  
  .text--blockquote .text__wrap,
  [class*="text--icon-"] .text__wrap {
  
    /* To reposition for narrow columns */
  
    .col-2 &,
    .col-3 &,
    .col-4 & {
      padding-left: 0;
  
      &:before {
        @include centerHorizontally;
        display: block;
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        top: 0;
        text-align: center;
        border-right: 0;
        border-bottom: 1px solid $regGray;
      }
    }
  }
  
  /* CTAs */
  
  .text__cta {
    @media (max-width: $breakpoint--medium - 1) {
      margin-top: 40px;
    }
  }