/*======
  Bookmark
  ======*/

  html {
    scroll-padding-top: 120px;
  }

@media (max-width: $breakpoint--small) {
    html {
        scroll-padding-top: 0px;
      }
}