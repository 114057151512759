/*======
  BANNER
  ======*/

.banner {
  position: relative;
  font-family: $aleck;

  &.banner--featured {
    overflow: hidden;

    @media (max-width: $breakpoint--small - 1) {
      .col & {
        border-bottom: 1px solid $drkGray;
        max-height: 250px;
      }

      .col:last-child & {
        border-bottom: 0;
      }
    }

    @media (min-width: $breakpoint--small) {
      .col & {
        border-left: 1px solid $drkGray;
        max-height: 450px;
        min-height: 325px;
      }

      .col:first-child & {
        border-left: 0;
      }
    }

    @media (min-width: $breakpoint--medium) {
      .col & {
        max-height: 530px;
      }
    }
  }

  .row--equal-heights & {
    height: 100%;
  }

  @media (min-width: $breakpoint--large) {
    overflow: hidden;
  }

  .banner__img,
  .banner__video {
    display: none;
  }
}

/* Banner Image */

.banner__img {

  /* If we're loading an image banner, make sure the image container displays in the page */

  .banner[data-type="image"] & {
    display: block;
  }

  /* Homepage Featured Banners */

  .banner--featured & {
    @media (max-width: $breakpoint--small - 1) {
      @include solid-overlay(.4);
    }
  }

  .col-4 .banner--featured & img,
  .col-6 .banner--featured & img {
    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
      min-height: 100%;
      max-width: none;
      width: auto;
    }
  }

  /* Gradient overlay */

  .banner--overlaid & {
    @media (min-width: $breakpoint--small) {
      @include gradient-overlay(5%, 1);
    }

    @media (min-width: $breakpoint--large) {
      @include gradient-overlay(30%, 1);
    }
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;

    @media (min-width: $breakpoint--small) {
      @include centerHorizontally;
    }
  }
}

/* Banner Video */

.banner__video {

  /* If we're loading a video banner, make sure the video container displays in the page */

  .banner[data-type="video"] & {
    display: block;
  }

  /* Gradient overlay */

  .banner--overlaid & {
    .no-touchevents.videoautoplay & {
      @include solid-overlay(.6);
    }

    .touchevents.no-videoautoplay & {
      @media (min-width: $breakpoint--small) {
        @include solid-overlay(.6);
      }
    }
  }

  [class^="embed--"] {
    display: none;

    &:first-child {
      display: block;
    }
  }

  .embed--youtube {
    background-position: center;
    background-size: cover;
  }

  .embed--gvp {
    gvp-data img {
      display: none;
      width: 100%;

      .touchevents.no-videoautoplay & {
        display: block;
      }
    }
  }

  .touchevents.no-videoautoplay & {

    iframe,
    gvp-background {
      display: none !important;
    }
  }

}

/* Banner Content */

.banner__content {
  max-width: $maxContentWidth;
  padding: $air--xSmall;
  margin: 0 auto;
  color: inherit;

  /* for Center or Default alignment */
  .reduced_width--10 {
    @media (min-width: $breakpoint--small) {
      margin: auto 10%;
    }
  }

  .reduced_width--20 {
    @media (min-width: $breakpoint--small) {
      margin: auto 20%;
    }
  }

  .reduced_width--30 {
    @media (min-width: $breakpoint--small) {
      margin: auto 30%;
    }
  }

  .reduced_width--40 {
    @media (min-width: $breakpoint--small) {
      margin: auto 40%;
    }
  }

  .reduced_width--50 {
    @media (min-width: $breakpoint--small) {
      margin: auto 50%;
    }
  }

  @media (min-width: $breakpoint--small) {
    position: absolute;
    padding: 0 $gutter--small;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: $breakpoint--xLarge) {
    padding: 0;

    .col & {
      padding: 0 $gutter--small;
    }

    .row--flush-horizontal .col & {
      padding: 0;
    }
  }

  /* Left-aligned or Right-aligned */

  &.banner__content--left,
  &.banner__content--right {
    .content__wrap {
      align-items: flex-start;
      text-align: left !important;

      .banner__text,
      .banner__cta {
        @media (min-width: $breakpoint--small) {
          position: relative;
        }
      }

      .banner__text {
        @media (min-width: $breakpoint--small) and (max-width: $breakpoint--medium - 1) {
          max-width: 60%;
        }
      }
    }
  }

  /* Left-aligned */
  &.banner__content--left {
    .reduced_width--10 {
      @media (min-width: $breakpoint--small) {
        margin: 0 10% 0 0;
      }
    }

    .reduced_width--20 {
      @media (min-width: $breakpoint--small) {
        margin: 0 20% 0 0;
      }
    }

    .reduced_width--30 {
      @media (min-width: $breakpoint--small) {
        margin: 0 30% 0 0;
      }
    }

    .reduced_width--40 {
      @media (min-width: $breakpoint--small) {
        margin: 0 40% 0 0;
      }
    }

    .reduced_width--50 {
      @media (min-width: $breakpoint--small) {
        margin: 0 50% 0 0;
      }
    }
  }

  /* Right-aligned */

  &.banner__content--right {
    .reduced_width--10 {
      @media (min-width: $breakpoint--small) {
        margin: 0 0 0 10%;
      }
    }

    .reduced_width--20 {
      @media (min-width: $breakpoint--small) {
        margin: 0 0 0 20%;
      }
    }

    .reduced_width--30 {
      @media (min-width: $breakpoint--small) {
        margin: 0 0 0 30%;
      }
    }

    .reduced_width--40 {
      @media (min-width: $breakpoint--small) {
        margin: 0 0 0 40%;
      }
    }

    .reduced_width--50 {
      @media (min-width: $breakpoint--small) {
        margin: 0 0 0 50%;
      }
    }

    .content__wrap {
      @media (min-width: $breakpoint--small) {

        .banner__text,
        .banner__cta {
          left: 40%;
        }
      }

      @media (min-width: $breakpoint--xLarge) {

        .banner__text,
        .banner__cta {
          left: 50%;
        }
      }
    }
  }

  /*Mobile centered text */
  &.banner__mobile--center {
    .content__wrap {
      @media (max-width: $breakpoint--small - 1) {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center !important;
      }
    }
  }

  /* Homepage Featured Banners */

  .banner--featured & {
    @media (max-width: $breakpoint--small - 1) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $breakpoint--small) {
      padding: 0;
    }
  }

  .content__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;

    /* Flexbox fallback */

    .no-flexbox.no-flexboxtweener.no-flexboxlegacy & {
      position: relative;
      height: auto;
      transform: translateY(-50%);
      top: 50%;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      @media (max-width: $breakpoint--small - 1) {
        flex-direction: row;
      }

      @media (min-width: $breakpoint--small) {
        justify-content: flex-end;
        padding-bottom: 6%;
      }

      @media (min-width: $breakpoint--large) {
        padding-bottom: 5%;
      }
    }
  }
}

/* Banner Text */

.banner__text {
  @include transitionAll(300ms);
  max-width: 100%;

  @media (min-width: $breakpoint--small) {
    color: $white !important;
    //max-width: none;
  }

  @media (min-width: $breakpoint--medium) {
    max-width: 50%;
  }

  /* Dark text */

  &.banner__text--dark {
    @media (min-width: $breakpoint--small) {
      color: $black !important;
    }
  }

  /* Homepage Featured Banners */

  .banner--featured & {
    width: 100%;
    max-width: 65%;
    padding: 0 10px;

    @media (min-width: $breakpoint--xSmall) {
      padding: 0 50px 0 0;
    }

    @media (min-width: $breakpoint--small) {
      padding: 0;
      max-width: 60%;
    }
  }

  .col-4 .banner--featured &,
  .col-6 .banner--featured & {
    @media (min-width: $breakpoint--small) {
      max-width: 80%;
    }
  }

  /* Bump banner text down if the banner component is the first component in the page after a transparent header */

  .header--transparent+.content &.banner__text--adjusted,
  .header--transparent+header+.content &.banner__text--adjusted {
    @media (min-width: $breakpoint--large) {
      margin-top: $desktopNavHgt;
    }
  }

  .header--transparent+.content &.banner__text--adjusted-lg,
  .header--transparent+header+.content &.banner__text--adjusted-lg {
    @media (min-width: $breakpoint--large) {
      // margin-top: calc(#{$desktopNavHgt} + 40px);
      margin-top: calc(#{$desktopNavHgt} - 40px);
    }
  }

  /* */

  .banner__level2,
  .banner__level3,
  .banner__level4 {
    @include transitionAll(300ms);
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
        margin-top: 15px;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  /* Banner Headline - Text Format */

  .banner__headline--text {
    @include transitionAll(300ms);
    font-weight: 300;

    @media (min-width: $breakpoint--large) {
      line-height: 1.15;
    }

    &:not(:only-child),
    &:not(:last-child) {
      @media (min-width: $breakpoint--small) {
        display: none;
      }
    }

    /* Featured Banners (thicker text) */

    .banner__content--featured & {
      font-weight: 700;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      @include font-size(27);
      line-height: 1;

      @media (max-width: $breakpoint--small - 1) {
        color: $white;
      }

      @media (min-width: $breakpoint--small) {
        @include font-size(35);
        line-height: 1.15;
      }

      @media (min-width: $breakpoint--medium) {
        @include font-size(38);

      }

      @media (min-width: $breakpoint--xLarge) {
        @include font-size(45);
      }
    }
  }

  /* Banner Headline - Image Format */

  .banner__headline--img {
    @include transitionAll(300ms);
    display: none;
    margin: 0 auto;

    @media (min-width: $breakpoint--small) {
      display: inline-block;
      max-width: 55%;
      margin-bottom: 5px;

      &:not(:first-child),
      &:not(:only-child) {
        display: inline-block;
      }
    }

    @media (min-width: $breakpoint--large) {
      max-width: 65%;
    }

    @media (min-width: $breakpoint--xLarge) {
      max-width: 45%;
    }

    @media (min-width: $breakpoint--xxLarge) {
      max-width: 75%;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      @media (min-width: $breakpoint--small) {
        max-width: 60%;
      }

      @media (min-width: $breakpoint--xLarge) {
        max-width: 85%;
      }
    }
  }

  /* Banner Text - Level 2 */

  .banner__level2 {
    @include font-size(30);
    line-height: 1.15;

    @media (min-width: $breakpoint--medium) {
      @include font-size(34);
    }

    @media (min-width: $breakpoint--large) {
      @include font-size(36);
    }

    @media (min-width: $breakpoint--xLarge) {
      @include font-size(40);
    }

    /* Featured Banners (thicker text) */

    .banner__content--featured & {
      font-weight: 700;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      font-family: $aleckMedium;

      @media (max-width: $breakpoint--small - 1) {
        margin-top: 5px;
        color: $white;

        &:last-child {
          margin-bottom: 5px;
        }
      }

      @media (max-width: $breakpoint--medium - 1) {
        @include font-size(17);
      }

      @media (min-width: $breakpoint--medium) {
        @include font-size(20);
      }
    }
  }

  /* Banner Text - Level 3 */

  .banner__level3,
  .banner__level4 {
    line-height: 1.5;

    a {
      text-decoration: underline;
    }

    /* Homepage Featured Banners */

    .banner--featured & {
      @media (max-width: $breakpoint--small - 1) {
        display: none;
      }

      @media (min-width: $breakpoint--large) {
        line-height: 1.25;
      }

      @media (min-width: $breakpoint--xxLarge) {
        line-height: 1.35;
      }

      .col-4 &,
      .col-6 & {
        @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
          line-height: 1.25;
        }
      }
    }
  }

  .banner__level3 {
    @include font-size(18);

    @media (min-width: $breakpoint--large) {
      @include font-size(21);
    }
  }

  /* Banner Text - Level 4 */

  .banner__level4 {
    @include font-size(12);
    font-family: $arial;
  }

  a {
    color: inherit;
  }
}

/* Banner Call-to-Action */

.banner__cta {

  @media (min-width: $breakpoint--small) {
    .cta--button {
      @include cta("blue");
    }

    margin-bottom: 0;
  }

  .cta--link {
    @include font-size(21);
    display: inline-block;
    margin-top: 10px;
    font-weight: 400;
  }

  /* Homepage Featured Banners */

  .banner--featured & {
    margin-top: 20px;

    @media (min-width: $breakpoint--small) {
      margin-bottom: 20px;
    }

    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
      margin: 25px 0;
    }

    @media (min-width: $breakpoint--large) {
      margin-top: 50px;
    }

    @media (min-width: $breakpoint--xxLarge) {
      margin-top: 70px;
    }

    .cta--button {
      @media (max-width: $breakpoint--xxLarge - 1) {

        .col & {
          @include font-size(15);
          min-width: 135px;
          padding: 12px;
        }

      }
    }
  }

  // /* Color Contrast Fix for Blue Banners */

  // .region[class*="-blue"] .banner__text:not(.banner__text--dark) + & .cta--button {
  //   @media (min-width: $breakpoint--small) {
  //     @include cta("white");
  //   }
  // }
}

/* Marquee Banner Settings */

.marquee--banner,
.marquee {

  .banner__content {
    @media (max-width: $breakpoint--small - 1) {
      padding-bottom: 0;
    }

    @media (max-width: $breakpoint--medium - 1) {
      max-width: 800px;
    }

    /* Anchor banner content to bottom */

    .content__wrap {
      @media (min-width: $breakpoint--small) {
        justify-content: flex-end;
        padding-bottom: 10%;
      }

      @media (min-width: $breakpoint--medium) {
        padding-bottom: 8%;
      }
    }
  }

  .banner__text {

    /* Anchor banner content to bottom */

    @media (min-width: $breakpoint--medium) and (max-width: $breakpoint--xLarge - 1) {
      max-width: 65%;
    }

    /* Adjust banner headline size */

    .banner__headline--text {
      @media (min-width: $breakpoint--small) {
        @include font-size(40);
      }

      @media (min-width: $breakpoint--medium) {
        @include font-size(50);
      }

      @media (min-width: $breakpoint--xLarge) {
        @include font-size(60);
      }

      @media (min-width: $breakpoint--xxLarge) {
        @include font-size(65);
      }
    }
  }

  .banner__text .banner__level2,
  .banner__text .banner__level3,
  .banner__text .banner__level4 {

    /* Tighten up the mobile spacing between headlines for banners used in marquees */

    @media (max-width: $breakpoint--small - 1) {
      margin-top: 10px;
    }
  }

  .banner__text .banner__level2 {
    @include font-size(26);

    /* Reduce mobile subheadline size for banners used in marquees */

    @media (max-width: $breakpoint--small - 1) {
      @include font-size(18);
      font-weight: 400;
    }

    @media (min-width: $breakpoint--medium) {
      @include font-size(30);
    }
  }

  /* Make sure any video banners are tall enough */

  .banner__video {
    min-height: 215px;

    @media (min-width: $breakpoint--small) {
      min-height: 425px;
    }

    @media (min-width: $breakpoint--medium) {
      height: 565px;
    }

    @media (min-width: $breakpoint--xxLarge + 1) {
      min-height: 632px;
    }
  }

  /* Tighten mobile spacing above cta for banners used in marquees */

  .banner__cta {
    margin: 20px 0 0;

    @media (min-width: $breakpoint--xLarge) {
      margin-top: 25px;
    }
  }

  .banner__cta .cta--button {
    margin-top: 5px;

    @each $name,
    $color in $backgroundColors {
      .region--#{$name} & {
        @if (nth($color, 2)==$white) {
          border: 0;
        }
      }
    }

    @media (min-width: $breakpoint--small) and (max-width: $breakpoint--large - 1) {
      margin-top: 0;
    }
  }

  .banner--featured .banner__cta {
    @media (max-width: $breakpoint--small - 1) {
      margin: 0;
    }

    @media (min-width: $breakpoint--small) {
      margin: 20px 0 0;
    }
  }

  /* Make sure any video banner content overlays its video */

  [class*="gvp_gvp-background__"] {
    z-index: -1 !important;
  }
}