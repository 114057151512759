/*======
  HEADER
  ======*/

  body > .wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  body > .wrapper .canvas {
    @include transitionAll(300ms);
    position: relative;
    width: 100%;
    min-height: 100vh;

    @media (max-width: $breakpoint--desktopNav - 1) {
      //transform: translateX(0);
      backface-visibility: hidden;

      &.canvas--on {
        transform: translateX(-80%);
        @media (min-width: 360px) {
          transform: translateX(-#{$navWidthMobile});
        }
        @media (min-width: $breakpoint--small) {
          transform: translateX(-#{$navWidthTablet});
        }
      }
    }

    @media (min-width: $breakpoint--desktopNav) {
      overflow: auto;
    }
  }

  /* Skip Navigation */

  .skip-navigation {
    background-color: darken($drkGray, 10%);

    @media (min-width: $breakpoint--small) {
      background-color: $black;
    }
  }
  .skip-navigation a {
    @include hidden-spoken;
    color: transparent;
    width: calc(100vw - 10px);
    padding: 5px;
    top: 5px;

    @media (min-width: $breakpoint--small) {
      top: -30px;
    }

    &:focus {
      @include transitionAll(300ms);
      font-size: 12px; // don't use rems here
      display: block;
      position: relative !important;
      text-decoration: underline;
      text-align: center;
      padding: 5px 5px 10px;
      height: auto;
      margin: 0 auto;
      top: 5px;
      clip-path: none;
      clip: initial;
      color: $regGray;
      z-index: 9999999999;

      @media (min-width: $breakpoint--small) {
        position: absolute !important;
        padding-bottom: 5px;
        width: auto;
        text-align: left;
        left: 5px;
      }
    }
  }

  /* Standard and Microsite Headers */

  #att-microsite-header.plusGlobal .siteNav{
    margin-top:0px;
  }  

  #att-about-header > div,
  #att-microsite-header .microsite-nav {
    background-color: $black;
    border-bottom: 1px solid $drkGray;
  }
  @media (min-width: $breakpoint--small) {
  #att-microsite-header.plusGlobal .siteNav{
    margin-top:50px;
  }   
}

  #att-about-header.header--transparent > div,
  #att-microsite-header.header--transparent .microsite-nav {
    @media (min-width: $breakpoint--large) {
      background-color: rgba($black, .65);
    }
  }

  #att-about-header.header--fixed > div,
  #att-microsite-header.header--fixed .microsite-nav {
    @media (min-width: $breakpoint--large) {
      background-color: $black;
    }
  }

  header {
    box-sizing: border-box;

    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    .touch &,
    .touchevents & {
      cursor: pointer;
    }
  }

  // Any immediate sibling of the secondary header

  .header--secondary + * {
    @media (min-width: $breakpoint--desktopNav) { 
      margin-top: $desktopNavHgt;
    }
  }

  // Any immediate sibling of the secondary header, IF the secondary header is in use

  .header--secondary.active + * {
    @media (min-width: $breakpoint--desktopNav) {
      margin-top: $mobileNavHgt;
    }
    @media (min-width: $breakpoint--xLarge) {
      margin-top: $desktopNavHgt;
    }
  }

  // Any immediate sibling of the secondary header, IF the secondary header is in use and contains condensed global nav

  .header--secondary.active--wGlobal + * {
    @media (min-width: $breakpoint--desktopNav) {
      margin-top: $mobileNavHgt + $micrositeCollapsedGlobalNav;
    }
    @media (min-width: $breakpoint--xLarge) {
      margin-top: $micrositeDesktopNavHgt + $micrositeCollapsedGlobalNav;
    }
  }

  .header + *,
  .header + .header--secondary + * {
    @media (min-width: $breakpoint--desktopNav) {
      margin-top: $desktopNavHgt;
      top: 0;
    }
  }

  .header.header--transparent + * {
    @media (min-width: $breakpoint--desktopNav) {
      margin-top: $desktopNavHgt;
    }

    @media (min-width: $breakpoint--large) {
      margin-top: 0;
      min-height: auto;
    }
  }
  
  //Removed z-index because it affects the RSS Feed modal
  .header.header--transparent + .header--secondary.header--transparent + * {
    @media (min-width: $breakpoint--large) {
      margin-top: 0;
      //z-index: 0;
    }
  }

  .header--secondary.header--transparent {
    @media (min-width: $breakpoint--large) {
      position: fixed;
      width: 100%;
      top: 0;
    }
  }

  .header--secondary.header--transparent + * {
    @media (min-width: $breakpoint--large) {
      margin-top: 0;
    }
  }

  .header--secondary.header--transparent.active.plusGlobal + *, .header--secondary.header--transparent.active--wGlobal + * {
    @media (min-width: $breakpoint--large) {
      margin-top: $micrositeCollapsedGlobalNav;
    }
  }

  .att-about-header__wrap {
    z-index: 10;

    @media (min-width: $breakpoint--desktopNav) {
      @include transitionAll(450ms);
      position: fixed;
      height: $desktopNavHgt;
      width: 100%;
      margin-top: 0;

      #att-about-header.header--fixed & {
        height: $mobileNavHgt;
      }
    }
  }

  .plusMicrosite{
    height:50px !important;
  }


  .header--secondary > * {
    z-index: 10;

    @media (min-width: $breakpoint--desktopNav) {
      @include transitionAll(450ms);
      position: fixed;
      // height: $desktopNavHgt;
      width: 100%;
      margin-top: 0;
      top: 0;

      // #att-about-header.header--fixed & {
      //   height: $mobileNavHgt;
      // }
    }
  }

  #att-about-header {
    @media (min-width: $breakpoint--large) {
      position: relative;
      z-index: 999999999;
    }
  }

  #att-microsite-header {
    z-index: 5;

    & .component {
      margin-bottom: 0;
    }

    & .microsite-nav {
      @include transitionAll(450ms);
      margin: 0;
      padding: 0;

      @media (min-width: $breakpoint--medium) {
        height: $desktopNavHgt;
      }
    }

    &.header--fixed .microsite-nav {
      @media (min-width: $breakpoint--medium) {
        height: $mobileNavHgt;
      }
    }

    & .microsite-nav.microsite-nav--plusGlobal {
      @media (min-width: $breakpoint--medium) {
        height: $micrositeDesktopNavHgt;
      }
      @media (min-width: $breakpoint--xLarge) {
        height: $micrositeDesktopNavHgt;
      }
    }

    &.header--fixed .microsite-nav.microsite-nav--plusGlobal {
      @media (min-width: $breakpoint--medium) {
        height: $micrositeMobileNavHgt;
      }
     
    }
  }

  /* Author Instance Display Fixes */

  .aem-AuthorLayer-Edit #att-about-header {
    min-height: $mobileNavHgt;
    @media (min-width: $breakpoint--desktopNav) {
      min-height: $desktopNavHgt;
    }
  }

  .aem-AuthorLayer-Edit #att-about-header + *,
  .aem-AuthorLayer-Edit #att-about-header + .header--secondary + *,
  .aem-AuthorLayer-Edit #att-microsite-header + * {
    margin-top: 0;
  }

  // .aem-AuthorLayer-Edit #att-about-header.header--transparent + *,
  // .aem-AuthorLayer-Edit #att-about-header.header--transparent + .header--secondary + *,
  // .aem-AuthorLayer-Edit #att-microsite-header.header--transparent + * {
  //   margin-top: 100px;
  // }

  .aem-AuthorLayer-Edit #att-microsite-header.active + *,
  .aem-AuthorLayer-Edit #att-microsite-header.active--wGlobal + * {
    min-height: 100px;
  }

  .aem-AuthorLayer-Edit #att-microsite-header.active + * {
    margin-top: 130px;
  }
  .aem-AuthorLayer-Edit #att-microsite-header.active--wGlobal + * {
    margin-top: 180px;
  }
